import React, { useState, useEffect, useRef } from 'react'
import api from '../../api';
import { useSelector } from 'react-redux';
import { getToken } from '../../redux/slices/applicationSlice';
import { useDebounce } from "../../helpers/useDebounce";
import { useClickOutside } from '../../helpers/useClickOutside';

const NewWordsSelect = ({ change, selected = [] }) => {
    const token = useSelector(getToken);
    const selectRef = useRef();
    const [updatedTimes, setUpdatedTimes] = useState(0);
    const [paginationData, setPaginationData] = useState({});
    const isUpdated = useDebounce(updatedTimes, 1500);
    const [newWordsData, setNewWordsData] = useState([]);
    const [search, setSearch] = useState('');
    const [focused, setFocused] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchData = async() => {
        if(!token) return;

        let queries = 'sortBy=name&sort=asc&isSimilar=true';

        const addQuery = (query, value) => {
            queries += queries.length ? `&${query}=${value}` : `${query}=${value}`;
        }

        if(search?.length) { addQuery('search', search); }

        if (paginationData.page) {
            addQuery('page', paginationData.page)
        }

        setLoading(true);

        await api.newWords.getWords({ token, queries }).then(data => {
            setNewWordsData(data.data);
            setPaginationData(data.pagination);
        })

        setLoading(false);
    }

    useEffect(()=>{
        fetchData();  
    }, [isUpdated, paginationData.page])

    useClickOutside(selectRef, () => setFocused(false));

    return (
        <div className="relative" ref={selectRef}>
            <div className="d-flex items-center flex-1">
                {/* Phytoniche */}
                <div className="relative w-100">
                    { focused && loading ? <div
                        className="flex flex-col justify-center items-center h-full flex-1" 
                        style={{ height: "80px", position: 'absolute', left: '-20px', top: '50%', transform: "translateY(-50%) scale(0.25)", pointerEvents: 'none' }}
                    >
                        <div className="lds-ring"><div /><div /><div /><div /></div>
                    </div> : focused ? <svg
                        width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                        style={{ position: 'absolute', left: '10px', top: '50%', transform: "translateY(-50%)", pointerEvents: 'none' }}
                    >
                        <g opacity="0.3">
                            <path d="M16.6667 16.6662L13.647 13.6465" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M3.33325 9.37467C3.33325 12.7114 6.0382 15.4163 9.37492 15.4163C12.7116 15.4163 15.4166 12.7114 15.4166 9.37467C15.4166 6.03795 12.7116 3.33301 9.37492 3.33301V3.33301C6.0383 3.33325 3.3335 6.03806 3.33325 9.37467" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                    </svg> : null }
                    <input
                        type="search" className="input-custom__input height-40 pointer" placeholder={focused ? "Search new words..." : "Select new words..."}
                        style={{ paddingLeft: focused ? '34px' : '14px' }}
                        value={search} onInput={(e) => { setSearch(e.target.value); setUpdatedTimes((prevState) => prevState + 1) }}
                        onFocus={()=>{ setFocused(true); }}
                    />
                    { search?.length ? 
                        <a 
                            href="#" className="opacity-50 hover:opacity-100 absolute right-[10px] top-[50%]" 
                            style={{ transform: "translateY(-50%)" }}
                            onClick={(e) => { e.preventDefault(); setSearch(""); setUpdatedTimes((prevState) => prevState + 1) }}
                        >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path id="Path" d="M5.33203 5.33301L10.6641 10.666" stroke="#FFFFFF" strokeOpacity="1.000000" strokeWidth="1.500000" strokeLinejoin="round" strokeLinecap="round"/>
                                <path id="Path" d="M10.6641 5.33301L5.33203 10.666" stroke="#FFFFFF" strokeOpacity="1.000000" strokeWidth="1.500000" strokeLinejoin="round" strokeLinecap="round"/>
                            </svg>
                        </a>
                    : null }
                </div>
            </div>
            { focused ? 
                <div className="new-words-dropdown">
                    { newWordsData.length ? <div>
                        {newWordsData.map((el,idx) => <div key={idx}>
                            <a href="#" className={selected.includes(el.word) ? "active" : ""} onClick={(e)=>{
                                e.preventDefault();
                                !selected.includes(el.word) && change(el.word);
                            }}>{ el.word }</a>
                        </div>)}
                    </div> : <div className='my-[30px] text-center text-[18px] font-semibold'>
                        No words found matching your query
                    </div>}
                </div>
            : null }
        </div>
    )
}

export default NewWordsSelect