import React, { forwardRef, useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import classNames from 'classnames';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import 'swiper/css';
import api from '../../api';
import getUserImageSrc from '../../helpers/getUserImageSrc';
import { getToken, getUser } from '../../redux/slices/applicationSlice';
import LikeButton from './LikeButton';
import PrivateButton from './PrivateButton';
import Popup from 'reactjs-popup';
import Share from '../GeneratedContent/NewWord/Share';

const highlightWordInText = (text, wordToHighlight) => {
    const words = text.split(' ')

    let highlighted = false
    const result = words.map((word, index) => {
        if (word.toLowerCase() === wordToHighlight.toLowerCase()) {
            highlighted = true
            return (
                `<span key=${index} class="text-primary font-[500]">
                    ${word}
                </span>`
            );
        } else {
            if (highlighted) {
                highlighted = false
                return `</span>${word}`
            } else {
                return word
            }
        }
    });

    if (highlighted) {
        result.push('</span>')
    }

    const formattedText = result.join(' ')
    return formattedText
}


export const NewWordsBigSwiper = forwardRef(function NewWordsBigSwiper({ data, initialSlide = 0, setOpenDeleteModal, fetchData }, ref) {
    let sliderId = Math.ceil(Math.random() * (9999 - 1) + 1);
    const token = useSelector(getToken);
    const user = useSelector(getUser);
    const [showShareModal, setShowShareModal] = useState(false)
    const [createSelectOpen, setCreateSelectOpen] = useState(false);

    const handleExport = async (id, item) => {
        console.log(item)
        setShowShareModal(item)
        // await api.share.shareKit({
        //     data: {
        //         wordId: id
        //     },
        //     token
        // })
        // await api.assets.exportAsset({
        //     token,
        //     id,
        //     type: 'new-word',
        //     ext: 'json',
        //     filename: `new-word-${id}.json`,
        // });
    }

    return (
        <>
            <div className="relative big-asset-swiper">
                <Swiper
                    ref={ref}
                    // loop={loop !== true ? loop : true}
                    loop={true}
                    spaceBetween={40}
                    // spaceBetween={spaceBetween ? spaceBetween : 50}
                    slidesPerView={1}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                    navigation={{
                        prevEl: `.swiper-arrow-button-prev-${sliderId}`,
                        nextEl: `.swiper-arrow-button-next-${sliderId}`,
                        disabledClass: "swiper-button-disabled"
                    }}
                    modules={[Navigation]}
                    initialSlide={initialSlide}
                // breakpoints={breakpoints}
                >
                    {/* {newWordsData.map((word, index) => 
                <SwiperSlide key={index}>
                    <div className='w-full p-[24px] rounded-[16px] border border-solid border-[#333333]
                    bg-[#1B1B1B] flex flex-col gap-[24px]'>

                    </div>
                </SwiperSlide>
            )} */}
                    {
                        data.map((item, index) => {
                            return <SwiperSlide key={index}>
                                <div className="card-cust w-full">
                                    <div className="card-cust--body p-32px pt-0">
                                        <div className="comment-block">
                                            <div className="comment-footer sm-filter-wrap">
                                                <div className="flex items-center comment-footer-user">
                                                    <div className="user user-36 mr-[12px]">
                                                        <img src={getUserImageSrc(item.creator)} alt='user' />
                                                    </div>
                                                    <div>
                                                        <div className="text-14 comment-footer-user--text opacity-80">
                                                            {item.creator.username || `${item.creator?.first_name} ${item.creator?.last_name}`}
                                                        </div>
                                                        <div className="text-[12px] text-white--03">
                                                            <span className="font-[500] mr-[5px]">Created:</span>
                                                            {format(new Date(item.createdAt), 'MM.dd.yyyy')}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-[8px]">
                                                    {/* <p className="text-white--03 text-14 mr-[16px]">02.03.2023</p> */}
                                                    <LikeButton item={item} />

                                                    {user.id === item.creator.id && (
                                                        <>
                                                            <button
                                                                className="btn btn-outline-black2 r-300 select-36 min-h-[36px] min-w-[36px] h-[36px] w-[36px] d-flex items-center justify-center hover-gradient"
                                                                onClick={() => handleExport(item.id, item)}
                                                            >
                                                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M6.66536 8.83301H4.9987C4.07786 8.83301 3.33203 9.57884 3.33203 10.4997V16.333C3.33203 17.2538 4.07786 17.9997 4.9987 17.9997H14.9987C15.9195 17.9997 16.6654 17.2538 16.6654 16.333V10.4997C16.6654 9.57884 15.9195 8.83301 14.9987 8.83301H13.332" stroke="#A7A7A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M9.9987 3V12.1667" stroke="#A7A7A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M7.5 5.5L9.99917 3L12.4992 5.5" stroke="#A7A7A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                </svg>
                                                            </button>
                                                            <div className={`dropdown change-user-drop  ${createSelectOpen ? 'dropdown-open' : ''}`} data-dropdown="dropdown">
                                                                <button
                                                                    onClick={() => setCreateSelectOpen(!createSelectOpen)}
                                                                    className="btn btn-outline-black2 whitespace-nowrap w-full justify-between btn-md-4 r-300 select-36"
                                                                    style={createSelectOpen ? {
                                                                        borderColor: '#FF8114'
                                                                    } : {}}
                                                                >
                                                                    <span className="info text-14 mr-[10px]">
                                                                        Actions
                                                                    </span>
                                                                    <span className={classNames("icon transition-all", {
                                                                        'rotate-180': createSelectOpen
                                                                    })}>
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M9.37489 12.2917L6.70822 9.64582C6.40267 9.32638 6.33496 8.97916 6.5051 8.60416C6.67524 8.22916 6.97211 8.04166 7.39572 8.04166H12.6666C13.0763 8.04166 13.3662 8.22916 13.5363 8.60416C13.7065 8.97916 13.6388 9.32638 13.3332 9.64582L10.6874 12.2917C10.5971 12.375 10.4947 12.4375 10.3801 12.4792C10.2655 12.5208 10.1457 12.5417 10.0207 12.5417C9.88183 12.5417 9.75857 12.5208 9.65093 12.4792C9.54329 12.4375 9.45128 12.375 9.37489 12.2917Z" fill="currentColor"></path>
                                                                        </svg>
                                                                    </span>
                                                                </button>
                                                                <div
                                                                    className={`dropdown__body dropdown__body-lighten  ${createSelectOpen ? 'is-open' : ''}`} data-role="dropdown"
                                                                    style={{ right: 32, position: "absolute" }}
                                                                >
                                                                    <ul className="default-select-options">
                                                                        <li className="d-block">
                                                                            <Link
                                                                                className="d-block py-[10px] px-[20px]"
                                                                                to={`/generated-page/new-word/${item.id}`}
                                                                            >
                                                                                Edit
                                                                            </Link>
                                                                        </li>
                                                                        <li className="d-block py-[10px] px-[20px]" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setCreateSelectOpen(false);
                                                                        }}>
                                                                            <PrivateButton item={item} fetchData={() => {
                                                                                if (fetchData) {
                                                                                    fetchData();
                                                                                }
                                                                            }} />
                                                                        </li>
                                                                        <li className="d-block">
                                                                            <button
                                                                                className="d-block py-[10px] px-[20px]"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    setCreateSelectOpen(false);
                                                                                    setOpenDeleteModal(item);
                                                                                }}
                                                                            >
                                                                                Delete
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-cust--header p-32px">
                                        <div className="flex flex-col max-w-[600px] mx-auto">
                                            <div className="text-16 text-white--09 capitalize">{item.word_category}</div>
                                            <div className="text-32 text-primary mt-6px">
                                                <span className="font-600">{item.word}</span>
                                                <span className="text-white--03 text-14 ml-[8px] lowercase">({item.part_speech})</span>
                                            </div>
                                            <ul className="ul-points mt-12px">
                                                <li className="ul-points--item">
                                                    <p className="text-16">{item.syllable_division}</p>
                                                </li>
                                                <li className="ul-points--item">
                                                    <p className="text-16">{item.pronunciation}</p>
                                                </li>
                                            </ul>
                                            <ul className="ul-sec mt-12px">
                                                <li className="ul-sec--item text-16 font-light">
                                                    <span className="text-white--03 mr-[6px]">noun:</span>
                                                    <span>{item.noun}</span>
                                                </li>
                                                <li className="ul-sec--item text-16 font-light">
                                                    <span className="text-white--03 mr-[6px]">plural noun:</span>
                                                    <span>{item.plural_noun}</span>
                                                </li>
                                            </ul>
                                            <p className="mt-30px text-16 font-300 text-white">
                                                {item.definition}
                                            </p>
                                            <p className="mt-[12px] text-16 font-300 text-white italic">
                                                Ex. <span dangerouslySetInnerHTML={{ __html: highlightWordInText(item.example, item.word) }}></span>
                                            </p>
                                            <div className="flex items-center mt-30px gap-[8px]">
                                                <span className="text-14 text-white--03 font-300">
                                                    Similar:
                                                </span>
                                                <ul className="flex items-center gap-[6px] overflow-hidden flex-wrap max-h-[25px]">
                                                    {item.similars.map(item => <li className="">
                                                        <a href="#" className="pill-btn px-[9px] text-14">
                                                            <span className="text-14">{item.word}</span>
                                                        </a>
                                                    </li>)}
                                                </ul>
                                                {/* <button href="#" className="pill-btn btn--25 btn p-0">
                                            <span className="ico">
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.9">
                                                    <path d="M6 8L9 11L12 8" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                    </g>
                                                </svg>
                                            </span>
                                        </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        })
                    }

                    {false && <SwiperSlide>
                        <div className="row">
                            <div className="col-lg-9 col-md-9 flex flex-col">
                                <div className="card-cust w-full h-full">
                                    <div className="card-cust--header p-32px">
                                        <div className="flex flex-col">
                                            <div className="text-16 text-white--09">Emotions</div>
                                            <div className="text-32 text-primary mt-6px">
                                                <span className="font-600">Serendipity</span>
                                                <span className="text-white--03 text-14">(noun)</span>
                                            </div>
                                            <ul className="ul-points mt-12px">
                                                <li className="ul-points--item">
                                                    <p className="text-16">ser·​en·​dip·​i·​ty</p>
                                                </li>
                                                <li className="ul-points--item">
                                                    <p className="text-16">/ˌser.ənˈdɪp.ə.ti/</p>
                                                </li>
                                            </ul>
                                            <ul className="ul-sec mt-12px">
                                                <li className="ul-sec--item text-16">
                                                    <span className="text-white--03">noun:</span>
                                                    <span>serendipity</span>
                                                </li>
                                                <li className="ul-sec--item text-16">
                                                    <span className="text-white--03">plural noun:</span>
                                                    <span>serendipities</span>
                                                </li>
                                            </ul>
                                            <p className="mt-30px text-16 font-300 text-white">
                                                The occurrence of finding valuable
                                                things by chance in a happy way.
                                            </p>
                                            <div className="similar-box flex items-center mt-30px">
                                                <span className="text-14 text-white--03 mr-[8px] mb-[8px]">
                                                    Similar:
                                                </span>
                                                <ul className="editor-pills mb-0">
                                                    <li className="mb-[8px]">
                                                        <a href="#" className="pill-btn">
                                                            <span className="text-14">chance</span>
                                                        </a>
                                                    </li>
                                                    <li className="mb-[8px]">
                                                        <a href="#" className="pill-btn text-14">
                                                            <span className="text-14">happy chance</span>
                                                        </a>
                                                    </li>
                                                    <li className="mb-[8px]">
                                                        <a href="#" className="pill-btn text-14">
                                                            <span className="text-14">accident</span>
                                                        </a>
                                                    </li>
                                                    <li className="mb-[8px]">
                                                        <a href="#" className="pill-btn text-14">
                                                            <span className="text-14">happy accident</span>
                                                        </a>
                                                    </li>
                                                    <li className="mb-[8px]">
                                                        <a href="#" className="pill-btn text-14">
                                                            <span className="text-14">fluke</span>
                                                        </a>
                                                    </li>
                                                    <li className="mb-[8px]">
                                                        <a href="#" className="pill-btn text-14">
                                                            <span className="text-14">luck</span>
                                                        </a>
                                                    </li>
                                                    <li className="mb-[8px]">
                                                        <a href="#" className="pill-btn btn--25 btn p-0">
                                                            <span className="ico">
                                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g opacity="0.9">
                                                                        <path d="M6 8L9 11L12 8" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-cust--body p-32px pt-0">
                                        <div className="comment-block">
                                            <div className="comment-footer">
                                                <div className="flex items-center comment-footer-user">
                                                    <div className="user user-36 mr-[12px]">
                                                        <img src={require("../../assets/img/user-image.png")} />
                                                    </div>
                                                    <div className="text-14 comment-footer-user--text">
                                                        User123
                                                    </div>
                                                </div>
                                                <div className="flex items-center">
                                                    <p className="text-white--03 text-14 mr-[16px]">02.03.2023</p>
                                                    <button className="btn btn-outline-black2 whitespace-nowrap w-full justify-between btn-md-4 r-300 select-36">
                                                        <span className="info text-14 mr-[10px]">
                                                            Actions
                                                        </span>
                                                        <span className="icon">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M9.37489 12.2917L6.70822 9.64582C6.40267 9.32638 6.33496 8.97916 6.5051 8.60416C6.67524 8.22916 6.97211 8.04166 7.39572 8.04166H12.6666C13.0763 8.04166 13.3662 8.22916 13.5363 8.60416C13.7065 8.97916 13.6388 9.32638 13.3332 9.64582L10.6874 12.2917C10.5971 12.375 10.4947 12.4375 10.3801 12.4792C10.2655 12.5208 10.1457 12.5417 10.0207 12.5417C9.88183 12.5417 9.75857 12.5208 9.65093 12.4792C9.54329 12.4375 9.45128 12.375 9.37489 12.2917Z" fill="currentColor"></path>
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-3 col-md-3 flex flex-col">
                                <div className="card-cust w-full h-full card-16">
                                    <div className="card-cust--header">
                                        <p className="text-14 text-white--03">Example Sentences</p>
                                    </div>
                                    <div className="card-cust--body mt-[16px]">
                                        <ul className="custom-counter--list">
                                            <li className="text-16 text-white--09 italic custom-counter--item">
                                                <p>
                                                    Her journey was full of <span className="text-primary font-[500]">serendipity</span>,
                                                    leading to unexpected opportunities.
                                                </p>
                                            </li>
                                            <li className="text-16 text-white--09 italic custom-counter--item">
                                                <p>
                                                    Their <span className="text-primary font-[500]">serendipitous</span> meeting became a lasting bond.
                                                </p>
                                            </li>
                                            <li className="text-16 text-white--09 italic custom-counter--item">
                                                <p>
                                                    That a Yank would come along decades later to
                                                    buy the house felt like <span className="text-primary font-[500]">serendipity</span> to the family.
                                                </p>
                                            </li>
                                            <li className="text-16 text-white--09 italic custom-counter--item">
                                                <p>
                                                    They're machines for creativity, collaboration, and <span className="text-primary font-[500]">serendipity</span>.
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>}
                </Swiper>

                <a href="#" className={`swiper-arrow-button swiper-arrow-button-prev-${sliderId}`}>
                    <svg width="19" height="36" viewBox="0 0 19 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.4">
                            <path d="M17.4154 2L1.58203 18L17.4154 34" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                    </svg>
                </a>
                <a href="#" className={`swiper-arrow-button swiper-arrow-button-next swiper-arrow-button-next-${sliderId}`}>
                    <svg width="19" height="36" viewBox="0 0 19 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.4">
                            <path d="M1.58463 34L17.418 18L1.58464 2" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                    </svg>
                </a>
            </div>
            <Popup
                open={!!showShareModal}
                onClose={() => setShowShareModal(false)}
                overlayStyle={{
                    background: 'rgba(0,0,0,0.5)',
                    overflowY: 'auto'
                }}
            >
                <Share setOpenedModal={() => setShowShareModal(false)} idFromProps={showShareModal?.id} data={showShareModal} />
            </Popup>
        </>
    )
});
