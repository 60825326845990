import { useEffect, useState, useRef } from "react"

export const TextBigContainer = ({data, title}) => {

    const defaultHeight = 55
    const [height, setHeight] = useState(null)
    const [open, setOpen] = useState(false)
    const ref = useRef(null)

    const [isAdd, setIsAdd] = useState(false)

    console.log(height, 'height')

    useEffect(() => {
        ref.current.style.height = 'auto'
        ref.current.style['-webkit-line-clamp'] = 'unset'
        setHeight(ref.current.clientHeight)
        ref.current.style['-webkit-line-clamp'] = '3'
        ref.current.style.height = null
    }, [])

    useEffect(() => {
        const closestAccordionOpen = ref.current.closest(".accordion-open")
        if (!closestAccordionOpen) return
        const accordionHeight = closestAccordionOpen.style.height

        if (open) {
            closestAccordionOpen.style.height = (parseInt(accordionHeight) + height - defaultHeight) + 'px'

        } else {
            closestAccordionOpen.style.height = (parseInt(accordionHeight) - height + defaultHeight) + 'px'
        }
        
    }, [open])

    const styleHeight = open ? height + 'px' : null

    return (
        <li className={`btc__block-item-big ${open && 'open'} ${isAdd && 'added'}`}>
            {title && <p className='btc__block-item-big-title'>{title}</p>}
            <p ref={ref} style={{height: styleHeight}} className='btc__block-item-big-paragraph'>{data}</p>

            <div className='btc__block-item-big-actions-container'>
                <div className='btc__block-item-big-words'>
                    157 words
                </div>
                <div className='btc__block-item-big-actions'>
                    {height > defaultHeight && <> <button onClick={() => setOpen(!open)}>
                        <span>{!open ? "Show More" : "Show Less"}</span>
                    </button>
                    <div className='btc__block-item-big-actions-divider'></div> </>}
                    <button onClick={() => setIsAdd(true)}>
                        {isAdd ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <g clipPath="url(#clip0_451_2114)">
                                    <path d="M6.98242 9.11666L5.66576 7.78333C5.52353 7.64999 5.34847 7.58333 5.14057 7.58333C4.93267 7.58333 4.75218 7.65277 4.59909 7.79166C4.45464 7.94166 4.38242 8.12222 4.38242 8.33333C4.38242 8.54444 4.4602 8.7111 4.61576 8.83333L6.44909 10.6833C6.58546 10.8389 6.76121 10.9167 6.97636 10.9167C7.19151 10.9167 7.37687 10.8389 7.53242 10.6833L11.3158 6.89999C11.4713 6.75777 11.5491 6.58549 11.5491 6.38314C11.5491 6.1808 11.4722 5.99753 11.3184 5.83333C11.1647 5.69999 10.9807 5.6361 10.7666 5.64166C10.5525 5.64722 10.3744 5.71666 10.2324 5.84999L6.98242 9.11666ZM8.00139 15.0167C7.03719 15.0167 6.12549 14.8355 5.26631 14.4733C4.40711 14.111 3.65946 13.6118 3.02336 12.9757C2.38726 12.3396 1.88808 11.5927 1.52582 10.7351C1.16356 9.87742 0.982422 8.96649 0.982422 8.00229C0.982422 7.03525 1.16388 6.12365 1.52679 5.26751C1.88971 4.41137 2.38981 3.66418 3.02707 3.02594C3.66435 2.3877 4.41076 1.88492 5.26631 1.51761C6.12186 1.15031 7.03156 0.96666 7.99541 0.96666C8.96326 0.96666 9.87613 1.14979 10.734 1.51604C11.5919 1.8823 12.3394 2.38373 12.9767 3.02033C13.6139 3.65693 14.1158 4.40387 14.4825 5.26116C14.8491 6.11846 15.0324 7.03187 15.0324 8.00138C15.0324 8.97019 14.8488 9.88132 14.4815 10.7348C14.1142 11.5882 13.6114 12.3337 12.9731 12.9714C12.3349 13.6091 11.5885 14.1094 10.7339 14.4723C9.87926 14.8352 8.96843 15.0167 8.00139 15.0167Z" fill="#331A04"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_451_2114">
                                        <rect width="16" height="16" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M8.99672 14.5312C8.76609 14.5312 8.56836 14.4523 8.40352 14.2945C8.23868 14.1367 8.15625 13.9281 8.15625 13.6687V9.86246H4.3125C4.08281 9.86246 3.88476 9.77771 3.71835 9.60821C3.55195 9.43872 3.46875 9.23685 3.46875 9.00259C3.46875 8.75084 3.55195 8.54684 3.71835 8.39059C3.88476 8.23434 4.08281 8.15621 4.3125 8.15621H8.15625V4.31246C8.15625 4.06527 8.23974 3.86285 8.40671 3.70519C8.57369 3.54754 8.77244 3.46871 9.00296 3.46871C9.23349 3.46871 9.43437 3.54754 9.60563 3.70519C9.77688 3.86285 9.8625 4.06527 9.8625 4.31246V8.15621H13.6875C13.9225 8.15621 14.125 8.23543 14.295 8.39387C14.465 8.55232 14.55 8.75429 14.55 8.99979C14.55 9.24531 14.465 9.45046 14.295 9.61526C14.125 9.78006 13.9225 9.86246 13.6875 9.86246H9.8625V13.6687C9.8625 13.9281 9.77719 14.1367 9.60656 14.2945C9.43593 14.4523 9.23264 14.5312 8.99672 14.5312Z" fill="white" fill-opacity="0.5"/>
                            </svg>
                        }
                        <span>{isAdd ? 'Added' : 'Add'}</span>
                    </button>
                </div>
            </div>



            {/*<div className='btc__block-item-big-actions'>*/}
            {/*    {height > defaultHeight && <button onClick={() => setOpen(!open)}>*/}
            {/*        <span>{!open ? "Show More" : "Show Less"}</span>*/}
            {/*        <svg className="btc__block-item-big-arrow" xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">*/}
            {/*            <path d="M4.02348 5.14466C3.92472 5.14466 3.82839 5.12497 3.7345 5.08558C3.64061 5.04618 3.56533 4.98809 3.50867 4.91133L0.192 1.62799C0.0586667 1.48077 -0.00522222 1.3023 0.000333333 1.09258C0.00588889 0.882856 0.0753334 0.702995 0.208667 0.552995C0.375333 0.391884 0.558667 0.319661 0.758667 0.336328C0.958667 0.352995 1.13089 0.427995 1.27533 0.561328L4.02533 3.31133L6.792 0.561328C6.93089 0.416884 7.10728 0.341884 7.32117 0.336328C7.53506 0.330773 7.71422 0.408156 7.85867 0.568478C8.01422 0.717678 8.08645 0.898234 8.07533 1.11015C8.06422 1.32205 7.98645 1.49466 7.842 1.62799L4.55867 4.91133C4.49015 4.98809 4.40851 5.04618 4.31377 5.08558C4.21901 5.12497 4.12225 5.14466 4.02348 5.14466Z" fill="white" fillOpacity="0.5"/>*/}
            {/*        </svg>*/}
            {/*    </button>}*/}
            {/*    <button>*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">*/}
            {/*            <path d="M4.99676 9.91732C4.79176 9.91732 4.616 9.84718 4.46947 9.7069C4.32294 9.56662 4.24967 9.38121 4.24967 9.15065V5.76732H0.833008C0.628841 5.76732 0.452797 5.69198 0.304874 5.54132C0.156963 5.39066 0.0830078 5.21122 0.0830078 5.00299C0.0830078 4.77921 0.156963 4.59787 0.304874 4.45898C0.452797 4.3201 0.628841 4.25065 0.833008 4.25065H4.24967V0.833984C4.24967 0.614262 4.32389 0.434329 4.47231 0.294184C4.62073 0.154051 4.7974 0.0839844 5.00231 0.0839844C5.20722 0.0839844 5.38579 0.154051 5.53801 0.294184C5.69023 0.434329 5.76634 0.614262 5.76634 0.833984V4.25065H9.16634C9.37523 4.25065 9.55523 4.32107 9.70634 4.4619C9.85745 4.60275 9.93301 4.78228 9.93301 5.0005C9.93301 5.21873 9.85745 5.4011 9.70634 5.54759C9.55523 5.69407 9.37523 5.76732 9.16634 5.76732H5.76634V9.15065C5.76634 9.38121 5.69051 9.56662 5.53884 9.7069C5.38716 9.84718 5.20647 9.91732 4.99676 9.91732Z" fill="white" fillOpacity="0.5"/>*/}
            {/*        </svg>*/}
            {/*        <span>Add</span>*/}
            {/*    </button>*/}
            {/*</div>*/}
        </li>
    )
}