import React from 'react';

const MyAssetsModal = ({close}) => {
    return (
        <div className='modal__wrap' onClick={close}>
            <div className='modal-content' onClick={(e) => e.stopPropagation()}>
                <div className="modal-top">
                    <div className="close-modal-block pointer" onClick={close}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g opacity="0.5">
                                <path d="M15 5L5 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M5 5L15 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                        </svg>
                    </div>
                </div>

                <div className="modal-body">
                    <div style={{width: '100%', marginTop: '-25px'}} className='flex flex-col'>
                        <span className='modal-body-title' >Add Brand Voice</span>
                        <span className='modal-body-subtitle' >Please write description of a brand tone and brand values</span>

                        <div className='modal-component' >
                            <label htmlFor='textarea' className='modal-component-label' >Brand Tone</label>
                            <textarea id='textarea' className="textarea" rows={10}
                            placeholder='Your brand tone description is here...'></textarea>
                        </div>

                        <div className='modal-component drag-area' >
                            <label htmlFor='drop' className='modal-component-label' >Upload Files</label>
                            <input type="file" id="drop"  hidden multiple />
                            <div className='drop-file-area'>
                                <span>Drag & Drop files here</span>
                            </div>
                        </div>

                        <div className='modal-actions'>
                            <button
                                href="#" className="btn btn--primary btn--lg d-flex align-items-center justify-between px-[10px] py-[8px] modal-primary-btn"

                            >
                                Add Brand Voice
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default MyAssetsModal;