import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Accordion } from './Accordion';

const HomeBlock = ({ blockId, index, data, changeOnOpenBlock, searchable }) => {
    
    const [openBlock, setOpenBlock] = useState(true)

    const providedData = (provided) => {return (searchable ? {} : provided.dragHandleProps)}

    return (
        <Draggable draggableId={blockId} index={index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className='blog-toolkit-content__block'
                >

                    <div className="btc__block-head">
                        <div className="btc__block-head-title">
                            {data.icon}
                            <span>{data.title}</span>
                        </div>

                    </div>

                    <Accordion openBlock={openBlock}>
                        <>
                        <ul className={`btc__block-content toolkit-block-wrapper ${data.variant||''}`}>
                        {data?.content?.map((item, idx) => <li key={idx} className={`btc__block-item ${data?.variant==='grey' ? 'grey' : null}`}>
                                <span className="btc__item-text">{item}</span>
                                {data?.variant==='elements-2' && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.99578 12.9166C7.79078 12.9166 7.61502 12.8465 7.4685 12.7062C7.32196 12.566 7.2487 12.3805 7.2487 12.15V8.76665H3.83203C3.62786 8.76665 3.45182 8.69131 3.3039 8.54065C3.15599 8.38999 3.08203 8.21055 3.08203 8.00231C3.08203 7.77854 3.15599 7.5972 3.3039 7.45831C3.45182 7.31942 3.62786 7.24998 3.83203 7.24998H7.2487V3.83331C7.2487 3.61359 7.32291 3.43366 7.47133 3.29351C7.61975 3.15338 7.79642 3.08331 8.00133 3.08331C8.20624 3.08331 8.38481 3.15338 8.53703 3.29351C8.68925 3.43366 8.76536 3.61359 8.76536 3.83331V7.24998H12.1654C12.3743 7.24998 12.5543 7.3204 12.7054 7.46123C12.8565 7.60207 12.932 7.78161 12.932 7.99983C12.932 8.21806 12.8565 8.40042 12.7054 8.54691C12.5543 8.6934 12.3743 8.76665 12.1654 8.76665H8.76536V12.15C8.76536 12.3805 8.68953 12.566 8.53786 12.7062C8.38619 12.8465 8.20549 12.9166 7.99578 12.9166Z" fill="white" fillOpacity="0.5"/>
                                </svg> }
                               
                            </li>)}
                        </ul>

                        <div {...(providedData(provided))} className={`${searchable ? 'not-allowed' : ''}`} title={searchable ? "undraggable in search" : null}>
                            <div className="btc__block-load disable-fill">
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="14" viewBox="0 0 48 14" fill="none">
                                    <g opacity="0.4">
                                        <path d="M18 4.66669L24 9.66669L30 4.66669" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        </>
                    </Accordion>
                </div>
            )}
        </Draggable>
    )
}

export default HomeBlock