export const newWordsData = [
    {
        id: 1,
        category: 'Emotions',
        noun: 'Serendipity',
        transcription: 'ser·​en·​dip·​i·​ty | /ˌser.ənˈdɪp.ə.ti/',
        desc: 'The occurrence of finding valuable things by chance in a happy way.',
        footerDesc: {
            text: 'Ex. Her journey was full of serendipity , leading to unexpected opportunities.',
            highlight: 'serendipity'
        },
        user: {
            avatar: require("../../assets/img/user-image.png"),
            name: 'User123'
        }

    },
    {
        id: 2,
        category: 'Emotions',
        noun: 'Euphoria',
        transcription: 'eu·​pho·​ria | /juˈfɔriə/',
        desc: 'A feeling of intense happiness and excitement.',
        footerDesc: {
            text: 'Ex. Winning the championship filled her with euphoria .',
            highlight: 'euphoria'
        },
        user: {
            avatar: require("../../assets/img/user-image.png"),
            name: 'Akash2411'
        }

    },
    {
        id: 3,
        category: 'Nature',
        noun: 'Solstice',
        transcription: 'sol·​stice | /ˈsɒl.stɪs/',
        desc: 'An astronomical event marking the longest or shortest day of the year.',
        footerDesc: {
            text: 'Ex. The summer solstice brings longer days and warmth.',
            highlight: 'solstice'
        },
        user: {
            avatar: require("../../assets/img/user-image.png"),
            name: 'Sandeeps'
        }

    },
    {
        id: 4,
        category: 'Technology',
        noun: 'Cyberspace',
        transcription: 'cy·​ber·​space | /ˈsaɪ.bə.speɪs/',
        desc: 'The interconnected space of digital communication and data.',
        footerDesc: {
            text: 'Ex. The concept of privacy is complex in the age of cyberspace .',
            highlight: 'cyberspace'
        },
        user: {
            avatar: require("../../assets/img/user-image.png"),
            name: 'RakshaSallan'
        }

    },
    {
        id: 5,
        category: 'Art',
        noun: 'Palette',
        transcription: 'pal·​ette | /ˈpæl.ət/',
        desc: 'A range of colors used by an artist in their work.',
        footerDesc: {
            text: 'Ex. The painter carefully selected colors from her palette .',
            highlight: 'palette'
        },
        user: {
            avatar: require("../../assets/img/user-image.png"),
            name: 'CodeWithSomya'
        }

    },
    {
        id: 6,
        category: 'Connections',
        noun: 'Kinship',
        transcription: 'kin·​ship | /ˈkɪn.ʃɪp/',
        desc: 'A close connection or relationship between people or groups.',
        footerDesc: {
            text: 'Ex. Their bond went beyond friendship, it was kinship .',
            highlight: 'kinship'
        },
        user: {
            avatar: require("../../assets/img/user-image.png"),
            name: 'User123'
        }

    },
    {
        id: 7,
        category: 'Technology',
        noun: 'TechnoGlimp',
        transcription: 'tech-no-glimp | /ˈtɛk.noʊ.ɡlɪmp/',
        desc: 'A tech-inspired insight in the fast-evolving digital world.',
        footerDesc: {
            text: 'Ex. His "TechnoGlimp" led to a groundbreaking mobile app.',
            highlight: 'TechnoGlimp'
        },
        user: {
            avatar: require("../../assets/img/user-image.png"),
            name: '山本 真綾'
        }
    },
    {
        id: 8,
        category: 'Environment',
        noun: 'EcoHarmonia',
        transcription: 'eco-har-mo-ni-a | /ˌɛkoʊ.hɑrˈmoʊ.ni.ə/',
        desc: "Balancing human life with nature's harmony and coexistence.",
        footerDesc: {
            text: 'Ex. They embrace "EcoHarmonia" in their daily actions.',
            highlight: 'EcoHarmonia'
        },
        user: {
            avatar: require("../../assets/img/user-image.png"),
            name: 'Divya Singh'
        }
    },
    {
        id: 9,
        category: 'Cuisine',
        noun: 'FlavorFusiona',
        transcription: 'fla-vor-fu-sion-a | /ˈfleɪ.vərˌfju.ʒən.ə/',
        desc: "A delightful blend of diverse culinary influences and flavors.",
        footerDesc: {
            text: 'Ex. The "FlavorFusiona" dish delighted food enthusiasts.',
            highlight: 'FlavorFusiona'
        },
        user: {
            avatar: require("../../assets/img/user-image.png"),
            name: 'Dasa Gandi Rajata '
        }
    },
    {
        id: 10,
        category: 'Adventure',
        noun: 'ExploraQuest',
        transcription: 'ex-plor-a-quest | /ɪkˈsplɔrəˌkwɛst/',
        desc: "An exciting journey filled with exploration, discovery, and adventure.",
        footerDesc: {
            text: 'Ex. Their "ExploraQuest" unearthed ancient mysteries.',
            highlight: 'ExploraQuest'
        },
        user: {
            avatar: require("../../assets/img/user-image.png"),
            name: 'Matías Rodríguez'
        }
    },
    {
        id: 11,
        category: 'Wellness',
        noun: 'Zenovitality',
        transcription: 'ze-no-vi-tal-i-ty | /ˌzɛnoʊ.vɪˈtæl.ɪ.ti/',
        desc: "Attaining holistic well-being through mind-body harmony.",
        footerDesc: {
            text: 'Attaining holistic well-being through mind-body harmony.',
            highlight: 'Zenovitality'
        },
        user: {
            avatar: require("../../assets/img/user-image.png"),
            name: 'Amira Bashar'
        }
    },
    {
        id: 12,
        category: 'Art',
        noun: 'PaletteWhirl',
        transcription: 'Pal-ette-Whirl | /ˈpælɪtˌwɜrl/',
        desc: "A canvas brought to life by dynamic",
        footerDesc: {
            text: 'Ex. The "PaletteWhirl" painting mesmerized art enthusiasts at the gallery.',
            highlight: 'PaletteWhirl'
        },
        user: {
            avatar: require("../../assets/img/user-image.png"),
            name: 'Andrei Jensen'
        }
    },
    {
        id: 13,
        category: 'Fashion',
        noun: 'ChicQuotient',
        transcription: 'Chic-Quo-ti-ent | /ʃiːkˈkwoʊ.ʃənt/',
        desc: "A measure of elegance and sophistication in personal style.",
        footerDesc: {
            text: 'Ex. Her outfit radiated a high "ChicQuotient" at the gala.',
            highlight: 'ChicQuotient'
        },
        user: {
            avatar: require("../../assets/img/user-image.png"),
            name: 'Sofía Gómez'
        }
    },
    {
        id: 14,
        category: 'Literature',
        noun: 'Novelverse',
        transcription: 'Nov-el-verse | /ˈnɒvəlˌvɜrs/',
        desc: "An imaginary world created within the pages of a novel.",
        footerDesc: {
            text: 'Ex. J.K. Rowling\'s "Harry Potter" series introduced a captivating "Novelverse."',
            highlight: "Novelverse."
        },
        user: {
            avatar: require("../../assets/img/user-image.png"),
            name: 'Lucía Romero'
        }
    },
    {
        id: 15,
        category: 'Music',
        noun: 'HarmoniGroove',
        transcription: 'Har-mon-i-Groove | /ˌhɑrˈmɒnɪˌɡruːv/',
        desc: "A rhythmic musical blend that captivates and resonates deeply.",
        footerDesc: {
            text: 'Ex. Their "HarmoniGroove" performance had the entire crowd dancing.',
            highlight: "HarmoniGroove"
        },
        user: {
            avatar: require("../../assets/img/user-image.png"),
            name: '桂佳'
        }
    },
]