import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import api from '../../api';
import { getUserThunk } from '../../redux/thunks/applicationThunks';
import { setToken } from '../../redux/slices/applicationSlice';
import { useLocation } from 'react-router-dom';

const CheckEmailCode = ({email, setIsFirstStage}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    console.log(state);
    const [codeValues, setCodeValues] = useState(["", "", "", "", "", ""]);
    const [codeError, setCodeError] = useState('');
    const [loading, setLoading] = useState(false);
    const handleLogin = async (e) => {
        e.preventDefault();
        setCodeError('');
        if(codeValues.some((code) => code === '')) {
            setCodeError('Enter valid 6-digit code')
            return;
        }
        try {
            setLoading(true);
            const response = await api.auth.loginWithCode({
                email,
                code: codeValues.join('')
            });
            if(response.success) {
                setLoading(false);
                localStorage.setItem('authToken', response.token);
                dispatch(setToken(response.token));
                dispatch(getUserThunk({token: response.token}))
                navigate(state?.sourse || '/');
            }
        } catch (e) {
            setLoading(false);
            setCodeError(e.response.data.error);
        }
    }
    return <form className="auth-form" onSubmit={handleLogin} noValidate>
        <h3 className="mb-[8px]">Check your email <br /> for a code</h3>
        <div className="auth-dont-have mb-[48px]">
            <p>We’ve sent a 6-digit code to <b><a href={`mailto:${email}`}>{email}</a></b>. <br /> The code expires shortly, so please enter it soon.</p>
        </div>
        <div className="input-custom mb-[14px]">
            <label className="input-custom__label mb-[10px]">Code</label>
                <div className="input-custom__wrap-input code-input-wrpr">
                    {
                        codeValues.map((item, index)=>{
                            return <input 
                                className={`input-custom__input input-custom__input-${index}`} 
                                type="text"
                                inputMode='numeric'
                                style={{borderColor: item.length ? '#EA8207' : 'rgba(255, 255, 255, 0.08)'}}
                                value={item} key={ index }
                                onInput={({target: {value}})=>{
                                    if(!/^\d+$/.test(value) && value !== '') return;
                                    setCodeValues((prevState)=>{
                                        if(value.length === 6) {
                                            return value.split('');
                                        }
                                        let copied = [...prevState];
                                        if(value.length < 2){
                                            copied[index] = value;
                                        }
                                        if(value.length && index !== 5){
                                            document.querySelector(`.input-custom__input-${index+1}`).focus();
                                        }
                                        return copied;
                                    })
                                }}
                                onKeyDown={(e)=>{
                                    console.log(e);
                                    if(e.keyCode === 8 && !e.target.value.length && index !== 0){
                                        console.log('ds')
                                        document.querySelector(`.input-custom__input-${index-1}`).focus();
                                    }
                                }}
                            />
                        })
                    }
                </div> 
            <p className='h-[14px] text-[12px] text-red-500 mt-[10px]'>{codeError}</p>
        </div>
        <div className="info-gray-box mb-[24px]">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0733 17.225C12.3474 17.225 12.5787 17.1292 12.7673 16.9378C12.9558 16.7463 13.0501 16.5087 13.0501 16.225V11.975C13.0501 11.7075 12.9555 11.4781 12.7662 11.2868C12.5769 11.0956 12.3477 11 12.0786 11C11.8095 11 11.575 11.0956 11.375 11.2868C11.1751 11.4781 11.0751 11.7075 11.0751 11.975V16.225C11.0751 16.5087 11.1729 16.7463 11.3686 16.9378C11.5644 17.1292 11.7993 17.225 12.0733 17.225ZM11.9996 9.57495C12.3416 9.57495 12.623 9.46745 12.8438 9.25245C13.0647 9.03747 13.1751 8.76309 13.1751 8.42933C13.1751 8.04391 13.0652 7.74683 12.8455 7.53808C12.6258 7.32933 12.3456 7.22495 12.0049 7.22495C11.6267 7.22495 11.3355 7.32787 11.1313 7.5337C10.9272 7.73952 10.8251 8.02681 10.8251 8.39558C10.8251 8.75016 10.9354 9.03537 11.1559 9.2512C11.3764 9.46703 11.6577 9.57495 11.9996 9.57495ZM11.9987 22.525C10.5478 22.525 9.17996 22.2533 7.89507 21.7099C6.61017 21.1665 5.49065 20.4177 4.5365 19.4636C3.58235 18.5094 2.83358 17.3893 2.2902 16.1033C1.7468 14.8173 1.4751 13.4465 1.4751 11.9909C1.4751 10.5371 1.74728 9.17098 2.29165 7.89255C2.83603 6.61412 3.58772 5.49534 4.54672 4.53623C5.50572 3.57711 6.62449 2.82295 7.90302 2.27375C9.18156 1.72455 10.5459 1.44995 11.996 1.44995C13.4521 1.44995 14.8233 1.72377 16.1096 2.2714C17.3958 2.81903 18.5159 3.57118 19.4696 4.52783C20.4234 5.48448 21.1752 6.60543 21.7252 7.89068C22.2751 9.17591 22.5501 10.548 22.5501 12.0069C22.5501 13.4647 22.2755 14.8303 21.7263 16.1039C21.1771 17.3775 20.4229 18.4923 19.4638 19.4484C18.5047 20.4045 17.3845 21.1563 16.1033 21.7038C14.8221 22.2512 13.4539 22.525 11.9987 22.525Z" fill="#A7A7A7"></path>
            </svg>
            <p>Can’t find your code? Check your spam folder!</p>
        </div>
        <button 
            className="btn btn-primary-10 btn-xl rounded-[8px] mb-[48px] w-100 submit-btn"
            type='submit'
            disabled={loading}
        >
            {loading ? <div className="d-flex justify-center align-items-center">
                <svg
                    xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 38 38" stroke="#fff"
                    style={{ height: '20px' }}
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(1 1)" strokeWidth="2">
                            <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/>
                            </path>
                        </g>
                    </g>
                </svg>
            </div> : 'Continue'}
        </button>
        <button onClick={()=> setIsFirstStage(true)} className="back-to-link">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.1665 9.99998H15.8332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.33317 5.83331L4.1665 9.99998" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.33317 14.1667L4.1665 10" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            Back to Login
        </button>
    </form>
};

export default CheckEmailCode;