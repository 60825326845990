import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import SelectBox from "../../components/base/SelectBox";

const CreateNewProject = () => {
    return (
        <section className="page-section pb-0">
            <div className="container">
                <div className="row-heading row-heading--lighten-60 rounded-[16px] px-[20px] mb-[20px]">
                    <div className="heading-item py-[10px]">
                        <div className="title-24 font-[800]">
                            Create a New Project
                        </div>
                    </div>
                </div>
                <div className="row g-20 max-w-[900px]">
                    <div className="col-lg-4">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">Project Name</label>
                            <div className="input-custom__wrap-input">
                                <input className="input-custom__input" type="text" placeholder="Project Name" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">Project Type</label>
                            <div className="select-wrapp">
                                <SelectBox
                                    className="default-select searchable flex-1"
                                    propsOptions={[
                                        {label: '', value: '', placeholder: true},
                                        {label: 'Project Type 1', value: 'Project Type 1'},
                                        {label: 'Project Type 2', value: 'Project Type 2'},
                                        {label: 'Project Type 3', value: 'Project Type 3'},
                                        {label: 'Project Type 4', value: 'Project Type 4'},
                                    ]}
                                    isSearchable={true}
                                    placeholder="Project Type"
                                    // value={modelData.target_audience}
                                    // change={(value)=>{
                                    //     setModelData((prevState)=>{ return {...prevState, target_audience: value } });
                                    // }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">Target Audience</label>
                            <div className="select-wrapp">
                                <SelectBox
                                    className="default-select searchable flex-1"
                                    propsOptions={[
                                        {label: '', value: '', placeholder: true},
                                        {label: 'Target Audience 1', value: 'Target Audience 1'},
                                        {label: 'Target Audience 2', value: 'Target Audience 2'},
                                        {label: 'Target Audience 3', value: 'Target Audience 3'},
                                        {label: 'Target Audience 4', value: 'Target Audience 4'},
                                    ]}
                                    isSearchable={true}
                                    placeholder="Target Audience"
                                    // value={modelData.target_audience}
                                    // change={(value)=>{
                                    //     setModelData((prevState)=>{ return {...prevState, target_audience: value } });
                                    // }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">Branding</label>
                            <div className="input-custom__wrap-input">
                                <input className="input-custom__input" type="text" placeholder="Select Branding" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">Design Style</label>
                            <div className="select-wrapp">
                                <SelectBox
                                    className="default-select searchable flex-1"
                                    propsOptions={[
                                        {label: '', value: '', placeholder: true},
                                        {label: 'Design Style 1', value: 'Design Style 1'},
                                        {label: 'Design Style 2', value: 'Design Style 2'},
                                        {label: 'Design Style 3', value: 'Design Style 3'},
                                        {label: 'Design Style 4', value: 'Design Style 4'},
                                    ]}
                                    isSearchable={true}
                                    placeholder="Select Design Style"
                                    // value={modelData.target_audience}
                                    // change={(value)=>{
                                    //     setModelData((prevState)=>{ return {...prevState, target_audience: value } });
                                    // }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">Copy Style </label>
                            <div className="select-wrapp">
                                <SelectBox
                                    className="default-select searchable flex-1"
                                    propsOptions={[
                                        {label: '', value: '', placeholder: true},
                                        {label: 'Copy Style 1', value: 'Copy Style 1'},
                                        {label: 'Copy Style 2', value: 'Copy Style 2'},
                                        {label: 'Copy Style 3', value: 'Copy Style 3'},
                                        {label: 'Copy Style 4', value: 'Copy Style 4'},
                                    ]}
                                    isSearchable={true}
                                    placeholder="Select Copy Style"
                                    // value={modelData.target_audience}
                                    // change={(value)=>{
                                    //     setModelData((prevState)=>{ return {...prevState, target_audience: value } });
                                    // }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">Content Theme</label>
                            <div className="select-wrapp">
                                <SelectBox
                                    className="default-select searchable flex-1"
                                    propsOptions={[
                                        {label: '', value: '', placeholder: true},
                                        {label: 'Content Theme 1', value: 'Content Theme 1'},
                                        {label: 'Content Theme 2', value: 'Content Theme 2'},
                                        {label: 'Content Theme 3', value: 'Content Theme 3'},
                                        {label: 'Content Theme 4', value: 'Content Theme 4'},
                                    ]}
                                    isSearchable={true}
                                    placeholder="Select Content Theme"
                                    // value={modelData.target_audience}
                                    // change={(value)=>{
                                    //     setModelData((prevState)=>{ return {...prevState, target_audience: value } });
                                    // }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]" style={{ minHeight: '21px' }}></label>
                            <label className="upload-files">
                                <input type="file" className="hidden" />
                                <span className="upload-files__content">
                                    <span className="upload-files__title">Write</span>
                                    <span className="upload-files__icon">
                                        <svg width="18" height="18" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 12.0969V20.7596H20.5169V12.0969H17.7814V18.024H2.73563V12.0969H0Z" fill="#ACACAC"></path>
                                            <path d="M10.2577 0.242676L4.55859 5.71386H7.75003V16.2003H12.7653V5.71386H15.9567L10.2577 0.242676Z" fill="#ACACAC"></path>
                                        </svg>
                                        Upload
                                    </span>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">Copy References</label>
                            <label className="upload-files">
                                <input type="file" className="hidden" />
                                <span className="upload-files__content">
                                    <span className="upload-files__title">Write</span>
                                    <span className="upload-files__icon">
                                        <svg width="18" height="18" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 12.0969V20.7596H20.5169V12.0969H17.7814V18.024H2.73563V12.0969H0Z" fill="#ACACAC"></path>
                                            <path d="M10.2577 0.242676L4.55859 5.71386H7.75003V16.2003H12.7653V5.71386H15.9567L10.2577 0.242676Z" fill="#ACACAC"></path>
                                        </svg>
                                        Upload
                                    </span>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">Music References</label>
                            <label className="upload-files">
                                <input type="file" className="hidden" />
                                <span className="upload-files__content">
                                    <span className="upload-files__title">Write</span>
                                    <span className="upload-files__icon">
                                        <svg width="18" height="18" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 12.0969V20.7596H20.5169V12.0969H17.7814V18.024H2.73563V12.0969H0Z" fill="#ACACAC"></path>
                                            <path d="M10.2577 0.242676L4.55859 5.71386H7.75003V16.2003H12.7653V5.71386H15.9567L10.2577 0.242676Z" fill="#ACACAC"></path>
                                        </svg>
                                        Upload
                                    </span>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">Video References</label>
                            <label className="upload-files">
                                <input type="file" className="hidden" />
                                <span className="upload-files__content">
                                    <span className="upload-files__title">Write</span>
                                    <span className="upload-files__icon">
                                        <svg width="18" height="18" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 12.0969V20.7596H20.5169V12.0969H17.7814V18.024H2.73563V12.0969H0Z" fill="#ACACAC"></path>
                                            <path d="M10.2577 0.242676L4.55859 5.71386H7.75003V16.2003H12.7653V5.71386H15.9567L10.2577 0.242676Z" fill="#ACACAC"></path>
                                        </svg>
                                        Upload
                                    </span>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">Collaboration</label>
                            <div className="input-custom__wrap-input">
                                <input className="input-custom__input" type="text" placeholder="Collaboration" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">Deadline</label>
                            <div className="input-custom__wrap-input">
                                <input className="input-custom__input" type="text" value="Choose date" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">Budget</label>
                            <div className="input-custom__wrap-input">
                                <input className="input-custom__input" type="text" placeholder="Write" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">Status</label>
                            <div className="select-wrapp">
                                <SelectBox
                                    className="default-select searchable flex-1"
                                    propsOptions={[
                                        {label: '', value: '', placeholder: true},
                                        {label: 'Choose 1', value: 'Choose 1'},
                                        {label: 'Choose 2', value: 'Choose 2'},
                                        {label: 'Choose 3', value: 'Choose 3'},
                                        {label: 'Choose 4', value: 'Choose 4'},
                                    ]}
                                    isSearchable={true}
                                    placeholder="Choose"
                                    // value={modelData.target_audience}
                                    // change={(value)=>{
                                    //     setModelData((prevState)=>{ return {...prevState, target_audience: value } });
                                    // }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">Note</label>
                            <textarea className="textarea" rows="5" placeholder="Write"></textarea>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">Project Description</label>
                            <textarea className="textarea" rows="5" placeholder="Write"></textarea>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">Project Goal</label>
                            <textarea className="textarea" rows="5" placeholder="Write"></textarea>
                        </div>
                    </div>
                </div>
                <div className="page-actions">
                    <Link to="/my-projects" className="btn btn-outline-black btn-lg-2 rounded-[8px]">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 13.6L7.05001 18.55C6.81667 18.7833 6.54584 18.9 6.23751 18.9C5.92917 18.9 5.66667 18.7833 5.45001 18.55C5.21667 18.3333 5.10001 18.0708 5.10001 17.7625C5.10001 17.4541 5.21667 17.1916 5.45001 16.975L10.4 12L5.45001 7.04998C5.23334 6.83331 5.12501 6.56664 5.12501 6.24998C5.12501 5.93331 5.23334 5.66664 5.45001 5.44998C5.65001 5.23331 5.90834 5.12498 6.22501 5.12498C6.54167 5.12498 6.81667 5.23331 7.05001 5.44998L12 10.425L16.975 5.42498C17.1917 5.20831 17.4542 5.09998 17.7625 5.09998C18.0708 5.09998 18.3417 5.20831 18.575 5.42498C18.7917 5.65831 18.9 5.92914 18.9 6.23748C18.9 6.54581 18.7917 6.80831 18.575 7.02498L13.6 11.975L18.55 16.95C18.7833 17.1833 18.9 17.4541 18.9 17.7625C18.9 18.0708 18.7833 18.3333 18.55 18.55C18.3333 18.7833 18.0667 18.9 17.75 18.9C17.4333 18.9 17.175 18.7833 16.975 18.55L12 13.6Z" fill="currentColor"></path>
                        </svg>
                        <span className="ml-[10px]">Cancel</span>
                    </Link>
                    <Link to="/my-projects" className="btn btn--primary btn--primary-shadow btn--lg radius-5">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.25 18.525H3.775C3.4525 18.525 3.18021 18.4196 2.95812 18.2086C2.73604 17.9976 2.625 17.731 2.625 17.4086C2.625 17.0862 2.73604 16.8125 2.95812 16.5875C3.18021 16.3625 3.4525 16.25 3.775 16.25H8.25C8.58667 16.25 8.8625 16.3638 9.0775 16.5913C9.2925 16.8188 9.4 17.0899 9.4 17.4044C9.4 17.7353 9.2925 18.0047 9.0775 18.2128C8.8625 18.421 8.58667 18.525 8.25 18.525ZM20.25 7.85005H3.775C3.4525 7.85005 3.18021 7.74457 2.95812 7.5336C2.73604 7.32263 2.625 7.05597 2.625 6.7336C2.625 6.41123 2.73604 6.13338 2.95812 5.90005C3.18021 5.66672 3.4525 5.55005 3.775 5.55005H20.25C20.5562 5.55005 20.8203 5.66797 21.0422 5.9038C21.2641 6.13965 21.375 6.41486 21.375 6.72942C21.375 7.06026 21.2641 7.32974 21.0422 7.53787C20.8203 7.74599 20.5562 7.85005 20.25 7.85005ZM14.25 13.2H3.775C3.4525 13.2 3.18021 13.0929 2.95812 12.8785C2.73604 12.6642 2.625 12.3934 2.625 12.066C2.625 11.7387 2.73604 11.4667 2.95812 11.25C3.18021 11.0334 3.4525 10.925 3.775 10.925H14.25C14.5796 10.925 14.8495 11.0365 15.0597 11.2594C15.2699 11.4824 15.375 11.75 15.375 12.0623C15.375 12.398 15.2699 12.6715 15.0597 12.8829C14.8495 13.0943 14.5796 13.2 14.25 13.2Z" fill="currentColor"></path>
                        </svg>
                        <span className="ml-[10px]">
                            Create Project
                        </span>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default CreateNewProject;