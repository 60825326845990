import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getGeneratedWords } from '../../redux/slices/newWordGeneratorSlice';
import { useNavigate, useParams } from 'react-router-dom';

import Popup from 'reactjs-popup';
import HelpModal from '../../components/base/HelpModal';
import NewWordCard from './NewWordCard';
import { getUser } from '../../redux/slices/applicationSlice';

export default function NewWordContent({ setSideBarOpened }) {
    const { id } = useParams();
    const meData = useSelector(getUser);
    const words = useSelector(getGeneratedWords);
    console.log(words);
    const navigate = useNavigate();
    const [cardFullScreen, setCardFullScreen] = useState(false);
    const [openedModal, setOpenedModal] = useState(null);
    useEffect(() => {
        setSideBarOpened(!cardFullScreen);
    }, [cardFullScreen, setSideBarOpened])
    const [showHelpModal, setShowHelpModal] = useState(false);
    const closeModal = () => setShowHelpModal(false);
    return <div className='flex-1 flex flex-col'>
        <div className={classNames('py-[13px] px-[16px] flex items-center justify-between', {
            'px-[30px]': cardFullScreen
        })}>
            <div>
                <h1 className='text-[14px] font-medium leading-normal'>
                    Generated Content Preview
                </h1>
                <div className='text-[12px] leading-[14px] opacity-40 flex items-center gap-[5px]'>
                    Create New Word
                    <div className='w-[3px] h-[3px] rounded-full bg-white'/>
                    12 words
                </div>
            </div>
            <button
                onClick={() => setShowHelpModal(true)}
                className='w-[32px] h-[32px] flex items-center justify-center opacity-50'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M9.68457 9.68482C9.92159 8.66326 10.852 7.95496 11.8998 7.99844C13.0725 7.93349 14.0778 8.82709 14.1508 9.99928C14.1508 11.5038 11.9999 12.0001 11.9999 13.0005" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12.1253 15.7523C12.1252 15.8213 12.0692 15.8772 12.0002 15.8772C11.9311 15.8772 11.8752 15.8212 11.8752 15.7522C11.8751 15.6832 11.931 15.6272 12 15.6271C12.0333 15.6271 12.0651 15.6402 12.0886 15.6637C12.1121 15.6872 12.1253 15.7191 12.1253 15.7523" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.62861 17.1605C1.94551 13.3308 2.63502 8.08546 6.21672 5.07926C9.79842 2.07305 15.0839 2.30347 18.3903 5.60997C21.6968 8.91646 21.9273 14.2019 18.9211 17.7836C15.9149 21.3653 10.6695 22.0548 6.8398 19.3717L3.99688 20.0034L4.62861 17.1605Z" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        </div>
        <div className='py-[4px] px-[16px] flex-1 gap-[12px] overflow-y-scroll no-scroll'>
            <ul className={classNames('flex flex-col gap-[12px]', {
                'h-full': cardFullScreen
            })}>
                {words.map((word, index) => 
                    <NewWordCard
                        key={index} 
                        word={word} 
                        index={index}
                        setCardFullScreen={setCardFullScreen}
                        isFullScreen={cardFullScreen}
                    />
                )}
            </ul>
        </div>
        <div className='generated-content-bottom-panel flex-wrap'>
            <div className='flex items-center gap-[20px] mob-flex-wrap'>
                <div className='flex items-center gap-[10px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M15.8346 1.66663H4.16797C2.79297 1.66663 1.66797 2.79163 1.66797 4.16663V15.8333C1.66797 17.2083 2.79297 18.3333 4.16797 18.3333H15.8346C17.2096 18.3333 18.3346 17.2083 18.3346 15.8333V4.16663C18.3346 2.79163 17.2096 1.66663 15.8346 1.66663ZM9.16797 12.5H5.83464C5.3763 12.5 5.0013 12.125 5.0013 11.6666C5.0013 11.2083 5.3763 10.8333 5.83464 10.8333H9.16797C9.6263 10.8333 10.0013 11.2083 10.0013 11.6666C10.0013 12.125 9.6263 12.5 9.16797 12.5ZM12.5013 9.16663H5.83464C5.3763 9.16663 5.0013 8.79163 5.0013 8.33329C5.0013 7.87496 5.3763 7.49996 5.83464 7.49996H12.5013C12.9596 7.49996 13.3346 7.87496 13.3346 8.33329C13.3346 8.79163 12.9596 9.16663 12.5013 9.16663Z" fill="#9D9D9D"/>
                    </svg>
                    <div className='text-[14px] text-[#9D9D9D] leading-normal flex'>
                        <div className='mr-[10px]'>{1}</div>
                        <span className='opacity-50'>words</span>
                    </div>
                </div>
                {/* <div className='flex items-center gap-[10px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M12.1055 18.4167C11.7305 18.7917 11.2756 18.9792 10.7409 18.9792C10.2062 18.9792 9.7513 18.7917 9.3763 18.4167L1.58464 10.625C1.37398 10.4179 1.23105 10.1987 1.15582 9.96735C1.08059 9.73603 1.04297 9.49693 1.04297 9.25004V2.93754C1.04297 2.40421 1.22561 1.95143 1.59089 1.57921C1.95616 1.20698 2.40547 1.02087 2.9388 1.02087H9.2513C9.51208 1.02087 9.7616 1.06355 9.99986 1.14892C10.2381 1.23428 10.4608 1.3791 10.668 1.58337L18.418 9.31254C18.8069 9.70143 18.9978 10.1702 18.9909 10.7188C18.9839 11.2674 18.793 11.7292 18.418 12.1042L12.1055 18.4167ZM5.11528 6.14587C5.39959 6.14587 5.64611 6.04008 5.85486 5.82848C6.0636 5.61687 6.16797 5.36687 6.16797 5.07848C6.16797 4.80396 6.0636 4.56254 5.85486 4.35421C5.64611 4.14587 5.39959 4.04171 5.11528 4.04171C4.83096 4.04171 4.58464 4.14751 4.3763 4.3591C4.16797 4.57071 4.0638 4.81377 4.0638 5.08827C4.0638 5.37667 4.16797 5.62504 4.3763 5.83337C4.58464 6.04171 4.83096 6.14587 5.11528 6.14587Z" fill="#9D9D9D"/>
                    </svg>
                    <p className='text-[14px] text-[#9D9D9D] leading-normal'>
                        2.44
                        <span className='opacity-50 ml-[4px]'>credits used</span>
                    </p>
                </div> */}
                <div className='flex items-center gap-[10px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M12.1055 18.4167C11.7305 18.7917 11.2756 18.9792 10.7409 18.9792C10.2062 18.9792 9.7513 18.7917 9.3763 18.4167L1.58464 10.625C1.37398 10.4179 1.23105 10.1987 1.15582 9.96735C1.08059 9.73603 1.04297 9.49693 1.04297 9.25004V2.93754C1.04297 2.40421 1.22561 1.95143 1.59089 1.57921C1.95616 1.20698 2.40547 1.02087 2.9388 1.02087H9.2513C9.51208 1.02087 9.7616 1.06355 9.99986 1.14892C10.2381 1.23428 10.4608 1.3791 10.668 1.58337L18.418 9.31254C18.8069 9.70143 18.9978 10.1702 18.9909 10.7188C18.9839 11.2674 18.793 11.7292 18.418 12.1042L12.1055 18.4167ZM5.11528 6.14587C5.39959 6.14587 5.64611 6.04008 5.85486 5.82848C6.0636 5.61687 6.16797 5.36687 6.16797 5.07848C6.16797 4.80396 6.0636 4.56254 5.85486 4.35421C5.64611 4.14587 5.39959 4.04171 5.11528 4.04171C4.83096 4.04171 4.58464 4.14751 4.3763 4.3591C4.16797 4.57071 4.0638 4.81377 4.0638 5.08827C4.0638 5.37667 4.16797 5.62504 4.3763 5.83337C4.58464 6.04171 4.83096 6.14587 5.11528 6.14587Z" fill="#EA8207"/>
                    </svg>
                    <p className='text-[14px] text-[#EA8207] leading-normal'>
                        {meData?.credits || 0}
                        <span className='opacity-50 ml-[4px]'>available credits</span>
                    </p>
                </div>
            </div>
           
        </div>
                    
        <Popup
            open={showHelpModal}
            onClose={closeModal}
            overlayStyle={{
                background: 'rgba(0,0,0,0.5)'
            }}
        >
            <HelpModal closeModal={closeModal}/>
        </Popup>
    </div>
}
