import React, { useState, useEffect, useRef } from 'react';
import Header from "../../components/parts/Header";
import SelectBox from "../../components/base/SelectBox";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import MyAssetsModal from "./MyAssetsModal";
import CircleProgressBar from './CircleProgressBar';
import MyGoalModal from './MyGoalModal';
import AddGoalModal from './AddGoalModal';
import { useSelector, useDispatch } from 'react-redux';
import { getToken, getUser } from '../../redux/slices/applicationSlice';
import api from '../../api';
import GeneratedContentModal from '../GeneratedContent/GeneratedContentModal';
import DatePicker from '../../components/base/DatePicker';
import BotImg from '../../assets/img/icons/icon-bot.svg';

import InfiniteScroll from "react-infinite-scroll-component";
import { useDebounce } from "../../helpers/useDebounce";
import { useClickOutside } from '../../helpers/useClickOutside';
import { format } from 'date-fns';

import { toast } from 'react-toastify';
 

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
} from "react-share";
import getBotImageSrc from '../../helpers/getBotImageSrc';
import Input from '../../components/base/Input';
import PrimaryButton from '../../components/base/PrimaryButton';
import CollectionCard from './CollectionCard';
import { setBlogs } from '../../redux/slices/blogGeneratorSlice';

import MyEmailNewsLetters from "../MyEmailNewsLetters/MyEmailNewsLetters"
import classNames from 'classnames';

// заглушка от ошибок data
const data = []

const MyAssets = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const [mainFilter, setMainFilter] = useState('All');
    const [cardView, setCardView] = useState(true);
    const [search, setSearch] = useState('');
    const [sortValue, setSortValue] = useState('createdAt');
    const [filterValue, setFilterValue] = useState('Images');
    const [assets, setAssets] = useState([]);
    const [assetsNotFind, setAssetsNotFind] = useState(false);

    const [fetchLoading, setFetchLoading] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    
    const [selectedFilters, setSelectedFilters] = useState([]);

    let [searchParams] = useSearchParams();

    const [mode, setMode] = useState(true)

    const [createSelectOpen, setCreateSelectOpen] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const [openGoalModal, setOpenGoalModal] = useState(null);
    const [addGoalModalOpened, setAddGoalModalOpened] = useState(false);

    const [openActionSelect, setOpenActionSelect] = useState({open: false, id: null})

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const onChangeDate = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const [updatedTimes, setUpdatedTimes] = useState(0);
    const isUpdated = useDebounce(updatedTimes, 1500);

    const [selected, setSelected] = useState([])
    const [collections, setCollections] = useState([]);

    const dropdownRef = useRef(null);
    useClickOutside(dropdownRef, () => {
        setCreateSelectOpen(false);
    })

    // const filteredContent = [...data[mainFilter].content].filter(item => item.title.toLowerCase().includes(search.toLowerCase()));
    const filteredContent = (
        mainFilter === 'All' ? assets : 
        mainFilter === 'My Collections' ? collections:
        mainFilter === 'My New Words' ? assets.filter(itemF => itemF?.productId === 10) :
        mainFilter === 'Saved Prompts' ? [...data].filter(itemF => itemF.category === 'Saved Prompt') :
        mainFilter === 'My Brand Voices' ? [...data].filter(itemF => itemF.category === 'My Brand Voices') :
        mainFilter === 'My Blogs' ? assets.filter(itemF => itemF.product?.id === 1) :
        mainFilter === 'My Goals' ? assets.filter(itemF => itemF.product?.id === 2) :
        mainFilter === 'My Bots' ? assets.filter(itemF => itemF.product?.id === 5) : 
        mainFilter === 'My Landing Pages' ? assets.filter(itemF => itemF.product?.id === 4) :
        mainFilter === 'My Entire Websites' ? assets.filter(itemF => itemF.product?.id === 15) :
        mainFilter === 'My General Products' ? assets.filter(itemF => itemF.product?.id === 8) :
        mainFilter === 'My Product Descriptions' ? assets.filter(itemF => itemF.product?.id === 7) :
        mainFilter === 'My Prompts' ? assets.filter(itemF => itemF.product?.id === 11) :
        mainFilter === 'My Meta Tags' ? assets.filter(itemF => itemF.product?.id === 13) :
        mainFilter === 'My Press Releases' ? assets.filter(itemF => itemF.product?.id === 12) :
        mainFilter === 'My Social Media Campaigns' ? assets.filter(itemF => itemF.product?.id === 14) :
        mainFilter === 'My Etsy' ? assets.filter(itemF => itemF.product?.id === 9) :
        mainFilter === 'My Essays' ? assets.filter(itemF => itemF.product?.id === 6) : []
    )
    
    // .filter(item => item.name.toLowerCase().includes(search.toLowerCase()));

    const sortBy = () => {
        const compare = function( a, b ) {
            if ( a[sortValue] < b[sortValue] ){
              return sortValue === "createdAt" ? 1 :-1;
            }
            if ( a[sortValue] > b[sortValue] ){
              return sortValue === "createdAt" ? -1 : 1;
            }
            return 0;
        }
    
        return sortValue ? filteredContent.sort(compare) : filteredContent;
    }

    const [paginationData, setPaginationData] = useState(null);
    const [cardsContentCount, setCardsContentCount] = useState(1);

    useEffect(() => {
        if(!token) return;
        api.collections.getCollections({token})
        .then(response => {
            setCollections(response);
        }).catch(e => {
            console.log(e)
        })
    }, [token])
    console.log(assets)
    const fetchAssets = async() => {
        if(!token) return;

        setFetchError(false)
        setFetchLoading(true)
        const tab = searchParams.get("tab") || mainFilter
        if(tab === 'My New Words') {
            await api.newWords.getWords({token, queries: 'my_words=true&page=1&sortBy=name&sort=asc&isSimilar=true'}).then(data => {
                console.log(data)
                setAssets(data.data.map(item => ({...item, productId: 10,content: JSON.stringify({
                    word: item.word,
                    definition: item.definition,
                
                })})));
                
                setAssetsNotFind(!data?.data?.length>0);
                
                setPaginationData(data.pagination);
            }).catch(e => {setFetchError(true)})
    
           setFetchLoading(false)
           return
        }
        let queries = 'productId=';

        switch (searchParams.get("tab") || mainFilter) {
            case 'My Goals':
                queries+= '2'
                break;
            case 'My Blogs':
                queries+= '1'
                break;
            case 'My Bots':
                queries+= '5'
                break;
            case 'My Landing Pages':
                queries+= '4'
                break;
            case 'My Entire Websites':
                queries+= '15'
                break;
            case 'My General Products':
                queries+= '8'
                break;
            case 'My Product Descriptions':
                queries+= '7'
                break;
            case 'My New Words':
                queries+= '10'
                break;
            case 'My Etsy':
                queries+= '9'
                break;
            case 'My Prompts':
                queries+= '11'
                break;
            case 'My Press Releases':
                queries+= '12'
                break;
            case 'My Meta Tags':
                queries+= '13'
                break;
            case 'My Social Media Campaigns':
                queries+= '14'
                break;
            case 'My Essays':
                queries+= '6'
                break;
            case 'All':
                queries+= ''
                break;
            default:
                queries+= ''
                break;
        }
        
        if(sortValue){
            queries += `&sort=${sortValue}&order=desc`;
        }        

        selectedFilters.forEach(element => {
            queries += `&${element[0]}=${element[1]}`
        });

        if(startDate && endDate){            
            queries += `&startDate=${startDate}`
            queries += `&endDate=${endDate}`
        }

        queries += `&limit=${10*cardsContentCount}`

        if(search?.length) {
            queries += `&searchText=${search}`
        }

        await api.assets.getAssets({token, queries}).then(data => {
            setAssets(data.data);
            
            setAssetsNotFind(!data?.data?.length>0);
            
            setPaginationData(data.pagination);
        }).catch(e => {setFetchError(true)})

       setFetchLoading(false)
    }

    const handleClickItem = (item) => () => {
        if (+item?.product?.id === 2) {
            setOpenGoalModal(item);
        }

        if(+item?.product?.id === 1){
            navigate(`/generated-page/blog/${item.id}`)
        }

        if(+item?.product?.id === 7){
            navigate(`/generated-page/product-description/${item.id}`)
        }

        if(+item?.product?.id === 4){
            navigate(`/generated-page/landing/${item.id}`)
        }
        if(item?.product?.id === 5){
            navigate(`/chat-bot/${item.bot.id}`)
        }
        if(item?.product?.id === 8){
            navigate(`/generated-page/general/${item.id}`)
        }
        if(item?.product?.id === 9){
            navigate(`/generated-page/etsy/${item.id}`)
        }
        if(item?.productId === 10){
            let navigateType = item.type;
            switch (item.type) {
                case 'social-campaing':
                    navigateType = 'social-media-campaign'
                    break;
                case 'new-word-improve':
                    navigateType = 'new-word'
                    break;
                default:
                    break;
            }
            navigate(`/generated-page/new-word/${item.id}`)
        }
        if(item?.product?.id === 11){
            navigate(`/generated-page/prompt/${item.id}`)
        }
        if(item?.product?.id === 13){
            navigate(`/generated-page/meta-tags/${item.id}`)
        }
        if(+item?.product?.id === 12){
            navigate(`/generated-page/press-release/${item.id}`)
        }
        if(item?.product?.id === 14){
            navigate(`/generated-page/social-media-campaign/${item.id}`)
        }
        if(+item?.product?.id === 15){
            navigate(`/generated-page/entire-website/${item.id}`)
        }
        if(+item?.product?.id === 6){
            navigate(`/generated-page/essay/${item.id}`)
        }
    }


    const [openedModal, setOpenedModal] = useState(null);

    const deleteAction = async(id, endAction = true) => {
        if(!id || !token){ return; }

        const response = await api.assets.deleteAsset({
            id,
            token
        });
        
        if(endAction){
            if(response.success){
                setOpenedModal({
                    name: 'save',
                    title: response.message,
                    text: ''
                });

                fetchAssets();

                return response;
            }

            setOpenedModal(null);
        } else {
            return response;
        }
    }

    const duplicateAction = async(id, endAction = true) => {
        if(!id || !token){ return; }
        const response = await api.assets.cloneAsset({
            id,
            token
        });
        
        if(endAction){
            if(response.success){
                setOpenedModal({
                    name: 'save',
                    title: `Asset successfully duplicated as: ”${response.data?.name}”`,
                    text: ''
                });

                fetchAssets();

                return response;
            }

            setOpenedModal(null);
        } else {
            return response;
        }
    }

    const exportAction = async({ id, ext, close = false }) => {
        const findedItem = filteredContent.find(item => item.id === id);
        const filename = `${findedItem.name}.${ext}`; 

        await api.assets.exportAsset({
            token,
            id,
            type: findedItem.productId === 2 ? 'goal' : findedItem.productId === 1 ? 'blog' : '',
            ext: ext,
            filename: filename,
        });

        if(close){
            setOpenedModal(null);
        }
    }

    const [linkCopied, setLinkCopied] = useState(false);
    const [collectionInput, setCollectionInput] = useState('');
    const [collectionSelectValue, setCollectionSelectValue] = useState('');
    const sharePopupActions = [
        [
            TwitterShareButton,
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.9">
                    <path d="M22.4591 6C21.6891 6.35 20.8591 6.58 19.9991 6.69C20.8791 6.16 21.5591 5.32 21.8791 4.31C21.0491 4.81 20.1291 5.16 19.1591 5.36C18.3691 4.5 17.2591 4 15.9991 4C13.6491 4 11.7291 5.92 11.7291 8.29C11.7291 8.63 11.7691 8.96 11.8391 9.27C8.27906 9.09 5.10906 7.38 2.99906 4.79C2.62906 5.42 2.41906 6.16 2.41906 6.94C2.41906 8.43 3.16906 9.75 4.32906 10.5C3.61906 10.5 2.95906 10.3 2.37906 10V10.03C2.37906 12.11 3.85906 13.85 5.81906 14.24C5.18979 14.4122 4.52916 14.4362 3.88906 14.31C4.16067 15.1625 4.6926 15.9084 5.41008 16.4429C6.12756 16.9775 6.99451 17.2737 7.88906 17.29C6.37269 18.4904 4.49306 19.1393 2.55906 19.13C2.21906 19.13 1.87906 19.11 1.53906 19.07C3.43906 20.29 5.69906 21 8.11906 21C15.9991 21 20.3291 14.46 20.3291 8.79C20.3291 8.6 20.3291 8.42 20.3191 8.23C21.1591 7.63 21.8791 6.87 22.4591 6Z" fill="white"/>
                </g>
            </svg>,
            'Twitter',
            () => {}
        ], [   
            FacebookShareButton,
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.9">
                    <path d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z" fill="white"/>
                </g>
            </svg>,
            'Facebook',
            () => {}
        ], [
            null,
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.9">
                    <path d="M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z" fill="white"/>
                </g>
            </svg>,
            'Instagram',
            () => {}
        ], [
            LinkedinShareButton,
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.9">
                    <path d="M19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19ZM18.5 18.5V13.2C18.5 12.3354 18.1565 11.5062 17.5452 10.8948C16.9338 10.2835 16.1046 9.94 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17C14.6813 12.17 15.0374 12.3175 15.2999 12.5801C15.5625 12.8426 15.71 13.1987 15.71 13.57V18.5H18.5ZM6.88 8.56C7.32556 8.56 7.75288 8.383 8.06794 8.06794C8.383 7.75288 8.56 7.32556 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19C6.43178 5.19 6.00193 5.36805 5.68499 5.68499C5.36805 6.00193 5.19 6.43178 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56ZM8.27 18.5V10.13H5.5V18.5H8.27Z" fill="white"/>
                </g>
            </svg>,
            'LinkedIn',
            () => {}
        ], [
            EmailShareButton,
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M3 6.8V6.8C3 7.4 3.3 7.9 3.8 8.3L9.8 12.4C11.2 13.3 12.9 13.3 14.3 12.4L20.3 8.4C20.7 7.9 21 7.4 21 6.8V6.8C21 5.8 20.2 5 19.2 5H4.8C3.8 5 3 5.8 3 6.8Z" stroke="#E9E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3 7V17C3 18.1 3.9 19 5 19H19C20.1 19 21 18.1 21 17V7" stroke="#E9E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.58594 18.4124L9.67994 12.3184" stroke="#E9E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14.3594 12.3604L20.4114 18.4124" stroke="#E9E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>,
            'Email',
            () => {}
        ]
    ];
    const linkToShare = `https://www.example.com/file/D7keGdZyuXb0NHmSL1Qyvr/aikit.ai?type=design&node-id=416%3A20984&mode=design&t=u2AAYmBG6J987C5w-1`;
    const copyCustomLink = () => {
        const textarea = document.createElement('textarea');
        textarea.value = linkToShare;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        setLinkCopied(true);

        toast.success('Copied successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

        setTimeout(() => {
            setLinkCopied(false);
        }, 1500);
    }

    const cardDropdownLists = [
        {
            title: 'Select',
            action: (e, item)=>{
                setMode(false);
                setOpenActionSelect({open: false, id: null});
                setSelected(prev => [...prev, item.id]);
            }
        },
        {
            title: 'Edit',
            action: (e, item) => {
                return item?.productId === 2 ? 
                    navigate(`/generated-page/goal/${item.id}`) : 
                        item?.productId === 1 ? 
                    navigate(`/generated-page/blog/${item.id}`) : null
            }
        },
        {
            title: 'Copy',
            action: (e, item)=>{
                return setOpenedModal({
                    name: 'confirm-delete',
                    action: () => duplicateAction(item.id),
                    title: `Do you really want to duplicate "${item.name}" ${item?.productId === 2 ? 'goal' : item?.productId === 1 ? 'blog' : ''}`
                })
            }
        },
        {
            title: 'Share',
            action: () => {
                setOpenedModal('share');
            }
        },
        {
            title: 'Export',
            action: (e, item)=>{
                return setOpenedModal({
                    name: 'export',
                    id: item.id
                })
            }
        },
        {
            divider: true
        },
        {
            title: 'Delete',
            action: (e, item)=>{
                return setOpenedModal({
                    name: 'confirm-delete',
                    action: () => deleteAction(item.id),
                    title: `Do you really want to delete "${item.name}" ${item?.productId === 2 ? 'goal' : item?.productId === 1 ? 'blog' : ''}?`
                })
            }
        },
    ];

    const closeDropdownHandler = (e) => {
        if (openActionSelect?.open && !e.target.closest('.saved-content-actions') && !e.target.closest('.default-select')) {
            setOpenActionSelect({open: false, id: null});
        }
    }

    const deleteSelected = (e) => {
        e.preventDefault();

        return setOpenedModal({
            name: 'confirm-delete',
            action: async() => {
                await Promise.all(
                    selected.map(id => deleteAction(id, false) )
                ).then((values) => {
                    setOpenedModal({
                        name: 'save',
                        title: 'Selected assets deleted successfully',
                        text: ''
                    });

                    setSelected([]);
                    fetchAssets();
                });
            },
            title: `Do you really want to delete selected assets?`
        });
    }

    const duplicateSelected = (e) => {
        e.preventDefault();

        return setOpenedModal({
            name: 'confirm-delete',
            action: async() => {
                await Promise.all(
                    selected.map(id => duplicateAction(id, false) )
                ).then((values) => {
                    setOpenedModal({
                        name: 'save',
                        title: 'Selected assets duplicated successfully',
                        text: ''
                    });

                    setSelected([]);
                    fetchAssets();
                });
            },
            title: `Do you really want to duplicate selected assets?`
        })
    }

    const exportSelected = (e) => {
        e.preventDefault();

        return setOpenedModal({
            name: 'export',
            action: async(e, ext) => {
                e.preventDefault();
                await Promise.all(
                    selected.map(async(id) => {
                        await exportAction({ id, ext });
                    } )
                ).then((values) => {
                    setOpenedModal({
                        name: 'save',
                        title: 'Selected assets export successfully',
                        text: ''
                    });

                    setSelected([]);
                });
            }
        });
    }

    const addToCollection = (e) => {
        e.preventDefault();

        return setOpenedModal({
            name: 'collection-add',
        });
    }

    const canTwoPageGenerate = paginationData?.total > filteredContent.length;

    useEffect(() => {
        dispatch(setBlogs([]));
    }, [mainFilter])

    useEffect(() => {
        setCardsContentCount(1);
    }, [mainFilter])

    useEffect(() => {
        if(canTwoPageGenerate && cardsContentCount === 1){
            setCardsContentCount(2);  
        }
    }, [mainFilter, cardsContentCount, canTwoPageGenerate])

    useEffect(() => {
        fetchAssets();
    }, [token, sortValue, selectedFilters, endDate, cardsContentCount, isUpdated, mainFilter])

    useEffect(() => {
        if(searchParams.get("tab")){
            setMainFilter(searchParams.get("tab"));
        }
    }, [searchParams])

    useEffect(() => {
        document.addEventListener('click', closeDropdownHandler);

        return () => {
            document.removeEventListener('click', closeDropdownHandler);
        }
    }, [openActionSelect])

    const removeHtmlTags = (inputString) => {
        return inputString.replace(/<[^>]*>/g, '');
    }

    return (
        <div
            className="page-section pt-[0px]" id="my-assets-page-section"
        >
            <div className="container" style={{ minHeight: "calc(100vh - 86px)" }}>
                <div className="assets-page">
                    <div className="d-flex flex-col md:flex-row gap-[20px] items-start md:items-center justify-between py-[16px] px-[20px]">
                        <div>
                            <h3 className="text-[18px] font-[600] text-[#ffffff] mb-[12px] lh-n">My Assets</h3>
                            <p className="text-[14px] font-[400] text-[#737373] lh-n">Control your saved contents, prompts, and generations</p>
                        </div>
                        
                        <div className="d-flex items-center flex-1 justify-end">
                            <div className="relative mr-[8px] max-w-[330px] w-100">
                                <svg
                                    width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    style={{ position: 'absolute', left: '10px', top: '50%', transform: "translateY(-50%)", pointerEvents: 'none' }}
                                >
                                    <g opacity="0.3">
                                        <path d="M16.6667 16.6662L13.647 13.6465" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M3.33325 9.37467C3.33325 12.7114 6.0382 15.4163 9.37492 15.4163C12.7116 15.4163 15.4166 12.7114 15.4166 9.37467C15.4166 6.03795 12.7116 3.33301 9.37492 3.33301V3.33301C6.0383 3.33325 3.3335 6.03806 3.33325 9.37467" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>
                                
                                <input
                                    type="search" className="form-search-radiused__input" placeholder="Search all assets by titles..."
                                    value={search} onInput={(e) => { setSearch(e.target.value); setUpdatedTimes((prevState) => prevState + 1) }}
                                />
                            </div>
                        
                            <div ref={dropdownRef} className={`dropdown change-user-drop dropdown-assets relative ${createSelectOpen ? 'dropdown-open' : ''}`} data-dropdown="dropdown">
                                <a 
                                    href="#" className="btn btn--primary btn--lg d-flex align-items-center justify-between px-[10px] py-[8px] orange-rounded-drop-btn"
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        setCreateSelectOpen(!createSelectOpen);
                                    }}
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path  d="M9.99967 6.66666V13.3333" stroke="#331A04" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path  d="M13.3337 10H6.66699" stroke="#331A04" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10 17.5V17.5C5.8575 17.5 2.5 14.1425 2.5 10V10C2.5 5.8575 5.8575 2.5 10 2.5V2.5C14.1425 2.5 17.5 5.8575 17.5 10V10C17.5 14.1425 14.1425 17.5 10 17.5Z" stroke="#331A04" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-[2px]">
                                        <path d="M6.66699 8.33334L10.0003 11.6667L13.3337 8.33334" stroke="#331A04" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </a>
                                <div
                                    className={`dropdown__body dropdown__body-lighten  ${createSelectOpen ? 'is-open' : ''}`} data-role="dropdown"
                                    style={{ right: "0", position: "absolute" }}
                                >
                                    <ul className="default-select-options">
                                        {/* <li className="d-block">
                                            <a 
                                                href="#" className="d-block py-[10px] px-[20px]"
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    setCreateSelectOpen(false);
                                                }}
                                            >Create a Collection</a>
                                        </li> */}
                                        <li className="d-block">
                                            <Link to="/generated-content?type=new-word" 
                                                className="d-block py-[10px] px-[20px]"
                                                // onClick={(e)=>{
                                                //     e.preventDefault();
                                                //     setCreateSelectOpen(false);
                                                // }}
                                            >Create a New Word</Link>
                                        </li>
                                        {/* <li className="d-block">
                                            <a 
                                                href="#" className="d-block py-[10px] px-[20px]"
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    setCreateSelectOpen(false);
                                                }}
                                            >Upload a File</a>
                                        </li> */}
                                        {/* <li className="d-block">
                                            <a
                                                href="#" className="d-block py-[10px] px-[20px]"
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    setCreateSelectOpen(false);
                                                    setOpenModal(true)
                                                }}
                                            >Add Brand Voice</a>
                                        </li> */}
                                        <li className="d-block">
                                            <Link
                                                to="/generated-content?type=goal" className="d-block py-[10px] px-[20px]"
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    setCreateSelectOpen(false);
                                                    setAddGoalModalOpened(true);
                                                }}
                                            >Add Goal</Link>
                                        </li>
                                        <li className="d-block">
                                            <Link
                                                to="/generated-content?type=blog" className="d-block py-[10px] px-[20px]"
                                                
                                            >Add Blog</Link>
                                        </li>
                                        <li className="d-block">
                                            <Link
                                                to="/generated-content?type=landing-page" className="d-block py-[10px] px-[20px]"
                                                
                                            >Add Landing Page</Link>
                                        </li>
                                        <li className="d-block">
                                            <Link
                                                to="/generated-content?type=entire-website" className="d-block py-[10px] px-[20px]"
                                                
                                            >Add Entire Website</Link>
                                        </li>
                                        <li className="d-block">
                                            <Link to="/generated-content?type=general" className="d-block py-[10px] px-[20px]">
                                                Add General Product
                                            </Link>
                                        </li>
                                        <li className="d-block">
                                            <Link to="/generated-content?type=meta-tags" className="d-block py-[10px] px-[20px]">
                                                Add Meta Tags
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="assets-page-body">
                        <div className="px-[20px] bb-1-grey d-flex align-items-center justify-between">
                            <ul className="tabs-controls overflow-y-auto no-scroll tabs-ml-fix md:flex-wrap">
                                {
                                    [
                                        'All',
                                        'My Collections',
                                        'My New Words',
                                        // 'Saved Prompts',
                                        // 'My Brand Voices',
                                        'My Goals',
                                        'My Blogs',
                                        'My Bots',
                                        'My Landing Pages',
                                        'My Entire Websites',
                                        'My General Products',
                                        'My Product Descriptions',
                                        'My Prompts',
                                        'My Press Releases',
                                        'My Meta Tags',
                                        'My Social Media Campaigns',
                                        'My Etsy',
                                        'My Essays',
                                        'My Lessons'
                                    ].map((item, index) => {
                                        return <li key={index}>
                                            <a href="#" className={classNames('w-max', {
                                                'active': mainFilter === item
                                            })} onClick={(e)=>{
                                                e.preventDefault();
                                                setMainFilter(item);
                                                navigate(`/my-assets?tab=${item}`)
                                            }}>
                                                {item}
                                            </a>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                        { mainFilter !== 'My Lessons' ? <div className="px-[20px] pt-[16px] d-flex align-items-center justify-between filters-wrap">
                            {/* <div className="input-custom__wrap-input icon-left transparent-input-wrpr flex-1">
                                <div className="icon-input">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.6662 16.6667L13.6465 13.6469" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M3.33301 9.37501C3.33301 12.7117 6.03795 15.4167 9.37467 15.4167C12.7114 15.4167 15.4163 12.7117 15.4163 9.37501C15.4163 6.03829 12.7114 3.33334 9.37467 3.33334V3.33334C6.03806 3.33359 3.33325 6.03839 3.33301 9.37501" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <input
                                    className="input-custom__input text-[24px]" type="text" placeholder="Search"
                                    value={search} onInput={(e)=>{ setSearch(e.target.value); }}
                                />
                            </div> */}
                            { mode ? 
                                <>
                                    <div className="d-flex align-items-center flex-1" style={{maxWidth: "700px"}}>
                                        {/* mainFilter === 'My Goals' */}
                                        { true ? <>
                                            {/* <SelectBox
                                                //  only-icon
                                                key={'my-goals-filter-date'}
                                                className="default-select mr-[8px] sort-select-box small-select"
                                                propsOptions={[
                                                    {label: 'Date Range', value: 'Date Range', placeholder: true},
                                                    {label: 'Date Range 1', value: 'Date Range 1'},
                                                    {label: 'Date Range 2', value: 'Date Range 2'},
                                                ]}
                                                // value={sortValue}
                                                // change={(value)=>{
                                                //     setSortValue(value);
                                                // }}
                                            /> */}
                                            <DatePicker
                                                classNamesImportant={`flex items-center p-[8px] pl-[14px] bg-[#1B1B1B] rounded-[8px] border border-solid border-[#333] text-[14px] h-[36px] mr-[8px] z-10 relative`}
                                                selected={startDate}
                                                onChange={onChangeDate}
                                                startDate={startDate}
                                                endDate={endDate}
                                                selectsRange
                                                placeholderText="Date Range"
                                                // open={true}
                                            />
                                            <SelectBox
                                                //  only-icon
                                                key={'my-goals-filter-category'}
                                                className="default-select mr-[8px] sort-select-box small-select sm-mr-[0]"
                                                propsOptions={[
                                                    {label: 'Category', value: 'Category', placeholder: true},
                                                    {label: 'All Categories', value: ''},
                                                    ...[ ...new Set(filteredContent.map(item => item.category)) ].filter(item => item).map(item => ({ label: item, value: item }))
                                                ]}
                                                // value={sortValue}
                                                change={(value)=>{
                                                    setSelectedFilters((prevState)=>{
                                                        return !prevState.map(itemF => itemF[0]).includes('category') ? 
                                                            [...prevState, ['category', value]] :
                                                            prevState.filter(item => item[0] !== 'category');
                                                    });
                                                    // setSortValue(value);
                                                }}
                                            />
                                            {/* <SelectBox
                                                //  only-icon
                                                className="default-select flex-1 w-auto mr-[8px] sort-select-box small-select"
                                                propsOptions={[
                                                    {label: 'Platform', value: 'Platform', placeholder: true},
                                                    {label: 'Platform 1', value: 'Platform 1'},
                                                    {label: 'Platform 2', value: 'Platform 2'},
                                                ]}
                                                // value={sortValue}
                                                // change={(value)=>{
                                                //     setSortValue(value);
                                                // }}
                                            />
                                            <SelectBox
                                                className="default-select flex-1 w-auto mr-[8px] sort-select-box small-select"
                                                propsOptions={[
                                                    {label: 'Creation Type', value: 'Creation Type', placeholder: true},
                                                    ...[ ...new Set(filteredContent.map(item => item.product.generator.id)) ].map(item => ({ label: filteredContent.map(item => item.product.generator).find(itemF => itemF.id === item).name, value: item }))
                                                ]}
                                                change={(value) => {
                                                    setSelectedFilters((prevState) => {
                                                        return !prevState.map(itemF => itemF[0]).includes('generatorId') ? 
                                                            [...prevState, ['generatorId', value]] :
                                                            prevState.filter(item => item[0] !== 'generatorId');
                                                    });
                                                }}
                                            />
                                            { selectedFilters?.length ?
                                                <a href="#" onClick={(e)=>{
                                                e.preventDefault();
                                                setSelectedFilters([]);
                                            }}>Reset filters</a> : null } */}
                                        </> : <>
                                            <SelectBox
                                                //  only-icon
                                                className="default-select flex-1 w-auto mr-[8px] sort-select-box small-select"
                                                propsOptions={[
                                                    {label: 'Date Range', value: 'Date Range', placeholder: true},
                                                    {label: 'Date Range 1', value: 'Date Range 1'},
                                                    {label: 'Date Range 2', value: 'Date Range 2'},
                                                ]}
                                                // value={sortValue}
                                                // change={(value)=>{
                                                //     setSortValue(value);
                                                // }}
                                            />
                                            <SelectBox
                                                //  only-icon
                                                className="default-select flex-1 w-auto mr-[8px] sort-select-box small-select"
                                                propsOptions={[
                                                    {label: 'Platform', value: 'Platform', placeholder: true},
                                                    {label: 'Platform 1', value: 'Platform 1'},
                                                    {label: 'Platform 2', value: 'Platform 2'},
                                                ]}
                                                // value={sortValue}
                                                // change={(value)=>{
                                                //     setSortValue(value);
                                                // }}
                                            />
                                            <SelectBox
                                                //  only-icon
                                                className="default-select flex-1 w-auto mr-[8px] sort-select-box small-select"
                                                propsOptions={[
                                                    {label: 'Creation Type', value: 'Creation Type', placeholder: true},
                                                    {label: 'Creation Type 1', value: 'Creation Type 1'},
                                                    {label: 'Creation Type 2', value: 'Creation Type 2'},
                                                ]}
                                                // value={sortValue}
                                                // change={(value)=>{
                                                //     setSortValue(value);
                                                // }}
                                            />
                                            {/* <SelectBox
                                                //  only-icon
                                                className="default-select flex-1 w-auto mr-[8px] sort-select-box small-select"
                                                propsOptions={[
                                                    {label: 'Prompt', value: 'Prompt', placeholder: true},
                                                    {label: 'Prompt 1', value: 'Prompt 1'},
                                                    {label: 'Prompt 2', value: 'Prompt 2'},
                                                ]}
                                                // value={sortValue}
                                                // change={(value)=>{
                                                //     setSortValue(value);
                                                // }}
                                            />
                                            <SelectBox
                                                //  only-icon
                                                className="default-select flex-1 w-auto mr-[8px] sort-select-box small-select"
                                                propsOptions={[
                                                    {label: 'Category', value: 'Category', placeholder: true},
                                                    {label: 'Category 1', value: 'Category 1'},
                                                    {label: 'Category 2', value: 'Category 2'},
                                                ]}
                                                // value={sortValue}
                                                // change={(value)=>{
                                                //     setSortValue(value);
                                                // }}
                                            /> */}
                                            <SelectBox
                                                //  only-icon
                                                className="default-select flex-1 w-auto mr-[8px] sort-select-box small-select"
                                                propsOptions={[
                                                    {label: 'Collection', value: 'Collection', placeholder: true},
                                                    {label: 'Collection 1', value: 'Collection 1'},
                                                    {label: 'Collection 2', value: 'Collection 2'},
                                                ]}
                                                // value={sortValue}
                                                // change={(value)=>{
                                                //     setSortValue(value);
                                                // }}
                                            />
                                        </>}
                                    </div>
                                    <div className={`d-flex align-items-center justify-between sm-filter ${mainFilter === 'My Collections' ? 'sm-filter-collection' : ''}`}>
                                        {
                                            mainFilter === 'My Collections' ?
                                                <SelectBox
                                                    //  only-icon
                                                    className="default-select flex-1 w-auto sort-select-box min-w-[234px] sm-filter-select"
                                                    propsOptions={[
                                                        {label: 'All', value: 'All'},
                                                        {label: 'Images', value: 'Images'},
                                                        {label: 'Text', value: 'Text'},
                                                    ]}
                                                    icon={
                                                        <>
                                                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M8.25 18.525H3.775C3.4525 18.525 3.18021 18.4195 2.95812 18.2085C2.73604 17.9976 2.625 17.7309 2.625 17.4085C2.625 17.0862 2.73604 16.8125 2.95812 16.5875C3.18021 16.3625 3.4525 16.25 3.775 16.25H8.25C8.58667 16.25 8.8625 16.3637 9.0775 16.5912C9.2925 16.8188 9.4 17.0898 9.4 17.4044C9.4 17.7352 9.2925 18.0047 9.0775 18.2128C8.8625 18.4209 8.58667 18.525 8.25 18.525ZM20.25 7.84999H3.775C3.4525 7.84999 3.18021 7.7445 2.95812 7.53354C2.73604 7.32257 2.625 7.0559 2.625 6.73354C2.625 6.41117 2.73604 6.13332 2.95812 5.89999C3.18021 5.66665 3.4525 5.54999 3.775 5.54999H20.25C20.5562 5.54999 20.8203 5.66791 21.0422 5.90374C21.2641 6.13959 21.375 6.4148 21.375 6.72936C21.375 7.0602 21.2641 7.32968 21.0422 7.53781C20.8203 7.74593 20.5562 7.84999 20.25 7.84999ZM14.25 13.2H3.775C3.4525 13.2 3.18021 13.0928 2.95812 12.8785C2.73604 12.6642 2.625 12.3933 2.625 12.066C2.625 11.7387 2.73604 11.4667 2.95812 11.25C3.18021 11.0333 3.4525 10.925 3.775 10.925H14.25C14.5796 10.925 14.8495 11.0364 15.0597 11.2594C15.2699 11.4823 15.375 11.7499 15.375 12.0623C15.375 12.3979 15.2699 12.6715 15.0597 12.8829C14.8495 13.0943 14.5796 13.2 14.25 13.2Z"
                                                                    fill="#A7A7A7"/>
                                                            </svg>
                                                            <span style={{
                                                                marginLeft: '10px',
                                                                color: 'rgba(255, 255, 255, 0.50)',
                                                                fontSize: '16px',
                                                                fontWeight: '400'
                                                            }}>Filter by</span>
                                                        </>
                                                    }
                                                    value={filterValue}
                                                    change={(value) => {
                                                        setFilterValue(value);
                                                    }}
                                                    menuPosition="absolute"
                                                /> : null
                                        }

                                        <SelectBox
                                            //  only-icon
                                            className="default-select flex-1 w-auto ml-[8px] sort-select-box sm-filter-select"
                                            propsOptions={mainFilter === 'My Goals' ? [
                                                {label: 'Name', value: 'name'},
                                                {label: 'Category', value: 'category'},
                                            ] : [
                                                {label: 'Name', value: 'name'},
                                                {label: 'Date', value: 'createdAt'},
                                                {label: 'Type', value: 'productId'},
                                            ]}
                                            icon={
                                                <>
                                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M8.25 18.525H3.775C3.4525 18.525 3.18021 18.4195 2.95812 18.2085C2.73604 17.9976 2.625 17.7309 2.625 17.4085C2.625 17.0862 2.73604 16.8125 2.95812 16.5875C3.18021 16.3625 3.4525 16.25 3.775 16.25H8.25C8.58667 16.25 8.8625 16.3637 9.0775 16.5912C9.2925 16.8188 9.4 17.0898 9.4 17.4044C9.4 17.7352 9.2925 18.0047 9.0775 18.2128C8.8625 18.4209 8.58667 18.525 8.25 18.525ZM20.25 7.84999H3.775C3.4525 7.84999 3.18021 7.7445 2.95812 7.53354C2.73604 7.32257 2.625 7.0559 2.625 6.73354C2.625 6.41117 2.73604 6.13332 2.95812 5.89999C3.18021 5.66665 3.4525 5.54999 3.775 5.54999H20.25C20.5562 5.54999 20.8203 5.66791 21.0422 5.90374C21.2641 6.13959 21.375 6.4148 21.375 6.72936C21.375 7.0602 21.2641 7.32968 21.0422 7.53781C20.8203 7.74593 20.5562 7.84999 20.25 7.84999ZM14.25 13.2H3.775C3.4525 13.2 3.18021 13.0928 2.95812 12.8785C2.73604 12.6642 2.625 12.3933 2.625 12.066C2.625 11.7387 2.73604 11.4667 2.95812 11.25C3.18021 11.0333 3.4525 10.925 3.775 10.925H14.25C14.5796 10.925 14.8495 11.0364 15.0597 11.2594C15.2699 11.4823 15.375 11.7499 15.375 12.0623C15.375 12.3979 15.2699 12.6715 15.0597 12.8829C14.8495 13.0943 14.5796 13.2 14.25 13.2Z"
                                                            fill="#A7A7A7"/>
                                                    </svg>
                                                    <span style={{
                                                        marginLeft: '10px',
                                                        color: 'rgba(255, 255, 255, 0.50)',
                                                        fontSize: '16px',
                                                        fontWeight: '400'
                                                    }}>Sort by</span>
                                                </>
                                            }
                                            value={sortValue}
                                            change={(value) => {
                                                setSortValue(value);
                                            }}
                                            menuPosition="absolute"
                                        />
                                        <div
                                            className="btn btn-outline-black btn-lg-2 rounded-[8px] d-flex align-items-center justify-between px-[10px] py-[6px] ml-[8px] sm-filter-select">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                setCardView(true);
                                            }}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M3.95833 9.37496C3.43438 9.37496 2.98741 9.1865 2.61744 8.80958C2.24748 8.43268 2.0625 7.98225 2.0625 7.45829V3.95829C2.0625 3.43433 2.24748 2.9839 2.61744 2.607C2.98741 2.23008 3.43438 2.04163 3.95833 2.04163H7.47917C7.98924 2.04163 8.43272 2.23008 8.80963 2.607C9.18654 2.9839 9.375 3.43433 9.375 3.95829V7.45829C9.375 7.98225 9.18654 8.43268 8.80963 8.80958C8.43272 9.1865 7.98924 9.37496 7.47917 9.37496H3.95833ZM3.95833 17.9375C3.43438 17.9375 2.98741 17.7525 2.61744 17.3825C2.24748 17.0125 2.0625 16.5656 2.0625 16.0416V12.5208C2.0625 12.0107 2.24748 11.5672 2.61744 11.1903C2.98741 10.8134 3.43438 10.625 3.95833 10.625H7.47917C7.98924 10.625 8.43272 10.8134 8.80963 11.1903C9.18654 11.5672 9.375 12.0107 9.375 12.5208V16.0416C9.375 16.5656 9.18654 17.0125 8.80963 17.3825C8.43272 17.7525 7.98924 17.9375 7.47917 17.9375H3.95833ZM12.5417 9.37496C12.0177 9.37496 11.5673 9.1865 11.1904 8.80958C10.8135 8.43268 10.625 7.98225 10.625 7.45829V3.95829C10.625 3.43433 10.8135 2.9839 11.1904 2.607C11.5673 2.23008 12.0177 2.04163 12.5417 2.04163H16.0417C16.5656 2.04163 17.0161 2.23008 17.393 2.607C17.7699 2.9839 17.9583 3.43433 17.9583 3.95829V7.45829C17.9583 7.98225 17.7699 8.43268 17.393 8.80958C17.0161 9.1865 16.5656 9.37496 16.0417 9.37496H12.5417ZM12.5417 17.9375C12.0177 17.9375 11.5673 17.7525 11.1904 17.3825C10.8135 17.0125 10.625 16.5656 10.625 16.0416V12.5208C10.625 12.0107 10.8135 11.5672 11.1904 11.1903C11.5673 10.8134 12.0177 10.625 12.5417 10.625H16.0417C16.5656 10.625 17.0161 10.8134 17.393 11.1903C17.7699 11.5672 17.9583 12.0107 17.9583 12.5208V16.0416C17.9583 16.5656 17.7699 17.0125 17.393 17.3825C17.0161 17.7525 16.5656 17.9375 16.0417 17.9375H12.5417Z"
                                                        fill={cardView ? '#FF8114' : '#A7A7A7'}/>
                                                </svg>
                                            </a>
                                            <a href="#" className="ml-[10px]" onClick={(e) => {
                                                e.preventDefault();
                                                setCardView(false);
                                            }}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M3 17.9375C2.7375 17.9375 2.51563 17.8468 2.33438 17.6656C2.15313 17.4843 2.0625 17.2625 2.0625 17V14.375C2.0625 14.1125 2.15313 13.8906 2.33438 13.7093C2.51563 13.5281 2.7375 13.4375 3 13.4375H17C17.2625 13.4375 17.4878 13.5281 17.676 13.7093C17.8642 13.8906 17.9583 14.1125 17.9583 14.375V17C17.9583 17.2625 17.8642 17.4843 17.676 17.6656C17.4878 17.8468 17.2625 17.9375 17 17.9375H3ZM3 12.25C2.7375 12.25 2.51563 12.1607 2.33438 11.9823C2.15313 11.8038 2.0625 11.5736 2.0625 11.2916V8.70829C2.0625 8.44579 2.15313 8.22045 2.33438 8.03225C2.51563 7.84406 2.7375 7.74996 3 7.74996H17C17.2625 7.74996 17.4878 7.84406 17.676 8.03225C17.8642 8.22045 17.9583 8.44579 17.9583 8.70829V11.2916C17.9583 11.5736 17.8642 11.8038 17.676 11.9823C17.4878 12.1607 17.2625 12.25 17 12.25H3ZM3 6.56246C2.7375 6.56246 2.51563 6.47175 2.33438 6.29033C2.15313 6.1089 2.0625 5.88017 2.0625 5.60413V2.99996C2.0625 2.73746 2.15313 2.51211 2.33438 2.32392C2.51563 2.13572 2.7375 2.04163 3 2.04163H17C17.2625 2.04163 17.4878 2.13572 17.676 2.32392C17.8642 2.51211 17.9583 2.73746 17.9583 2.99996V5.60413C17.9583 5.88017 17.8642 6.1089 17.676 6.29033C17.4878 6.47175 17.2625 6.56246 17 6.56246H3Z"
                                                        fill={cardView ? '#A7A7A7' : '#FF8114'}/>
                                                </svg>
                                            </a>
                                        </div>
                                        {/* search btn */}
                                        {/* <a href="#"
                                        className="btn btn-outline-black btn-lg-2 rounded-[8px] d-flex align-items-center justify-between px-[8px] py-[6px] ml-[8px]">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="9.21698" cy="9.21549" r="5.88495" stroke="#A7A7A7"
                                                        strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M16.668 16.6694L13.375 13.3764" stroke="#A7A7A7" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </a> */}
                                    </div>
                                </> : 
                                <div className="d-flex align-items-center flex-1">
                                    <button onClick={() => {setMode(true); setSelected([])}} className='actions-assets-panel-item'>Cancel</button>
                                    <button className='actions-assets-panel-item' onClick={()=>{ setSelected(prev => (mainFilter === 'My Goals' ? filteredContent : sortBy()).map(item => item.id)) }}>Select All</button>
                                    <button className={`actions-assets-panel-item ${selected?.length ? '' : 'block-disabled'}`} onClick={(e)=>{ duplicateSelected(e); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12.5 17.5H3.33333C2.87333 17.5 2.5 17.1267 2.5 16.6667V7.50002C2.5 7.04002 2.87333 6.66669 3.33333 6.66669H12.5C12.96 6.66669 13.3333 7.04002 13.3333 7.50002V16.6667C13.3333 17.1267 12.96 17.5 12.5 17.5Z" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M17.5002 4.16667V3.33333C17.5002 2.87333 17.1268 2.5 16.6668 2.5H15.8335" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M17.5002 12.5V13.3333C17.5002 13.7933 17.1268 14.1667 16.6668 14.1667H15.8335" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M5.8335 4.16667V3.33333C5.8335 2.87333 6.20683 2.5 6.66683 2.5H7.50016" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M10.8335 2.49998H12.5002" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M17.5 9.16667V7.5" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        Copy</button>
                                    <button className={`actions-assets-panel-item ${selected?.length ? '' : 'block-disabled'}`} onClick={(e)=>{ exportSelected(e); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M6.66683 8.33331H5.00016C4.07933 8.33331 3.3335 9.07915 3.3335 9.99998V15.8333C3.3335 16.7541 4.07933 17.5 5.00016 17.5H15.0002C15.921 17.5 16.6668 16.7541 16.6668 15.8333V9.99998C16.6668 9.07915 15.921 8.33331 15.0002 8.33331H13.3335" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M10.0002 2.5V11.6667" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7.50098 5L10.0001 2.5L12.5001 5" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        Export</button>
                                    { collections?.length ?  
                                    <button className={`actions-assets-panel-item ${selected?.length ? '' : 'block-disabled'}`} onClick={(e)=>{ addToCollection(e); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M9.9987 6.66699V13.3337" stroke="#331A04" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M13.3346 9.99967H6.66797" stroke="#331A04" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 17.5V17.5C5.8575 17.5 2.5 14.1425 2.5 10V10C2.5 5.8575 5.8575 2.5 10 2.5V2.5C14.1425 2.5 17.5 5.8575 17.5 10V10C17.5 14.1425 14.1425 17.5 10 17.5Z" stroke="#331A04" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        Add to Collection</button>
                                    : null }
                                    <button className={`actions-assets-panel-item ${selected?.length ? '' : 'block-disabled'}`} onClick={(e)=>{ deleteSelected(e); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.4487 17.5H6.54867C5.67617 17.5 4.95117 16.8266 4.88617 15.9558L4.13867 5.83331H15.8337L15.1112 15.9516C15.0487 16.8241 14.3228 17.5 13.4487 17.5V17.5Z" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M9.99967 9.16669V14.1667" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M3.33301 5.83335H16.6663" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M14.1663 5.83333L13.3222 3.58167C13.078 2.93083 12.4563 2.5 11.7613 2.5H8.23801C7.54301 2.5 6.92134 2.93083 6.67717 3.58167L5.83301 5.83333" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M12.8585 9.16669L12.5002 14.1667" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7.1418 9.16669L7.50013 14.1667" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        Delete</button>
                                </div>
                            }
                        </div> : null }
                        { mainFilter !== 'My Lessons' ? 
                        <div className="p-[20px] pt-[16px]">
                            {/* <div className={`saved-content-items-wrpr ${ !cardView ? 'row-view' : '' }`}> */}
                                { paginationData ? <InfiniteScroll
                                    key={mainFilter}
                                    className={`saved-content-items-wrpr ${ !cardView ? 'row-view' : '' }`}
                                    dataLength={filteredContent.length}
                                    next={()=>{ setCardsContentCount((prevState) => prevState + 1); }}
                                    hasMore={true}
                                    scrollableTarget="my-assets-page-section"
                                >
                                { mainFilter === 'My Collections' ?
                                    <button
                                        className={`saved-content-item cols-5 d-flex flex-col align-items-center justify-center add-new-word-btn ${ !cardView ? 'row-view' : '' }`}
                                        onClick={() => {
                                            setOpenedModal({
                                                name: 'collection',
                                            });
                                        }}
                                    >
                                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23.9913 38.7499C23.3763 38.7499 22.849 38.5395 22.4094 38.1187C21.9698 37.6978 21.75 37.1416 21.75 36.4499V26.2999H11.5C10.8875 26.2999 10.3594 26.0739 9.9156 25.6219C9.47187 25.1699 9.25 24.6316 9.25 24.0069C9.25 23.3356 9.47187 22.7916 9.9156 22.3749C10.3594 21.9582 10.8875 21.7499 11.5 21.7499H21.75V11.4999C21.75 10.8407 21.9726 10.3009 22.4179 9.88051C22.8632 9.46011 23.3932 9.24991 24.0079 9.24991C24.6226 9.24991 25.1583 9.46011 25.615 9.88051C26.0717 10.3009 26.3 10.8407 26.3 11.4999V21.7499H36.5C37.1267 21.7499 37.6667 21.9612 38.12 22.3837C38.5733 22.8062 38.8 23.3448 38.8 23.9995C38.8 24.6542 38.5733 25.2012 38.12 25.6407C37.6667 26.0802 37.1267 26.2999 36.5 26.2999H26.3V36.4499C26.3 37.1416 26.0725 37.6978 25.6175 38.1187C25.1625 38.5395 24.6204 38.7499 23.9913 38.7499Z" fill="currentColor"/>
                                        </svg>
                                        <span className="mt-[10px]">Add Collection</span>
                                    </button> : null
                                }
                                {/* {
                                    sortBy().length ? sortBy().map((item, index) => {
                                        return <div
                                            className={`saved-content-item cols-${data[mainFilter].config.cols} ${ !cardView ? 'row-view' : '' }`} key={index}
                                            style={{ minHeight: cardView ? '250px' : 'initial' }}
                                        >
                                            <h3>{ item.title }</h3>
                                            { cardView ? <p className="mb-[13px]">{ item.subtitle }</p> : null }
                                            { cardView && item?.content ? 
                                                <p>
                                                    { data[mainFilter].config?.content_length ? 
                                                    item.content.slice(0, data[mainFilter].config?.content_length) + (item.content.length > 255 ? '...' : '') : 
                                                    item.content }
                                                </p> 
                                            : null }
                                        </div>
                                    }) : <div className="d-flex justify-center align-items-center w-100 min-h-[255px]">
                                        <h3 className="text-[24px]">Not found</h3>
                                    </div>
                                } */}
                                {
                                    ((mainFilter === 'My Goals' ? filteredContent : sortBy())?.length) && !assetsNotFind ? (mainFilter === 'My Goals' ? filteredContent : sortBy()).map((item, index) => {
                                        const content = item.content ? JSON.parse(item.content) : null;
                                        const itemLink = +item?.product?.id === 1 ? 
                                            `/generated-page/blog/${item.id}` : +item?.product?.id === 4 ? 
                                            `/generated-page/landing/${item.id}` : +item?.product?.id === 8 ? 
                                            `/generated-page/general/${item.id}` : '#';

                                        return <div
                                            className="cols-5 animate__animated animate__fadeIn" key={item.id+'-'+index}
                                            onClick={handleClickItem(item)} style={{animationDelay: `${0.05 * index}s`}}
                                        >
                                            <a href={itemLink} onClick={(e)=>{e.preventDefault();}} className="saved-content-item-card"> 
                                                <div
                                                    className={`saved-content-item relative ${selected.includes(item.id) && !mode && 'selected-content-item'}`} key={index}
                                                    
                                                >
                                                    {cardView && (mode ? <div className='saved-content-actions'>
                                                        <svg 
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                setOpenActionSelect({open: openActionSelect?.id === item.id && openActionSelect.open ? false : true, id: item.id})
                                                            }} 
                                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none"
                                                        >
                                                            <g opacity="0.7">
                                                                <path
                                                                    d="M18.5024 12C18.5024 12.2762 18.2784 12.5002 18.0022 12.5002C17.7259 12.5002 17.502 12.2762 17.502 12C17.502 11.7237 17.7259 11.4998 18.0022 11.4998C18.2784 11.4998 18.5024 11.7237 18.5024 12"
                                                                    stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                                                    strokeLinejoin="round"/>
                                                                <path
                                                                    d="M12.4985 12C12.4985 12.2762 12.2745 12.5002 11.9983 12.5002C11.722 12.5002 11.498 12.2762 11.498 12C11.498 11.7237 11.722 11.4998 11.9983 11.4998C12.2745 11.4998 12.4985 11.7237 12.4985 12"
                                                                    stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                                                    strokeLinejoin="round"/>
                                                                <path
                                                                    d="M6.49651 12C6.49651 12.2762 6.27256 12.5002 5.9963 12.5002C5.72004 12.5002 5.49609 12.2762 5.49609 12C5.49609 11.7237 5.72004 11.4998 5.9963 11.4998C6.27256 11.4998 6.49651 11.7237 6.49651 12"
                                                                    stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                                                    strokeLinejoin="round"/>
                                                            </g>
                                                        </svg>

                                                            {openActionSelect.id === item.id && openActionSelect.open && <ul className='action-item-dropdown'>
                                                                {
                                                                    cardDropdownLists.map((menuItem, menuItemIndex)=>{
                                                                        return <li
                                                                            className={ menuItem?.divider ? 'action-item-dropdown-divider' : 'action-item-dropdown-item' }
                                                                            key={menuItemIndex}
                                                                            onClick={(e)=>{
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                setOpenActionSelect({open: false, id: null});
                                                                                return menuItem?.action ? menuItem?.action(e, item) : ()=>{}}
                                                                            }
                                                                        >
                                                                            {menuItem.title}
                                                                        </li>
                                                                    })
                                                                }
                                                            </ul>}
                                                    </div>
                                                    :
                                                        <div
                                                            className='saved-content-actions left-content-action'
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                if (!mode){
                                                                    selected.includes(item.id) ? 
                                                                        setSelected(prev => [...prev].filter(itemF => itemF !== item.id)) :
                                                                        setSelected(prev => [...prev, item.id])
                                                                }
                                                            }}
                                                        >
                                                            <svg className='no-select-ico pointer-events-none' xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                                                <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" fill="#1B1B1B" stroke="#333333"/>
                                                            </svg>
                                                            <svg className='select-ico pointer-events-none' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M16.6668 5.41669L7.50016 14.5834L3.3335 10.4167" stroke="#151515" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                        </div>)}

                                                    { item.text ? <p className="item-text">
                                                        {item.text} </p> : null }


                                                    { item.productId === 1 ? <p className="item-text">
                                                        {content && removeHtmlTags(content[0]?.versions[0]?.content || '')} </p> : null }
                                                    { item.productId === 11 ? <p className="item-text">
                                                        {content && removeHtmlTags(content[0]?.versions[0]?.content || '')} </p> : null }
                                                    { item.productId === 14 ? <p className="item-text">
                                                        {content && removeHtmlTags(content[0]?.versions[0]?.content || '')} </p> : null }
                                                    { item.productId === 9 ? <p className="item-text">
                                                        {content && (content?.[0]?.versions?.[0]?.description || '')} </p> : null }

                                                    { item.productId === 12 ? <p className="item-text">
                                                        {content && removeHtmlTags(content[0]?.versions[0]?.content || '')} </p> : null }
                                                    { item.productId === 7 ? <p className="item-text">
                                                        {content && removeHtmlTags(content[0]?.versions[0]?.content || '')} </p> : null }
                                                    { item.productId === 6 ? <p className="item-text">
                                                        {content && removeHtmlTags(content[0]?.versions[0]?.content || '')} </p> : null }
                                                    {mainFilter === 'My Collections' && <CollectionCard id={item.id}/>}
                                                    {
                                                        item.images?.length === 1 ? 
                                                        <img src={item.images[0]} alt="" className={`single-image ${item.category === 'My Brand Voices' && 'brand-voice-img'}`} /> :
                                                        item.images?.length > 1 ?
                                                        <div className="multiple-images-wrpr">
                                                            {
                                                                [...item.images].slice(0, 4).map((imageItem, imageItemIndex)=>{
                                                                    return <div className="img-wrpr" key={imageItemIndex}>
                                                                        <img src={imageItem} alt="" />
                                                                        {
                                                                            [...item.images].length > 4 && imageItemIndex === 3 ? 
                                                                                <div className="plus-images-count">{[...item.images].slice(4).length}+</div>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                })
                                                            }
                                                        </div> : null
                                                    }
                                                    {item?.product?.id === 2 && 
                                                        <CircleProgressBar 
                                                            cardView={cardView}
                                                            data={{
                                                                endDate: content.settings.goalEndDate,
                                                                checkpoints: content.tasks
                                                            }}
                                                        />}

                                                    { item.productId === 4 || item.productId === 8 || item.productId === 13 || item.productId === 15 ? 
                                                        <div style={{ overflow: 'hidden', maxHeight: "180px", pointerEvents: 'none' }}>
                                                            {
                                                                content[0]?.versions[0]?.activeContent || content[0].versions[0]?.content ? <div
                                                                    dangerouslySetInnerHTML={{ __html: content[0].versions[0]?.activeContent || content[0].versions[0]?.content }}
                                                                    style={{ zoom: '0.4', pointerEvents: 'none' }}
                                                                ></div> : null
                                                            }
                                                        </div>
                                                    : null }
                                                    {item.productId === 5 && <div>
                                                        <img src={getBotImageSrc(item?.bot?.logo)}  alt='bot' className='rounded-[8px] w-[44px] h-[44px]'/>
                                                        <p className='mt-[12px] text-[12px] text-[#A7A7A7] opacity-60 font-light
                                                        leading-[18px] h-[126px] overflow-hidden text-ellipsis'>{item?.bot?.description}</p>
                                                    </div>}
                                                    {item.productId === 10 && <div>
                                                        <div className='text-[12px] text-[#A7A7A7] opacity-60 font-light
                                                        leading-[18px] h-[180px] overflow-hidden text-ellipsis'>
                                                            <pre className='whitespace-pre-wrap text-4xl'>{item?.word}</pre>
                                                            <pre className='whitespace-pre-wrap'>{item?.definition}</pre>
                                                        </div>
                                                    </div>}
                                                </div>
                                                <div className="saved-content-item-bottom-content">
                                                    <h3 className="saved-content-item-name">{ item.name }</h3>
                                                    <p className="saved-content-item-category">{ item.category }</p>
                                                    {mainFilter === 'Saved Prompt' && <div className="saved-content-item-category-label">Category 2</div>}
                                                </div>
                                                {
                                                    !cardView ? <div className="card-date">
                                                        {item.createdAt ? format(new Date(item.createdAt), 'MM.dd.yyyy, H:mm') : ''}
                                                    </div> : null
                                                }
                                            </a>
                                        </div>
                                    })
                                    : <>{ (fetchLoading && fetchError) || assetsNotFind && <div className={`d-flex justify-center align-items-center w-100 min-h-[255px] `}>
                                        <h3 className="text-[24px]">Not found</h3>
                                    </div>}</>
                                }
                                </InfiniteScroll> : null }
                            {/* </div> */}
                        </div> :
                            <MyEmailNewsLetters innerAssets={true} />
                        }
                    </div>
                </div>
            </div>
            
            {openModal && <MyAssetsModal close={() => setOpenModal(false)}/>}
            {openGoalModal && <MyGoalModal setAssets={setAssets} goal={openGoalModal} close={() => setOpenGoalModal(null)} setOpenedModal={setOpenedModal} />}
            {addGoalModalOpened && <AddGoalModal close={() => setAddGoalModalOpened(false)}/>}
            {openedModal && <GeneratedContentModal isOpened close={() => setOpenedModal(null)} >
                { (openedModal === 'save' || openedModal?.name === 'save') && <div className="modal__body w-[450px] modal-body-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.99 26.9305L9.38 30.0585L9.896 33.9525C10.182 36.1125 11.88 37.8125 14.04 38.1005L17.944 38.6225L21.07 41.0105C22.8 42.3325 25.198 42.3325 26.928 41.0105L30.056 38.6205H30.052L33.948 38.1045C36.108 37.8185 37.808 36.1205 38.096 33.9605L38.616 30.0565C38.616 30.0585 39.824 28.4765 41.006 26.9305C42.328 25.2005 42.326 22.8025 41.006 21.0725L38.62 17.9425L38.104 14.0485C37.818 11.8885 36.12 10.1885 33.96 9.90048L30.054 9.38048L26.928 6.99248C25.198 5.67048 22.8 5.67048 21.07 6.99248L17.942 9.38048H17.946L14.05 9.89848C11.89 10.1845 10.19 11.8825 9.902 14.0425L9.38 17.9465C9.38 17.9445 8.172 19.5265 6.99 21.0725C5.67 22.8005 5.67 25.2005 6.99 26.9305V26.9305Z" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M29.6064 21.2041L22.6044 28.2061L18.3984 24.0041" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <div className="text-center">
                        <h3 className="text-[24px] font-[500] leading-none">
                            { openedModal?.title ? openedModal?.title : 'Saved' }
                        </h3>
                        { (openedModal?.name === 'save' && openedModal?.text) || openedModal === 'save' ?
                        <p className="mt-[20px] text-[14px] text-[#A7A7A7] leading-[22px]">
                            { openedModal?.text ? openedModal?.text : 'Your plan “Publish a Novel” has been successfully saved' }
                        </p> : null }
                    </div>
                    <button 
                        className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]" 
                        onClick={()=>{ setOpenedModal(null) }}
                    >
                        <span className="text-[14px] font-medium">OK</span>
                    </button>
                </div> }
                { openedModal?.name === 'confirm-delete' && <div className="modal__body w-[450px] modal-body-flex">
                    <div className="text-center">
                        <h3 className="text-[24px] font-[500]" style={{ lineHeight: '1.5' }}>
                            { openedModal?.title ? openedModal.title : 'Do you really want to delete?' }
                        </h3>
                    </div>
                    <div className="d-flex items-center gap-[10px]">
                        <button
                            className="btn bg-[#202020] border border-solid box-border rounded-full py-[10px] px-[36px]"
                            onClick={() => { setOpenedModal(null); }}
                        >
                            <span className="text-[14px] font-medium">Close</span>
                        </button>
                        <button
                            className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]"
                            onClick={() => {
                                openedModal?.action && openedModal?.action();
                            }}
                        >
                            <span className="text-[14px] font-medium">Confirm</span>
                        </button>
                    </div>
                </div> }
                { openedModal?.name === 'collection' && <div className="modal__body w-[600px]">
                    <button 
                        className="modal-close-btn" 
                        onClick={() => setOpenedModal(null)}
                    >
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 8.5L16 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M16 8.5L8 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    <h3 className="text-[18px] font-[500] text-[#E8E8E8]">Create a collection</h3>
                    <p className="my-[12px] text-[14px] font-[400] text-[#6F6F6F]">Enter collection name</p>
                    <div className='flex gap-[10px]'>
                        <Input 
                            value={collectionInput}
                            onChange={(e) => setCollectionInput(e.target.value)}
                            placeholder='Collection name'
                        />
                        <PrimaryButton onClick={async () => {
                            try {
                                const response = await api.collections.createCollection({
                                    data: {
                                        name: collectionInput
                                    },
                                    token
                                });
                                setOpenedModal(null)
                                setCollections([...collections, response])
                            } catch(e) {
                                console.log(e);
                            }
                        }}>Create Collection</PrimaryButton>
                    </div>
                </div> }
                { openedModal?.name === 'collection-add' && <div className="modal__body w-[600px]">
                    <button 
                        className="modal-close-btn" 
                        onClick={() => setOpenedModal(null)}
                    >
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 8.5L16 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M16 8.5L8 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    <h3 className="text-[18px] font-[500] text-[#E8E8E8]">Add selected items to collection</h3>
                    <p className="my-[12px] text-[14px] font-[400] text-[#6F6F6F]">Choose collection</p>
                    <div className='flex gap-[10px] items-center'>
                        <SelectBox 
                            propsOptions={[
                                {label: 'Select Collection', value: '', placeholder: true},
                                ...collections.map(item => ({
                                    label: item.name,
                                    value: item.id
                                }))
                            ]}
                            value={collectionSelectValue}
                            change={setCollectionSelectValue}
                            className='flex-1'
                        />
                        <PrimaryButton className='' onClick={async () => {
                            await Promise.all(
                                selected.map(async(id) => {
                                    await api.assets.changeAsset({
                                        id,
                                        data: {
                                            collectionId: collectionSelectValue
                                        },
                                        token
                                    })
                                } )
                            ).then((values) => {
                                setOpenedModal({
                                    name: 'save',
                                    title: `Selected assets added to collection`,
                                    text: ''
                                });

                                setSelected([]);
                            });
                            
                        }}>Add to Collection</PrimaryButton>
                    </div>
                </div> }
                { openedModal?.name === 'export' && <div className="modal__body w-[600px]">
                    <button 
                        className="modal-close-btn" 
                        onClick={() => setOpenedModal(null)}
                    >
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 8.5L16 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M16 8.5L8 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    <h3 className="text-[18px] font-[500] text-[#E8E8E8]">Export Generated Content</h3>
                    <p className="mb-[24px] text-[14px] font-[400] text-[#6F6F6F]">Select one of options to export content</p>
                    <div className="flex items-stretch gap-[9px]">
                        <a href="#" className="share-platforms-item share-item" onClick={(e)=>{
                            e.preventDefault();
                            return openedModal?.action ? openedModal.action(e, 'csv') : exportAction({id: openedModal?.id, ext: 'csv', close: true})
                        }}>
                            <span className="d-flex align-items-center mb-[14px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12 11V18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8 14.5H16" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M15 18H9C8.448 18 8 17.552 8 17V12C8 11.448 8.448 11 9 11H15C15.552 11 16 11.448 16 12V17C16 17.552 15.552 18 15 18Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            </span>
                            <span>CSV</span>
                        </a>
                        <a href="#" className="share-platforms-item share-item" onClick={(e)=>{
                            e.preventDefault();
                            return openedModal?.action ? openedModal.action(e, 'json') : exportAction({id: openedModal?.id, ext: 'json', close: true})
                        }}>
                            <span className="d-flex align-items-center mb-[14px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8 11H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8 14H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8 17H12.33" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            </span>
                            <span>JSON</span>
                        </a>
                        <a href="#" className="share-platforms-item share-item" onClick={(e)=>{
                            e.preventDefault();
                            return openedModal?.action ? openedModal.action(e, 'pdf') : exportAction({id: openedModal?.id, ext: 'pdf', close: true})
                        }}>
                            <span className="d-flex align-items-center mb-[14px]">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21.4817 7.483L18.1824 4.18367C17.7449 3.74617 17.151 3.5 16.5327 3.5H8.16536C6.8762 3.5 5.83203 4.54417 5.83203 5.83333V22.1667C5.83203 23.4558 6.8762 24.5 8.16536 24.5H19.832C21.1212 24.5 22.1654 23.4558 22.1654 22.1667V9.13267C22.1654 8.51433 21.9192 7.9205 21.4817 7.483V7.483Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M22.1654 9.33333H17.4987C16.8547 9.33333 16.332 8.81067 16.332 8.16667V3.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12.0625 19.3079H15.9358" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.6367 14.8758V13.5132C12.6367 13.1375 12.9412 12.833 13.3169 12.833H14.6796C15.0552 12.833 15.3597 13.1375 15.3597 13.5132V14.8758C15.3597 15.2515 15.0552 15.556 14.6796 15.556H13.3169C12.9412 15.5548 12.6367 15.2503 12.6367 14.8758Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.33203 20.3202V18.9575C9.33203 18.5818 9.63653 18.2773 10.0122 18.2773H11.3749C11.7505 18.2773 12.055 18.5818 12.055 18.9575V20.3202C12.055 20.6958 11.7505 21.0003 11.3749 21.0003H10.0122C9.63653 21.0003 9.33203 20.6958 9.33203 20.3202Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.9453 20.3202V18.9575C15.9453 18.5818 16.2498 18.2773 16.6255 18.2773H17.9881C18.3638 18.2773 18.6683 18.5818 18.6683 18.9575V20.3202C18.6671 20.6958 18.3626 21.0003 17.987 21.0003H16.6243C16.2498 21.0003 15.9453 20.6958 15.9453 20.3202H15.9453Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M16.6132 18.2817L14.8516 15.5283" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M11.3867 18.2817L13.1484 15.5283" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </span>
                            <span>PDF</span>
                        </a>
                    </div>
                </div> }
                {openedModal === 'share' && <div className="modal__body w-[600px]">
                    <button 
                        className="modal-close-btn" 
                        onClick={() => setOpenedModal(null)}
                    >
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 8.5L16 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M16 8.5L8 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    <h3 className="text-[18px] font-[500] text-[#E8E8E8]">Share Generated Content </h3>
                    <p className="mb-[24px] text-[14px] font-[400] text-[#6F6F6F]">Select on of options or copy link to share content</p>
                    <div className="share-platforms-items">
                        {
                            sharePopupActions.map(([Button, icon, title]) => {
                                return Button ? <Button className="share-platforms-item" key={title} url={linkToShare}>
                                    <span className="d-flex align-items-center mb-[14px]">
                                        { icon }
                                    </span>
                                    <span>{ title }</span>
                                </Button> : null
                            })
                        }
                    </div>
                    <div className="input-custom">
                        <label className="input-custom__label mb-[10px] text-[12px] font-[400]">Custom Link</label>
                        <div className="input-custom__wrap-input icon-right">
                            <input className="input-custom__input h-[40px]" type="text" placeholder="Custom Link" value={linkToShare} />
                            <a href="#" className="icon-input" style={{ pointerEvents: 'initial' }} onClick={(e)=>{
                                e.preventDefault();
                                copyCustomLink();
                            }}>
                                {
                                    linkCopied ? 
                                    <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.99 26.9305L9.38 30.0585L9.896 33.9525C10.182 36.1125 11.88 37.8125 14.04 38.1005L17.944 38.6225L21.07 41.0105C22.8 42.3325 25.198 42.3325 26.928 41.0105L30.056 38.6205H30.052L33.948 38.1045C36.108 37.8185 37.808 36.1205 38.096 33.9605L38.616 30.0565C38.616 30.0585 39.824 28.4765 41.006 26.9305C42.328 25.2005 42.326 22.8025 41.006 21.0725L38.62 17.9425L38.104 14.0485C37.818 11.8885 36.12 10.1885 33.96 9.90048L30.054 9.38048L26.928 6.99248C25.198 5.67048 22.8 5.67048 21.07 6.99248L17.942 9.38048H17.946L14.05 9.89848C11.89 10.1845 10.19 11.8825 9.902 14.0425L9.38 17.9465C9.38 17.9445 8.172 19.5265 6.99 21.0725C5.67 22.8005 5.67 25.2005 6.99 26.9305V26.9305Z" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M29.6064 21.2041L22.6044 28.2061L18.3984 24.0041" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg> : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="7.5" y="7.5" width="10" height="10" rx="2" stroke="#8F8F8F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M5 12.5H4.16667C3.24619 12.5 2.5 11.7538 2.5 10.8333V4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5H10.8333C11.7538 2.5 12.5 3.24619 12.5 4.16667V5" stroke="#8F8F8F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                }
                            </a>
                        </div>
                    </div>
                </div>}
            </GeneratedContentModal>}
        </div>
    )
}

export default MyAssets;