import React, {useEffect, useState} from 'react';
import {Accordion} from "../../components/ToolKit/Accordion";

const OneLineSquareBlocks = ({offTitle, withCustom=false, isCallFromGenerate, data, onChange, configData}) => {

    const [activeTitle, setActiveTitle] = useState(configData?.title || null)
    const [activeOptions, setActiveOptions] = useState(configData?.options || [])
    const [openBlock, setOpenBlock] = useState(false)
    const [openCustomField, setOpenCustomField] = useState(false)

    const [sugg, setSugg] = useState(data && Object.entries(data))

    const test = sugg && sugg.filter(el => el[0] === activeTitle)

    // TODO придумать решение конфига с добавлением Add Custom + сохранение значения
    // TODO Решить вопрос с высотой акордиона, в активном состоянии сделать авто

    useEffect(() => {
        if (withCustom && sugg) {
            setSugg((prevSugg) => {
                return prevSugg.map((el) => {
                    return [el[0], [...el[1], 'Add Custom']];
                });
            });
        }
    }, [withCustom]);

    useEffect(() => {
        if (!activeOptions) return
        onChange && onChange({title: activeTitle, options: activeOptions})
    }, [activeOptions, activeTitle])

    if (!sugg) {
        return <></>
    }

    return (
        <div className='mt-[36px]'>
            {!offTitle && <label className="input-custom__label mb-[10px]">Blog Suggestions</label>}
            <ul className={`d-flex ${isCallFromGenerate && 'flex-col'}`}>
                {sugg && sugg.map((el,ind) => {

                return <li key={ind} className={`oneline-square-item-li ${isCallFromGenerate ? 'one' : 'two'}`}
                >
                <div className={`oneline-square-item ${activeTitle === el[0] && 'active'}`}
                    onClick={() => {
                        setActiveTitle(el[0])
                        setOpenBlock(true)
                    }}>
                    {el[0]}
                </div>
                {
                   isCallFromGenerate && activeTitle === el[0]  && <OptionComponent openBlock={openBlock} sugg={el[1]} activeTitle={activeTitle}
                   value={activeOptions} openCustomField={openCustomField} setOpenCustomField={setOpenCustomField} setValue={setActiveOptions} />

                }
                </li>})}
            </ul>

            {!isCallFromGenerate && <OptionComponent openBlock={openBlock} sugg={data && data[activeTitle]} activeTitle={activeTitle}
            value={activeOptions} openCustomField={openCustomField} setOpenCustomField={setOpenCustomField} setValue={setActiveOptions} />}
        </div>
    );
};

export default OneLineSquareBlocks;

function OptionComponent({openBlock, openCustomField, sugg, value, setOpenCustomField, setValue, activeTitle}) {

    if (!sugg) {
        return <></>
    }

    return (
        <>
        <Accordion openBlock={openBlock}  className='inp-sugg'>
            <ul className='oneline-square-suggestions mt-[16px]'>
                {sugg && sugg.map((el, idx) => <li className={`gen4-input-suggestion ${value.includes(el) && 'selected-i-sugg'}`} key={idx}
                                           onClick={() => {
                                                if (!value.includes(el)) {
                                                    setValue(prev => [...prev, el])
                                                } else {
                                                    setValue(prev => prev.filter(i => i !== el))
                                                }
                                               if (el === 'Add custom') {
                                                   setOpenCustomField(true)
                                               } else {
                                                   setOpenCustomField(false)
                                               }
                                           }}>
                    {el}
                    <div>
                        <svg className='i-s-ico' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                             fill="none">
                            <path d="M8 3.33331V12.6666" stroke="white" stroke-width="1.3" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M3.33398 8H12.6673" stroke="white" stroke-width="1.3" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                        <svg className='i-s-ico-selected' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g clip-path="url(#clip0_1124_11601)">
                                <path d="M6.98242 9.11668L5.66576 7.78334C5.52353 7.65001 5.34847 7.58334 5.14057 7.58334C4.93267 7.58334 4.75218 7.65279 4.59909 7.79168C4.45464 7.94168 4.38242 8.12223 4.38242 8.33334C4.38242 8.54445 4.4602 8.71112 4.61576 8.83334L6.44909 10.6833C6.58546 10.8389 6.76121 10.9167 6.97636 10.9167C7.19151 10.9167 7.37687 10.8389 7.53242 10.6833L11.3158 6.90001C11.4713 6.75779 11.5491 6.5855 11.5491 6.38316C11.5491 6.18081 11.4722 5.99754 11.3184 5.83334C11.1647 5.70001 10.9807 5.63612 10.7666 5.64167C10.5525 5.64723 10.3744 5.71668 10.2324 5.85001L6.98242 9.11668ZM8.00139 15.0167C7.03719 15.0167 6.12549 14.8355 5.26631 14.4733C4.40711 14.111 3.65946 13.6118 3.02336 12.9757C2.38726 12.3396 1.88808 11.5928 1.52582 10.7351C1.16356 9.87744 0.982422 8.96651 0.982422 8.00231C0.982422 7.03526 1.16388 6.12367 1.52679 5.26753C1.88971 4.41138 2.38981 3.66419 3.02707 3.02596C3.66435 2.38771 4.41076 1.88494 5.26631 1.51762C6.12186 1.15032 7.03156 0.966675 7.99541 0.966675C8.96326 0.966675 9.87613 1.1498 10.734 1.51606C11.5919 1.88231 12.3394 2.38374 12.9767 3.02034C13.6139 3.65694 14.1158 4.40389 14.4825 5.26117C14.8491 6.11847 15.0324 7.03188 15.0324 8.00139C15.0324 8.9702 14.8488 9.88133 14.4815 10.7348C14.1142 11.5882 13.6114 12.3338 12.9731 12.9714C12.3349 13.6091 11.5885 14.1094 10.7339 14.4723C9.87926 14.8352 8.96843 15.0167 8.00139 15.0167Z" fill="#FF8114"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1124_11601">
                                    <rect width="16" height="16" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </li>)}
            </ul>
        </Accordion>
        <Accordion openBlock={openCustomField && openBlock} className='inp-sugg'>
            <div className='input-container w-100 mt-[10px] mb-[8px]'>
                <label htmlFor='addons' className='modal-component-label' >Add-on Name</label>
                <input id='addons'  className="input-custom__input input-mt-10 height-40" type="addons" placeholder='Type your add-on name...' ></input>
            </div>
        </Accordion>
        </>
    )
}