import classNames from 'classnames'
import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import { copyWordVersion, deleteWordVersion, getActiveWordIndex, getGeneratedWords, setActiveVersionData, setActiveWordIndex, setSelectedWordVersion, setWordData } from '../../redux/slices/newWordGeneratorSlice';
import { useDispatch } from 'react-redux';
import { ReactComponent as ArrowIcon } from '../../assets/img/icons/arrow-top.svg';
import { ReactComponent as UndoIcon } from '../../assets/img/icons/undo.svg';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

export default function NewWordCard({ index, isFullScreen, word, setCardFullScreen}) {
    const dispatch = useDispatch();
    const words = useSelector(getGeneratedWords);
    console.log(words);
   
    const exclude_words = words.map(({data}) => data.map(({word}) => word)).flat();
    const activeWordIndex = useSelector(getActiveWordIndex);
    const isOpen = index === activeWordIndex;
    const [activeVersionIndex, setActiveVersionIndex] = useState(0);
    const activeVersion = word.data[activeVersionIndex] || {};
    const [activeButton, setActiveButton] = useState(null);
    const [generatingVersion, setGeneratingVersion] = useState();
    const [preEditData, setPreEditData] = useState(null);
    const [editBtns, setEditBtns] = useState({index: 0, length: 0});
    const displayVersion = generatingVersion || activeVersion;
    const [customPrompt, setCustomPrompt] = useState('');
    const [formValues, setFormValues] = useState(activeVersion);
    const [history, setHistory] = useState(word.data.map(version => ({
        data: [version],
        currentIndex: 0
    })));
    const activeHistory = history[activeVersionIndex];
    console.log('HISTORY', history)
    useEffect(() => {
        if(word.data.length && !history.length) {
            console.log('setHistory')
            setHistory(word.data.map(version => ({
                data: [version],
                currentIndex: 0
            })))
        }
    }, [word, history]);
    useEffect(() => {
        setFormValues(activeVersion);
    }, [activeVersion])
    const wordsCount = Object.values(displayVersion).filter(value => typeof value === 'string').reduce((accumulator, value) => accumulator + value.trim().split(/\s+/).length, 0);



    const editorRef = useRef();

    const handleUndo = () => {
        editorRef.current.undo()
    }

    const handleRedo = () => {
        editorRef.current.redo()
    }

    useEffect(() => {

        if (activeButton === 'edit') {
            setPreEditData(formValues)
        } else {
            setPreEditData(null)
        }

    }, [activeButton])

    console.log(preEditData, 'formvalues 1212');

    return <li 
        className={classNames(
            'blog-post-card', 
            isFullScreen ? 'h-full' : isOpen ? 'h-auto' : 'h-[42px]',
            (isFullScreen && !isOpen) ? 'hidden': 'flex'
        )}
        
    >
        <div 
            className='flex items-center justify-between py-[11px] px-[16px] mob-flex-wrap'
        >
            <div 
                className="gen-con-header-title gap-[8px] cursor-pointer" 
                onClick={() => dispatch(setActiveWordIndex(index))}
            >
                <div className={`gen-header-arrow ${!isOpen && 'rotate-180'}`} onClick={(e) => {e.stopPropagation()}}>
                    <ArrowIcon />
                </div>
                <span className="leading-[18px]">{displayVersion?.word}</span>
            </div>
            <div className="gen-con-header-date leading-[18px]">
                Generated {format(new Date(word.generatedAt), 'MM.dd.yyyy, H:mm')}
            </div>
        </div>
        
        {isOpen && <>
            
            <div className='w-full max-w-[882px] mx-auto py-[24px] px-[16px] flex flex-col gap-[16px] h-[304px] overflow-y-scroll no-scroll flex-1'>
                    <p className='text-[14px] font-semibold leading-[24px] opacity-90'>
                        Part of Speech: <span className='text-[#A7A7A7] font-normal'>{displayVersion?.part_speech}</span>
                    </p>
                    <p className='text-[14px] font-semibold leading-[24px] opacity-90'>
                        Pronunciation: <span className='text-[#A7A7A7] font-normal'>{displayVersion?.pronunciation}</span>
                    </p>
                    <p className='text-[14px] font-semibold leading-[24px] opacity-90'>
                        Syllable Division: <span className='text-[#A7A7A7] font-normal'>{displayVersion?.syllable_division}</span>
                    </p>
                    <p className='text-[14px] font-semibold leading-[24px] opacity-90'>
                        Word Category: <span className='text-[#A7A7A7] font-normal'>{displayVersion?.word_category}</span>
                    </p>
                    <p className='text-[14px] font-semibold leading-[24px] opacity-90'>
                        Definition: <span className='text-[#A7A7A7] font-normal'>{displayVersion?.definition}</span>
                    </p>
                    <p className='text-[14px] font-semibold leading-[24px] opacity-90'>
                        Example: <span className='text-[#A7A7A7] font-normal italic'>{displayVersion?.example}</span>
                    </p>
            </div>
            <div className='flex justify-between items-center py-[12px] px-[16px] gap-[10px]'>
                {activeButton === 'customPrompt' ? <>
                    <input
                        className='bg-inherit h-[32px] text-[13px] flex-1 pl-[12px] pr-[8px]
                        border border-solid border-[#333] rounded-[8px]'
                        value={customPrompt}
                        onChange={(e) => setCustomPrompt(e.target.value)}
                        placeholder="Ex. Rewrite the introductory paragraph to create a stronger hook and engage the reader's interest from the start..."
                    />
                    <button 
                        className='goal-generator-action btn--primary-gradient btn'
                        style={{
                            height: 32,
                            padding: '0 12px',
                            fontSize: 13
                        }}
                        
                        disabled={!customPrompt}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M13.3337 4.33301L6.00033 11.6663L2.66699 8.33301" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Apply
                    </button>
                </> : <>
                    <ul className='flex gap-[8px]'>
                        {word.data.map((value, index) => <li
                            key={index}
                            className={classNames(`rounded-[8px] border border-solid border-[#333333] bg-[#1B1B1B]
                            p-[10px] h-[32px] flex items-center text-[13px] opacity-90 text-[#A7A7A7] cursor-pointer`, {
                                'border-[#FF8114] text-white': index === activeVersionIndex
                            })}
                            onClick={() => setActiveVersionIndex(index)}
                        >
                            <span>Version<span className='ml-[8px]'>{index + 1}</span></span>
                        </li>)}
                    </ul>
                    {activeButton === 'edit' ? <div className='flex gap-[8px] items-center'>
                        <button 
                            className='rounded-full border border-solid border-[#333333] bg-[#1B1B1B]
                            px-[12px] h-[32px] flex items-center text-[13px] opacity-90 text-[#A7A7A7]'
                            onClick={() => {setActiveButton(null); setFormValues(preEditData);}}
                        >
                            Discard
                        </button>
                        <button 
                            className='goal-generator-action btn--primary-gradient btn'
                            style={{
                                height: 32,
                                padding: '0 12px',
                                fontSize: 13
                            }}
                            onClick={() => {
                                dispatch(setActiveVersionData({
                                    activeVersionIndex,
                                    data: formValues
                                }));
                                setHistory((prev) => {
                                    const result = [...prev];
                                    const activeHistory = result[activeVersionIndex];
                                    activeHistory.data = activeHistory.data.slice(0, activeHistory.currentIndex + 1);
                                    const length = activeHistory.data.push(formValues);
                                    activeHistory.currentIndex = length - 1;
                                    return result;
                                });
                                setActiveButton(null);
                            }}
                        >
                            Apply Changes
                        </button>
                    </div> : <div className='flex gap-[16px] items-center'>
                        <span className='text-[13px] opacity-30'>
                            {wordsCount} words
                        </span>
                        
                    </div>}
                </>}
            </div>
        </>}
    </li>
};