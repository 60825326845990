import React from 'react';

const CardLoader = () => {
    return <div className='flex flex-col gap-[14px] my-[24px]'>
        <div className='generator-card-loader-item w-[280px]'/>
        <div className='generator-card-loader-item w-[220px]'/>
        <div className='generator-card-loader-item w-[900px]'/>
        <div className='generator-card-loader-item w-[800px]'/>
        <div className='generator-card-loader-item w-[830px]'/>
        <div className='flex gap-[14px]'>
            <div className='generator-card-loader-item w-[150px]'/>
            <div className='generator-card-loader-item w-[120px]'/>
            <div className='generator-card-loader-item w-[150px]'/>
        </div>
    </div>
};

export default CardLoader;