import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from "react-router-dom";
import { getAuthorized } from '../redux/slices/applicationSlice';

const PrivateRouteLayout = () => {
    const authorized = useSelector(getAuthorized);
    if (authorized === false) return <Navigate to='/sign-in' replace />
    if(authorized === true) return <Outlet />;
    return null;
};

export default PrivateRouteLayout;