import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    metaTags: [],
    activeMetaTagIndex: 0,
}

const metaTagsGeneratorSlice = createSlice({
    name: 'metaTagsGenerator',
    initialState,
    reducers: {
        setMetaTags: (state, action) => {
            state.metaTags = action.payload;
        },
    }
})


export const {
    setMetaTags,
} = metaTagsGeneratorSlice.actions

export const getGeneratedBlogs = (state) => state.blogGenerator.blogs;

export const getGeneratedMetaTags = (state) => state.metaTagsGenerator.metaTags;

export default metaTagsGeneratorSlice.reducer