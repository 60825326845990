import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { getToken } from '../../redux/slices/applicationSlice';
import api from '../../api';

export default function LikeButton({item}) {
    const [loacalLiked, setLocalLiked] = useState(!!item.isLiked);
    const [loacalCount, setLocalCount] = useState(item.likesCount);
    const token = useSelector(getToken);

    const handleLikeButton = async (id) => {
        console.log(token);
        try {
            const response = await api.newWords.likeNewWord({
                id,
                token
            });
            if(!response.success) return;
            setLocalLiked(response.data.isLike);
            setLocalCount(response.data.isLike ? loacalCount + 1 : loacalCount - 1);
            console.log(response);
        } catch (e) {
            console.log(e);
        }
    }
    return <button 
        className="btn btn-outline-black2 whitespace-nowrap w-full justify-between btn-md-4 r-300 select-36 hover-gradient"
        style={loacalLiked ? {
            background: 'rgba(255, 129, 20, 0.10)',
            border: 'none'
        }: {}}
        onClick={() => handleLikeButton(item.id)}
    >
        <span className="icon">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.245 5.42644V5.42644C17.0871 6.2728 17.5405 7.43053 17.497 8.6237C17.4536 9.81686 16.9172 10.9386 16.0158 11.7214L10.5446 16.4636C10.2321 16.7348 9.76777 16.7348 9.45527 16.4636L3.98412 11.7215C3.08266 10.9386 2.54629 9.81689 2.50286 8.62372C2.45942 7.43056 2.91281 6.27282 3.75495 5.42647V5.42647C5.33987 3.83337 7.88786 3.73994 9.58519 5.21269L9.99995 5.57219L10.4147 5.21267C12.1121 3.73994 14.66 3.83336 16.245 5.42644Z" 
                fill={loacalLiked ? '#FF8114' : 'none'}  stroke={loacalLiked ? '#FF8114' : '#A7A7A7'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </span>
        <span className="info text-14 ml-[8px]" style={loacalLiked ? {
            color: '#FF8114'
        }: {}}>
            {loacalCount}
        </span>
    </button>
}
