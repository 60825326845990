import Keywords from "./Keywords"
import Settings from "./Settings"

export const labelSuggestions = [
    'Create New Word',
    'Headline Generator',
    'Rewrite',
    'Grammar Correction',
    'Outlines'
]

export const navLinks = [
    {
        title: 'Settings',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M18 5.5H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 5.5H11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13 12.5H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 12.5H6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18 19.5H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 19.5H11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.4137 4.08575C18.1947 4.86675 18.1947 6.13275 17.4137 6.91375C16.6327 7.69475 15.3668 7.69475 14.5857 6.91375C13.8047 6.13275 13.8047 4.86675 14.5857 4.08575C15.3668 3.30475 16.6327 3.30475 17.4137 4.08575" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.41375 11.0857C10.1947 11.8668 10.1947 13.1327 9.41375 13.9137C8.63275 14.6947 7.36675 14.6947 6.58575 13.9137C5.80475 13.1327 5.80475 11.8668 6.58575 11.0857C7.36675 10.3047 8.63275 10.3047 9.41375 11.0857" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.4137 18.0857C18.1947 18.8668 18.1947 20.1327 17.4137 20.9137C16.6327 21.6947 15.3668 21.6947 14.5857 20.9137C13.8047 20.1327 13.8047 18.8668 14.5857 18.0857C15.3668 17.3047 16.6327 17.3047 17.4137 18.0857" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        Component: Settings
    },
    {
        title: 'Keywords',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.99902 11.961L13.3164 8.63361C12.5587 6.64849 13.2966 4.40405 15.0845 3.25586C16.8724 2.10767 19.2204 2.37029 20.7104 3.88511C22.2004 5.39992 22.4242 7.75193 21.2467 9.5206C20.0691 11.2893 17.8128 11.99 15.8405 11.1997L12.5391 14.5111H9.99902V11.961Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.6272 7.00314C17.6272 6.93407 17.5712 6.87809 17.5022 6.87809C17.4331 6.87809 17.3771 6.93407 17.3771 7.00314C17.3771 7.0722 17.4331 7.12819 17.5022 7.12819C17.5712 7.12819 17.6272 7.0722 17.6272 7.00314" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.99919 3.72754C5.65267 4.71418 2.68214 8.73245 3.01346 13.1772C3.34479 17.622 6.87823 21.1554 11.323 21.4868C15.7678 21.8181 19.786 18.8476 20.7727 14.501" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        Component: Keywords
    },
    // {
    //     title: 'Toolkit',
    //     icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    //         <path d="M16.2602 12.76L20.7002 8.32002C21.1003 7.91945 21.1003 7.27058 20.7002 6.87002L17.6302 3.80002C17.2297 3.39999 16.5808 3.39999 16.1802 3.80002L11.7402 8.24002" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M7.77682 12.2764L3.30002 16.6796C2.89999 17.0801 2.89999 17.729 3.30002 18.1296L6.37002 21.1996C6.77058 21.5996 7.41945 21.5996 7.82002 21.1996L12.26 16.7596" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M19.2089 15.8701L15.3701 19.7089" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M8.67188 5.17188L4.67188 9.17188" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path fillRule="evenodd" clipRule="evenodd" d="M18.622 15.122L7.58579 4.08579C7.21071 3.71071 6.70201 3.5 6.17157 3.5C5.64114 3.5 5.13243 3.71071 4.75736 4.08579L3.58579 5.25736C3.21071 5.63243 3 6.14114 3 6.67157C3 7.20201 3.21071 7.71071 3.58579 8.08579L14.622 19.122C15.0611 19.5611 15.5964 19.8919 16.1855 20.0883L20.341 21.4735C20.5207 21.5334 20.7188 21.4866 20.8527 21.3527C20.9866 21.2188 21.0334 21.0207 20.9735 20.841L19.5883 16.6855C19.3919 16.0964 19.0611 15.5611 18.622 15.122Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //     </svg>,
    //     Component: ToolKit
    // }, {
    //     title: 'Cover',
    //     icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    //         <path d="M8 6.5H4" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M6 8.5V4.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M20.9999 8.5L13.9141 15.5858" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M3.08594 16.5858L7.58594 12.0858C8.36699 11.3047 9.63331 11.3047 10.4144 12.0858L18.8931 20.5645" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M11 3.5H16C18.7614 3.5 21 5.73858 21 8.5V16.5C21 19.2614 18.7614 21.5 16 21.5H8C5.23858 21.5 3 19.2614 3 16.5V11.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M14 8.5H14.005H14H13.995H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //     </svg>,
    //     Component: null
    // }, {
    //     title: 'Tutorials',
    //     icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    //         <path d="M9.99902 21.5041H14.0007" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M9.99902 3.4963H14.0007" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M2.9963 10.499V14.5007" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M21.0041 10.499V14.5007" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M6.99776 21.5036V21.5036C4.7877 21.5036 2.99609 19.712 2.99609 17.502V17.502" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M6.99776 21.5036C4.7877 21.5036 2.99609 19.712 2.99609 17.502" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M17.002 3.49609C19.212 3.49609 21.0036 5.2877 21.0036 7.49776" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M2.99609 7.49776C2.99609 5.2877 4.7877 3.49609 6.99776 3.49609" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M21.0036 17.502C21.0036 19.712 19.212 21.5036 17.002 21.5036" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M11.9999 14.0003V12.4997C13.1049 12.4997 14.0007 11.6039 14.0007 10.4989C14.0007 9.39385 13.1049 8.49805 11.9999 8.49805C10.8948 8.49805 9.99902 9.39385 9.99902 10.4989" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M12.0002 16.4517C11.9737 16.4511 11.9518 16.4721 11.9512 16.4986V16.5017C11.9512 16.5215 11.9631 16.5394 11.9814 16.547C11.9998 16.5546 12.0209 16.5504 12.0349 16.5364C12.0489 16.5223 12.0531 16.5012 12.0455 16.4829C12.0379 16.4646 12.02 16.4527 12.0002 16.4527H11.9992" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M12.0502 16.5022C12.0502 16.5298 12.0278 16.5522 12.0002 16.5522C11.9726 16.5522 11.9502 16.5298 11.9502 16.5022C11.9502 16.4745 11.9726 16.4521 12.0002 16.4521" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M12.0006 16.4518C12.0282 16.4518 12.0506 16.4742 12.0506 16.5018" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //     </svg>,
    //     Component: null
    // }
]