import { add } from "date-fns"

export const step3Data = {
    "Positive": [
        "Reframing negative situations in a positive light ",
        "Focus on solutions & positive outcomes",
        "Affirmations",
        "Positive self talk",
        "Keep sentences positives",
        "Replace negative words with positive",
    ],
    "Category": [
        "Focus on solutions & positive outcomes category",
        "Reframing negative situations in a positive light category",
        "Affirmations category",
        "Positive self talk category",
        "Keep sentences positives category",
        "Replace negative words with positive category",
    ],
    "Templates": [
        "Affirmations templates",
        "Reframing negative situations in a positive light templates",
        "Focus on solutions & positive outcomes templates",
        "Positive self talk templates",
        "Keep sentences positives templates",
        "Replace negative words with positive templates",
    ],
    "Structure": [
        "Positive self talk structure",
        "Reframing negative situations in a positive light structure",
        "Focus on solutions & positive outcomes structure",
        "Affirmations structure",
        "Keep sentences positives structure",
        "Replace negative words with positive structure",
    ],
    "SEO": [
        "Keep sentences positives seo",
        "Reframing negative situations in a positive light seo",
        "Focus on solutions & positive outcomes seo",
        "Affirmations seo",
        "Positive self talk seo",
        "Replace negative words with positive seo",
    ]
}

export const step4Data = {
    "SEO": [
        "Reframing negative situations in a positive light ",
        "Focus on solutions & positive outcomes",
        "Affirmations",
        "Positive self talk",
        "Keep sentences positives",
        "Replace negative words with positive",
    ],
    "Meta Tags": [
        "Focus on solutions & positive outcomes category",
        "Reframing negative situations in a positive light category",
        "Affirmations category",
        "Positive self talk category",
        "Keep sentences positives category",
        "Replace negative words with positive category",
    ],
    "Social Media": [
        "Affirmations templates",
        "Reframing negative situations in a positive light templates",
        "Focus on solutions & positive outcomes templates",
        "Positive self talk templates",
        "Keep sentences positives templates",
        "Replace negative words with positive templates",
    ],
    "Ads": [
        "Positive self talk structure",
        "Reframing negative situations in a positive light structure",
        "Focus on solutions & positive outcomes structure",
        "Affirmations structure",
        "Keep sentences positives structure",
        "Replace negative words with positive structure",
    ],
    "Misc": [
        "Keep sentences positives seo",
        "Reframing negative situations in a positive light seo",
        "Focus on solutions & positive outcomes seo",
        "Affirmations seo",
        "Positive self talk seo",
        "Replace negative words with positive seo",
    ]
}

export const goalMilestones = [
    {
        id: 1,
        title: 'Complete the Manuscript',
        deadline: add(new Date(), {days: 1}),
        description: 'Start by writing your novel, aiming to complete the entire manuscript. This timing can vary widely based on the length and complexity of your novel.',
        labels: ['Text Generator'],
        generatedDate: new Date(),
        done: true,
    }, {
        id: 2,
        title: 'First Draft Review and Revision',
        deadline: add(new Date(), {days: 3}),
        description: 'After finishing the first draft, take a break before revising. Review your manuscript for plot holes, character development, and overall flow. Make necessary revisions.',
        labels: ['Text Generator'],
        generatedDate: new Date(),
        done: true,
    }, {
        id: 3,
        title: 'Beta Reader Feedback',
        deadline: add(new Date(), {days: 5}),
        description: 'Share your manuscript with beta readers (trusted individuals who provide feedback). Give them time to read and provide comments.',
        labels: [],
        generatedDate: new Date(),
        done: true
    }, {
        id: 4,
        title: 'Second Draft and Editing',
        deadline: add(new Date(), {days: 7}),
        description: 'Incorporate feedback from beta readers and conduct a thorough self-edit. Consider hiring a professional editor for structural and line editing.',
        labels: ['Text Generator'],
        generatedDate: new Date(),
        done: true
    }, {
        id: 5,
        title: 'Final Manuscript Review',
        deadline: add(new Date(), {days: 9}),
        description: "Review the manuscript one final time for any remaining issues. Ensure it's polished and ready for submission or publication.",
        labels: ['Text Generator'],
        generatedDate: new Date(),
        done: true
    }, {
        id: 6,
        title: 'Publishing Decisions',
        deadline: add(new Date(), {days: 11}),
        description: 'Decide whether you want to pursue traditional publishing or self-publishing. Research and choose your publishing path. If going traditional, query agents and publishers.',
        labels: [],
        generatedDate: new Date(),
        done: true
    }, {
        id: 7,
        title: 'Formatting and Cover Design',
        deadline: add(new Date(), {days: 13}),
        description: 'Prepare your manuscript for publication, which includes formatting it for print and e-book versions. Work on cover design or hire a professional designer.',
        labels: ['Cover Generator', 'Product Description'],
        generatedDate: new Date(),
        done: true
    }, 
    {
        id: 8,
        title: 'Publishing and Marketing',
        deadline: add(new Date(), {days: 15}),
        description: `Release your book according to your chosen publishing route. Timing can vary widely here. For traditional publishing, it depends on acceptance and the publisher's schedule. For self-publishing, it's your decision.`,
        labels: ['Landing Page Content', 'Tweet Campaign', 'SEO', 'Social Media Campaign'],
        generatedDate: new Date(),
        done: false
    }, 
]

export const partOfSpeechDefinitions = {
    '': 'Please select part of speech to see definition',
    Noun: 'Noun - A word that represents a person, place, thing, or idea',
    Pronoun: 'Pronoun - A word that takes the place of a noun, often used to avoid repetition.',
    Verb: 'Verb - A word that expresses an action, occurrence, or state of being.',
    Adjective: 'Adjective - A word that describes or modifies a noun or pronoun by providing more information about its qualities.',
    Adverb: 'Adverb - A word that modifies a verb, adjective, or other adverb, typically providing information about how, when, where, or to what degree.',
    Preposition: 'Preposition - A word that shows the relationship between a noun (or pronoun) and other words in a sentence, indicating location, direction, time, or manner.',
    Conjunction: 'Conjunction - A word that connects words, phrases, or clauses, indicating the relationship between them.',
    Interjection: 'Interjection - A word or phrase used to express strong emotion or surprise, often as a standalone expression.',
}

export const kitDetailsContent = {
    "blog": `<h1>AIKIT.AI’s Blog Generator: Elevate Your Content Creation</h1>
        <h2>Introduction:</h2>
        <p>Discover the power of Artificial Intelligence in content creation with AIKIT.AI's Blog Kit generator. Tailor-made for bloggers, marketers, and content creators, this advanced blogging  tool enables the crafting of engaging, SEO-optimized blog posts. It's designed to cater to a diverse range of topics, from educational articles to lifestyle blogs, ensuring your content is both compelling and audience-centric.</p>
        <h2>Maximizing AIKIT.AI's Blog Generator:</h2>
        <h3>Topic Selection:</h3>
        <p>Begin by entering a broad topic or explore AI-generated suggestions for trending subjects.</p>
        <p>Your choice of topic sets the stage for content that aligns with your audience's interests and search trends.</p>
        <h3>Detailed Blog Description:</h3>
        <h4>The Blog Description is essential for guiding the AI's content generation. </h4>
        <p>Include your main theme, specific angles, and key points.</p>
        <p>Example: "A detailed guide on urban gardening, covering space-efficient techniques, suitable plants, and beginner tips."</p>
        <p>Effective descriptions should be clear and specific, encompassing the scope and unique aspects of the topic. Incorporate relevant keywords for better SEO performance.</p>
        <h4>Customizing with Add-Ons:</h4>
        <p>Expand your content's reach with simultaneous add-ons like a competitor SEO analysis, social media ad creation, and crafting of meta tags. These add-ons ensure a comprehensive approach, enhancing your blog’s online visibility and engagement. </p>
        <h4>Integrating Suggestions for Enhanced Output:</h4>
        <p>Leverage AIKIT.AI's suggestions to integrate call-to-action prompts, power words, or other features, including advanced SEO optimization into your blog.</p>
        <p>These suggestions are integrated directly into the content generation process, enriching your blog with impactful and persuasive elements.</p>
        <h4>Select Settings for Target Audience, Tone and Word Count:</h4>
        <p>Tailor your blog's appeal by selecting settings for the target audience, desired tone, number of variations and word count, ensuring your content resonates with the intended readers.</p>
        <h3>Content Generation and Refinement:</h3>
        <p>• Define the number of variations, language, and word count.</p>
        <p>• Review the generated versions, each varying in style and keyword focus, and select or combine elements for a refined final piece.</p>`,

    "new-word": `<p>Join AIKIT.AI's mission to introduce 500,000 new words into various languages. Our goal is to see these innovations become part of everyday language. We challenge you to get creative, contribute and share your creations, helping to shape the future of global communication.</p>
        <h2>Detailed Instructions for Word Description:</h2>
        <p>Embark on a linguistic journey with AIKIT.AI's New Word Kit. Our innovative tool opens the door to creating new words, enriching the tapestry of global languages. This first-of-its-kind platform not only allows for the generation of new words but also aims to embed them into everyday usage. In a world where communication is ever-evolving, we invite you to be a pioneer in shaping the future of language.</p>
        <p>Our mission is ambitious – introduce over 500,000 new words into various languages. Whether it's through AI-driven suggestions or your imaginative inputs, each word has the potential to capture unique experiences, emotions, or any concept. As you navigate our user-friendly platform, you'll find detailed step-by-step instructions to guide you in crafting words that are not just creative but meaningful and resonant.</p>
        <h2>AI-Powered Word Creation:</h2>
        <p>Word Description: Provide a clear and imaginative description of the concept for your word. Think about what emotion, action, or idea you want this new word to represent.</p>
        <p>Example: For a word that captures the joy in small daily moments, describe it as "A term for finding happiness in everyday simplicity.</p>
        <p>Variants and Settings: Select the number of variants, language, category (like emotion, technology, nature), style (modern, traditional), and tone (positive, humorous). For uplifting words, use the 'keep it positive' option.</p>
        <h2>Crafting Your Own Word:</h2>
        <p>Input Details: Enter your invented word, its meaning, part of speech, and use in a sentence. Be creative and specific.</p>
        <p>Example: Word: "Dayglee" - Meaning: "The delight found in simple daily activities." Part of Speech: Noun. Usage: "Her morning coffee brought a sense of dayglee."</p>
        <p>Customization: Choose the category, tone, style, and number of variations. This helps tailor your word to fit different contexts and uses.</p>
        <p>Embrace the power of language with AIKIT.AI’s New Word Kit. Share your linguistic creations and see them become part of a broader language evolution. Let's innovate together, word by word!</p>`,
    
    "lesson-kit": `<p>Embark on a transformative educational journey with AIKIT.AI's Lesson Kit. This innovative AI lesson generator is designed to revolutionize the way we teach and learn, making education personalized, accessible, and adaptable. Whether you're a teacher crafting lessons for students, a professional developing skills, or a curious mind exploring new topics, the Lesson Kit caters to all. With its AI-driven approach, the platform ensures that each lesson is tailored to individual learning styles and objectives.</p>
        <h2>Using AIKIT.AI's Lesson Kit - A Step-by-Step Guide:</h2>
        <h3>Setting Your Learning Focus:</h3>
        <p>First, choose whether to base your lesson on a specific topic or a broader learning goal. For instance, select 'Spanish' for topic-based or 'Become proficient in Spanish' for goal-oriented lessons.</p>
        <h3>Generating Lesson Ideas:</h3>
        <p>After selecting your focus, type in your learning goal or choose a topic. The platform then suggests related goals or topics. For example, if your goal is 'Learn basic coding', it might suggest 'Introduction to Python'.</p>
        <h3>Setting Up & Customizing the Lesson:</h3>
        <p>Once you select the lessons you wish to create, click 'Generate'.</p> 
        <p>Proceed to Set Up each lesson. Enter 'More Details', providing additional background or specific requirements for the lesson, such as 'Focus on Python basics for beginners' as well as more details. After providing details, customize each lesson by choosing the type, number of lessons, language, subject level, reading level, special education needs, and competency mapping.</p>
        <h3>Scheduling and Enhancing Lessons:</h3>
        <p>Schedule lessons for email delivery at convenient times.Choose from add-ons like homework assignments or warm-ups to enhance the learning process.</p>
        <p>Join AIKIT.AI's Lesson Kit platform to unlock a world where teaching and learning are not just necessary tasks but exciting adventures. With practical, AI-driven solutions, education becomes a personalized experience, adaptable to everyone's unique learning path.</p>`,

    "goal": `<p>Welcome to AIKIT.AI's Goal Realization Kit, an AI-driven generator designed to revolutionize the way you plan and achieve your goals. In today’s fast-paced world, effective goal setting and task organization are crucial for success, whether in personal projects or professional settings. Our Goal Generator leverages advanced AI technology to create structured, actionable plans tailored to your specific objectives. From organizing a major event to launching a new business venture, this tool breaks down your aspirations into manageable milestones and tasks, ensuring a clear path to success.</p>
        <p>Discover how to effectively set and achieve your goals with AIKIT.AI's Goal Generator. This guide will help you through every step, from defining your goal to leveraging AI for planning and execution. Learn to break down your objectives into manageable milestones, anticipate challenges, and keep on track with personalized schedules.</p> 
        <h3>Identifying Your Goal:</h3>
        <p>Begin with pinpointing what you want to achieve. This could range from personal development goals like learning a new skill, to professional objectives such as launching a product. The Goal Generator starts by understanding your ambition.</p>
        <h3>Setting Milestones:</h3>
        <p>Break down your goal into smaller, achievable milestones. This step is crucial for maintaining momentum and tracking progress. The AI suggests a number of milestones based on your goal, but you have the flexibility to specify the exact number.</p>
        <h3>Describing Your Goal:</h3>
        <p>A clear, detailed description helps the AI understand the nuances of your goal. Whether it’s a complex project or a straightforward task, outlining key aspects, expected outcomes, and specific requirements gives the AI the necessary information to generate a comprehensive action plan.</p>
        <h3>Addressing Challenges:</h3>
        <p>Anticipate potential hurdles and input them into the system. This proactive approach allows the AI to tailor your plan with these challenges in mind, offering solutions or alternative paths.</p>
        <h3>Scheduling for Success:</h3>
        <p>Define your timeline by setting start and end dates. Consistent, well-paced progression is vital for goal achievement. The tool also provides an option to schedule regular email reminders, keeping you aligned and focused.</p>
        <h3>Leveraging AIKIT.AI’s Ecosystem:</h3>
        <p>The Goal Generator is part of a suite of tools designed to facilitate various aspects of project planning and execution. From content creation to lesson planning, explore how other AIKIT.AI tools can integrate into and streamline your goal achievement process.</p>
        <p>Through this detailed tutorial, AIKIT.AI's Goal Generator emerges as more than a planning tool – it's a comprehensive system designed to guide you from goal conception to completion, ensuring a structured, AI-optimized approach to achieving your aspirations.</p>`,

    "landing-page": `<h1>AIKIT.AI’s Landing Page Content Kit: Crafting Content with Precision and Creativity</h1>
        <h2>Introduction:</h2>
        <p>AIKIT.AI's Landing Page Content Kit is a text generator designed to redefine the creation of web content. Tailored for marketers, entrepreneurs, and content creators, this advanced AI-driven text to copy tool offers an unmatched level of customization and efficiency in crafting landing pages. Whether it's for a startup, an established business, or a personal blog, our Content Kit ensures that your landing page not only captures attention but arlso effectively communicates your message. By seamlessly integrating user input with a rich array of add-ons and AI-generated suggestions, the Content Kit delivers content that is both engaging and strategically aligned with your goals. From enhancing your SEO to refining your brand's voice, this tool is a comprehensive solution for anyone looking to elevate their digital presence.</p>
        <h2>Content Generation with Enhanced Options:</h2>
        <h3>Customized Content Creation:</h3>
        <p>Input details about your landing page, including the selection of keywords.</p>
        <p>The AI uses this information to generate targeted, relevant content.</p>
        <h3>Add-Ons and Suggestions for Enhanced Functionality:</h3>
        <p>Add-Ons: Choose from additional items like SEO enhancements, user engagement tools, or specific calls to action. These elements enrich your content, making it more effective and user-friendly.</p>
        <p>Suggestions: The tool offers suggestions that integrate with your content generation, such as stylistic elements or thematic ideas, enhancing the overall quality and appeal of your content.</p>
        <p>These features provide comprehensive options to tailor your landing page, ensuring it meets your specific needs and goals.</p>
        <h3>SEO as an Integral Part of Add-Ons and Suggestions:</h3>
        <p>While users can select keywords, advanced SEO optimization is available through the add-ons and suggestions, ensuring your content is not only user-focused but also search engine friendly.</p>
        <p>AIKIT.AI’s Landing Page Content Kit offers a unique blend of AI-driven efficiency and creative customization, ensuring your landing page stands out in both appeal and effectiveness.</p>`,

    "entire-website": `<h1>Entire Website Content Kit: Streamlined, AI-Powered Web Content Creation</h1>
        <h2>Introduction:</h2>
        <p>In the digital age, your website is your virtual storefront. Make it count with our AI-driven Entire Website Content Kit. This tool is a game-changer for businesses and individuals seeking to establish a compelling online presence. With an intuitive interface and advanced AI algorithms, it simplifies the process of creating consistent, engaging content for every page of your site, tailored to your unique brand voice.</p>
        <h2>Efficient Content Creation for Every Page:</h2>
        <p>• Specify the pages you need, like 'Home', 'About Us', or 'Services'.</p>
        <p>• The AI generates content fields for each page, offering tailored suggestions and add-ons.</p>
        <p>• Customize the language and tone to align with your brand identity.</p>
        <h2>Enhancing Your Site with SEO and User Engagement:</h2>
        <p>• Benefit from SEO-optimized content that boosts your online visibility.</p>
        <p>• Engage your audience with compelling, cohesive narratives across your site.</p>
        <p>This tool is your partner in crafting a dynamic, user-friendly website that resonates with your audience and strengthens your online presence.</p>`,

    "bot": `<p>AIKIT.AI’s Custom Bots, a revolutionary tool that brings the power of artificial intelligence to your fingertips. Customizable to serve as an assistant in any just about any role you need, these bots are more than just virtual assistants; they are specialized experts in your chosen field. Whether it's for business, education, personal well-being, or creative pursuits, our Custom AI assistant bots  are designed to adapt and respond to your specific needs. By selecting from a variety of roles, tones, and settings, you can create a bot that acts as your personal tutor, marketing advisor, wellness coach, or any other assistant you might need.</p>
        <h2>Versatile Applications of Custom Bots:</h2>
        <p>• In educational settings, they can tutor students in complex subjects.</p>
        <p>• For businesses, they can help generate marketing emails or offer strategic advice.</p>
        <p>• Wellness coaches for personal health and fitness goals.</p>
        <p>• Creative companions aiding in artistic or writing projects.</p>
        <h2>Setting Up Your Custom Bot:</h2>
        <p>• Begin by choosing a name and crafting a unique description for your bot.</p>
        <p>• Customize its role, tone, and response style to suit your specific needs.</p>
        <p>• Benefit from a bot that understands and aligns with your objectives.</p>
        <p>AIKIT.AI’s Custom Bots offer a new level of personalized AI interaction, ensuring you have the right assistance for any scenario. Embrace the future of personalized AI with a bot that’s tailor-made for you, enhancing productivity, learning, and decision-making.</p>`,

    "general": `<h1>AIKIT.AI’s General Kit: Versatile AI Content Creation for Everyone</h1>
        <h2>Introduction:</h2>
        <p>AIKIT.AI’s General Kit is not just for businesses but also a perfect tool for individuals seeking to create diverse content types. From unique cover letters, poems to crafting personalized recipes, this AI-driven tool tailors content for a variety of needs. Whether you're a startup, a blogger, or a hobbyist, the General Kit ensures your content stands out, combining engaging narrative with customization tools.</p>
        <h2>Utilizing the General Kit:</h2>
        <h3>Selecting Product Type:</h3>
        <p>Choose the content category, be it professional documents, creative writing, or personal projects.</p>
        <p>The AI adapts to a wide range of content types, understanding your specific needs.</p>
        <h3>Crafting Detailed Descriptions:</h3>
        <p>Provide clear instructions to shape the content, whether it’s a business plan, a recipe, or a personal blog post.</p>
        <p>The more specific your input, the more tailored the AI output.</p>
        <h3>Customization and Review:</h3>
        <p>The AI generates content based on your specifications.</p>
        <p>Review and refine the output to ensure it aligns perfectly with your vision.</p>
        <p>AIKIT.AI’s General Kit is your go-to tool for crafting high-quality, customized content, simplifying the creative process for both individuals and businesses. Its intuitive design and AI capabilities make content creation effortless and effective.</p>`,
    
    "essay": `<h1>AI Essay Writing Tool: Revolutionizing Academic and Creative Writing</h1>
        <h2>Introduction:</h2>
        <p>Unlock the power of AI to revolutionize your essay writing process with our innovative Essay Writing Kit. Designed for students, educators, and writers, this advanced AI-driven generator offers a seamless, user-friendly experience, turning the daunting task of essay writing into an enjoyable and efficient process. By simply entering your essay topic, our tool generates trending, relevant topic ideas, and provides customizable options for your essay. Whether it's crafting an argumentative piece, a detailed research essay, or a creative narrative, our essay generator caters to a wide array of writing styles and academic needs.</p>
        <h2>Optimized Content Creation:</h2>
        <p>• Enter your desired essay topic and receive AI-generated suggestions for trending and relevant themes.</p>
        <p>• Provide a detailed description to tailor the content to your specific needs and writing style.</p>
        <p>• Choose from a range of customization options, including add-ons and suggestions, to enhance your essay's effectiveness.</p>
        <p>Embrace a new era of writing with our AI Essay Writing Kit, your ideal partner for producing high-quality, engaging, and original essays. Say goodbye to writer's block and hello to academic success and creative freedom.</p>`,

    "etsy-listing": `<h1>AIKIT.AI's Etsy Listing Kit: Optimize Your Etsy Store with AI-Driven Tools</h1>
        <h2>Introduction:</h2>
        <p>Enhance your Etsy store effortlessly with AIKIT.AI's Etsy Listing Kit, a one-stop solution for generating all elements of your Etsy listings simultaneously. This innovative Etsy AI generator streamlines the creation of complete listings, including product descriptions, titles, and up to 13 targeted hashtags. Ideal for both new and existing Etsy sellers, our kit ensures each listing element is optimized for maximum impact and visibility, making it a valuable asset for anyone looking to succeed in the competitive Etsy marketplace.</p>
        <h2>Streamlining Your Listing Process:</h2>
        <p>• Input product details, shipping info, and return policies for custom-tailored content.</p>
        <p>• Utilize the AI-driven research tool to generate relevant, SEO-optimized hashtags.</p>
        <p>• Set your listing preferences including target audience, product category, number of variation and style for personalized content.</p>
        <p>AIKIT.AI's Etsy Listing Kit combines advanced AI technology with user-friendly functionality, making it an essential tool for elevating your Etsy store's online presence and appeal.</p>`,
    
    "product-description": `<h1>AIKIT.AI’s Product Description Kit: Perfect Descriptions for All E-commerce Platforms</h1>
        <h2>Introduction:</h2>
        <p>AIKIT.AI's Product Description Kit is an essential tool for e-commerce businesses across various platforms like Shopify, Etsy, Amazon, and eBay or any website. This AI-powered text to copy generator simplifies creating compelling, SEO-optimized product descriptions, tailored to engage customers and enhance visibility on any website or online marketplace. Whether you're a small Etsy shop or a large Amazon seller, our kit provides the versatility and precision needed to make your products stand out and drive sales across multiple digital storefronts.</p>
        <h2>Effortless Description Creation for Diverse Platforms:</h2>
        <p>• Choose product types and add details for AI-tailored descriptions.</p>
        <p>• Optimize your listings for Shopify, Etsy, Amazon, eBay, and more with specific add-ons and keywords.</p>
        <p>This product description writing generator is designed to elevate your online sales, providing a seamless way to craft high-quality product descriptions suitable for any e-commerce platform.</p>`,

    "meta-tags": `<h1>AIKIT.AI's Meta Tag Kit: Streamlining SEO for All Web Content</h1>
        <h2>Introduction:</h2>
        AIKIT.AI’s Meta Tag Kit is an essential SEO tool for optimizing your web content. Perfect for website owners and digital marketers, this kit allows you to select from a range of SEO elements or create custom tags. It simplifies the process of enhancing your website's search engine visibility by offering AI-generated meta tags based on detailed descriptions you provide.
        <h2>Step-by-Step Guide:</h2>
        <h3>Select SEO Elements or Add Custom Tags:</h3>
        <p>• Choose from available SEO elements like titles, descriptions, or alt tags, or input a custom tag requirement.</p>
        <h3>Input Detailed Descriptions:</h3>
        <p>• Provide a comprehensive description of your content to guide the AI in generating accurate, SEO-optimized meta tags.</p>
        <p>With AIKIT.AI’s Meta Tag Kit, enhance your website’s SEO performance easily and effectively, ensuring your content ranks higher and reaches the intended audience.</p>`,
    
    "prompt-assistant": `<h1>AIKIT.AI's Prompt Assistant Kit: Your Gateway to Creative AI Prompts</h1>
        <h2>Introduction:</h2>
        <p>AIKIT.AI’s Prompt Assistant Kit is a groundbreaking tool designed to enhance your experience with popular AI platforms like Chat GPT, Midjourney, and Kaiber.ai. This SEO-optimized webpage is dedicated to helping content creators, AI enthusiasts, and digital artists unlock their creative potential. By inputting basic ideas, our prompt generation tool refines and transforms these into detailed, effective prompts, tailored to specific AI applications and user preferences.</p>
        <h2>Crafting the Perfect Prompt:</h2>
        <p>• Begin with a basic prompt idea and watch the AI refine it into a detailed, engaging input for your chosen AI platform.</p>
        <p>• Customize your prompts with options for bot roles, tone, and target platforms, ensuring versatility and relevance.</p>
        <p>AIKIT.AI's Prompt Assistant Kit is not just a tool, it's your creative partner in exploring the limitless possibilities of AI, enhancing your projects and workflows across various platforms.</p>`,
    
    "press-release": `<h1>AIKIT.AI's Press Release Kit: Streamlining Your PR Campaigns</h1>
        <h2>Introduction:</h2>
        <p>AIKIT.AI's Press Release Kit is an essential tool for creating effective, SEO-optimized press releases. Designed for businesses, PR professionals, and organizations, this AI-powered kit simplifies the process of crafting and distributing impactful press releases. It guides users through every step, from constructing a compelling narrative to selecting the perfect target audience, ensuring that each press release achieves its desired impact.</p>
        <h2>Maximize Your Media Outreach:</h2>
        <p>• Tailor your press releases to resonate with your target audience.</p>
        <p>• Utilize suggestions and add-ons to enhance the appeal and newsworthiness of your release.</p>
        <p>AIKIT.AI’s Press Release Kit is your strategic partner in public relations, helping you generate buzz, attract media attention, and convey your message effectively in the digital age.</p>`,
    
    "social-media-campaign": `<h1>AIKIT.AI's Social Media Campaign Kit: Comprehensive Campaigns for Every Platform</h1>
        <h2>Introduction:</h2>
        <p>• Welcome to AIKIT.AI's Social Media Campaign Kit, a groundbreaking AI tool designed for crafting entire social media campaigns. From holiday promotions to timed sales, this versatile kit caters to all your campaign needs across various platforms. It's the perfect solution for businesses seeking to expand their digital reach and engage audiences effectively.</p>
        <h2>Creating Dynamic Campaigns:</h2>
        <p>• Campaign Objectives: Start by defining the purpose of your campaign. Whether it's boosting sales, increasing engagement, or promoting a special event.</p>
        <p>• Campaign Details: Fill out the 'Describe Your Campaign Details' field with specifics about your campaign, including themes like holiday specials or limited-time offers.</p>
        <p>• Variations and Promotions: Distinguish between the number of variations (different ways to present a single promotional concept) and the number of promotions (total unique posts for the campaign).</p>
        <p>• Language and Platform Diversity: Craft your content in any language and tailor it to any social media platform, ensuring your campaign has a global appeal.</p>
        <p>• Targeting and Scheduling: Select your target audience, set the campaign's duration, and choose keywords for enhanced SEO impact.</p>
        <h2>Conclusion:</h2>
        <p>AIKIT.AI's Social Media Campaign Kit is a comprehensive solution for your social media marketing, offering the flexibility and efficiency to run successful campaigns on any platform. With its ability to handle multiple aspects of campaign creation, this tool is invaluable for driving organic traffic and achieving your marketing goals.</p>`,

}