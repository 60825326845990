import React, { useRef } from 'react';
import { useClickOutside } from '../../helpers/useClickOutside';

const GeneratedContentModal = ({ isOpened, close, modalContentClassList, children, showCloseBtn, modalClassList = ''}) => {
    const modalRef = useRef();
    useClickOutside(modalRef, close);
    return <div className={`modal ${isOpened ? 'modal--show' : ''} ${modalClassList}`}>
        <div className="modal__dialog">
            <div className={modalContentClassList || 'modal__content w-auto'} ref={modalRef}>
                { showCloseBtn && 
                    <button className="modal-outside-close-btn" onClick={()=>{ close && close() }}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.8">
                                <path d="M22.5 7.5L7.5 22.5" stroke="#A7A7A7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M7.5 7.5L22.5 22.5" stroke="#A7A7A7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                        </svg>
                    </button>
                }
                {children}
            </div>
        </div>
    </div>
};

export default GeneratedContentModal;