import React from 'react';


const BottomPanel = ({ meData, wordsCount, isNotDone, setOpenedModal, deleteAction, blogs, duplicate, save  }) => {
    return <div className='generated-content-bottom-panel flex-wrap gap-[5px] lg:gap-[0px]'>
        <div className='flex items-center gap-[10px] lg:gap-[20px] mob-flex-wrap'>
            <div className='flex items-center gap-[10px]'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M15.8346 1.66663H4.16797C2.79297 1.66663 1.66797 2.79163 1.66797 4.16663V15.8333C1.66797 17.2083 2.79297 18.3333 4.16797 18.3333H15.8346C17.2096 18.3333 18.3346 17.2083 18.3346 15.8333V4.16663C18.3346 2.79163 17.2096 1.66663 15.8346 1.66663ZM9.16797 12.5H5.83464C5.3763 12.5 5.0013 12.125 5.0013 11.6666C5.0013 11.2083 5.3763 10.8333 5.83464 10.8333H9.16797C9.6263 10.8333 10.0013 11.2083 10.0013 11.6666C10.0013 12.125 9.6263 12.5 9.16797 12.5ZM12.5013 9.16663H5.83464C5.3763 9.16663 5.0013 8.79163 5.0013 8.33329C5.0013 7.87496 5.3763 7.49996 5.83464 7.49996H12.5013C12.9596 7.49996 13.3346 7.87496 13.3346 8.33329C13.3346 8.79163 12.9596 9.16663 12.5013 9.16663Z" fill="#9D9D9D"/>
                </svg>
                <div className='text-[14px] text-[#9D9D9D] leading-normal flex'>
                    <div className='w-[40px]'>{wordsCount}</div>
                    <span className='opacity-50'>words</span>
                </div>
            </div>
            {/* <div className='flex items-center gap-[10px]'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M12.1055 18.4167C11.7305 18.7917 11.2756 18.9792 10.7409 18.9792C10.2062 18.9792 9.7513 18.7917 9.3763 18.4167L1.58464 10.625C1.37398 10.4179 1.23105 10.1987 1.15582 9.96735C1.08059 9.73603 1.04297 9.49693 1.04297 9.25004V2.93754C1.04297 2.40421 1.22561 1.95143 1.59089 1.57921C1.95616 1.20698 2.40547 1.02087 2.9388 1.02087H9.2513C9.51208 1.02087 9.7616 1.06355 9.99986 1.14892C10.2381 1.23428 10.4608 1.3791 10.668 1.58337L18.418 9.31254C18.8069 9.70143 18.9978 10.1702 18.9909 10.7188C18.9839 11.2674 18.793 11.7292 18.418 12.1042L12.1055 18.4167ZM5.11528 6.14587C5.39959 6.14587 5.64611 6.04008 5.85486 5.82848C6.0636 5.61687 6.16797 5.36687 6.16797 5.07848C6.16797 4.80396 6.0636 4.56254 5.85486 4.35421C5.64611 4.14587 5.39959 4.04171 5.11528 4.04171C4.83096 4.04171 4.58464 4.14751 4.3763 4.3591C4.16797 4.57071 4.0638 4.81377 4.0638 5.08827C4.0638 5.37667 4.16797 5.62504 4.3763 5.83337C4.58464 6.04171 4.83096 6.14587 5.11528 6.14587Z" fill="#9D9D9D"/>
                </svg>
                <p className='text-[14px] text-[#9D9D9D] leading-normal'>
                    2.44
                    <span className='opacity-50 ml-[4px]'>credits used</span>
                </p>
            </div> */}
            <div className='flex items-center gap-[10px]'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M12.1055 18.4167C11.7305 18.7917 11.2756 18.9792 10.7409 18.9792C10.2062 18.9792 9.7513 18.7917 9.3763 18.4167L1.58464 10.625C1.37398 10.4179 1.23105 10.1987 1.15582 9.96735C1.08059 9.73603 1.04297 9.49693 1.04297 9.25004V2.93754C1.04297 2.40421 1.22561 1.95143 1.59089 1.57921C1.95616 1.20698 2.40547 1.02087 2.9388 1.02087H9.2513C9.51208 1.02087 9.7616 1.06355 9.99986 1.14892C10.2381 1.23428 10.4608 1.3791 10.668 1.58337L18.418 9.31254C18.8069 9.70143 18.9978 10.1702 18.9909 10.7188C18.9839 11.2674 18.793 11.7292 18.418 12.1042L12.1055 18.4167ZM5.11528 6.14587C5.39959 6.14587 5.64611 6.04008 5.85486 5.82848C6.0636 5.61687 6.16797 5.36687 6.16797 5.07848C6.16797 4.80396 6.0636 4.56254 5.85486 4.35421C5.64611 4.14587 5.39959 4.04171 5.11528 4.04171C4.83096 4.04171 4.58464 4.14751 4.3763 4.3591C4.16797 4.57071 4.0638 4.81377 4.0638 5.08827C4.0638 5.37667 4.16797 5.62504 4.3763 5.83337C4.58464 6.04171 4.83096 6.14587 5.11528 6.14587Z" fill="#EA8207"/>
                </svg>
                <p className='text-[14px] text-[#EA8207] leading-normal'>
                    {meData?.credits || 0}
                    <span className='opacity-50 ml-[4px]'>available credits</span>
                </p>
            </div>
        </div>
        <div className={`flex items-center gap-[8px] editor-footer-column ${isNotDone ? 'block-disabled' : ''}`}>
            <button 
                className="btn btn-outline-black btn-lg-2 rounded-full lg:mr-auto"
                style={{ padding: '9px' }} 
                onClick={() => setOpenedModal('export')}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M6.66536 8.33301H4.9987C4.07786 8.33301 3.33203 9.07884 3.33203 9.99967V15.833C3.33203 16.7538 4.07786 17.4997 4.9987 17.4997H14.9987C15.9195 17.4997 16.6654 16.7538 16.6654 15.833V9.99967C16.6654 9.07884 15.9195 8.33301 14.9987 8.33301H13.332" stroke="#A4A4A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.9987 2.5V11.6667" stroke="#A4A4A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7.5 5L9.99917 2.5L12.4992 5" stroke="#A4A4A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
            <button 
                className="btn btn-outline-black btn-lg-2 rounded-full"
                style={{ padding: '9px' }}
                onClick={() => setOpenedModal('share')}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g opacity="0.6">
                        <path d="M15.1483 18.7708C14.3747 18.7708 13.7086 18.4901 13.1502 17.9285C12.5917 17.367 12.3125 16.7107 12.3125 15.9595C12.3125 15.8524 12.3264 15.7236 12.3542 15.573C12.3819 15.4225 12.4097 15.2815 12.4375 15.1502L6.881 11.8958C6.61311 12.1736 6.3101 12.3993 5.97196 12.5729C5.63382 12.7465 5.27128 12.8333 4.88433 12.8333C4.12132 12.8333 3.46026 12.553 2.90117 11.9923C2.34206 11.4317 2.0625 10.7667 2.0625 9.99754C2.0625 9.22835 2.34206 8.56771 2.90117 8.01562C3.46026 7.46354 4.12132 7.1875 4.88433 7.1875C5.26636 7.1875 5.6242 7.26042 5.95785 7.40625C6.29151 7.55208 6.59722 7.76389 6.875 8.04167L12.4375 4.82725C12.4097 4.73092 12.3819 4.60502 12.3542 4.44956C12.3264 4.29412 12.3125 4.16242 12.3125 4.05446C12.3125 3.29131 12.5909 2.6331 13.1477 2.07985C13.7045 1.52662 14.3698 1.25 15.1434 1.25C15.902 1.25 16.5608 1.52686 17.1198 2.08058C17.6788 2.63429 17.9583 3.29575 17.9583 4.06496C17.9583 4.83415 17.6788 5.49479 17.1197 6.04687C16.5606 6.59896 15.8995 6.875 15.1365 6.875C14.7308 6.875 14.3665 6.81966 14.0436 6.70898C13.7207 6.5983 13.4334 6.40364 13.1815 6.125L7.60417 9.17102C7.63194 9.29242 7.65625 9.43784 7.67708 9.60727C7.69792 9.7767 7.70833 9.90928 7.70833 10.005C7.70833 10.1008 7.69792 10.2143 7.67708 10.3457C7.65625 10.477 7.63194 10.6034 7.60417 10.7248L13.1815 13.8542C13.4334 13.6311 13.715 13.4538 14.0265 13.3223C14.3379 13.1908 14.7079 13.125 15.1365 13.125C15.8995 13.125 16.5606 13.4053 17.1197 13.966C17.6788 14.5267 17.9583 15.1916 17.9583 15.9608C17.9583 16.7161 17.6796 17.3733 17.1222 17.9323C16.5648 18.4913 15.9069 18.7708 15.1483 18.7708Z" fill="white"/>
                    </g>
                </svg>
            </button>
            <div className='goal-generator-divider' />
            <button 
                className="btn btn-outline-black btn-lg-2 rounded-full"
                style={{ padding: '9px' }}
                onClick={() => setOpenedModal({
                    name: 'confirm-delete',
                    action: () => deleteAction(),
                    title: `Do you really want to delete?`
                })}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M5.26953 17.9378C4.76692 17.9378 4.32182 17.7509 3.93424 17.3772C3.54666 17.0034 3.35286 16.5583 3.35286 16.042V4.54199H2.14453V2.64616H6.89453V1.66699H13.082V2.64616H17.832V4.54199H16.6237V16.042C16.6237 16.5583 16.4348 17.0034 16.0569 17.3772C15.679 17.7509 15.2291 17.9378 14.707 17.9378H5.26953ZM7.4362 14.417H8.97786V6.12533H7.4362V14.417ZM11.0404 14.417H12.6029V6.12533H11.0404V14.417Z" fill="white" fillOpacity="0.6"/>
                </svg>
            </button>
            <button 
                className="btn btn-outline-black btn-lg-2 rounded-full"
                style={{ padding: '9px' }}
                onClick={() => setOpenedModal({
                    name: 'confirm-delete',
                    action: () => duplicate(),
                    title: `Do you really want to duplicate?`
                })}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M2.5 15.833V16.7854C2.50014 17.1798 2.81985 17.4995 3.21428 17.4997H4.16667" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.832 7.5H11.7844C12.1788 7.50014 12.4986 7.81985 12.4987 8.21428V9.16667" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8.33464 17.4997H6.66797" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8.33464 7.49967H6.66797" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.832 17.4997H11.7844C12.1788 17.4995 12.4986 17.1798 12.4987 16.7854V15.833" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M2.5 9.16667V8.21428C2.50014 7.81985 2.81985 7.50014 3.21428 7.5H4.16667" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12.4987 11.667V13.3337" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M2.4987 11.667V13.3337" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.66797 5V4.16667C6.66785 3.7246 6.8434 3.30061 7.15599 2.98802C7.46858 2.67543 7.89257 2.49988 8.33464 2.5H15.8346C16.2767 2.49988 16.7007 2.67543 17.0133 2.98802C17.3259 3.30061 17.5014 3.7246 17.5013 4.16667V11.6667C17.5014 12.1087 17.3259 12.5327 17.0133 12.8453C16.7007 13.1579 16.2767 13.3335 15.8346 13.3333H15.0013" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
            <button 
                className="btn btn-outline-black btn-lg-2 rounded-full lg:h-[40px] px-[14px]"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M9.99967 15.5208L6.35384 17.0625C5.72884 17.3264 5.13162 17.2747 4.56217 16.9075C3.99273 16.5403 3.70801 16.0086 3.70801 15.3125V3.85417C3.70801 3.33767 3.89976 2.8891 4.28326 2.50846C4.66677 2.12782 5.10697 1.9375 5.60384 1.9375H14.3955C14.8981 1.9375 15.3432 2.12782 15.7308 2.50846C16.1184 2.8891 16.3122 3.33767 16.3122 3.85417V15.3125C16.3122 16.0086 16.024 16.5403 15.4476 16.9075C14.8712 17.2747 14.2705 17.3264 13.6455 17.0625L9.99967 15.5208Z" fill="white" fillOpacity="0.6"/>
                </svg>
                <span className='ml-[8px] text-[14px] font-medium opacity-60 text-white hidden lg:inline-block'>Add to My Collection</span>
            </button>
            <button 
                className={`generated-button btn generated-content-bottom-panel-btn ${isNotDone ? 'block-disabled' : ''}`}
                onClick={() => save()}
            >
                Save
            </button>
        </div>
    </div>;
};

export default BottomPanel;