import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { useDebounce } from "../../../helpers/useDebounce";
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';

import { GenerateFormContext, initialState } from '../GeneratorContextComponent';

const Presets = ({ generateSuggestedList, initialCourse }) => {
    const [updatedTimes, setUpdatedTimes] = useState(0);
    const isUpdated = useDebounce(updatedTimes, 1500);

    const [inputValue, setInputValue] = useState("");

    const [generateForm, setGenerateForm] = useContext(GenerateFormContext);
    const { selectedMethod, courses, } = generateForm;

    const clearItem = {
        method: "goal",
        text: "",
        backgroundInformation: "test",
        language: "English",
        country: "Global",
        numberOfLessons: "1",
        lessonType: "Analysis",
        subjectLevel: "Advanced",
        readingLevel: "Emergent",
        specialEducation: "ESE (special needs)",
        competencyMapping: "Critical Thinking, Team Collaboration",
        addons: [
            {
                title: "Transcription",
                key: "transcription"
            },
        ],
        scheduleLessonFrequency: null,
        scheduleRepeatOn: null,
        scheduleBegins: null,
        scheduleTime: null,
        scheduleIsSendOneMessage: false,
        haveSettings: true,
    };

    const data = [
        {
            category: "IT",
            items: [
                {
                    ...clearItem,
                    method: 'goal',
                    text: 'Understanding the Basics of Computer Programming'
                },
                {
                    ...clearItem,
                    method: 'goal',
                    text: 'Exploring Network Security and Cyber Defense'
                },
                {
                    ...clearItem,
                    method: 'goal',
                    text: 'Mastering Web Development: From HTML to JavaScript'
                }
            ]
        },
        {
            category: "Languages",
            items: [
                {
                    ...clearItem,
                    method: 'topic',
                    text: 'Unraveling Linguistic Roots: The Etymology of Modern Vocabulary'
                },
                {
                    ...clearItem,
                    method: 'topic',
                    text: 'The Syntax Symbiosis: How Structure Shapes Meaning'
                },
                {
                    ...clearItem,
                    method: 'topic',
                    text: 'Tongues of the World: An Introduction to Global Linguistics'
                }
            ]
        },
        {
            category: "Music",
            items: [
                {
                    ...clearItem,
                    method: 'goal',
                    text: 'Understanding Musical Theory: An Introduction'
                },
                {
                    ...clearItem,
                    method: 'goal',
                    text: 'The Evolution of Classical Music'
                },
                {
                    ...clearItem,
                    method: 'goal',
                    text: 'Jazz Improvisation Techniques'
                }
            ]
        },
        {
            category: "Digital Marketing",
            items: [
                {
                    ...clearItem,
                    method: 'topic',
                    text: 'Fundamentals of Digital Marketing Strategies'
                },
                {
                    ...clearItem,
                    method: 'topic',
                    text: 'Crafting Engaging Content for Social Media'
                },
                {
                    ...clearItem,
                    method: 'topic',
                    text: 'Search Engine Optimization: Mastering the SERPs'
                }
            ]
        },
        {
            category: "Design",
            items: [
                {
                    ...clearItem,
                    method: 'goal',
                    text: 'Design Fundamentals Exploration'
                },
                {
                    ...clearItem,
                    method: 'goal',
                    text: 'Color Theory and Application in Design'
                },
                {
                    ...clearItem,
                    method: 'goal',
                    text: 'Typography and Communication Design'
                }
            ]
        },
        {
            category: "Finance and Investing",
            items: [
                {
                    ...clearItem,
                    method: 'topic',
                    text: 'Fundamentals of Financial Markets: An Introduction'
                },
                {
                    ...clearItem,
                    method: 'topic',
                    text: 'Portfolio Management Strategies: Maximizing Returns'
                },
                {
                    ...clearItem,
                    method: 'topic',
                    text: 'The Psychology of Investing: Behavioral Finance Insights'
                }
            ]
        },
    ];

    const [selectedCategory, setSelectedCategory] = useState(data[0].category);
    const inheritBlockSize = false;
    const sliderBlockStyle = {};

    const filteredItems = data?.find(itemF => itemF.category === selectedCategory)?.items?.filter(item => item.text.toLowerCase().includes(inputValue?.toLowerCase()))

    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slidePrev();
    }, []);
  
    const handleNext = useCallback(() => {
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slideNext();
    }, []);

    // useEffect(() => {
    //     if(inputValue?.length){
    //         generateSuggestedList(inputValue);
    //     }
    // }, [isUpdated])

    return <>
        <div className='flex mb-[16px]'>
            <div className="flex-1 w-100 relative">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute pointer-events-none' style={{ top: '50%', left: '8px', transform: "translateY(-50%)" }}>
                    <g opacity="0.3">
                        <circle cx="9.21698" cy="9.21503" r="5.88495" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16.668 16.67L13.375 13.377" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                </svg>
                <input
                    className='w-100 h-[40px] p-[8px] px-[14px] pl-[36px] bg-[#1B1B1B] rounded-[8px] border border-solid border-[#333] text-[14px]'
                    placeholder='Search newsletters...'
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        // setUpdatedTimes((prevState) => prevState + 1);
                    }}
                />
            </div>
            <a href="#" className="h-[40px] p-[8px] bg-[#1B1B1B] rounded-[8px] border border-solid border-[#333] ml-[10px] hover:opacity-70">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.944 19H5.056C4.473 19 4 18.527 4 17.944V10.556C4 9.973 4.473 9.5 5.056 9.5H6.944C7.527 9.5 8 9.973 8 10.556V17.944C8 18.527 7.527 19 6.944 19V19Z" stroke="#767676" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8 10.5716L11.649 5.82058C12.328 4.93558 13.654 4.91358 14.363 5.77458V5.77458C14.628 6.09558 14.772 6.49958 14.772 6.91558V10.1866H17.868C18.469 10.1866 19.03 10.4866 19.364 10.9856L19.693 11.4766C19.988 11.9176 20.074 12.4666 19.927 12.9756L18.568 17.6976C18.346 18.4686 17.641 18.9996 16.839 18.9996H10.55C10.05 18.9996 9.572 18.7916 9.232 18.4256L8 17.0996" stroke="#767676" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </a>
        </div>

        <Swiper
            ref={sliderRef}
            loop={false}
            spaceBetween={5}
            slidesPerView={"auto"}
            navigation={{
                // prevEl: <div>prev</div>,
                // nextEl: <div>next</div>,
                // disabledClass: "swiper-button-disabled"
            }}
            modules={[Navigation]}
            className={`blog-sugg-swiper mb-[16px] ${inheritBlockSize ? 'px-[34px]' : ''}`}
            style={sliderBlockStyle}
        >
            { data.map(item => item.category).map((category, index) => <SwiperSlide className='w-auto' key={index}>
                <div 
                    className={classNames(`h-[40px] px-[14px] flex-1 flex items-center justify-center 
                    rounded-[8px] cursor-pointer gap-[8px]`, 
                    category === selectedCategory ? 'bg-inherit border border-solid border-orange-primary text-orange-primary' : 'bg-[#202020] text-[#ffffff80] border border-solid border-[#202020]')}
                    onClick={() => setSelectedCategory(selectedCategory === category ? null : category)}
                >
                    <span className='text-[13px] font-medium'>{category}</span>
                    {category === 'Keep It Positive' && <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M4.73893 12.7891C4.37921 12.7891 4.0681 12.6637 3.8056 12.4128C3.5431 12.162 3.41185 11.8451 3.41185 11.462V5.55577C3.41185 5.38725 3.44152 5.22329 3.50087 5.06388C3.56021 4.90448 3.66179 4.75733 3.8056 4.62243L6.15352 2.1141C6.33824 1.91966 6.56428 1.79084 6.83164 1.72764C7.099 1.66445 7.36393 1.69118 7.62643 1.80785C7.85977 1.93424 8.03234 2.12382 8.14414 2.3766C8.25595 2.62938 8.28268 2.89674 8.22435 3.17868L7.84518 5.01618H12.2931C12.6412 5.01618 12.9518 5.15424 13.225 5.43035C13.4982 5.70646 13.6348 6.01077 13.6348 6.34327V6.88766C13.6348 7.01007 13.6178 7.15095 13.5837 7.3103C13.5497 7.46964 13.5132 7.59938 13.4743 7.69952L11.7243 11.7683C11.6174 12.0405 11.4183 12.2787 11.1271 12.4829C10.8359 12.687 10.5272 12.7891 10.2011 12.7891H4.73893ZM1.61629 12.7891C1.37444 12.7891 1.16602 12.703 0.991016 12.5309C0.816016 12.3587 0.728516 12.1483 0.728516 11.8995V5.92035C0.728516 5.67729 0.810143 5.46584 0.973399 5.28597C1.13665 5.10611 1.34325 5.01618 1.59319 5.01618H1.63694C1.87716 5.01618 2.0872 5.10611 2.26706 5.28597C2.44692 5.46584 2.53685 5.67729 2.53685 5.92035V11.8995C2.53685 12.1483 2.44674 12.3587 2.26653 12.5309C2.08631 12.703 1.87443 12.7891 1.63087 12.7891H1.61629Z" fill="#FF8114"/>
                    </svg>}
                </div>
            </SwiperSlide>)}
            <button
                className={`w-[24px] h-[24px] flex items-center justify-center bg-[#111] rounded-full absolute top-[8px] left-${inheritBlockSize ? '0' : '[-34px]'} z-10`}
                onClick={handlePrev}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M14.5 8L10.5 12L14.5 16" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
            <button
                className={`w-[24px] h-[24px] flex items-center justify-center bg-[#111] rounded-full absolute top-[8px] right-${inheritBlockSize ? '0' : '[-34px]'} z-10`}
                onClick={handleNext}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M10.5 16L14.5 12L10.5 8" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        </Swiper>
        
        
        <div className="d-flex items-center justify-between mb-[12px]">
            <div>
                <div className="text-[12px] text-[#A7A7A7] mb-[4px]">Preset Newsletters</div>
                <div className="text-[12px] text-[#A7A7A7] mb-[4px] opacity-40">{courses?.length} selected</div>
            </div>
            <div className="d-flex items-center">
                <a
                    href="#" className="btn btn-outline-black btn-lg-2 rounded-full text-[13px] h-[32px] px-[14px] mr-[8px]"
                    onClick={(e)=>{
                        e.preventDefault();
                        setGenerateForm((prevState) => ({
                            ...prevState,
                            courses: filteredItems
                        }) );
                    }}
                >
                    Select All
                </a>
            </div>
        </div>
        <ul>
            { filteredItems?.map((item, index) => {
                return <li key={index} className="mb-[12px]">
                    <div className="d-flex items-center justify-between">
                        <a
                            href="#" className="d-flex items-center min-h-[30px] text-[14px] text-[#E8E8E8]"
                            onClick={(e)=>{
                                e.preventDefault();
                                setGenerateForm((prevState) => ({
                                    ...prevState,
                                    courses: prevState.courses?.map(itemC => itemC.text).includes(item.text) ?
                                        prevState.courses.filter(itemC => itemC.text !== item.text) : 
                                        [...prevState.courses, item]
                                }) );
                            }}
                        >
                            <div className={`checkbox-item-square mr-[12px] ${courses?.map(itemC => itemC.text).includes(item.text) ? 'active' : ''}`}>
                                {
                                    courses?.map(itemC => itemC.text).includes(item.text) && <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.6654 3.79199L5.2487 10.2087L2.33203 7.29199" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                }
                            </div>
                            { item.text }
                        </a>
                    </div>
                </li>
            }) }
        </ul>
        
    </>;
};

export default Presets;