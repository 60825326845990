import React, { useContext, useEffect, useState, version } from 'react';
import { today } from '../../../configs/goalFormSettings';
import { format, isAfter, isToday, isYesterday, subWeeks } from 'date-fns';
import { useDispatch } from 'react-redux';
import { setTextGeneratorLoading } from '../../../redux/slices/TextGeneratorSlice';
import api from '../../../api';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getToken } from '../../../redux/slices/applicationSlice';
import { setGoalState } from '../../../redux/slices/goalGeneratorSlice';
import { toast } from 'react-toastify';

const getChatHistory = (history) => {
    const result = {
        Today: [],
        Yesterday: [],
        'Previous 7 days': [],
    };
    history.forEach((item) => {
        const date = new Date(item.createdAt);
        if(isToday(date)) {
            result.Today.push(item);
        } else if(isYesterday(date)) {
            result.Yesterday.push(item);
        } else if(isAfter(date, subWeeks(new Date(), 1))) {
            result['Previous 7 days'].push(item);
        }
    })
    return result;
} 

const GoalHistory = ({setIsLoading, updateHistory, setUpdateHistory}) => {
    const [currentId, setCurrentId] = useState(null);
    const [history, setHistory] = useState([]);
    const [filteredHistory, setFilteredHistory] = useState([]);
    const dispatch = useDispatch();
    const { id } = useParams();
    const token = useSelector(getToken);
    useEffect(() => {
        if(!updateHistory && history.length) return;
        setUpdateHistory(false);
        api.assetsHistories.getAssetHistory({
            id,
            token
        }).then(response => {
            const result = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            const activeItem = result.find(({isActive}) => isActive === true);
            setHistory(result);
            setFilteredHistory(result)
            setCurrentId(activeItem?.id);
        })
    }, [id, token, updateHistory, setUpdateHistory, history.length])
    useEffect(() => {
        if(!currentId) return;
        setIsLoading(true);
        api.assetsHistories.getAssetHistoryContent({
            id,
            itemId: currentId,
            token
        }).then(response => {
            if(!response.success) return;
            dispatch(setGoalState(JSON.parse(response.data.content)));
        }).finally(() => setIsLoading(false));
    }, [currentId, token, id, dispatch, setIsLoading])

    const handleDeleteItem = async (e, id) => {
        e.stopPropagation();
        const toastId = toast.loading('Deleting History...');
        const response = await api.assetsHistories.deleteHistoryItem({
            id,
            token
        });
        toast.update(toastId, {
            render: response.message,
            type: response.success ? 'success' : 'error', 
            isLoading: false,
            autoClose: 5000
        });
        if(!response.success) return;
        const result = history.filter(item => item.id !== id);
        setHistory(result);
        setFilteredHistory(result);
        setCurrentId(response.data.activeHistoryId);
    }
    return <div className='py-[13px] px-[16px] flex flex-col h-full'>
        <div>
            <h2 className='text-[14px] font-medium leading-normal'>History</h2>
            <p className='text-[12px] leading-[14px] opacity-40'>Manage previous versions of your plan</p>
        </div>
        <div className='flex-1 mt-[20px]'>
            <div className='flex items-center h-[40px] px-[8px] rounded-[8px] gap-[8px]
            border border-solid border-[#333333] bg-[#1B1B1B]'>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" opacity={0.3}>
                    <circle cx="10.217" cy="10.216" r="5.88495" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M17.668 17.67L14.375 14.377" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <input
                    className='bg-inherit text-[14px] flex-1'
                    placeholder='Search by title...'
                    onChange={(e) => {
                        console.log(history);
                        setFilteredHistory(history.filter(({name}) => name.toLowerCase().includes(e.target.value.toLowerCase())))
                    }}
                />
            </div>
            <ul className='flex-auto mt-[16px] gap-[16px] flex flex-col overflow-y-scroll no-scroll'>
                {Object.entries(getChatHistory(filteredHistory)).map(([title, items]) => items.length ? <li key={title}>
                    <p className='mt-[16px] mb-[10px] text-[12px] leading-[14px]'>
                        {title}
                    </p>
                    <ul className='flex flex-col gap-[4px]'>
                        {items.map(({id, name, createdAt}) => <li
                            key={id}
                            className={`rounded-[8px] border border-solid h-[48px] flex cursor-pointer
                            items-center justify-between px-[12px] bg-inherit hover:bg-[#1B1B1B] group
                            ${id === currentId ? 'border-orange-primary': 'border-transparent'}`}
                            onClick={() => {
                                setCurrentId(id);
                            }}
                        >
                            <div>
                                <div className={`text-[13px] font-medium leading-normal
                                ${id === currentId ? 'text-orange-primary': 'text-white'}`}>
                                    {name}
                                </div>
                                <div className='text-[11px] opacity-30'>{format(new Date(createdAt), 'MM.dd.yyyy, H:mm')}</div>
                            </div>
                            <button 
                                className='w-[30px] h-[30px] rounded-full items-center justify-center
                                border border-solid border-[#333333] hidden group-hover:flex'
                                onClick={(e) => handleDeleteItem(e, id)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <path d="M3.69063 12.5563C3.33613 12.5563 3.0239 12.4262 2.75392 12.1659C2.48395 11.9056 2.34896 11.5933 2.34896 11.2292V3.17925H2.18854C2.00625 3.17925 1.85373 3.11673 1.73099 2.99171C1.60825 2.86669 1.54688 2.7087 1.54688 2.51775C1.54688 2.3268 1.60819 2.16814 1.73081 2.04175C1.85343 1.91536 2.00601 1.85216 2.18854 1.85216H4.84271C4.84271 1.65772 4.90651 1.49487 5.03411 1.36362C5.16172 1.23237 5.32153 1.16675 5.51354 1.16675H8.48854C8.67934 1.16675 8.84127 1.23359 8.97434 1.36727C9.10742 1.50095 9.17396 1.66258 9.17396 1.85216H11.7978C11.995 1.85216 12.1562 1.91718 12.2817 2.04722C12.4071 2.17726 12.4698 2.33338 12.4698 2.51558C12.4698 2.71139 12.4071 2.87096 12.2817 2.99427C12.1562 3.11759 11.9953 3.17925 11.799 3.17925H11.6385V11.2292C11.6385 11.5933 11.507 11.9056 11.2438 12.1659C10.9806 12.4262 10.665 12.5563 10.2969 12.5563H3.69063ZM5.20729 9.56675C5.20729 9.70383 5.26101 9.8256 5.36845 9.93206C5.47589 10.0385 5.59888 10.0917 5.73741 10.0917C5.87594 10.0917 6.00208 10.0385 6.11583 9.93206C6.22958 9.8256 6.28646 9.70383 6.28646 9.56675V4.84175C6.28646 4.70053 6.22889 4.57287 6.11375 4.45876C5.9986 4.34464 5.86966 4.28758 5.72692 4.28758C5.58832 4.28758 5.46706 4.34464 5.36316 4.45876C5.25925 4.57287 5.20729 4.70053 5.20729 4.84175V9.56675ZM7.73021 9.56675C7.73021 9.70383 7.78636 9.8256 7.89866 9.93206C8.01096 10.0385 8.13638 10.0917 8.27491 10.0917C8.41344 10.0917 8.53958 10.0385 8.65333 9.93206C8.76708 9.8256 8.82396 9.70383 8.82396 9.56675V4.84175C8.82396 4.70053 8.76784 4.57287 8.65559 4.45876C8.54335 4.34464 8.41441 4.28758 8.26877 4.28758C8.12726 4.28758 8.00213 4.34464 7.89337 4.45876C7.78459 4.57287 7.73021 4.70053 7.73021 4.84175V9.56675Z" fill="#9D9D9D"/>
                                </svg>
                            </button>
                        </li>)}
                    </ul>
                </li> : null)}
            </ul>
        </div>
    </div>;
};

export default GoalHistory;