import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
} from "react-share";
import Share from "../Blog/Share";

const Modals = ({
    openedModal,
    setOpenedModal,
    closeModal,
    showHelpModal,
    GeneratedContentModal,
    handleExport,
    id,
    HelpModal,
    docx = false,
}) => {
    const [linkCopied, setLinkCopied] = useState(false);

    const sharePopupActions = [
        [
            TwitterShareButton,
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.9">
                    <path d="M22.4591 6C21.6891 6.35 20.8591 6.58 19.9991 6.69C20.8791 6.16 21.5591 5.32 21.8791 4.31C21.0491 4.81 20.1291 5.16 19.1591 5.36C18.3691 4.5 17.2591 4 15.9991 4C13.6491 4 11.7291 5.92 11.7291 8.29C11.7291 8.63 11.7691 8.96 11.8391 9.27C8.27906 9.09 5.10906 7.38 2.99906 4.79C2.62906 5.42 2.41906 6.16 2.41906 6.94C2.41906 8.43 3.16906 9.75 4.32906 10.5C3.61906 10.5 2.95906 10.3 2.37906 10V10.03C2.37906 12.11 3.85906 13.85 5.81906 14.24C5.18979 14.4122 4.52916 14.4362 3.88906 14.31C4.16067 15.1625 4.6926 15.9084 5.41008 16.4429C6.12756 16.9775 6.99451 17.2737 7.88906 17.29C6.37269 18.4904 4.49306 19.1393 2.55906 19.13C2.21906 19.13 1.87906 19.11 1.53906 19.07C3.43906 20.29 5.69906 21 8.11906 21C15.9991 21 20.3291 14.46 20.3291 8.79C20.3291 8.6 20.3291 8.42 20.3191 8.23C21.1591 7.63 21.8791 6.87 22.4591 6Z" fill="white"/>
                </g>
            </svg>,
            'Twitter',
            () => {}
        ], [   
            FacebookShareButton,
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.9">
                    <path d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z" fill="white"/>
                </g>
            </svg>,
            'Facebook',
            () => {}
        ], [
            null,
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.9">
                    <path d="M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z" fill="white"/>
                </g>
            </svg>,
            'Instagram',
            () => {}
        ], [
            LinkedinShareButton,
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.9">
                    <path d="M19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19ZM18.5 18.5V13.2C18.5 12.3354 18.1565 11.5062 17.5452 10.8948C16.9338 10.2835 16.1046 9.94 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17C14.6813 12.17 15.0374 12.3175 15.2999 12.5801C15.5625 12.8426 15.71 13.1987 15.71 13.57V18.5H18.5ZM6.88 8.56C7.32556 8.56 7.75288 8.383 8.06794 8.06794C8.383 7.75288 8.56 7.32556 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19C6.43178 5.19 6.00193 5.36805 5.68499 5.68499C5.36805 6.00193 5.19 6.43178 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56ZM8.27 18.5V10.13H5.5V18.5H8.27Z" fill="white"/>
                </g>
            </svg>,
            'LinkedIn',
            () => {}
        ], [
            EmailShareButton,
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M3 6.8V6.8C3 7.4 3.3 7.9 3.8 8.3L9.8 12.4C11.2 13.3 12.9 13.3 14.3 12.4L20.3 8.4C20.7 7.9 21 7.4 21 6.8V6.8C21 5.8 20.2 5 19.2 5H4.8C3.8 5 3 5.8 3 6.8Z" stroke="#E9E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3 7V17C3 18.1 3.9 19 5 19H19C20.1 19 21 18.1 21 17V7" stroke="#E9E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.58594 18.4124L9.67994 12.3184" stroke="#E9E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14.3594 12.3604L20.4114 18.4124" stroke="#E9E9E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>,
            'Email',
            () => {}
        ]
    ];

    const linkToShare = `https://www.example.com/file/D7keGdZyuXb0NHmSL1Qyvr/aikit.ai?type=design&node-id=416%3A20984&mode=design&t=u2AAYmBG6J987C5w-1`;

    const copyCustomLink = () => {
        const textarea = document.createElement('textarea');
        textarea.value = linkToShare;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        setLinkCopied(true);

        toast.success('Copied successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

        setTimeout(() => {
            setLinkCopied(false);
        }, 1500);
    }

    return <>
        { openedModal && <GeneratedContentModal isOpened close={() => setOpenedModal(null)} >
            { openedModal === 'export' && <div className="modal__body w-full lg:w-[600px]">
                <button 
                    className="modal-close-btn" 
                    onClick={() => setOpenedModal(null)}
                >
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 8.5L16 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16 8.5L8 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <h3 className="text-[18px] font-[500] text-[#E8E8E8]">Export Generated Content</h3>
                <p className="mb-[24px] text-[14px] font-[400] text-[#6F6F6F]">Select one of options to export content</p>
                <div className="flex items-stretch gap-[9px] mob-flex-wrap">
                    <button
                        onClick={() => handleExport(id, 'csv')}
                        className="share-platforms-item share-item min-w-[110px]"
                    >
                        <span className="d-flex align-items-center mb-[14px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12 11V18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 14.5H16" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M15 18H9C8.448 18 8 17.552 8 17V12C8 11.448 8.448 11 9 11H15C15.552 11 16 11.448 16 12V17C16 17.552 15.552 18 15 18Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        </span>
                        <span>CSV</span>
                    </button>
                    <button
                        onClick={() => handleExport(id, 'json')}
                        className="share-platforms-item share-item min-w-[110px]">
                        <span className="d-flex align-items-center mb-[14px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 11H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 14H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 17H12.33" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        </span>
                        <span>JSON</span>
                    </button>
                    <button
                        onClick={() => handleExport(id, 'pdf')}
                        className="share-platforms-item share-item min-w-[110px]"
                    >
                        <span className="d-flex align-items-center mb-[14px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.4817 7.483L18.1824 4.18367C17.7449 3.74617 17.151 3.5 16.5327 3.5H8.16536C6.8762 3.5 5.83203 4.54417 5.83203 5.83333V22.1667C5.83203 23.4558 6.8762 24.5 8.16536 24.5H19.832C21.1212 24.5 22.1654 23.4558 22.1654 22.1667V9.13267C22.1654 8.51433 21.9192 7.9205 21.4817 7.483V7.483Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22.1654 9.33333H17.4987C16.8547 9.33333 16.332 8.81067 16.332 8.16667V3.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12.0625 19.3079H15.9358" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M12.6367 14.8758V13.5132C12.6367 13.1375 12.9412 12.833 13.3169 12.833H14.6796C15.0552 12.833 15.3597 13.1375 15.3597 13.5132V14.8758C15.3597 15.2515 15.0552 15.556 14.6796 15.556H13.3169C12.9412 15.5548 12.6367 15.2503 12.6367 14.8758Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.33203 20.3202V18.9575C9.33203 18.5818 9.63653 18.2773 10.0122 18.2773H11.3749C11.7505 18.2773 12.055 18.5818 12.055 18.9575V20.3202C12.055 20.6958 11.7505 21.0003 11.3749 21.0003H10.0122C9.63653 21.0003 9.33203 20.6958 9.33203 20.3202Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.9453 20.3202V18.9575C15.9453 18.5818 16.2498 18.2773 16.6255 18.2773H17.9881C18.3638 18.2773 18.6683 18.5818 18.6683 18.9575V20.3202C18.6671 20.6958 18.3626 21.0003 17.987 21.0003H16.6243C16.2498 21.0003 15.9453 20.6958 15.9453 20.3202H15.9453Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16.6132 18.2817L14.8516 15.5283" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M11.3867 18.2817L13.1484 15.5283" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                        <span>PDF</span>
                    </button>
                    { docx ? <button
                        onClick={() => handleExport(id, 'docx')}
                        className="share-platforms-item share-item min-w-[110px]"
                    >
                        <span className="d-flex align-items-center mb-[14px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 11H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 14H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 17H12.33" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        </span>
                        <span>Word</span>
                    </button> : false }
                </div>
            </div> }
            { openedModal === 'share' && <Share setOpenedModal={setOpenedModal} /> }
            { openedModal === 'duplicate' || openedModal?.name === 'duplicate' && <div className="modal__body w-full lg:w-[450px] modal-body-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path d="M6 38V40.2857C6.00034 41.2323 6.76765 41.9997 7.71428 42H10" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M26 18H28.2857C29.2323 18.0003 29.9997 18.7677 30 19.7143V22" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M20 42H16" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M20 18H16" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M26 42H28.2857C29.2323 41.9997 29.9997 41.2323 30 40.2857V38" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6 22V19.7143C6.00034 18.7677 6.76765 18.0003 7.71428 18H10" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M30 28V32" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6 28V32" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M16 12V10C15.9997 8.93904 16.421 7.92146 17.1713 7.17125C17.9215 6.42104 18.939 5.99971 20 6H38C39.061 5.99971 40.0785 6.42104 40.8288 7.17125C41.579 7.92146 42.0003 8.93904 42 10V28C42.0003 29.061 41.579 30.0785 40.8288 30.8288C40.0785 31.579 39.061 32.0003 38 32H36" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <div className="text-center">
                    <h3 className="text-[24px] font-[500] leading-none">
                        Duplicated
                    </h3>
                    <p className="mt-[20px] text-[14px] text-[#A7A7A7] leading-[22px]">
                        { openedModal?.title ? openedModal?.title : 'Plan successfully duplicated' }
                    </p>
                </div>
                <button 
                    className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]" 
                    onClick={() => setOpenedModal(null)}
                >
                    <span className="text-[14px] font-medium">OK</span>
                </button>
            </div> }
            { (openedModal === 'save' || openedModal?.name === 'save') && <div className="modal__body w-full lg:w-[450px] modal-body-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.99 26.9305L9.38 30.0585L9.896 33.9525C10.182 36.1125 11.88 37.8125 14.04 38.1005L17.944 38.6225L21.07 41.0105C22.8 42.3325 25.198 42.3325 26.928 41.0105L30.056 38.6205H30.052L33.948 38.1045C36.108 37.8185 37.808 36.1205 38.096 33.9605L38.616 30.0565C38.616 30.0585 39.824 28.4765 41.006 26.9305C42.328 25.2005 42.326 22.8025 41.006 21.0725L38.62 17.9425L38.104 14.0485C37.818 11.8885 36.12 10.1885 33.96 9.90048L30.054 9.38048L26.928 6.99248C25.198 5.67048 22.8 5.67048 21.07 6.99248L17.942 9.38048H17.946L14.05 9.89848C11.89 10.1845 10.19 11.8825 9.902 14.0425L9.38 17.9465C9.38 17.9445 8.172 19.5265 6.99 21.0725C5.67 22.8005 5.67 25.2005 6.99 26.9305V26.9305Z" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M29.6064 21.2041L22.6044 28.2061L18.3984 24.0041" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <div className="text-center">
                    <h3 className="text-[24px] font-[500] leading-none">
                        { openedModal?.title ? openedModal?.title : 'Saved' }
                    </h3>
                    { (openedModal?.name === 'save' && openedModal?.text) || openedModal === 'save' ?
                    <p className="mt-[20px] text-[14px] text-[#A7A7A7] leading-[22px]">
                        { openedModal?.text ? openedModal?.text : 'Your plan “Publish a Novel” has been successfully saved' }
                    </p> : null }
                </div>
                <button 
                    className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]"
                    onClick={() => {
                        setOpenedModal(null);
                    }}
                >
                    <span className="text-[14px] font-medium">OK</span>
                </button>
            </div> }
            { openedModal?.name === 'confirm-delete' && <div className="modal__body w-full lg:w-[450px] modal-body-flex">
                <div className="text-center">
                    <h3 className="text-[24px] font-[500]" style={{ lineHeight: '1.5' }}>
                        { openedModal?.title ? openedModal.title : 'Do you really want to delete?' }
                    </h3>
                </div>
                <div className="d-flex items-center gap-[10px]">
                    <button
                        className="btn bg-[#202020] border border-solid box-border rounded-full py-[10px] px-[36px]"
                        onClick={() => { setOpenedModal(null); }}
                    >
                        <span className="text-[14px] font-medium">Close</span>
                    </button>
                    <button
                        className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]"
                        onClick={() => {
                            openedModal?.action && openedModal?.action();
                        }}
                    >
                        <span className="text-[14px] font-medium">Confirm</span>
                    </button>
                </div>
            </div> }
        </GeneratedContentModal> }
        <Popup
            open={showHelpModal}
            onClose={closeModal}
            overlayStyle={{
                background: 'rgba(0,0,0,0.5)'
            }}
        >
            <HelpModal closeModal={closeModal}/>
        </Popup>
    </>;
};

export default Modals;