import { createSlice } from '@reduxjs/toolkit'
import { getUserThunk, getUserUpdateThunk, fetchKeepPositiveThunk } from '../thunks/applicationThunks';
import testImage from '../../assets/img/user-image.png';
import Socket from '../../helpers/socketClient';

const testUser = {
    name: 'Test',
    email: 'michael.mitc@example.com',
    username: 'cosmic_dreamer',
    firstName: 'Wade',
    lastName: 'Warren',
    company: 'Biffco Enterprises Ltd.',
    jobTitle: 'Project Manager',
    image: testImage
}

const initialState = {
    authorized: null,
    user: null,
    token: null,
    settings: null,
    socket: null,
    formOptions: [],
    keepItPositiveData: [],
}

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        setAuthorized: (state, action) => {
			state.authorized = action.payload;
		},
        setUser: (state, action) => {
			state.user = action.payload;
		},
        setToken: (state, action) => {
			state.token = action.payload;
            const socket = new Socket(action.payload);
            state.socket = socket;
		},
        setSettings: (state, action) => {
			state.settings = action.payload;
		},
        setSocket: (state, action) => {
			state.socket = action.payload;
		},
        setFormOptions: (state, action) => {
			state.formOptions = action.payload;
		},
        setKeepItPositiveData: (state, action) => {
            state.keepItPositiveData = action.payload;
        }
    },
    extraReducers(builder) {
        builder.addCase(getUserUpdateThunk.fulfilled, (state, action) => {
            state.user = action.payload;
        });

        builder.addCase(getUserThunk.fulfilled, (state, action) => {
            state.user = action.payload;
            state.authorized = true;
        });
        builder.addCase(getUserThunk.pending, (state, action) => {
            state.authorized = null;
        });
        builder.addCase(getUserThunk.rejected, (state, action) => {
            localStorage.removeItem('authToken');
            state.authorized = false;
            state.token = null;
        });
        
        builder.addCase(fetchKeepPositiveThunk.fulfilled, (state, action) => {
            state.keepItPositiveData = action.payload;
        });
        // builder.addCase(fetchKeepPositiveThunk.pending, (state) => {
        //     state.keepItPositiveData = [];
        // });
        builder.addCase(fetchKeepPositiveThunk.rejected, (state) => {
            state.keepItPositiveData = null;
        });
    }
})

export const { setUser, setAuthorized, setToken, setSettings, setSocket, setFormOptions } = applicationSlice.actions;
export const getUser = (state) => state.application.user;
export const getAuthorized = (state) => state.application.authorized;
export const getToken = (state) => state.application.token;
export const getSettings = (state) => state.application.settings;
export const getSocket = (state) => state.application.socket;
export const getFormOptions = (state) => state.application.formOptions;
export const getKeepItPositiveData = (state) => state.application.keepItPositiveData;

export default applicationSlice.reducer