import { ReactComponent as PickerIcon } from '../../../assets/img/icons/picker.svg';
import { ReactComponent as CustomIcon } from '../../../assets/img/icons/custom.svg';
import { ReactComponent as PlusIcon } from '../../../assets/img/icons/plus.svg';

const colorOptions = [
    '#000000',
    '#141414',
    '#292929',
    '#3D3D3D',
    '#525252',
    '#858585',
    '#FFFFFF',
    '#3772FF',
    '#7209B7',
    '#DF2935',
    '#EB5E28',
    '#FDCA40',
    '#8AC926'
]

const ColorPicker = ({handlePickColor, activeColor}) => {
    return <div className="flex flex-col gap-[16px] p-[12px]">
        <div className="grid grid-cols-7 gap-[6px]">
            {colorOptions.map(option => 
                <button
                    key={option}
                    className="w-[28px] h-[28px] rounded-full border border-solid border-white border-opacity-[0.12]
                    flex items-center justify-center"
                    style={{
                        backgroundColor: option
                    }}
                    onClick={() => handlePickColor(option)}
                >
                    {activeColor?.toLowerCase() === option.toLowerCase() && 
                        <div className="w-[22px] h-[22px] rounded-full border-[2.5px] border-solid border-[#1B1B1B]">
                        </div>
                    }
                </button>
            )}
            <button 
                className="w-[28px] h-[28px] rounded-full bg-[#202020] flex items-center justify-center"
            >
                <PlusIcon />
            </button>
        </div>
        <div className="flex gap-[8px]">
            <button className="flex-1 flex h-[32px] items-center justify-center gap-[8px]
            border border-solid border-[#333333] bg-[#1B1B1B] rounded-full">
                <PickerIcon />
                <span className="text-[13px] text-[#A7A7A7] opacity-90">Pick Color</span>
            </button>
            <button className="flex-1 flex h-[32px] items-center justify-center gap-[8px]
            border border-solid border-[#333333] bg-[#1B1B1B] rounded-full">
                <CustomIcon />
                <span className="text-[13px] text-[#A7A7A7] opacity-90">Custom</span>
            </button>
        </div>
    </div>
};

export default ColorPicker;