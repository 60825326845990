import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { newWordsData } from '../../routes/NewWords/newWordsData'
import { Accordion } from './Accordion'

export const NewWordsToolKit = () => {


    const [data, setData] = useState(null)

    const handleDragEnd = result => {
        // const {destination,source} = result
        // if (!result.destination) return
        
        // const newItems= Array.from(myAssets)
        // const [reOrdered] = newItems.splice(source.index, 1)
        // newItems.splice(destination.index, 0, reOrdered)
        // setMyAssets(newItems)
    }

    // const providedData = (provided) => {return (searchable ? {} : provided.dragHandleProps)}
    const providedData = (provided) => {return provided.dragHandleProps}

    const searchable = false

    useEffect(() => {
        const newData = {}
        for (let i = 0; i < newWordsData.length; i++) {
            const element = newWordsData[i]

            if (newData[element.category]) {
                newData[element.category].push(element)
            } else {
                newData[element.category] = [element]
            }
            
        }

        setData(newData);

    }, [])


    const highlightWordInText = (text, wordToHighlight) => {
        const words = text.split(' ')
      
        let highlighted = false
        const result = words.map((word, index) => {
            if (word.toLowerCase() === wordToHighlight.toLowerCase()) {
                highlighted = true
                return (
                    `<span key=${index} class="font-500">
                        ${word}
                    </span>`
                );
            } else {
                if (highlighted) {
                highlighted = false
                return `</span>${word}`
                } else {
                return word
                }
            }
        });
      
        if (highlighted) {
            result.push('</span>')
        }
      
        const formattedText = result.join(' ')
        return (
            `<p class="comment-text">
                ${formattedText}
            </p>`
            )
    }


    return (
        <>
        {<DragDropContext onDragEnd={result => {handleDragEnd(result)}}>
            <Droppable droppableId="blockContainer" direction="vertical">
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps} className="blog-toolkit-content__container">

                        <div className="widget-finder--header">
                            <div className='input-search-variant-two w-full'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <circle cx="10.215" cy="10.2155" r="5.88495" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M17.67 17.6694L14.377 14.3764" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <input  placeholder='Search assets...' />
                            </div>
                            <a href="#" className="btn btn-40 btn--prim-balck rounded-[8px] ml-[8px]">
                                <span className="ico ico-20">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.2">
                                            <path fillRule="evenodd" clip-rule="evenodd" d="M3.04852 8.0976C2.69574 7.71326 2.5 7.21054 2.5 6.68883V5C2.5 3.61929 5.85787 2.5 10 2.5C14.1421 2.5 17.5 3.61929 17.5 5V6.68883C17.5 7.21054 17.3043 7.71326 16.9515 8.0976L12.5 12.9473V15.6482C12.5 16.6709 11.6709 17.5 10.6481 17.5H9.35186C8.32911 17.5 7.50001 16.6709 7.5 15.6482V12.9473L3.04852 8.0976Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </g>
                                    </svg>
                                </span>
                            </a>
                        </div>

                        {data && Object.entries(data).map((el,index) => {

                            const title = el[0]
                            const options = el[1]

                        return <Draggable draggableId={String(index)} index={index} key={index}>
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    className='blog-toolkit-content__block'
                                >

                                    <div className="result-widget--header mb-[10px]">
                                        <p className="text-12 text-sec">{title}</p>
                                    </div>

                                    <Accordion openBlock={true} >
                                        <div className="result-widget--body">
                                            {options && options.map((item, idx) => <div key={idx} className="card-cust card-cust--row mb-[8px]">
                                                <div className="card-cust--header p-16px flex-auto">
                                                    <div className="flex flex-col">
                                                        <div className="text-12 text-white--09">{item.category}</div>
                                                        <div className="text-16 text-primary mt-6px">
                                                            <span className="font-600">{item.noun}</span>
                                                            <span className="text-white--03 text-10">(noun)</span>
                                                        </div>
                                                        <ul className="ul-points mt-16px">
                                                            <li className="ul-points--item">
                                                                <p>{item.transcription.split('|')[0]}</p>
                                                            </li>
                                                            <li className="ul-points--item">
                                                                <p>{item.transcription.split('|')[1]}</p>
                                                            </li>
                                                        </ul>
                                                        <p className="mt-16px text-12 font-300 text-white">
                                                            {item.desc}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="card-cust--body p-16px pt-0">
                                                    <div className="comment-block">
                                                        <div className="comment-body" 
                                                            dangerouslySetInnerHTML={{ __html: highlightWordInText(item.footerDesc.text, item.footerDesc.highlight) }}> 
                                                        </div>
                                                        <div className="comment-footer justify-end">
                                                            <div className="flex items-center">
                                                                <div className="user user-20">
                                                                    <img src={item.user.avatar} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)}
                                        </div>
                                    </Accordion>
                                    


                                    <div {...provided.dragHandleProps} className={`${searchable ? 'not-allowed' : ''}`} title={searchable ? "undraggable in search" : null}>
                                        <div className='toolkit-block-footer'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="14" viewBox="0 0 48 14" fill="none">
                                                <g opacity="0.4">
                                                    <path d="M18 4.66669L24 9.66669L30 4.66669" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                            
                                </div>
                                )}
                            </Draggable>
                        })}
                        
                        
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        
        }
        </>
    )
}
