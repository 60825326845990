import React, {useEffect, useState} from 'react';

const options = {
    blog: {
        label: 'Blog Description',
        placeholder: 'Ex. I want 4 paragraphs about farming using AI...',
        rows: 10
    },
    goalDescription: {
        label: 'Goal Description',
        placeholder: 'Ex. I want to write a 50,000 words novel by the end of this year...',
        rows: 4
    },
    goalChallenges: {
        label: 'Challenges to Overcome',
        placeholder: "Ex. Finding dedicated writing time, Overcoming writer's block...",
        rows: 4
    }
}

const TextareaGenerator = ({setTextareaTouched, onChange, data, options: {label, placeholder, rows}}) => {

    const [value, setValue] = useState(data)

    useEffect(() => {
        onChange && onChange(value)
    }, [value])

    return <div className='mt-[16px]'>
        {label && <label className="input-custom__label mb-[10px]">{label}</label>}
        <textarea className="textarea pointer"
                  rows={rows}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  onClick={() => {
                        setTextareaTouched()
                        //setValue(option.defaultValue)
                  }}
                  placeholder={placeholder}>
        </textarea>
    </div>
}

export default TextareaGenerator;