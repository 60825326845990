import React from 'react'
import iconPerson from '../../assets/img/icons/icon-person.svg';

function SettingsNotifications() {
  return (
    <div className="tabs-content flex-auto mb-[20px]">
            <div className="container-820">
                <div className="row mb-[-20px]">
                    <div className="col-xl-6 col-lg-12 mb-[20px]">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">
                                Current Password
                            </label>
                            <div className="input-custom__wrap-input icon-left">
                                <div className="icon-input">
                                    <img src={iconPerson}/>
                                </div>
                                <input className="input-custom__input" type="text" placeholder="Current Password"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 mb-[20px]">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">
                                New Password
                            </label>
                            <div className="input-custom__wrap-input icon-left">
                                <div className="icon-input">
                                    <img src={iconPerson}/>
                                </div>
                                <input className="input-custom__input" type="text" placeholder="New Password"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default SettingsNotifications