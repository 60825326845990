import React, { Fragment, useEffect, useState, version } from 'react';
import GeneratedContentModal from '../GeneratedContent/GeneratedContentModal';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import ChatBot from '../GeneratedContent/ChatBot';
import SelectBox from '../../components/base/SelectBox';
import { useSelector } from 'react-redux';
import BlogPostCard from './BlogPostCard';
import api from '../../api';
import { getActiveBlogIndex, getGeneratedBlogs, getGenerateLoading, setActiveBlogIndex, setBlogVersions, setBlogs, setBlogDone, setGenerateLoading, setBlogAddonsValues, setBlogSelectedVersionId } from '../../redux/slices/blogGeneratorSlice';
import { useDispatch } from 'react-redux';
import { getSocket, getToken } from '../../redux/slices/applicationSlice';

import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import HelpModal from '../../components/base/HelpModal';
import Share from '../GeneratedContent/Blog/Share';
import GoalGeneratorCard from "../GeneratedContent/GoalGeneratedContent/GoalGeneratorCard";

const AssetSharedPage = () => {
    const token = useSelector(getToken);
    
    const activeBlogIndex = useSelector(getActiveBlogIndex);
    const blogs = useSelector(getGeneratedBlogs);
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    console.log('blogs:', blogs);

    const wordsCount = (blogs?.length ? blogs?.reduce((accumulator, blog) => {
        const blogWordCount = blog.versions.reduce((accumulator, version) => {
            const displayText = version?.activeContent || version?.content || '';
            return accumulator + displayText.split(' ').filter(word => word.length > 0).length;
        }, 0);

        return accumulator + blogWordCount
    }, 0) : 0);

    const [openedModal, setOpenedModal] = useState();
    const [configForm, setConfigForm] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const activeBlog = blogs[activeBlogIndex];

    const [asset, setAsset] = useState();

    console.log('asset:', asset);

    const [sideBarOpened, setSideBarOpened] = useState(true);
    const [keywordsOptions, setKeywordsOptions] = useState([]);

    const [initKeywords, setInitKeywords] = useState([])

    const [keywordsSelected, setKeywordsSelected] = useState([]);
    
    const [cardFullScreen, setCardFullScreen] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const closeModal = () => setShowHelpModal(false);

    const handleExport = async (id, ext) => {
        await api.assets.exportAsset({
            token,
            id: asset.id,
            type: 'blog',
            ext,
            filename: `${blogs[activeBlogIndex].blog_topic}.${ext}`,
        });

        setOpenedModal(null);
    }

    useEffect(() => {
        if(!navigate || !id || !dispatch) return;
        const fetchBlogData = async () => {
            try {
                const response = await api.share.getKitData({
                    id,
                });
                if(!response.success) return;
                setAsset(response.data.payload);
                console.log(response);
                console.log('FETCH BLOG', JSON.parse(response.data.payload.content))
                dispatch(setBlogs(JSON.parse(response.data.payload.content)));
            } catch (e) {
                console.log(e);
            }
        }
        fetchBlogData();
    }, [id, navigate, dispatch])

    useEffect(() => {
        if(!blogs.length) return;
        setConfigForm(blogs[activeBlogIndex]);
    }, [blogs, activeBlogIndex])

    useEffect(() => {
        const filterDuplicate = (arr1, arr2) => {
            const combinedArray = [...arr1, ...arr2]

            const uniqueArray = Array.from(new Set(combinedArray))

            return uniqueArray.map(el => ({label: el, value: el}))
        }

        if (configForm && configForm.keywords) {
            const arr = configForm.keywords.split(',')
            !initKeywords.length && setInitKeywords(arr)

            const options = initKeywords.length ? filterDuplicate(initKeywords, arr) : [...arr.map(el => ({label: el, value: el}))]

            setKeywordsOptions(options)
            setKeywordsSelected(arr)

        }
    }, [configForm])

    useEffect(() => {
        setSideBarOpened(!cardFullScreen);
    }, [cardFullScreen])

    return <main className='w-screen h-screen overflow-hidden flex'>
        <ChatBot className='bottom-[74px] right-[16px]'/>
        {isLoading ? <div className="flex flex-col justify-center items-center h-full flex-1">
            <div className="lds-ring"><div /><div /><div /><div /></div>
            <div className="mt-[36px] text-[18px] font-medium leading-[13px] opacity-90">Loading...</div>
            <div className="mt-[24px] text-[14px] leading-[10px] opacity-50 text-[#A7A7A7]">Please wait for your results...</div>
        </div> :
        <div className='flex-1 flex flex-col'>
            <div className={classNames('py-[13px] px-[16px] flex items-center justify-between', {
                'px-[30px]': cardFullScreen
            })}>
                <div>
                    <h1 className='text-[14px] font-medium leading-normal'>
                        Generated Content
                    </h1>
                    <div className='text-[12px] leading-[14px] opacity-40 flex items-center gap-[5px] mob-flex-wrap'>
                        Text
                        <div className='w-[3px] h-[3px] rounded-full bg-white'/>
                        {asset?.product?.name}
                        <div className='w-[3px] h-[3px] rounded-full bg-white'/>
                        {asset?.name}
                    </div>
                </div>
                <button 
                    onClick={() => setShowHelpModal(true)}
                    className='w-[32px] h-[32px] flex items-center justify-center opacity-50'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9.68457 9.68482C9.92159 8.66326 10.852 7.95496 11.8998 7.99844C13.0725 7.93349 14.0778 8.82709 14.1508 9.99928C14.1508 11.5038 11.9999 12.0001 11.9999 13.0005" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12.1253 15.7523C12.1252 15.8213 12.0692 15.8772 12.0002 15.8772C11.9311 15.8772 11.8752 15.8212 11.8752 15.7522C11.8751 15.6832 11.931 15.6272 12 15.6271C12.0333 15.6271 12.0651 15.6402 12.0886 15.6637C12.1121 15.6872 12.1253 15.7191 12.1253 15.7523" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.62861 17.1605C1.94551 13.3308 2.63502 8.08546 6.21672 5.07926C9.79842 2.07305 15.0839 2.30347 18.3903 5.60997C21.6968 8.91646 21.9273 14.2019 18.9211 17.7836C15.9149 21.3653 10.6695 22.0548 6.8398 19.3717L3.99688 20.0034L4.62861 17.1605Z" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            <div className='py-[4px] px-[16px] flex-1 gap-[12px] overflow-y-scroll no-scroll'>
                <ul className={classNames('flex flex-col gap-[12px]', {
                    'h-full': cardFullScreen
                })}>
                    { +asset?.productId === 2 ? 
                        blogs?.tasks?.map((milestone, index) =>{
                            return <Fragment key={index}>
                                <GoalGeneratorCard
                                    milestone={{
                                        title: milestone.task_title,
                                        description: milestone.task_description,
                                        deadline: milestone.deadline ? new Date(milestone.deadline) : new Date(),
                                        generatedDate: new Date(milestone.generatedDate),
                                        subtasks: milestone.subtasks
                                    }}
                                    currentTask={milestone}
                                    index={index}
                                    cardFullScreen={cardFullScreen}
                                    setCardFullScreen={setCardFullScreen}
                                    sharePage={true}
                                />
                            </Fragment>
                        })
                    : blogs?.length ? blogs.map((data, index) => {
                        console.log('BLOG CARD DATA', data)
                        return <Fragment key={index}>
                            <BlogPostCard
                                asset={asset}
                                data={data.versions} 
                                index={index}
                                setCardFullScreen={setCardFullScreen}
                                isFullScreen={cardFullScreen}
                            />
                            {/* {Boolean(data?.versions?.some(version => Object.keys(version.addons).length)) && data?.add_ons?.map((title, addonIndex) => <AddonCard
                                key={title?.title || title}
                                title={title?.title || title}
                                data={data}
                                index={index}
                                addonIndex={addonIndex}
                                isFullScreen={cardFullScreen}
                            />)} */}
                        </Fragment>
                    }) : null}
                </ul>
            </div>
            <div className='generated-content-bottom-panel flex-wrap'>
                <div className='flex items-center gap-[20px]'>
                    <div className='flex items-center gap-[10px]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M15.8346 1.66663H4.16797C2.79297 1.66663 1.66797 2.79163 1.66797 4.16663V15.8333C1.66797 17.2083 2.79297 18.3333 4.16797 18.3333H15.8346C17.2096 18.3333 18.3346 17.2083 18.3346 15.8333V4.16663C18.3346 2.79163 17.2096 1.66663 15.8346 1.66663ZM9.16797 12.5H5.83464C5.3763 12.5 5.0013 12.125 5.0013 11.6666C5.0013 11.2083 5.3763 10.8333 5.83464 10.8333H9.16797C9.6263 10.8333 10.0013 11.2083 10.0013 11.6666C10.0013 12.125 9.6263 12.5 9.16797 12.5ZM12.5013 9.16663H5.83464C5.3763 9.16663 5.0013 8.79163 5.0013 8.33329C5.0013 7.87496 5.3763 7.49996 5.83464 7.49996H12.5013C12.9596 7.49996 13.3346 7.87496 13.3346 8.33329C13.3346 8.79163 12.9596 9.16663 12.5013 9.16663Z" fill="#9D9D9D"/>
                        </svg>
                        <div className='text-[14px] text-[#9D9D9D] leading-normal flex'>
                            <div className='w-[40px]'>{wordsCount}</div>
                            <span className='opacity-50'>words</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
        {openedModal && <GeneratedContentModal isOpened close={() => setOpenedModal(null)} >
            {openedModal === 'export' && <div className="modal__body w-[800px]">
                <button 
                    className="modal-close-btn" 
                    onClick={() => setOpenedModal(null)}
                >
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 8.5L16 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16 8.5L8 16.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
                <h3 className="text-[18px] font-[500] text-[#E8E8E8]">Export Generated Content</h3>
                <p className="mb-[24px] text-[14px] font-[400] text-[#6F6F6F]">Select one of options to export content</p>
                <div className="flex items-stretch gap-[9px]">
                    <button
                        onClick={() => handleExport(id, 'csv')}
                        className="share-platforms-item share-item"
                    >
                        <span className="d-flex align-items-center mb-[14px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12 11V18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 14.5H16" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M15 18H9C8.448 18 8 17.552 8 17V12C8 11.448 8.448 11 9 11H15C15.552 11 16 11.448 16 12V17C16 17.552 15.552 18 15 18Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        </span>
                        <span>CSV</span>
                    </button>
                    <button
                        onClick={() => handleExport(id, 'json')}
                        className="share-platforms-item share-item">
                        <span className="d-flex align-items-center mb-[14px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 11H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 14H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 17H12.33" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        </span>
                        <span>JSON</span>
                    </button>
                    <button
                        onClick={() => handleExport(id, 'pdf')}
                        className="share-platforms-item share-item"
                    >
                        <span className="d-flex align-items-center mb-[14px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.4817 7.483L18.1824 4.18367C17.7449 3.74617 17.151 3.5 16.5327 3.5H8.16536C6.8762 3.5 5.83203 4.54417 5.83203 5.83333V22.1667C5.83203 23.4558 6.8762 24.5 8.16536 24.5H19.832C21.1212 24.5 22.1654 23.4558 22.1654 22.1667V9.13267C22.1654 8.51433 21.9192 7.9205 21.4817 7.483V7.483Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22.1654 9.33333H17.4987C16.8547 9.33333 16.332 8.81067 16.332 8.16667V3.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12.0625 19.3079H15.9358" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M12.6367 14.8758V13.5132C12.6367 13.1375 12.9412 12.833 13.3169 12.833H14.6796C15.0552 12.833 15.3597 13.1375 15.3597 13.5132V14.8758C15.3597 15.2515 15.0552 15.556 14.6796 15.556H13.3169C12.9412 15.5548 12.6367 15.2503 12.6367 14.8758Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.33203 20.3202V18.9575C9.33203 18.5818 9.63653 18.2773 10.0122 18.2773H11.3749C11.7505 18.2773 12.055 18.5818 12.055 18.9575V20.3202C12.055 20.6958 11.7505 21.0003 11.3749 21.0003H10.0122C9.63653 21.0003 9.33203 20.6958 9.33203 20.3202Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.9453 20.3202V18.9575C15.9453 18.5818 16.2498 18.2773 16.6255 18.2773H17.9881C18.3638 18.2773 18.6683 18.5818 18.6683 18.9575V20.3202C18.6671 20.6958 18.3626 21.0003 17.987 21.0003H16.6243C16.2498 21.0003 15.9453 20.6958 15.9453 20.3202H15.9453Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16.6132 18.2817L14.8516 15.5283" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M11.3867 18.2817L13.1484 15.5283" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                        <span>PDF</span>
                    </button>
                    <button
                        onClick={() => handleExport(id, 'docx')}
                        className="share-platforms-item share-item"
                    >
                        <span className="d-flex align-items-center mb-[14px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 11H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 14H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 17H12.33" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        </span>
                        <span>Word</span>
                    </button>
                    <button
                        onClick={() => handleExport(id, 'txt')}
                        className="share-platforms-item share-item"
                    >
                        <span className="d-flex align-items-center mb-[14px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 11H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 14H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8 17H12.33" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        </span>
                        <span>Text File</span>
                    </button>
                </div>
            </div>}
            {openedModal === 'share' && <Share setOpenedModal={setOpenedModal}/>}
            {openedModal === 'duplicate' || openedModal?.name === 'duplicate' && <div className="modal__body w-[450px] modal-body-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path d="M6 38V40.2857C6.00034 41.2323 6.76765 41.9997 7.71428 42H10" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M26 18H28.2857C29.2323 18.0003 29.9997 18.7677 30 19.7143V22" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M20 42H16" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M20 18H16" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M26 42H28.2857C29.2323 41.9997 29.9997 41.2323 30 40.2857V38" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6 22V19.7143C6.00034 18.7677 6.76765 18.0003 7.71428 18H10" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M30 28V32" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6 28V32" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M16 12V10C15.9997 8.93904 16.421 7.92146 17.1713 7.17125C17.9215 6.42104 18.939 5.99971 20 6H38C39.061 5.99971 40.0785 6.42104 40.8288 7.17125C41.579 7.92146 42.0003 8.93904 42 10V28C42.0003 29.061 41.579 30.0785 40.8288 30.8288C40.0785 31.579 39.061 32.0003 38 32H36" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <div className="text-center">
                    <h3 className="text-[24px] font-[500] leading-none">
                        Duplicated
                    </h3>
                    <p className="mt-[20px] text-[14px] text-[#A7A7A7] leading-[22px]">
                        { openedModal?.title ? openedModal?.title : 'Plan successfully duplicated' }
                    </p>
                </div>
                <button 
                    className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]" 
                    onClick={() => setOpenedModal(null)}
                >
                    <span className="text-[14px] font-medium">OK</span>
                </button>
            </div>}
            {(openedModal === 'save' || openedModal?.name === 'save') && <div className="modal__body w-[450px] modal-body-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.99 26.9305L9.38 30.0585L9.896 33.9525C10.182 36.1125 11.88 37.8125 14.04 38.1005L17.944 38.6225L21.07 41.0105C22.8 42.3325 25.198 42.3325 26.928 41.0105L30.056 38.6205H30.052L33.948 38.1045C36.108 37.8185 37.808 36.1205 38.096 33.9605L38.616 30.0565C38.616 30.0585 39.824 28.4765 41.006 26.9305C42.328 25.2005 42.326 22.8025 41.006 21.0725L38.62 17.9425L38.104 14.0485C37.818 11.8885 36.12 10.1885 33.96 9.90048L30.054 9.38048L26.928 6.99248C25.198 5.67048 22.8 5.67048 21.07 6.99248L17.942 9.38048H17.946L14.05 9.89848C11.89 10.1845 10.19 11.8825 9.902 14.0425L9.38 17.9465C9.38 17.9445 8.172 19.5265 6.99 21.0725C5.67 22.8005 5.67 25.2005 6.99 26.9305V26.9305Z" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M29.6064 21.2041L22.6044 28.2061L18.3984 24.0041" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <div className="text-center">
                    <h3 className="text-[24px] font-[500] leading-none">
                        { openedModal?.title ? openedModal?.title : 'Saved' }
                    </h3>
                    { (openedModal?.name === 'save' && openedModal?.text) || openedModal === 'save' ?
                    <p className="mt-[20px] text-[14px] text-[#A7A7A7] leading-[22px]">
                        { openedModal?.text ? openedModal?.text : 'Your plan “Publish a Novel” has been successfully saved' }
                    </p> : null }
                </div>
                <button 
                    className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]"
                    onClick={() => {
                        setOpenedModal(null);
                    }}
                >
                    <span className="text-[14px] font-medium">OK</span>
                </button>
            </div>}
            {openedModal?.name === 'confirm-delete' && <div className="modal__body w-[450px] modal-body-flex">
                <div className="text-center">
                    <h3 className="text-[24px] font-[500]" style={{ lineHeight: '1.5' }}>
                        { openedModal?.title ? openedModal.title : 'Do you really want to delete?' }
                    </h3>
                </div>
                <div className="d-flex items-center gap-[10px]">
                    <button
                        className="btn bg-[#202020] border border-solid box-border rounded-full py-[10px] px-[36px]"
                        onClick={() => { setOpenedModal(null); }}
                    >
                        <span className="text-[14px] font-medium">Close</span>
                    </button>
                    <button
                        className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]"
                        onClick={() => {
                            openedModal?.action && openedModal?.action();
                        }}
                    >
                        <span className="text-[14px] font-medium">Confirm</span>
                    </button>
                </div>
            </div>}
        </GeneratedContentModal>}
        <Popup
            open={showHelpModal}
            onClose={closeModal}
            overlayStyle={{
                background: 'rgba(0,0,0,0.5)'
            }}
        >
            <HelpModal closeModal={closeModal}/>
        </Popup>
    </main>;
};

export default AssetSharedPage;