import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from '../../assets/img/icons/arrow-bottom.svg';
import api from '../../api';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getActiveBlogIndex, getGeneratedBlogs, setActiveBlogIndex } from '../../redux/slices/blogGeneratorSlice';
import { useDispatch } from 'react-redux';
import ButtonTooltip from '../GeneratedContent/Blog/ButtonTooltip';

import { getToken } from '../../redux/slices/applicationSlice';
import CardContent from './CardContent';
import EtsyCardContent from './EtsyCardContent';

const BlogPostCard = ({asset = null, data, index, setCardFullScreen, isFullScreen, isNotDone = false}) => {
    console.log('BlogPostCard asset:', asset);
    console.log('BlogPostCard data:', data);
    const token = useSelector(getToken);
    const activeBlogIndex = useSelector(getActiveBlogIndex);
    const blogs = useSelector(getGeneratedBlogs);
    const {activeVersionIndex, versions } = blogs[index] || {};
    const { generatedDate, selectedVersionId } = blogs[index];
    const { activeButton, isEdit } = versions[activeVersionIndex] || {};
    const dispatch = useDispatch();
    const isOpen = index === activeBlogIndex;

    const isSelected = selectedVersionId === data[activeVersionIndex]?.id;
    const [customPrompt, setCustomPrompt] = useState('');

    const { id } = useParams();
    const [updateBlogs, setUpdateBlogs] = useState(false);
    useEffect(() => {
        if(updateBlogs) {
            api.assets.changeAsset({
                id,
                data: {
                    content: JSON.stringify(blogs)
                },
                token
            }).catch(e => console.log(e))
            setUpdateBlogs(false);
        }
    }, [updateBlogs])

    const markup = data[activeVersionIndex]?.activeContent || data[activeVersionIndex || 0]?.content;

    return <li 
        className={classNames(
            'blog-post-card', 
            isFullScreen ? 'h-full' : isOpen ? 'h-[442px]' : 'min-h-[42px]',
            (isFullScreen && !isOpen) ? 'hidden': 'flex'
        )}
        style={{
            borderColor: isSelected ? '#FF8114': '#1F1F1F'
        }}
    >
        <div 
            className='flex items-center justify-between py-[11px] px-[16px] mob-flex-wrap'
        >
            <div className={`gen-con-header-title gap-[8px] cursor-pointer ${isNotDone ? 'disabled' : ''}`} onClick={() => dispatch(setActiveBlogIndex(index))}>
                <div
                    className={`gen-header-arrow pointer-events-none ${isOpen && 'rotate-180'}`}
                    //  onClick={(e) => {e.stopPropagation()}}
                >
                    <ArrowIcon />
                </div>
                <span className="leading-[18px]">{+asset?.productId === 1 ? 'Blog Post' : 'Content'} {index + 1}</span>
            </div>
            
            <div className="d-flex items-center mob-flex-wrap">
                <div className="gen-con-header-date leading-[18px]">
                    Generated  {format(generatedDate ? new Date(generatedDate) : new Date(), 'MM.dd.yyyy, H:mm')}
                </div>
                <ButtonTooltip
                    tooltip='Expand'
                >
                    <button
                        className={classNames('blog-generator-button fill ml-[10px]', {
                            'active': isFullScreen
                        })}
                        onClick={() => setCardFullScreen(isFullScreen ? false : true)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M2.83125 16.5939C2.57187 16.5939 2.36328 16.515 2.20547 16.3572C2.04766 16.1994 1.96875 15.9908 1.96875 15.7314V11.2502C1.96875 11.0152 2.05224 10.8127 2.21921 10.6427C2.38619 10.4727 2.58494 10.3877 2.81546 10.3877C3.04599 10.3877 3.24687 10.4727 3.41812 10.6427C3.58937 10.8127 3.675 11.0152 3.675 11.2502V13.6689L13.125 4.21895H10.6875C10.4578 4.21895 10.2598 4.13546 10.0933 3.96848C9.92695 3.80151 9.84375 3.60276 9.84375 3.37223C9.84375 3.14171 9.92695 2.94082 10.0933 2.76957C10.2598 2.59832 10.4578 2.5127 10.6875 2.5127H15.1875C15.44 2.5127 15.6469 2.59332 15.8081 2.75457C15.9694 2.91582 16.05 3.1227 16.05 3.3752V7.8752C16.05 8.10488 15.9647 8.30293 15.7941 8.46934C15.6234 8.63574 15.4201 8.71895 15.1842 8.71895C14.9536 8.71895 14.7559 8.63574 14.591 8.46934C14.4262 8.30293 14.3437 8.10488 14.3437 7.8752V5.4377L4.89375 14.8877H7.3125C7.5475 14.8877 7.75 14.973 7.92 15.1436C8.09 15.3143 8.175 15.5176 8.175 15.7535C8.175 15.9841 8.09 16.1818 7.92 16.3467C7.75 16.5115 7.5475 16.5939 7.3125 16.5939H2.83125Z" fill="#A7A7A7"/>
                        </svg>
                    </button>
                </ButtonTooltip>
            </div>
        </div>
        
        {asset?.productId === 9 &&
        isOpen ? <EtsyCardContent data={data[0]} /> :
        isOpen && <CardContent
            index={index}
            isFullScreen={isFullScreen}
            setCardFullScreen={setCardFullScreen}
            setUpdateBlogs={setUpdateBlogs}
            markup={markup}
            isNotDone={isNotDone}
        />}
    </li>;
};

export default BlogPostCard;