import classNames from 'classnames';
import React from 'react';

const PrimaryButton = ({ 
    onClick, disabled, className, children, ...props
}) => {
    return <button
        className={classNames('primary-button', className)}
        onClick={onClick}
        disabled={disabled}
        {...props}
    >
        {children}
    </button>
};

export default PrimaryButton;