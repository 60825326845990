import React, { useState } from 'react';
import NewsletterItem from './NewsletterItem';
import NewsletterCard from './NewsletterCard';
import { Link, useParams } from 'react-router-dom';
import Lessons from './Lessons';
import NewsletterSettingsPopup from './NewsletterSettingsPopup/NewsletterSettingsPopup';
import Popup from 'reactjs-popup';
import { useSelector, useDispatch } from 'react-redux';
import { getGeneratedCourses } from '../../../redux/slices/courseGeneratorSlice';
import { db } from "../../../db";
import api from '../../../api';
import { getToken, getSocket, getUser, setUser } from '../../../redux/slices/applicationSlice';
import { getUserUpdateThunk } from '../../../redux/thunks/applicationThunks';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import PrimaryButton from '../../../components/base/PrimaryButton';
import { checkUserHaveCredits } from '../../../helpers/userHelpers';

const GeneratedContent = ({
    cardFullScreen, setCardFullScreen, coursesFromIndexedDb, updateIndexedDB, mobileScreen = false,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const socket = useSelector(getSocket);
    const token = useSelector(getToken);
    const meData = useSelector(getUser);
    const courses = useSelector(getGeneratedCourses);

    const { id } = useParams();

    const generationIsNotDone = coursesFromIndexedDb?.filter(item => (item?.lessons?.length && !item.isDone))?.length;

    const [newsletterSettingsPopupOpen, setNewsletterSettingsPopupOpen] = useState(null);
    const [selectedForSetting, setSelectedForSetting] = useState(null);

    const closeNewsletterSettings = () => {
        setNewsletterSettingsPopupOpen(false);
        setSelectedForSetting(null);
    }

    const updateIndexBd = async() => {
        let checkRes = await checkUserHaveCredits(token);
        if(!checkRes?.success){
            dispatch(setUser(checkRes?.data));

            closeNewsletterSettings();
            return;
        }

        await db.courses.put(selectedForSetting);
        
        let findedCourse = courses?.find(item => item.id === selectedForSetting.id);
        let findedCourseFromIndexedDb = coursesFromIndexedDb?.find(item => item.id === selectedForSetting.id);
        
        if((findedCourse && !findedCourse?.lessons) || selectedForSetting.settings.addons?.length){
            const payloadForGenerate = [{
                method: findedCourseFromIndexedDb?.settings.method,
                text: findedCourse.text,
                backgroundInformation: selectedForSetting.settings.backgroundInformation,
                language: findedCourse.language,
                country: findedCourse.country,
                numberOfLessons: selectedForSetting.settings.numberOfLessons,
                lessonType: findedCourse.lessonType,
                subjectLevel: findedCourse.subjectLevel,
                readingLevel: findedCourse.readingLevel,
                specialEducation: findedCourse.specialEducation,
                competencyMapping: findedCourse.competencyMapping,
                addons: selectedForSetting.settings.addons,
                scheduleLessonFrequency: findedCourse.scheduleLessonFrequency,
                scheduleRepeatOn: findedCourse.scheduleRepeatOn,
                scheduleBegins: findedCourse.scheduleBegins,
                scheduleTime: findedCourse.scheduleTime,
                scheduleIsSendOneMessage: findedCourse.scheduleIsSendOneMessage,
            }];

            let payloadForDb = {
                ...selectedForSetting.settings,
                id: selectedForSetting.id,
                title: null,
                description: null,
                lessons: null,
                isDone: null,
                settings: selectedForSetting.settings,
                materials: [],
                materials_files: [],
            };

            const generateAddons = (lessonsValue) => {
                lessonsValue?.forEach(async(lesson) => {
                    lesson.materials = [];

                    if(lesson?.addons && Object.entries(lesson.addons)?.length){
                        await Promise.all(
                            Object.entries(lesson.addons).map(async(addonEntries) => {
                                const response = await api.courses.uploadAddon({
                                    data: {
                                        type: 'addon',
                                        title: addonEntries[0].split('_').map(item => `${item[0].toUpperCase()}${item.slice(1)}`).join(' '),
                                        content: addonEntries[1]
                                    },
                                    token
                                });

                                return response;
                            } )
                        ).then(async(values) => {
                            lesson.materials = [...lesson.materials, ...values.map(item => ({
                                id: item.data.id,
                                content: item.data.content,
                                type: item.data.type,
                                title: item.data.title,
                            }))];

                            payloadForDb.materials = [...payloadForDb.materials, ...values.map(item => item.data.id)];

                            await db.courses.put(payloadForDb);
                        });
                    }
                });
            }

            const uploadFiles = async() => {
                await Promise.all(
                    // courses[index]?.files
                    selectedForSetting.settings?.files.map(async(fileItem) => {
                        const response = await api.courses.uploadAddon({
                            data: {
                                type: 'file',
                                file: fileItem
                            },
                            token
                        });

                        return response;
                    } )
                ).then(async(values) => {
                    payloadForDb.materials = [...payloadForDb.materials, ...values.map(item => item.data.id)];
                    payloadForDb.materials_files = [...payloadForDb.materials_files, ...values];

                    await db.courses.put(payloadForDb);
                });
            }

            if(findedCourse && !findedCourse?.lessons){
                const eventId = v4();

                const toastId = toast.loading(<div className='flex flex-col items-center gap-[10px]'>
                    <p>{`Generating your courses, please wait...`}</p>
                    <div className="d-flex items-center gap-[10px]">
                        <PrimaryButton 
                            className="min-w-[100px]"
                            onClick={() => { 
                                navigate('/generated-page/courses')
                            }}
                        >View</PrimaryButton>
                        <PrimaryButton onClick={() => {
                            socket.send({
                                event: 'stop-event-generation',
                                payload: {
                                    id: eventId
                                },
                                handler: (stopResponse) => {
                                    toast.dismiss(toastId)
                                }
                            })
                        }}>Stop Generation</PrimaryButton>
                    </div>
                </div>);

                socket.send({
                    id: eventId,
                    event: 'lesson-kit-generate-cources',
                    payload: {
                        courses: payloadForGenerate
                    },
                    handler: async(response) => {
                        if(response?.isError){
                            toast.error("AI bot is not available at the moment");
                            return;
                        }

                        if(response?.isDone){
                            dispatch(getUserUpdateThunk({ token }));
                        }

                        response.payload.forEach(async(item, index) => {
                            payloadForDb = {
                                ...payloadForDb,
                                title: item.title,
                                description: item.description,
                                lessons: item.lessons,
                                isDone: item.isDone,
                            };


                            if(response.isDone){
                                generateAddons(payloadForDb.lessons);

                                if(selectedForSetting.settings?.files?.length){
                                    uploadFiles();
                                }

                                toast.update(toastId, {
                                    render: 'Generated successfully!',
                                    type: 'success', 
                                    isLoading: false,
                                    autoClose: 5000
                                });
                            }

                            await db.courses.put(payloadForDb);
                        });
                    }
                });

                closeNewsletterSettings();
                return false;
            }

            if(selectedForSetting?.lessons?.length){
                generateAddons(selectedForSetting?.lessons);

                payloadForDb = {
                    ...payloadForDb,
                    title: selectedForSetting.title,
                    description: selectedForSetting.description,
                    lessons: selectedForSetting?.lessons,
                    isDone: true,
                };

                await db.courses.put(payloadForDb);

                if(selectedForSetting.settings?.files?.length){
                    uploadFiles();
                }
                
                closeNewsletterSettings();
            }
        }

        closeNewsletterSettings();
    }

    const clearCourses = async() => {
        await db.courses.clear();

        navigate("/my-lessons");
    }

    const save = async() => {
        if(generationIsNotDone){
            return false;
        }
        
        const formatDataForSave = courses?.map(item => ({
            cover: item.cover,
            title: item.title,
            description: item.description,
            language: item.language,
            country: item.language,
            numberOfLessons: item.numberOfLessons,
            lessonType: item.lessonType,
            subjectLevel: item.subjectLevel,
            readingLevel: item.readingLevel,
            specialEducation: item.specialEducation,
            competencyMapping: item.competencyMapping,
            scheduleLessonFrequency: item.scheduleLessonFrequency,
            scheduleRepeatOn: item.scheduleRepeatOn,
            scheduleBegins: item.scheduleBegins,
            scheduleTime: item.scheduleTime,
            scheduleIsSendOneMessage: item.scheduleIsSendOneMessage,
            materials: item.materials.filter(itemM => itemM),
            lessons: item?.lessons?.map(itemLesson => ({
                title: itemLesson.title,
                content: itemLesson.content,
                generated: itemLesson.generated,
                timeToComplete: itemLesson.timeToComplete,
                materials: itemLesson.materials.filter(itemM => itemM).map(materialsItem => (materialsItem?.type === 'addong' ? { id: materialsItem.id, content: materialsItem.content } : { id: materialsItem.id })),
            })),
            audiences: item.audiences,
        }));

        if(id && coursesFromIndexedDb.length === 0) {
            // return;
            const result = await api.courses.updateCourse({
                id,
                data: {
                    ...formatDataForSave[0],
                    materials: formatDataForSave[0].materials.map(item => item.id)
                },
                token
            }).then(resp => {
                return resp;
            })

            if(result?.success){
                toast.success('Lessons updated successfully');
            } else {
                toast.error('Something went wrong');
            }

            return;
        }

        await Promise.all(
            formatDataForSave.map(async(course) => {
                let response = null;
                try {
                    response = await api.courses.createCourse({
                        data: course,
                        token
                    });
                } catch (error) {
                    toast.error(error?.response?.data?.message || 'Something went wrong');
                    return false;
                }

                return response;
            } )
        ).then(async(values) => {
            if(!values.filter(item => item === false)?.length){
                await db.courses.clear();
                
                toast.success('Lessons created successfully');

                navigate("/my-lessons");
            }
        });

        closeNewsletterSettings();
    }

    return <div className='flex-1 flex flex-col'>
        {id ? <Lessons cardFullScreen={cardFullScreen} setCardFullScreen={setCardFullScreen} mobileScreen={mobileScreen} /> : <>
            <div className='py-[13px] px-[16px]'>
                <h1 className='text-[14px] font-medium leading-normal'>Generated Content Preview</h1>
                <div className='text-[12px] leading-[14px] opacity-40 flex items-center gap-[5px]'>
                    Email Educator
                    <div className='w-[3px] h-[3px] rounded-full bg-white'/>
                    { courses?.length || 0 } newsletter{courses?.length > 1  ? 's' : ''}
                </div>
            </div>
            <ul className='py-[4px] px-[16px] flex-1 gap-[12px] flex flex-col overflow-y-scroll no-scroll'>
                {courses?.map((item, index) => <NewsletterItem
                    key={index}
                    data={item}
                    openSettings={()=>{
                        setSelectedForSetting({...coursesFromIndexedDb[index]});
                        setNewsletterSettingsPopupOpen(true)
                    }}
                    generationIsNotDone={generationIsNotDone}
                />)}
            </ul>
        </>}
        <div className='generated-content-bottom-panel mob-flex-wrap'>
            <div className='editor-footer-column flex items-center gap-[10px] lg:gap-[20px]'>
                <div className='flex items-center gap-[10px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M15.8346 1.66663H4.16797C2.79297 1.66663 1.66797 2.79163 1.66797 4.16663V15.8333C1.66797 17.2083 2.79297 18.3333 4.16797 18.3333H15.8346C17.2096 18.3333 18.3346 17.2083 18.3346 15.8333V4.16663C18.3346 2.79163 17.2096 1.66663 15.8346 1.66663ZM9.16797 12.5H5.83464C5.3763 12.5 5.0013 12.125 5.0013 11.6666C5.0013 11.2083 5.3763 10.8333 5.83464 10.8333H9.16797C9.6263 10.8333 10.0013 11.2083 10.0013 11.6666C10.0013 12.125 9.6263 12.5 9.16797 12.5ZM12.5013 9.16663H5.83464C5.3763 9.16663 5.0013 8.79163 5.0013 8.33329C5.0013 7.87496 5.3763 7.49996 5.83464 7.49996H12.5013C12.9596 7.49996 13.3346 7.87496 13.3346 8.33329C13.3346 8.79163 12.9596 9.16663 12.5013 9.16663Z" fill="#9D9D9D"/>
                    </svg>
                    <p className='text-[14px] text-[#9D9D9D] leading-normal'>
                        { courses?.length || 0 }
                        <span className='opacity-50 ml-[4px]'>newsletter{courses?.length > 1  ? 's' : ''}</span>
                    </p>
                </div>
                {/* <div className='flex items-center gap-[10px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M12.1055 18.4167C11.7305 18.7917 11.2756 18.9792 10.7409 18.9792C10.2062 18.9792 9.7513 18.7917 9.3763 18.4167L1.58464 10.625C1.37398 10.4179 1.23105 10.1987 1.15582 9.96735C1.08059 9.73603 1.04297 9.49693 1.04297 9.25004V2.93754C1.04297 2.40421 1.22561 1.95143 1.59089 1.57921C1.95616 1.20698 2.40547 1.02087 2.9388 1.02087H9.2513C9.51208 1.02087 9.7616 1.06355 9.99986 1.14892C10.2381 1.23428 10.4608 1.3791 10.668 1.58337L18.418 9.31254C18.8069 9.70143 18.9978 10.1702 18.9909 10.7188C18.9839 11.2674 18.793 11.7292 18.418 12.1042L12.1055 18.4167ZM5.11528 6.14587C5.39959 6.14587 5.64611 6.04008 5.85486 5.82848C6.0636 5.61687 6.16797 5.36687 6.16797 5.07848C6.16797 4.80396 6.0636 4.56254 5.85486 4.35421C5.64611 4.14587 5.39959 4.04171 5.11528 4.04171C4.83096 4.04171 4.58464 4.14751 4.3763 4.3591C4.16797 4.57071 4.0638 4.81377 4.0638 5.08827C4.0638 5.37667 4.16797 5.62504 4.3763 5.83337C4.58464 6.04171 4.83096 6.14587 5.11528 6.14587Z" fill="#9D9D9D"/>
                    </svg>
                    <p className='text-[14px] text-[#9D9D9D] leading-normal'>
                        2.44
                        <span className='opacity-50 ml-[4px]'>credits used</span>
                    </p>
                </div> */}
                <div className='flex items-center gap-[10px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M12.1055 18.4167C11.7305 18.7917 11.2756 18.9792 10.7409 18.9792C10.2062 18.9792 9.7513 18.7917 9.3763 18.4167L1.58464 10.625C1.37398 10.4179 1.23105 10.1987 1.15582 9.96735C1.08059 9.73603 1.04297 9.49693 1.04297 9.25004V2.93754C1.04297 2.40421 1.22561 1.95143 1.59089 1.57921C1.95616 1.20698 2.40547 1.02087 2.9388 1.02087H9.2513C9.51208 1.02087 9.7616 1.06355 9.99986 1.14892C10.2381 1.23428 10.4608 1.3791 10.668 1.58337L18.418 9.31254C18.8069 9.70143 18.9978 10.1702 18.9909 10.7188C18.9839 11.2674 18.793 11.7292 18.418 12.1042L12.1055 18.4167ZM5.11528 6.14587C5.39959 6.14587 5.64611 6.04008 5.85486 5.82848C6.0636 5.61687 6.16797 5.36687 6.16797 5.07848C6.16797 4.80396 6.0636 4.56254 5.85486 4.35421C5.64611 4.14587 5.39959 4.04171 5.11528 4.04171C4.83096 4.04171 4.58464 4.14751 4.3763 4.3591C4.16797 4.57071 4.0638 4.81377 4.0638 5.08827C4.0638 5.37667 4.16797 5.62504 4.3763 5.83337C4.58464 6.04171 4.83096 6.14587 5.11528 6.14587Z" fill="#EA8207"/>
                    </svg>
                    <p className='text-[14px] text-[#EA8207] leading-normal'>
                        {meData?.credits || 0}
                        <span className='opacity-50 ml-[4px]'>available credits</span>
                    </p>
                </div>
            </div>
            <div className='editor-footer-column flex items-center gap-[8px]'>
                <button className='generated-content-bottom-panel-delete hover:opacity-70' onClick={(e)=>{
                    e.preventDefault();
                    clearCourses();
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M5.26953 17.9375C4.76692 17.9375 4.32182 17.7506 3.93424 17.3768C3.54666 17.003 3.35286 16.558 3.35286 16.0417V4.54167H2.14453V2.64584H6.89453V1.66667H13.082V2.64584H17.832V4.54167H16.6237V16.0417C16.6237 16.558 16.4348 17.003 16.0569 17.3768C15.679 17.7506 15.2291 17.9375 14.707 17.9375H5.26953ZM7.4362 14.4167H8.97786V6.125H7.4362V14.4167ZM11.0404 14.4167H12.6029V6.125H11.0404V14.4167Z" fill="white" fillOpacity="0.6"/>
                    </svg>
                </button>
                {/* <Link to='/my-email-newsletters'> */}
                    <button
                        className={`generated-button btn generated-content-bottom-panel-btn lg:min-w-[196px] ${generationIsNotDone ? 'block-disabled' : ''}`} disabled={generationIsNotDone}
                        onClick={(e)=>{
                            e.preventDefault();
                            save();
                        }}
                    >
                        {
                            generationIsNotDone ? 'Generating...' : id && coursesFromIndexedDb?.length === 0 ? 'Save Changes' : 'Empower Your Audience'
                        }
                    </button>
                {/* </Link> */}
            </div>
        </div>

        <Popup
            open={newsletterSettingsPopupOpen}
            onClose={() => closeNewsletterSettings()}
            overlayStyle={{
                background: 'rgba(0,0,0,0.5)'
            }}
        >
            <NewsletterSettingsPopup
                closeModal={() => closeNewsletterSettings()}
                selectedForSetting={selectedForSetting}
                setSelectedForSetting={setSelectedForSetting}
                save={updateIndexBd}
            />
        </Popup>
    </div>;
};

export default GeneratedContent;