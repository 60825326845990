import classNames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Created from './Created';
import Subscribed from './Subscribed';

const tabs = [
    {
        name: 'Created',
        Component: Created
    }, {
        name: 'Subscribed',
        Component: Subscribed
    }
]

const MyEmailNewsLetters = ({ innerAssets = false }) => {
    const [activeTab, setActiveTab] = useState(tabs[0]);
    return <div className={ innerAssets ? 'email-newsletter-inner-assets' : 'email-newsletter-container no-scroll' }>
        { !innerAssets ? <div className='flex justify-between py-[16px] px-[20px]'>
            <div>
                <h1 className="text-[18px] font-semibold leading-[22px] font-[Inter]">
                    My Lessons
                </h1>
                <h2 className='mt-[12px] text-[14px] font-normal leading-[10px] opacity-40 font-[Inter]'>
                    Discover exclusive content in my email newsletters
                </h2>
            </div>
            <Link to='/generated-content?type=lesson-kit'>
                <button className='generated-button btn email-newsletter-btn'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M9.9987 6.66666V13.3333" stroke="#331A04" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.3346 10H6.66797" stroke="#331A04" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 17.5V17.5C5.8575 17.5 2.5 14.1425 2.5 10V10C2.5 5.8575 5.8575 2.5 10 2.5V2.5C14.1425 2.5 17.5 5.8575 17.5 10V10C17.5 14.1425 14.1425 17.5 10 17.5Z" stroke="#331A04" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Create New
                </button>
            </Link>
        </div> : null }
        <ul className='h-[44px] flex gap-[8px] border-b border-solid border-[#1F1F1F] px-[20px]'>
            {tabs.map(tab => <li 
                className={classNames(
                    'flex items-center px-[8px] border-b-2 border-solid cursor-pointer',
                    activeTab.name === tab.name ? 'border-[#FF8114]' : 'border-transparent'
                )}
                onClick={() => setActiveTab(tab)}
            >
                <span className={classNames('text-[14px]', 
                    activeTab.name === tab.name ? 'font-medium opacity-90' : 'opacity-50'
                )}>
                    {tab.name}
                </span> 
            </li>)}
        </ul>
        {<activeTab.Component />}
    </div>;
};

export default MyEmailNewsLetters;