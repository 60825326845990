import React, {useEffect, useRef, useState} from 'react';

export const Tabs = ({labelsArray, idxCallBack, classes = ''}) => {
    const [activeTabIdx, setActiveTabIdx] = useState(0)
    const [tabCurrentWidth, setTabCurrentWidth] = useState(0)
    const [tabCurrentLeft, setTabCurrentLeft] = useState(0)

    const tabsRef = useRef([])

    useEffect(() => {
        const setTabPosition = () => {
            const currentTab = tabsRef.current[activeTabIdx]
            idxCallBack(labelsArray[activeTabIdx])
            setTabCurrentLeft(currentTab?.offsetLeft ?? 0)
            setTabCurrentWidth(currentTab?.clientWidth ?? 0)
        }

        setTabPosition()
        window.addEventListener("resize", setTabPosition)

        return () => window.removeEventListener("resize", setTabPosition)
    }, [activeTabIdx])



    return (
        <div className={`toolbar-body-nav-container ${classes ? classes : null}`}>
            <ul className="toolbar-body-nav">
                {labelsArray && labelsArray.map((el, idx) => <li
                        className={`tab ${activeTabIdx === idx ? 'activeTab' : ''}`}
                        onClick={() => setActiveTabIdx(idx)}
                        ref={(el) => (tabsRef.current[idx] = el)}
                        key={idx}>
                        {el}
                            </li>
                )}
            </ul>

            <span
                className="tabs-underline"
                style={{ left: tabCurrentLeft, width: tabCurrentWidth }}
            />

        </div>
    )
}

