import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { isAfter, isToday, isYesterday, subWeeks } from 'date-fns';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../../api';
import { useSelector } from 'react-redux';
import { getToken } from '../../redux/slices/applicationSlice';
import useScreenWidth from '../../helpers/useScreenWidth';

const ChatItem = ({chat: {title, id, tags}, setChats}) => {
    const { botId, chatId } = useParams();
    const token = useSelector(getToken);
    const navigate = useNavigate();

    const [inputTitle, setInputTitle] = useState(title);
    const [readOnly, setReadOnly] = useState(true);
    const inputRef = useRef(null);
    // console.log(readOnly);

    const screenWidth = useScreenWidth();
    const mobileScreen = screenWidth < 1199;

    const handleDeleteChat = async (deleteId) => {
        try {
            const response = await api.botKits.deleteChat({
                chatId: deleteId,
                token
            });
            if(!response.success) return;
            console.log(response, chatId, deleteId)
            setChats(prev => prev.filter(({id}) => id !== deleteId));
            if(chatId === String(deleteId)) navigate(`/chat-bot/${botId}`)
        } catch (e) {
            console.log(e);
        }
    }

    const handleEditChat = async () => {
        try {
            const response = await api.botKits.updateChat({
                chatId: id,
                body: {
                    title: inputTitle
                },
                token
            });
            if(!response.success) return;
            setReadOnly(true);
        } catch (e) {
            console.log(e);
        }
    }

    return <li
        className={classNames('flex items-center p-[10px] rounded-[8px] cursor-pointer relative group hover:bg-[#1B1B1B]', {
            'bg-[#1B1B1B] text-[#FF8114]': String(id) === chatId 
        })}
        onClick={() => navigate(`/chat-bot/${botId}/c/${id}`)}
    >
        <div className='flex flex-col gap-[10px] w-full'>
            <input 
                value={inputTitle}
                onChange={e => setInputTitle(e.target.value)}
                readOnly={readOnly}
                className='text-[13px] opacity-90 line-clamp-1 bg-inherit w-full'
                ref={inputRef}
                onKeyDown={(e) => {
                    if(e.key === 'Enter' && !readOnly) handleEditChat();
                }}
            />
            <ul className='flex gap-[6px] flex-wrap'>
                {tags.map((tag, index) => 
                    <li key={index} className='px-[8px] py-[6px] text-[#A7A7A7] text-[11px] rounded-full
                    border border-solid border-[#ffffff1f]'>
                        {tag}
                    </li>
                )}
            </ul>
        </div>
        {readOnly ?
        <div
            // onClick={e => e.stopPropagation()}
            className='h-full w-[140px] chat-bot-gradient rounded-[8px] hidden group-hover:flex
            absolute top-0 right-0 items-center justify-end pr-[7px] gap-[8px]'
        >
            <button 
                className='generated-content-letter-card-button w-[32px] rounded-full'
                onClick={(e) => {
                    e.stopPropagation();
                    setReadOnly(false);
                    inputRef.current.focus();
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.40823 13.0159L13.0132 4.41086C13.3382 4.08586 13.8657 4.08586 14.1907 4.41086L15.5874 5.80753C15.9124 6.13253 15.9124 6.66003 15.5874 6.98503L6.98156 15.5892C6.82573 15.7459 6.61406 15.8334 6.39323 15.8334H4.16406V13.6042C4.16406 13.3834 4.25156 13.1717 4.40823 13.0159Z" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11.4609 5.96667L14.0359 8.54167" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteChat(id);
                }}
                className='generated-content-letter-card-button w-[32px] rounded-full'
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M15 5L5 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M5 5L15 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        </div> : mobileScreen ? <div
            className='h-full w-[140px] chat-bot-gradient rounded-[8px] d-flex
            absolute top-0 right-0 items-center justify-end pr-[7px] gap-[8px]'
        >
            <button 
                className='generated-content-letter-card-button w-[32px] rounded-full'
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if(inputTitle?.length){
                        handleEditChat();
                    }
                }}
            >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.3337 4.33325L6.00033 11.6666L2.66699 8.33325" stroke="#A7A7A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        </div> : null
        }
    </li>
};

export default ChatItem;