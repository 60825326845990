import React, { Fragment, useContext, useEffect, useState } from 'react'
import classNames from 'classnames';
import logo from '../../../assets/img/gen4logo.svg';
import { navLinks } from './consts';
import { GenerateFormContext } from '../../GeneratorV4/GeneratorContextComponent';
import BottomPanel from '../LandingContent/BottomPanel';
import { ReactComponent as ArrowIcon } from '../Blog/icons/arrow.svg';
import { SidePanel } from './SidePanel';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getSocket, getToken, getUser, setUser,  } from '../../../redux/slices/applicationSlice';
import { getUserUpdateThunk } from '../../../redux/thunks/applicationThunks';
import { 
    getActiveBlogIndex, getGeneratedBlogs, getGenerateLoading,
    setBlogs, setBlogDone, setBlogVersions, setGenerateLoading,
    setActiveBlogIndex, setBlogVersionAddon, resetBlogGenerator,
    setBlogAdd_Ons, setBlogAddonVersion,
} from '../../../redux/slices/blogGeneratorSlice';
import { checkUserHaveCredits } from '../../../helpers/userHelpers';
import Modals from '../LandingContent/Modals';
import AddonCard from '../Blog/AddonCard';
import LandingPageCard from '../LandingContent/LandingPageCard';
import ProductPageCard from './ProductPageCard';
import GeneratedContentModal from '../GeneratedContentModal';
import HelpModal from '../../../components/base/HelpModal';
import { toast } from 'react-toastify';
import api from '../../../api';
import ProductDescriptionAddons from './ProductDescriptionAddons';
import PrimaryButton from '../../../components/base/PrimaryButton';
import { v4 } from 'uuid';
import useScreenWidth from '../../../helpers/useScreenWidth';

const ProductDescriptionGeneratedContent = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const meData = useSelector(getUser);

    const token = useSelector(getToken);
    const socket = useSelector(getSocket);
    const generateLoading = useSelector(getGenerateLoading);
    const activeBlogIndex = useSelector(getActiveBlogIndex);
    const blogs = useSelector(getGeneratedBlogs);
    console.log('blogs', blogs);

    const [openedModal, setOpenedModal] = useState();
    const [configForm, setConfigForm] = useState();
    const [isLoading, setIsLoading] = useState(false);
    // const [toastId, setToastId] = useState(null);
    const [cardFullScreen, setCardFullScreen] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);

    const [generateOnlyAddons, setGenerateOnlyAddons] = useState(false);

    const screenWidth = useScreenWidth();
    const mobileScreen = screenWidth < 1199;
    
    const activeBlog = blogs[activeBlogIndex];
    console.log('generateLoading:', generateLoading);
    const isNotDone = generateLoading ? blogs.filter(item => !item?.done).length : false;
    console.log(isNotDone, 'done');
    // const isNotDone = false

    const wordsCount = blogs.reduce((accumulator, blog) => {
        const blogWordCount = blog.versions.reduce((accumulator, version) => {
            const displayText = version?.activeContent || version?.content || '';
            return accumulator + displayText.split(' ').filter(word => word.length > 0).length;
        }, 0);
        return accumulator + blogWordCount
    }, 0)

    const closeModal = () => setShowHelpModal(false);
    
    // copy
    const duplicate = async() => {
        if(!navigate || !id || !token) return;
        
        const response = await api.assets.cloneAsset({
            id,
            token
        });

        if(response?.success){
            setOpenedModal({
                name: 'duplicate',
                title: `Product description “${blogs?.[0]?.blog_topic}” successfully duplicated as: ”${response.data?.name}”`
            });

            return;
        }

        setOpenedModal(null);
    }

    const deleteAction = async() => {
        const response = await api.assets.deleteAsset({
            id,
            token
        });

        if(response.success){
            setOpenedModal({
                name: 'save',
                title: response.message,
                text: ''
            });

            setTimeout(() => {
                navigate('/');
            }, 1500);

            return;
        }

        setOpenedModal(null);
    }

    const save = async() => {
        let res = await api.assets.changeAsset({
            id,
            data: {
                content: JSON.stringify(blogs)
            },
            token
        });

        if(res?.success){
            setOpenedModal({
                name: 'save',
                title: 'Saved',
                text: `Your product description has been successfully saved`
            })
        } else {
            console.error(res);
        }
    }

    const handleExport = async (id, ext) => {
        await api.assets.exportAsset({
            token,
            id,
            type: 'product-description',
            ext,
            filename: `${blogs[0].versions[0].title}.${ext}`,
        });

        setOpenedModal(null);
    }
    // end copy
    console.log(configForm);
    const handleGenerate = async () => {

        try {
            let checkRes = await checkUserHaveCredits(token);
            if(!checkRes?.success){
                dispatch(setUser(checkRes?.data));
                return;
            }

            console.log('handleGenerate configForm:', configForm);

            const formatedAddons = configForm.add_ons

            const { title, suggestions, keywords, description, platform } = configForm
            const Ckeywords = keywords
            let settings = {
                title,
                addons: formatedAddons,
                suggestions,
                language: 'English',
                tone: 'Professional',
                keywords: Ckeywords,
                description,
                platform
            };

            if(generateOnlyAddons){
                dispatch(setGenerateLoading(true));

                console.log('--===generateOnlyAddons===--');

                dispatch(setBlogAdd_Ons(configForm?.add_ons));

                let addonsNotDone = [];
                let addonsIsDone = [];

                for (let addonBlogIndex = 0; addonBlogIndex < blogs.length; addonBlogIndex++) {
                    for (let addonBlogVersionIndex = 0; addonBlogVersionIndex < blogs[addonBlogIndex].versions.length; addonBlogVersionIndex++) {
                        addonsNotDone.push(false);

                        setTimeout(() => {
                            socket.send({
                                event: 'generation-addons',
                                payload: {
                                    data:[{
                                        name: "Product Description content",
                                        content: blogs[addonBlogIndex].versions[addonBlogVersionIndex].content,
                                    }],
                                    addons: formatedAddons
                                },
                                handler: (response) => {
                                    if(response.done){
                                        addonsIsDone.push(true);
                                    }

                                    if(Object.keys(response.data)?.length){
                                        const addonKeys = Object.keys(response.data);
                                        for (let addonKeyIndex = 0; addonKeyIndex < addonKeys.length; addonKeyIndex++) {
                                            dispatch(setBlogAddonVersion({
                                                blogIndex: addonBlogIndex,
                                                versionIndex: addonBlogVersionIndex,
                                                key: addonKeys[addonKeyIndex],
                                                value: response.data[addonKeys[addonKeyIndex]],
                                                onlyAddonGenerate: true,
                                                kit: "product-description",
                                            }))
                                        }
                                    }

                                    if(addonsNotDone?.length === addonsIsDone?.length){
                                        dispatch(setGenerateLoading(false));

                                        toast.success('Generated successfully!');
                                    }
                                }
                            });
                        }, addonBlogVersionIndex*100);
                    }
                }

                return;
            }

            setIsLoading(true);

            const response = await api.assets.changeAsset({
                id,
                data: {
                    content: JSON.stringify(
                        [...blogs,
                            {
                            add_ons: formatedAddons,
                            settings,
                            versions: [],
                            activeVersionIndex: 0,
                            generatedDate: new Date().toUTCString(),
                            selectedVersionId: null,
                            addonsValues: []
                        }
                    ])
                },
                token
            });

            setIsLoading(false)
            if(!response.success) return;
            // navigate(`/generated-page/product-description/${response.data.id}`);
            const dataRes = JSON.parse(response.data.content);
            const lastDataRes = dataRes[dataRes.length - 1];
            dispatch(setBlogs(dataRes));
            dispatch(setActiveBlogIndex(dataRes.length - 1));
            dispatch(setGenerateLoading(true));
            const generatorId = v4(); 
            const toastId = toast.loading(<div className='flex flex-col items-center gap-[10px]'>
                <p>{`Generating product description, please wait...`}</p>
                <div className="d-flex items-center gap-[10px]">
                    <PrimaryButton
                        className="min-w-[100px]"
                        onClick={() => { 
                            navigate(`/generated-page/product-description/${id}`)
                        }}
                    >View</PrimaryButton>
                    <PrimaryButton onClick={() => {
                        socket.send({
                            event: 'stop-event-generation',
                            payload: {
                                id: generatorId
                            },
                            handler: (stopResponse) => {
                                console.log(stopResponse);
                                toast.dismiss(toastId)
                            }
                        })
                    }}>Stop Generation</PrimaryButton>
                </div>
            </div>);
            socket.send({
                id: generatorId,
                event: 'product-description-create',
                payload: {
                    title,
                    description,
                    platform,
                    keywords: Ckeywords.split(','),
                    suggestions,
                    add_ons: formatedAddons,
                },
                handler: async(resData) => {
                    if(resData?.isError){
                        dispatch(setGenerateLoading(false));
                        toast.error("AI bot is not available at the moment");
                        return;
                    }

                    dispatch(setBlogVersions([
                        {
                            "isDone": resData.isDone,
                            'title': resData.data.title,
                            'content': resData.data.content,
                            'id': response.data.id,
                            description,
                            platform,
                            keywords,
                            suggestions,
                            add_ons: formatedAddons,
                        }
                    ]))

                    if(resData.isDone) {
                        if(configForm.add_ons?.length) {
                                let checkRes2 = await checkUserHaveCredits(token);
                                if(!checkRes2?.success){
                                    dispatch(setUser(checkRes2?.data));
                                    return;
                                }

                                socket.send({
                                    event: 'product-description-addons',
                                    payload: {
                                        content: resData.payload.content,
                                        title: resData.payload.title,
                                        addons: formatedAddons,
                                    },
                                    handler: (addonsResponse) => {
                                        if(addonsResponse?.isError){
                                            dispatch(setGenerateLoading(false));
                                            toast.error("AI bot is not available at the moment");
                                            return;
                                        }

                                        dispatch(setBlogVersionAddon({
                                            index: 0,
                                            data: {
                                                ...addonsResponse.payload,
                                                isDone: addonsResponse.isDone
                                            }
                                        }));

                                        if(addonsResponse.isDone){
                                            console.log('product-description-addons done');
                                            dispatch(getUserUpdateThunk({ token }));
                                            api.assets.changeAsset({
                                                id: response.data.id,
                                                data: {
                                                    content: JSON.stringify([...blogs,
                                                        {
                                                            add_ons: configForm.addons,
                                                            // settings: configForm.settings,
                                                            versions: [{
                                                                "isDone": resData.isDone,
                                                                'title': resData.data.title,
                                                                'content': resData.data.content,
                                                                'id': response.data.id,
                                                                description,
                                                                platform,
                                                                keywords,
                                                                suggestions,
                                                                add_ons: formatedAddons,
                                                                }],
                                                            activeVersionIndex: 0,
                                                            generatedDate: new Date().toUTCString(),
                                                            selectedVersionId: null,
                                                            addonsValues: [{
                                                                isDone: true,
                                                                ...addonsResponse.payload,
                                                            }],
                                                        }
                                                    ])
                                                },
                                                token
                                            }).then(resp => {console.log(resp)});
                                
                                            toast.update(toastId, {
                                                render: 'Generated successfully!',
                                                type: 'success', 
                                                isLoading: false,
                                                autoClose: 5000
                                            });
                                
                                            dispatch(setGenerateLoading(false));
                                
                                            dispatch(setBlogDone(true));
                                        }
                                    }
                                })
                            
                        } else {
                            api.assets.changeAsset({
                                id: response.data.id,
                                data: {
                                    content: JSON.stringify([...blogs,
                                        {
                                            add_ons: configForm.addons,
                                            settings: configForm,
                                            versions: [{
                                                "isDone": resData.isDone,
                                                'title': resData.data.title,
                                                'content': resData.data.content,
                                                'id': response.data.id,
                                                description,
                                                platform,
                                                keywords,
                                                suggestions,
                                                add_ons: formatedAddons,
                                                }],
                                            activeVersionIndex: 0,
                                            generatedDate: new Date().toUTCString(),
                                            selectedVersionId: null,
                                            addonsValues: []
                                        }
                                    ])
                                },
                                token
                            }).then(resp => {console.log(resp)});
                
                            toast.update(toastId, {
                                render: 'Generated successfully!',
                                type: 'success', 
                                isLoading: false,
                                autoClose: 5000
                            });
                
                            dispatch(setGenerateLoading(false));
                
                            dispatch(setBlogDone(true));
                        }
                        dispatch(getUserUpdateThunk({ token }));
                    }
                    
                }
            })
            
        } catch (error) {
            console.log(error, 'error');
        } finally {
            
            console.log('done 33333');
        }

    }


    // useEffect(() => {
    //     if(!generateLoading) return;
    //     if(!toastId) {
    //         setToastId(toast.loading('Generating your product, please wait...'));
    //     }

    //     if(activeBlog?.versions?.length && activeBlog?.versions.every(version => version.isDone)) {
    //         if(activeBlog?.add_ons?.length && (activeBlog.versions?.length !== activeBlog.addonsValues?.length || activeBlog?.addonsValues.some(addon => !addon.isDone))) return;

    //         api.assets.changeAsset({
    //             id,
    //             data: {
    //                 name: blogs?.length && blogs[0]?.versions?.length ? ( blogs[0]?.versions?.length === 1 ? blogs[0].versions[0].title : blogs[0].versions.map(item => item.title).join(', ') ) : 'Product description',
    //                 content: JSON.stringify(blogs)
    //             },
    //             token
    //         }).then(resp => {console.log(resp)});

    //         toast.update(toastId, {
    //             render: 'Generated successfully!',
    //             type: 'success', 
    //             isLoading: false,
    //             autoClose: 5000
    //         });

    //         dispatch(setGenerateLoading(false));
    //         setToastId(null);

    //         dispatch(setBlogDone(true));
    //     }
    // }, [activeBlog, generateLoading, blogs, id, token, dispatch, toastId])

    useEffect(() => {
        if(!navigate || !id || !dispatch || !token) return;
        const fetchBlogData = async () => {
            try {
                const response = await api.assets.getAssetById({
                    id,
                    token
                });
                if(!response.success) return;
                dispatch(setBlogs(JSON.parse(response.data.content)));
            } catch (e) {
                if(e.response?.status === 404) {
                    navigate('/')
                }
            }
        }
        fetchBlogData();
    }, [id, navigate, dispatch, token])

    useEffect(() => {
        if(!blogs.length) return;

        const payloadToConfigForm = {
            ...blogs[activeBlogIndex]?.settings,
            add_ons: blogs[activeBlogIndex]?.settings?.addons || [],
        };

        if(
            (JSON.stringify(configForm?.title) !== JSON.stringify(payloadToConfigForm?.title)) &&
            (JSON.stringify(configForm?.description) !== JSON.stringify(payloadToConfigForm?.description)) &&
            (JSON.stringify(configForm?.suggestions) !== JSON.stringify(payloadToConfigForm?.suggestions)) &&
            (JSON.stringify(configForm?.keywords) !== JSON.stringify(payloadToConfigForm?.keywords)) &&
            (JSON.stringify(configForm?.platform) !== JSON.stringify(payloadToConfigForm?.platform))
        ){
            setConfigForm(payloadToConfigForm);
        }
    }, [blogs, activeBlogIndex])


    return (
        <main className='w-screen h-screen overflow-hidden flex'>
            
            <SidePanel
                cardFullScreen={cardFullScreen}
                configForm={configForm}
                setConfigForm={setConfigForm}
                setIsLoading={setIsLoading}
                handleGenerate={handleGenerate}
                isNotDone={isNotDone}
                mobileScreen={mobileScreen}
                generateOnlyAddons={generateOnlyAddons}
                setGenerateOnlyAddons={setGenerateOnlyAddons}
            />

        {isLoading ? <div className="flex flex-col justify-center items-center h-full flex-1">
            <div className="lds-ring"><div /><div /><div /><div /></div>
            <div className="mt-[36px] text-[18px] font-medium leading-[13px] opacity-90">Loading...</div>
            <div className="mt-[24px] text-[14px] leading-[10px] opacity-50 text-[#A7A7A7]">Please wait for your results...</div>
        </div> :
        <div className='flex-1 flex flex-col'>
            <div className={classNames('py-[13px] px-[16px] flex items-center justify-between', {
                'px-[30px]': cardFullScreen
            })}>
                <div>
                    <h1 className='text-[14px] font-medium leading-normal'>
                        Generated Content
                    </h1>
                    <div className='text-[12px] leading-[14px] flex items-center gap-[5px] flex-wrap'>
                        <Link
                            to="/generated-content?type=product-description"
                            className='opacity-40 hover:text-[#FF8114] hover:underline hover:opacity-100'
                        >
                            Product Description Kit
                        </Link>
                        <div className='w-[3px] h-[3px] rounded-full bg-white opacity-40'/>
                        <span className="opacity-40">
                            Product Description
                        </span>
                    </div>
                </div>
                <button 
                    onClick={() => setShowHelpModal(true)}
                    className='w-[32px] h-[32px] flex items-center justify-center opacity-50'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9.68457 9.68482C9.92159 8.66326 10.852 7.95496 11.8998 7.99844C13.0725 7.93349 14.0778 8.82709 14.1508 9.99928C14.1508 11.5038 11.9999 12.0001 11.9999 13.0005" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12.1253 15.7523C12.1252 15.8213 12.0692 15.8772 12.0002 15.8772C11.9311 15.8772 11.8752 15.8212 11.8752 15.7522C11.8751 15.6832 11.931 15.6272 12 15.6271C12.0333 15.6271 12.0651 15.6402 12.0886 15.6637C12.1121 15.6872 12.1253 15.7191 12.1253 15.7523" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.62861 17.1605C1.94551 13.3308 2.63502 8.08546 6.21672 5.07926C9.79842 2.07305 15.0839 2.30347 18.3903 5.60997C21.6968 8.91646 21.9273 14.2019 18.9211 17.7836C15.9149 21.3653 10.6695 22.0548 6.8398 19.3717L3.99688 20.0034L4.62861 17.1605Z" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            <div className='py-[4px] px-[16px] pb-[12px] flex-1 gap-[12px] overflow-y-scroll no-scroll'>
                <ul className={classNames('flex flex-col gap-[12px]', {
                    'h-full': cardFullScreen
                })}>
                    {blogs.map((data, index) => {
                        return <Fragment key={index}>
                            <ProductPageCard
                                isNotDone={isNotDone}
                                data={data.versions} 
                                index={index}
                                setCardFullScreen={setCardFullScreen}
                                isFullScreen={cardFullScreen}
                            />
                            { Boolean(data?.addonsValues?.length) && data?.add_ons?.map((addonItem, addonIndex) => 
                            <ProductDescriptionAddons
                                key={addonItem?.title}
                                title={addonItem?.title}
                                description={addonItem?.description}
                                data={data}
                                index={index}
                                addonIndex={addonIndex}
                                forceActiveVersionIndex={data?.activeVersionIndex}
                                isFullScreen={cardFullScreen}
                                selectFromVersion={true}
                            />)}
                        </Fragment>
                    })}
                </ul>
            </div>

            <BottomPanel
                meData={meData}
                blogs={blogs}
                wordsCount={wordsCount}
                isNotDone={isNotDone}
                setOpenedModal={setOpenedModal}
                deleteAction={deleteAction}
                duplicate={duplicate}
                save={save}
            />
        </div>}

        <Modals
            openedModal={ openedModal }
            setOpenedModal={ setOpenedModal }
            closeModal={ closeModal }
            showHelpModal={ showHelpModal }
            GeneratedContentModal={ GeneratedContentModal }
            handleExport={ handleExport }
            id={ id }
            HelpModal={ HelpModal }
            docx={true}
        />

        </main>
    )
}


export default ProductDescriptionGeneratedContent