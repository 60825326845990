import React, { useState } from 'react';
import { SmallImageSwiper } from '../../GeneratorV4/SmallImageSwiper';
import { categories } from './consts';

const Presets = ({ setActiveTab, setSelectedFromPresets }) => {
    const [search, setSearch] = useState("");
    const [selectedNewsletters, setSelectedNewsletters] = useState([]);

    const filteredCategories = categories.filter(item => item.newsletters.filter(item2 => item2.title.toLowerCase().includes(search.toLowerCase())).length )

    const selectPreset = (value) => {
        setSelectedFromPresets(value);
        setActiveTab('Kit')
    }

    return <>
        <div className='py-[13px] px-[16px]'>
            <h2 className='text-[14px] font-medium leading-normal'>Presets</h2>
            <p className='text-[12px] leading-[14px] opacity-40'>List of preset newsletters that you can use</p>
        </div>
        <div className='flex-auto'>
            <div className='input-search mx-[16px] mt-[7px]'>
                <svg opacity='0.3' xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <circle cx="10.215" cy="10.2155" r="5.88495" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M17.67 17.6694L14.377 14.3764" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <input  placeholder='Search newsletters...' value={search} onInput={(e)=>{
                    setSearch(e.target.value);
                }} />
            </div>
            <ul className='flex flex-col gap-[16px] ml-[16px] mt-[16px]'>
                {filteredCategories.map(({title, newsletters}, categoryIndex) => <li key={categoryIndex}>
                    <p className="input-custom__label mb-[10px]">{title}</p>
                    <SmallImageSwiper 
                        size='sm'
                        data={newsletters}
                        activeSlideIndex={selectedNewsletters[categoryIndex]}
                        setActiveSlideIndex={(itemIndex) => {
                            // setSelectedNewsletters(prev => {
                            //     const result = [...prev];
                            //     result[categoryIndex] = itemIndex;
                            //     return result;
                            // })
                            selectPreset(categories[categoryIndex].newsletters[itemIndex].title)
                        }}
                    />
                </li>)}
            </ul>
        </div>
        <div className='py-[13px] px-[16px]'>
            <button className='generated-button btn'>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                    <path d="M10.5 4.16663V15.8333" stroke="#331A04" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.66797 10H16.3346" stroke="#331A04" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Add Newsletter(s)
            </button>
        </div>
    </>;
};

export default Presets;