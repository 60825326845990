export const openai = (instance) => ({
    async generateText(payload) {
        const { data } = await instance.post('/generate-text', payload);
        return data
    },

    async editText(payload) {
        const { data } = await instance.post('/edit-text', payload);
        return data
    },
})