import React, {useEffect, useRef, useState} from 'react';
import SelectBox from "../../components/base/SelectBox";
import MultiSelect from "../../components/base/MultiSelect";
import AddCardModal, { brandIcons } from "./AddCardModal";
import { getSettings, getToken, getUser, setUser } from '../../redux/slices/applicationSlice';
import { useSelector } from 'react-redux';
import api from '../../api';
import getUserImageSrc from '../../helpers/getUserImageSrc';
import { useDispatch } from 'react-redux';
import Label from '../../components/base/Label';
import Input from '../../components/base/Input';
import PrimaryButton from '../../components/base/PrimaryButton';
import { Link, NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import Popup from 'reactjs-popup';
import CardItem from './CardItem';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import { toast  } from 'react-toastify';
 
import Security from './Security';
import KeepItPositive from './KeepItPositive';
import classNames from 'classnames';

import { 
    cardsContent
 } from "../../data";

const pages = {
    general: 'General',
    security: 'Security',
    plan: 'Plan & Billing',
    notifications: 'Notifications',
    referral: 'Referral Program',
    'settings-section': 'Settings Section',
    positive: 'Keep It Positive',
}

const SettingsNew = () => {
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();

    const user = useSelector(getUser);
    const settings = useSelector(getSettings);
    const stripeRef = useRef();
    
    const referralLink = `${window.location.origin}/sign-up?referralCode=${user.referralCode}`
    const token = useSelector(getToken);
    const dispatch = useDispatch();
    const [generalSettings, setGeneralSettings] = useState(user);
   
 
    
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const activePage = searchParams.get("tab") || 'general';
    // console.log(activePage);
    const [inviteInput, setInviteInput] = useState('');

    const [addCardModal, setAddCardModal] = useState(false);
    const closeModal = () => setAddCardModal(false);

    const handleChangeInput = ({target}) => {
        setGeneralSettings(prev => ({
            ...prev,
            [target.id]: target.value
        }))
    }
    
    const handleInputImage = (e) => {
        const file = e.target.files[0];
        if(!file) return;
        setGeneralSettings({...generalSettings, image: file})
    }

    const handleUpdateProfile = async () => {
        try {
            const response = await toast.promise(api.users.updateUser({
                data: generalSettings,
                token
            }), {
                pending: 'Updating your profile...',
                success: 'Profile updated successfully!',
                error: {
                    render({data}) {
                        return data.response.data.message;
                    }
                }
            });
            if(response.success) {
                dispatch(setUser(response.data));
            }
        } catch (e) {
            console.log(e);
        }
    }
    
    const validateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };
    const shareButtons = [
        [   
            FacebookShareButton,
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M11.6654 11.2503H13.7487L14.582 7.91699H11.6654V6.25033C11.6654 5.39199 11.6654 4.58366 13.332 4.58366H14.582V1.78366C14.3104 1.74783 13.2845 1.66699 12.2012 1.66699C9.9387 1.66699 8.33203 3.04783 8.33203 5.58366V7.91699H5.83203V11.2503H8.33203V18.3337H11.6654V11.2503Z" fill="#A7A7A7"/>
            </svg>,
            () => {}
        ], [
            TwitterShareButton,
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M14.6007 3H17.054L11.694 8.93095L18 17H13.0627L9.196 12.1055L4.77067 17H2.316L8.04933 10.656L2 3.00065H7.06267L10.558 7.47435L14.6007 3ZM13.74 15.5789H15.0993L6.324 4.34689H4.86533L13.74 15.5789Z" fill="#A7A7A7"/>
            </svg>,
            () => {}
        ], [
            WhatsappShareButton,
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M15.8737 4.09204C15.1097 3.32031 14.1997 2.70843 13.1967 2.29208C12.1938 1.87574 11.118 1.66326 10.032 1.66704C5.48203 1.66704 1.7737 5.37537 1.7737 9.92537C1.7737 11.3837 2.15703 12.8004 2.8737 14.0504L1.70703 18.3337L6.08203 17.1837C7.29036 17.842 8.6487 18.192 10.032 18.192C14.582 18.192 18.2904 14.4837 18.2904 9.93371C18.2904 7.72537 17.432 5.65037 15.8737 4.09204ZM10.032 16.792C8.7987 16.792 7.59036 16.4587 6.53203 15.8337L6.28203 15.6837L3.68203 16.367L4.3737 13.8337L4.20703 13.5754C3.52182 12.4812 3.15798 11.2164 3.15703 9.92537C3.15703 6.14204 6.24036 3.05871 10.0237 3.05871C11.857 3.05871 13.582 3.77538 14.8737 5.07538C15.5133 5.71201 16.0201 6.46926 16.3649 7.30323C16.7096 8.1372 16.8854 9.0313 16.882 9.93371C16.8987 13.717 13.8154 16.792 10.032 16.792ZM13.7987 11.6587C13.5904 11.5587 12.5737 11.0587 12.3904 10.9837C12.1987 10.917 12.0654 10.8837 11.9237 11.0837C11.782 11.292 11.3904 11.7587 11.2737 11.892C11.157 12.0337 11.032 12.0504 10.8237 11.942C10.6154 11.842 9.9487 11.617 9.16536 10.917C8.5487 10.367 8.14036 9.69204 8.01536 9.48371C7.8987 9.27537 7.9987 9.16704 8.10703 9.05871C8.1987 8.96704 8.31536 8.81704 8.41536 8.70037C8.51536 8.58371 8.55703 8.49204 8.6237 8.35871C8.69036 8.21704 8.65703 8.10038 8.60703 8.00038C8.55703 7.90038 8.14036 6.88371 7.9737 6.46704C7.80703 6.06704 7.63203 6.11704 7.50703 6.10871H7.10703C6.96536 6.10871 6.7487 6.15871 6.55703 6.36704C6.3737 6.57538 5.84036 7.07538 5.84036 8.09204C5.84036 9.10871 6.58203 10.092 6.68203 10.2254C6.78203 10.367 8.14036 12.4504 10.207 13.342C10.6987 13.5587 11.082 13.6837 11.382 13.7754C11.8737 13.9337 12.3237 13.9087 12.682 13.8587C13.082 13.8004 13.907 13.3587 14.0737 12.8754C14.2487 12.392 14.2487 11.9837 14.1904 11.892C14.132 11.8004 14.007 11.7587 13.7987 11.6587Z" fill="#A7A7A7"/>
            </svg>,
            () => {}
        ], [
            LinkedinShareButton,
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M5.78255 4.16652C5.78233 4.60855 5.60652 5.03239 5.29381 5.34479C4.98109 5.6572 4.55708 5.83258 4.11505 5.83236C3.67302 5.83214 3.24919 5.65633 2.93678 5.34361C2.62438 5.0309 2.449 4.60688 2.44922 4.16486C2.44944 3.72283 2.62525 3.29899 2.93796 2.98659C3.25068 2.67419 3.67469 2.4988 4.11672 2.49902C4.55875 2.49924 4.98258 2.67505 5.29499 2.98777C5.60739 3.30049 5.78277 3.7245 5.78255 4.16652ZM5.83255 7.06652H2.49922V17.4999H5.83255V7.06652ZM11.0992 7.06652H7.78255V17.4999H11.0659V12.0249C11.0659 8.97486 15.0409 8.69152 15.0409 12.0249V17.4999H18.3326V10.8915C18.3326 5.74986 12.4492 5.94152 11.0659 8.46652L11.0992 7.06652Z" fill="#A7A7A7"/>
            </svg>,
            () => {}
        ]
    ]
    const [cards, setCards] = useState([]);

    const productsTurnOffOptions = cardsContent.map(item => ({ label:item.title, value:item.type }));

    // useEffect(() => {
    //     if(!settings?.stripe_pk) return;
    //     stripeRef.current = loadStripe(settings.stripe_pk);
    // }, [settings])

    useEffect(() => {
        if(!token) return;
        api.paymentCards.getCards({token}).then(response => {
            if(!response.success) return;
            setCards(response.data);
        }).catch(e => console.log(e)) 
    }, [token])

    return (
        <div className="page-section pt-[0px] d-flex">
            <div className="container d-flex h-full">
                <div className="assets-page flex flex-col">
                    <div className="d-flex align-items-center justify-between py-[16px] px-[20px]">
                        <div>
                            <h3 className="text-[18px] font-[600] text-[#ffffff] mb-[12px] lh-n">Account Settings</h3>
                            <p className="text-[14px] font-[400] text-[#737373] lh-n">Control your account</p>
                        </div>

                    </div>

                    <div className="assets-page-body">
                        <div className="px-[20px] bb-1-grey d-flex align-items-center justify-between">
                            <ul className="tabs-controls flex-wrap">
                                {
                                    Object.entries(pages).map(([key, value], index) => {
                                        return <li key={index}>
                                            <Link 
                                                to={`/settings?tab=${key}`}
                                                // className={({ isActive, isPending }) =>
                                                //     classNames('d-flex items-center', {
                                                //         'active': isActive
                                                //     })
                                                // }
                                                className={`${activePage === key ? `active` : ''} d-flex items-center`}
                                            >
                                                {value}
                                                { value === 'Keep It Positive' ?
                                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className='ml-[6px]'>
                                                        <path d="M4.74089 12.7891C4.38116 12.7891 4.07005 12.6637 3.80755 12.4128C3.54505 12.162 3.4138 11.8451 3.4138 11.462V5.55577C3.4138 5.38725 3.44347 5.22329 3.50282 5.06388C3.56216 4.90448 3.66374 4.75733 3.80755 4.62243L6.15547 2.1141C6.34019 1.91966 6.56623 1.79084 6.83359 1.72764C7.10096 1.66445 7.36589 1.69118 7.62839 1.80785C7.86172 1.93424 8.03429 2.12382 8.14609 2.3766C8.2579 2.62938 8.28464 2.89674 8.2263 3.17868L7.84714 5.01618H12.2951C12.6431 5.01618 12.9537 5.15424 13.2269 5.43035C13.5001 5.70646 13.6367 6.01077 13.6367 6.34327V6.88766C13.6367 7.01007 13.6197 7.15095 13.5857 7.3103C13.5516 7.46964 13.5152 7.59938 13.4763 7.69952L11.7263 11.7683C11.6194 12.0405 11.4203 12.2787 11.129 12.4829C10.8378 12.687 10.5292 12.7891 10.203 12.7891H4.74089ZM1.61824 12.7891C1.37639 12.7891 1.16797 12.703 0.992969 12.5309C0.817969 12.3587 0.730469 12.1483 0.730469 11.8995V5.92035C0.730469 5.67729 0.812097 5.46584 0.975352 5.28597C1.13861 5.10611 1.3452 5.01618 1.59514 5.01618H1.63889C1.87911 5.01618 2.08915 5.10611 2.26901 5.28597C2.44887 5.46584 2.5388 5.67729 2.5388 5.92035V11.8995C2.5388 12.1483 2.4487 12.3587 2.26849 12.5309C2.08826 12.703 1.87638 12.7891 1.63283 12.7891H1.61824Z" fill="#FF8114"/>
                                                    </svg>
                                                : null }
                                            </Link>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='flex-1 overflow-y-auto no-scroll flex flex-col px-[20px]'>
                    { activePage === 'general' &&
                        <>
                            <div className='settings-component-wrapper'>
                                <div className="settings-component-side flex-col">
                                    <span className='settings-component-title' >Profile Picture</span>
                                    <span className='settings-component-subtitle'>Min. 200x200 px, PNG or JPG</span>
                                </div>
                                <div className="settings-component-side items-center">
                                    <div className='settings-component-avatar' >
                                        <img 
                                            src={getUserImageSrc(generalSettings)}
                                            alt="user"
                                            className='w-full h-full object-cover'
                                        />
                                    </div>
                                    <label htmlFor='image' className='btn-outline-primary cursor-pointer'>Upload Picture</label>
                                    <input
                                        id='image'
                                        type='file'
                                        accept="image/png, image/jpeg"
                                        multiple={false}
                                        hidden
                                        onChange={handleInputImage}
                                    />
                                </div>
                            </div>
                            <div className='settings-component-wrapper border-t border-solid border-[#1F1F1F]'>
                                <div className="settings-component-side flex-col">
                                    <span className='settings-component-title' >Profile Info</span>
                                    <span className='settings-component-subtitle'>Manage your profile information</span>
                                </div>
                                <div className="settings-component-side flex-col">
                                    <div className='input-container w-100 mb-[24px]'>
                                        <label htmlFor='username' className='modal-component-label'>Username</label>
                                        <input
                                            id='username'
                                            autoComplete='nickname'
                                            className="input-custom__input input-mt-10"
                                            type="text"
                                            value={generalSettings.username}
                                            onChange={handleChangeInput}
                                        />
                                    </div>
                                    <div className='input-container w-100 mb-[24px]'>
                                        <label htmlFor='email' className='modal-component-label' >Email</label>
                                        <input 
                                            id='email'
                                            autoComplete='username'
                                            className="input-custom__input input-mt-10"
                                            type="email"
                                            value={generalSettings.email}
                                            readOnly
                                        />
                                    </div>
                                    <div className='w-100 d-flex'>
                                        <div className='input-container mr-[16px]'>
                                            <label htmlFor='first_name' className='modal-component-label' >First Name</label>
                                            <input 
                                                id='first_name'
                                                autoComplete='given-name'
                                                className="input-custom__input input-mt-10"
                                                type="text"
                                                value={generalSettings.first_name}
                                                onChange={handleChangeInput}
                                            />
                                        </div>
                                        <div className='input-container'>
                                            <label htmlFor='last_name' className='modal-component-label' >Last Name</label>
                                            <input 
                                                id='last_name'
                                                autoComplete='family-name'
                                                className="input-custom__input input-mt-10"
                                                type="text"
                                                value={generalSettings.last_name}
                                                onChange={handleChangeInput}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='settings-component-wrapper'>
                                <div className="settings-component-side flex-col">
                                    <span className='settings-component-title' >Company Info</span>
                                    <span className='settings-component-subtitle'>Manage your company information</span>
                                </div>
                                <div className="settings-component-side flex-col">
                                    <div className='input-container w-100 mb-[24px]'>
                                        <label htmlFor='company' className='modal-component-label' >Company</label>
                                        <input 
                                            id='company'
                                            autoComplete='organization'
                                            className="input-custom__input input-mt-10"
                                            type="text"
                                            value={generalSettings.company}
                                            onChange={handleChangeInput}
                                        />
                                    </div>
                                    <div className='input-container w-100 '>
                                        <label htmlFor='job_title' className='modal-component-label' >Job Title</label>
                                        <input
                                            id='job_title'
                                            autoComplete='organization-title'
                                            className="input-custom__input input-mt-10"
                                            type="text"
                                            value={generalSettings.job_title}
                                            onChange={handleChangeInput}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='settings-action border-t border-solid border-[#1F1F1F]'>
                                <button 
                                    className='btn btn-outline-primary-fill ml-auto'
                                    onClick={handleUpdateProfile}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </>
                    }
                    {
                        activePage === 'security' && <Security />
                    }

                    {
                        activePage === 'plan' &&
                        <>
                            <div className='flex justify-between py-[30px]'>
                                <div className="flex flex-col flex-1">
                                    <span className='settings-component-title' >Current Plan</span>
                                    <span className='settings-component-subtitle'>Manage your plan and upgrade if you need</span>
                                </div>
                                {user.plan &&
                                <div className='flex-1'>
                                    <div className="settings-starter">
                                        <div className="settings-starter-head mb-[20px]">
                                            <div className="settings-starter-head-desc">
                                                <p className="sett-title">{user.planDetails?.name}</p>
                                                <p className="settings-component-subtitle">{user.planDetails?.description}</p>
                                            </div>
                                            <div className='settings-starter-price'>
                                                <span className='settings-starter-price-title'>${user.planDetails?.price}</span>
                                                <span className="settings-component-subtitle">/ month</span>
                                            </div>
                                        </div>
                                        <Link to='/pricing' className='btn-outline-primary'>Upgrade Plan</Link>
                                    </div>
                                </div>
                                }
                            </div>
                            <div className='flex justify-between py-[30px]'>
                                <div className="flex flex-col flex-1 sm-full-width">
                                    <span className='settings-component-title' >Card Details</span>
                                    <span className='settings-component-subtitle mb-[30px]'>Manage your credit cards and payment options</span>

                                    <div className='d-flex'>
                                        <button className='btn btn-outline-primary-fill mr-[12px]' onClick={() => setAddCardModal(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M10 4.16667V15.8333" stroke="#331A04" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M4.16602 10H15.8327" stroke="#331A04" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            Add Card
                                        </button>
                                        <button className='btn-outline-primary'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.08953 17.9577L7.38383 16.0255L6.72824 16.0097H3.59766L5.7733 1.75156C5.78008 1.70838 5.80199 1.6683 5.83399 1.63979C5.86615 1.61128 5.90713 1.59564 5.95001 1.59564H11.2286C12.9812 1.59564 14.1906 1.97249 14.8219 2.71641C15.1179 3.0654 15.3064 3.43019 15.3977 3.83148C15.4934 4.25264 15.495 4.75576 15.4016 5.3695L15.3949 5.41414V5.80745L15.6909 5.9808C15.9401 6.11749 16.1383 6.2739 16.2902 6.45296C16.5434 6.75144 16.7072 7.13073 16.7764 7.58025C16.8479 8.04263 16.8243 8.593 16.7072 9.21603C16.5721 9.93258 16.3537 10.5567 16.0588 11.0674C15.7877 11.5379 15.4422 11.9283 15.0318 12.2307C14.6401 12.5181 14.1748 12.7362 13.6486 12.8758C13.1387 13.013 12.5573 13.0823 11.9197 13.0823H11.5089C11.2152 13.0823 10.9299 13.1916 10.7059 13.3876C10.4813 13.5877 10.3328 13.8611 10.2873 14.16L10.2562 14.334L9.73618 17.7397L9.71269 17.8646C9.70639 17.9042 9.69567 17.9239 9.6799 17.9373C9.66588 17.9495 9.6457 17.9577 9.62599 17.9577H7.08953Z" fill="#28356A"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M15.9703 5.45955C15.9547 5.56366 15.9366 5.67005 15.9164 5.77938C15.2203 9.47357 12.8386 10.7498 9.79695 10.7498H8.24821C7.87619 10.7498 7.56266 11.0289 7.50481 11.4082L6.48729 18.0792C6.44962 18.3283 6.63531 18.5526 6.87838 18.5526H9.62528C9.95048 18.5526 10.2268 18.3084 10.278 17.9768L10.305 17.8327L10.8222 14.4404L10.8554 14.2543C10.906 13.9216 11.183 13.6772 11.5082 13.6772H11.919C14.5803 13.6772 16.6637 12.5605 17.2727 9.32872C17.5269 7.97872 17.3953 6.85143 16.7222 6.05863C16.5186 5.81962 16.2659 5.62118 15.9703 5.45955Z" fill="#A54C00"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M15.2413 5.15949C15.1349 5.12739 15.0252 5.09839 14.9126 5.07216C14.7995 5.04658 14.6836 5.02394 14.5643 5.00406C14.1467 4.93433 13.6891 4.90125 13.199 4.90125H9.06163C8.95964 4.90125 8.86286 4.92504 8.77632 4.96805C8.58542 5.06288 8.44371 5.24959 8.40935 5.47817L7.52913 11.2402L7.50391 11.4082C7.56176 11.0289 7.87529 10.7498 8.2473 10.7498H9.79604C12.8377 10.7498 15.2194 9.47296 15.9155 5.77942C15.9363 5.6701 15.9538 5.56371 15.9694 5.4596C15.7933 5.36299 15.6026 5.28038 15.3972 5.21C15.3464 5.19256 15.2941 5.17578 15.2413 5.15949Z" fill="#D0690F"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.41037 5.47819C8.44474 5.2496 8.58645 5.06289 8.77734 4.96872C8.86451 4.92554 8.96067 4.90176 9.06266 4.90176H13.2C13.6901 4.90176 14.1477 4.93499 14.5653 5.00473C14.6846 5.02444 14.8005 5.04725 14.9137 5.07283C15.0262 5.0989 15.1359 5.12806 15.2423 5.16C15.2951 5.17629 15.3475 5.19323 15.3987 5.21001C15.6041 5.2804 15.795 5.36365 15.9711 5.45962C16.1782 4.09446 15.9693 3.16496 15.2552 2.32329C14.4679 1.39656 13.047 1 11.2287 1H5.94989C5.5785 1 5.26166 1.27909 5.20428 1.65904L3.00563 16.0638C2.96228 16.3488 3.17492 16.6059 3.45299 16.6059H6.71188L8.41037 5.47819Z" fill="#FF8114"/>
                                            </svg>
                                            Add Pay Pal
                                        </button>
                                    </div>
                                </div>
                                <ul className="flex-1 flex flex-col gap-[16px]">
                                    {cards.map(card => <CardItem
                                        key={card.id}
                                        card={card}
                                        setCards={setCards}
                                    />)}
                                </ul>
                            </div>
                        </>
                    }

                    {
                        activePage === 'notifications' &&
                        <>
                            <div className='settings-component-wrapper'>
                                <div className="settings-component-side flex-col">
                                    <span className='settings-component-title' >Email Notifications</span>
                                    <span className='settings-component-subtitle'>Select option you want to get notifications</span>
                                </div>
                                <div className="settings-component-side flex-col">
                                    <div className='settings-checkbox-container mb-[30px]'>
                                        <label htmlFor='notify_general_updates' className='sett-checkbox'>
                                            <input 
                                                id='notify_general_updates'
                                                defaultChecked={user.notify_general_updates}
                                                type="checkbox"
                                                className='sett-checkbox-hid'
                                                hidden
                                                onChange={(e) => {
                                                    api.users.updateUser({
                                                        data: {
                                                            notify_general_updates: e.target.checked,
                                                        },
                                                        token
                                                    })
                                                }}
                                            />
                                            <div className='sett-checkbox-sub'></div>
                                        </label>
                                        <div className='sett-checkbox-desc'>
                                            <span className='sett-title'>General Updates</span>
                                            <span className='sett-checkbox-desc-sub-title'>Subscribe to general updates newsletter</span>
                                        </div>
                                    </div>

                                    <div className='settings-checkbox-container mb-[30px]'>
                                        <label htmlFor='notify_education_program' className='sett-checkbox'>
                                            <input
                                                id='notify_education_program'
                                                type="checkbox" 
                                                className='sett-checkbox-hid'
                                                hidden
                                                defaultChecked={user.notify_education_program}
                                                onChange={(e) => {
                                                    api.users.updateUser({
                                                        data: {
                                                            notify_education_program: e.target.checked,
                                                        },
                                                        token
                                                    })
                                                }}
                                             />
                                            <div className='sett-checkbox-sub'></div>
                                        </label>
                                        <div className='sett-checkbox-desc'>
                                            <span className='sett-title'>Education Program</span>
                                            <span className='sett-checkbox-desc-sub-title'>Subscribe to general education program newsletter</span>
                                        </div>
                                    </div>

                                    <div className='settings-checkbox-container'>
                                        <label htmlFor='notify_marketing' className='sett-checkbox'>
                                            <input 
                                                id='notify_marketing'
                                                type="checkbox"
                                                className='sett-checkbox-hid' 
                                                hidden
                                                defaultChecked={user.notify_marketing}
                                                onChange={(e) => {
                                                    api.users.updateUser({
                                                        data: {
                                                            notify_marketing: e.target.checked,
                                                        },
                                                        token
                                                    })
                                                }}
                                            />
                                            <div className='sett-checkbox-sub'></div>
                                        </label>
                                        <div className='sett-checkbox-desc'>
                                            <span className='sett-title'>Marketing</span>
                                            <span className='sett-checkbox-desc-sub-title'>Subscribe to general marketing newsletter</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {
                        activePage === 'referral' &&
                        <>
                            <div className='settings-component-wrapper'>
                                <div className="settings-component-side flex-col">
                                    <span className='settings-component-title' >Invite your friends and get cash back</span>
                                    <span className='settings-component-subtitle'>Receive up to 50% money back from your  friends</span>
                                </div>
                                <div className="settings-component-side flex-col">
                                    <div className='flex pb-[30px] border-b border-solid border-[#1F1F1F] mb-[30px]'>
                                        <div className="refferal-statistic">
                                            <p className="refferal-statistic-title">Total Referrals</p>
                                            <p className="sett-title">{user.referralCount}</p>
                                        </div>

                                        <div className="refferal-statistic">
                                            <p className="refferal-statistic-title">Clicks Number</p>
                                            <p className="sett-title">{user.referrals_click}</p>
                                        </div>

                                        <div className="refferal-statistic">
                                            <p className="refferal-statistic-title">Total Income</p>
                                            <p className="sett-title">$2,123</p>
                                        </div>
                                    </div>
                                    <div className='flex flex-col gap-[8px]'>
                                        <Label
                                            htmlFor='invite-friends-email'
                                        >
                                            Invite friends through email
                                        </Label>
                                        <div className='flex gap-[12px]'>
                                            <Input
                                                id='invite-friends-email'
                                                placeholder='Enter email address...'
                                                type='email'
                                                value={inviteInput}
                                                onChange={(e) => setInviteInput(e.target.value)}
                                            />
                                            <PrimaryButton
                                                onClick={() => {
                                                    const emails = inviteInput.split(',');
                                                    let isValid = true;
                                                    emails.forEach((email) => {
                                                        if(!validateEmail(email.trim())) {
                                                            isValid = false;
                                                            return;
                                                        }
                                                    })
                                                    if(!isValid) {
                                                        toast.error('Invalid email input!')
                                                        return;
                                                    };
                                                    toast.promise(
                                                        api.refferals.sendInvitation({
                                                            data: {
                                                                email: inviteInput
                                                            },
                                                            token
                                                        }),
                                                        {
                                                          pending: 'Sending email(s)...',
                                                          success: 'Email(s) sent!',
                                                          error: 'Error sending email(s). Try again later.'
                                                        }
                                                    )
                                                }}
                                            >
                                                <span>Send</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.87778 18.2219L18.1044 12.0811C19.0192 11.6567 19.0192 10.3422 18.1044 9.91779L4.87778 3.77887C3.92903 3.33887 2.92528 4.27204 3.28003 5.26479L5.32694 10.9976L3.28003 16.7369C2.92528 17.7287 3.92903 18.6619 4.87778 18.2219Z" stroke="#331A04" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M5.32422 11.0003H18.7901" stroke="#331A04" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </PrimaryButton>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <span className='text-[#A7A7A7] text-[12px] opacity-40'>Separate emails with commas</span>
                                            <Link className='text-orange-primary text-[12px] underline underline-offset-2'>
                                                Preview Email
                                            </Link>
                                        </div>
                                    </div>
                                    <p className='mt-[24px] mb-[8px] text-[#A7A7A7] text-[12px]'>
                                        Or share you personal referral link
                                    </p>
                                    <div className='flex gap-[8px] w-full'>
                                        <div className='h-[40px] flex items-center justify-between flex-1 overflow-hidden
                                        bg-[#1B1B1B] border border-solid border-[#333] rounded-[8px] px-[14px] gap-[8px]'>
                                            <div className='overflow-hidden whitespace-nowrap overflow-ellipsis flex-1
                                            text-[#ffffffe6] text-[14px]'>
                                                {referralLink}
                                            </div>
                                            <button 
                                                className='min-w-fit text-orange-primary text-[14px] font-medium'
                                                onClick={() => {
                                                    navigator.clipboard.writeText(referralLink).then(() => {
                                                        toast.success('Copied successfully!', {
                                                            position: "top-right",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress: undefined,
                                                            theme: "dark",
                                                        });
                                                    })
                                                }}
                                            >
                                                Copy Link
                                            </button>
                                        </div>
                                        {shareButtons.map(([Button, icon], index) =>
                                            <Button
                                                url={referralLink}
                                                key={index}
                                            >
                                                <div className='min-w-[40px] h-[40px] flex justify-center items-center rounded-[8px]
                                                border border-solid border-[#333] bg-[#1B1B1B]'>
                                                    {icon}
                                                </div>
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {
                        activePage === 'settings-section' &&
                        <>
                            <div className='settings-component-wrapper'>
                                <div className="settings-component-side flex-col">
                                    <span className='settings-component-title' >Invite your friends and get cash back</span>
                                    <span className='settings-component-subtitle'>Receive up to 50% money back from your  friends</span>
                                </div>
                                <div className="settings-component-side flex-col">
                                    <div className='input-container w-100 mb-[24px]'>
                                        <label htmlFor='mission' className='modal-component-label' >Your Mission</label>
                                        <input id='mission'  className="input-custom__input input-mt-10" type="text" placeholder={'Create your mission and make it happen'} ></input>
                                    </div>
                                    <div className='input-container w-100 mb-[24px]'>
                                        <label htmlFor='num' className='modal-component-label' >Default Variations</label>
                                        <input id='num'  className="input-custom__input input-mt-10" type="email" placeholder={'Variations on the product pages, ex. 1, 2, 3...'} ></input>
                                    </div>

                                    <div className="mb-[16px]">
                                        <label className="custom-checkbox custom-checkbox-settings">
                                            {/* checked */}
                                            <input type="checkbox" className="hidden" />
                                            <span className="custom-checkbox-fake"></span>
                                            <span className="custom-checkbox-text default-collor">Use new word library</span>
                                        </label>
                                    </div>
                                    {/* <div className="mb-[30px]">
                                        <label className="custom-checkbox custom-checkbox-settings">
                                            <input type="checkbox" className="hidden" />
                                            <span className="custom-checkbox-fake"></span>
                                            <span className="custom-checkbox-text default-collor">Keep it positive</span>
                                        </label>
                                    </div> */}
                                    <div className="settings-divider"></div>
                                    <div className={`input-custom mb-[24px] mt-[30px] w-100`}>
                                        <label className="input-custom__label mb-[10px]">Set Default Brand Voice</label>
                                        <div className="input-custom__wrap-input">
                                            <SelectBox
                                                className='default-select flex-1'
                                                propsOptions={[{value: '1', label: 'Type or select brand voice'}]}
                                                value={'1'}
                                            />
                                        </div>
                                    </div>

                                    <div className={`input-custom w-100`}>
                                        <label className="input-custom__label mb-[10px]">Select which product page you want to turn off</label>
                                        <div className="input-custom__wrap-input">
                                            {/* <SelectBox
                                                className='default-select flex-1'
                                                propsOptions={[{value: '1', label: 'Select which product page you want to turn off'}]}
                                                value={'1'}
                                            /> */}
                                            <MultiSelect
                                                className="w-full"
                                                placeholder="Select Product Pages"
                                                absolute={true}
                                                options={productsTurnOffOptions}
                                                value={[]}
                                                // options={categoryOptions.filter(item => !item.placeholder)}
                                                // value={categories}
                                                // change={setCategories}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    }
                    {
                        activePage === 'positive' && <KeepItPositive />
                    }
                    </div>

                </div>
            </div>
            <Popup
                open={addCardModal}
                onClose={closeModal}
                overlayStyle={{
                    background: 'rgba(0,0,0,0.5)'
                }}
            >
                {/* <Elements stripe={stripeRef.current}>
                    <AddCardModal close={closeModal} setCards={setCards} cards={cards}/>
                </Elements> */}
                <AddCardModal close={closeModal} setCards={setCards} cards={cards}/>
            </Popup>
        </div>
    )
}

export default SettingsNew;