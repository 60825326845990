import React, { useState } from 'react';
import { GeneratedToolkit } from '../../components/ToolKit/GeneratedToolkit';
import GeneratorV4 from "../GeneratorV4/GeneratorV4";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loader } from './Loader';

const GeneratedContent = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const modelType = searchParams.get('type') || 'blog';

    const navigate = useNavigate()

    const onGenerate = () => {
        setIsLoading(true)
        setTimeout(() => {
            navigate(`/generated-page/${modelType}`)
            setIsLoading(false)
        }, 1000)
    }

    return (
        <>
            {!isLoading ? <>
            <div className={`gen4-main-block ${sidebarOpen && 'gen4-side-open'}`} >
                <GeneratorV4 setLoading={setIsLoading} setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} onGenerate={onGenerate}/>
            </div>

            <div className={`gen4-toolkit-wrapper ${sidebarOpen && 'gen4-side-open'}`}>
                <div className="gen4-toolkit-header">
                    <span>Toolkit</span>
                    <div className='gen4-toolkit-close' onClick={() => setSidebarOpen(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g opacity="0.5">
                                <path d="M18 6L6 18" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path d="M6 6L18 18" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </g>
                        </svg>
                    </div>
                </div>
                <GeneratedToolkit/>
            </div>
            </>
            :
            <Loader />
            }
        </>
    )
}

export default GeneratedContent