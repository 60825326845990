import React, { useState } from 'react';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import HomeBlock from './HomeBlock';

export const ToolkitBlocksContainer = ({setBlocks, blocks, changeOnOpenBlock, searchable}) => {
    

    const handleDragEnd = result => {
        const {destination,source} = result
        if (!result.destination) return
        
            const newItems= Array.from(blocks)
            const [reOrdered] = newItems.splice(source.index, 1)
            newItems.splice(destination.index, 0, reOrdered)
            setBlocks(newItems)
        }

    return (
        <>
        
        {<DragDropContext onDragEnd={result => {handleDragEnd(result)}}>
            <Droppable droppableId="blockContainer" direction="vertical">
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps} className="blog-toolkit-content__container">

                        <div className='input-search-variant-two'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <circle cx="10.215" cy="10.2155" r="5.88495" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M17.67 17.6694L14.377 14.3764" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <input  placeholder='Search assets...' />
                        </div>

                        {blocks.map((block, index) => (
                            <HomeBlock
                                searchable={searchable}
                                changeOnOpenBlock={changeOnOpenBlock}
                                key={block.id}
                                blockId={block.id}
                                index={index}
                                data={block}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        
        }


        </>
    )
}
