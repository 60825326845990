import React, { forwardRef, useEffect, useRef, useState } from 'react'
import Label from '../../../components/base/Label'
import Input from '../../../components/base/Input'

export const TypeSelectInput = ({label, value, change, options}) => {

    const [inpVal, setInpVal] = useState(value || '')
    const [selectOpen, setSelectOpen] = useState(false)
    const blockRef = useRef(null)

    const inputHandle = (e) => {
        setSelectOpen(true)
    }

    const closeHandler = (e) => {
        if (blockRef.current && !blockRef.current.contains(e.target)) {
            setSelectOpen(false)
        }
    }

    const handleInputVal = (e) => {
        setInpVal(e.target.value)
        change && change(e.target.value)
    }

    const handleSelectVal = (el) => {
        setSelectOpen(false)
        setInpVal(el.label)
        change && change(el.label)
    }

    useEffect(() => {
        if (selectOpen) {
            document.addEventListener('click', closeHandler);
        } else {
            document.removeEventListener('click', closeHandler)
        }


        return () => document.removeEventListener('click', closeHandler)
    }, [selectOpen])
    
    const optionsItem = options ? options.map(item => ({ label: item.title, value: item.title })) : []

    return (
        <>
        <div className='flex flex-col gap-[10px] flex-1 ' ref={blockRef}>
                <Label>{label}</Label>

                <Input value={inpVal} placeholder={'Select or Type...'} className={'type-select-input min-h-[40px]'} onChange={(e) => handleInputVal(e)} onClick={inputHandle} />
                <div className='relative'>
                    
                    <div className={`type-select-dropdown ${selectOpen ? 'open' : ''}`}>
                        <ul className='type-select-dropdown-menu'>
                            {optionsItem.map((el, idx) => (
                                <li key={idx} onClick={() => handleSelectVal(el)} className={`type-select-dropdown-item ${el.label.toLowerCase() === inpVal.toLowerCase() ? 'active' : ''}`}>{el.label}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>       
    )
}
