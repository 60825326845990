const base = 'api/assets-reminder';

export const assetsReminder = (instance) => ({
    async createReminder(payload) {
        try {
            const { data } = await instance.post(`${base}/${payload.assetId}`, payload.body, {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            });
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async deleteReminders(payload) {
        try {
            const { data } = await instance.delete(`${base}/all/${payload.assetId}`, {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            });
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
})