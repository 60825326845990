import React, { useState } from "react";

const GeneratedContentV1 = () => {

    const [activeSidebar, setActiveSidebar] = useState('Content');

    return (
        <div className={`new-sidebar-layout`}>
            <nav className="toolbar-sidebar-nav">
                <a href="#" className={`sidebar-logo ${activeSidebar === 'Content' ? 'pre-active' : ''}`}>
                    <img src={require("../../assets/img/react/sidebar-logo-xs.png")} alt="" className="show-in-close" />
                </a>
                <a 
                    href="#" className={`toolbar-sidebar-nav-link ${activeSidebar === 'Content' ? 'active' : ''}`}
                    onClick={(e)=>{e.preventDefault(); setActiveSidebar('Content'); }}
                >
                    <span className="icon-wrpr mb-[8px]">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="3" y="3.5" width="18" height="18" rx="5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12 16H7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7.5 9H12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M16.5 12.5H7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span>
                    <span>Content</span>
                </a>
                <a href="#" className={`toolbar-sidebar-nav-link ${activeSidebar === 'Suggestions' ? 'active' : ''} ${activeSidebar === 'Keywords' ? 'pre-active' : ''}`}
                    onClick={(e)=>{e.preventDefault(); setActiveSidebar('Suggestions'); }}
                >
                    <span className="icon-wrpr mb-[8px]">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g>
                            <path fillRule="evenodd" clipRule="evenodd" d="M2.99609 17.294C5.20615 17.294 6.99776 19.0856 6.99776 21.2956C6.99776 19.0856 8.78937 17.294 10.9994 17.294C8.78937 17.294 6.99776 15.5024 6.99776 13.2923C6.99776 15.5024 5.20615 17.294 2.99609 17.294Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M13 14.2927C15.2101 14.2927 17.0017 16.0844 17.0017 18.2944C17.0017 16.0844 18.7933 14.2927 21.0033 14.2927C18.7933 14.2927 17.0017 12.5011 17.0017 10.2911C17.0017 12.5011 15.2101 14.2927 13 14.2927V14.2927Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M7 7.28981C9.21006 7.28981 11.0017 9.08142 11.0017 11.2915C11.0017 9.08142 12.7933 7.28981 15.0033 7.28981C12.7933 7.28981 11.0017 5.49821 11.0017 3.28815C11.0017 5.49821 9.21006 7.28981 7 7.28981Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                        </svg>
                    </span>
                    <span>Suggestions</span>
                </a>
                <a href="#" className={`toolbar-sidebar-nav-link ${activeSidebar === 'Keywords' ? 'active' : ''} ${activeSidebar === 'Add-ons' ? 'pre-active' : ''}`}
                    onClick={(e)=>{e.preventDefault(); setActiveSidebar('Keywords'); }}
                >
                    <span className="icon-wrpr mb-[8px]">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g>
                            <path fillRule="evenodd" clipRule="evenodd" d="M10 11.9608L13.3174 8.63337C12.5597 6.64824 13.2976 4.40381 15.0855 3.25562C16.8733 2.10743 19.2213 2.37004 20.7114 3.88486C22.2014 5.39968 22.4252 7.75169 21.2476 9.52035C20.0701 11.289 17.8138 11.9898 15.8414 11.1994L12.5401 14.5108H10V11.9608Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M17.6292 7.00271C17.6292 6.93365 17.5732 6.87766 17.5041 6.87766C17.4351 6.87766 17.3791 6.93365 17.3791 7.00271C17.3791 7.07178 17.4351 7.12776 17.5041 7.12776C17.5732 7.12776 17.6292 7.07178 17.6292 7.00271" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9.99919 3.72736C5.65267 4.714 2.68214 8.73226 3.01346 13.177C3.34479 17.6218 6.87823 21.1552 11.323 21.4866C15.7678 21.8179 19.786 18.8474 20.7727 14.5008" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                        </svg>
                    </span>
                    <span>Keywords</span>
                </a>
                <a href="#" className={`toolbar-sidebar-nav-link ${activeSidebar === 'Add-ons' ? 'active' : ''} ${activeSidebar === 'Settings' ? 'pre-active' : ''}`}
                    onClick={(e)=>{e.preventDefault(); setActiveSidebar('Add-ons'); }}
                >
                    <span className="icon-wrpr mb-[8px]">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g>
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.16425 10.9973C4.72707 10.9083 4.25287 10.9253 3.76367 11.0884C2.93632 11.3645 2.28105 12.0758 2.07596 12.9242C1.65379 14.6679 2.96333 16.2245 4.63603 16.2245C4.8171 16.2245 4.99318 16.2055 5.16425 16.1715V19.3928C5.16425 20.5583 6.10964 21.5037 7.27613 21.5037H18.892C20.0585 21.5037 21.0038 20.5583 21.0038 19.3918V7.77601C21.0038 6.60952 20.0585 5.66413 18.892 5.66413H15.6706C15.7597 5.22694 15.7427 4.75275 15.5796 4.26354C15.3025 3.4362 14.5912 2.78093 13.7438 2.57584C12.0001 2.15366 10.4434 3.46321 10.4434 5.13591C10.4434 5.31698 10.4625 5.49306 10.4965 5.66413H7.27513C6.10864 5.66413 5.16325 6.60952 5.16325 7.77601V10.9973H5.16425Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M13.0002 10.9993V15.001" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M15.0017 13.0002H11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                        </svg>
                    </span>
                    <span>Add-ons</span>
                </a>
            </nav>
            <aside className="toolbar-sidebar">

            </aside>
            <main className="content-box-wrpr">
                <div className="content-box">
                    <div className="content-box-header flex items-center flex-wrap bg-[#393939]">
                        <div className="content-box-title">
                            <h2>Generated Content</h2>
                            <p className="content-box-subtitle skeleton-ignore">
                                <span>Text</span><span className="sleketon-hide-element ml-[5px] mr-[5px]">•</span><span>Blog</span><span className="sleketon-hide-element ml-[5px] mr-[5px]">•</span><span>No Generated Content</span>
                            </p>
                        </div>
                        <a href="#" className="btn btn-outline-black-new btn-md-2 rounded-[8px] mr-[16px] ">
                            <span className="ico">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="1">
                                        <path d="M2.51667 14.75C2.28611 14.75 2.10069 14.6799 1.96042 14.5396C1.82014 14.3993 1.75 14.2139 1.75 13.9833V10C1.75 9.79111 1.82421 9.61111 1.97263 9.46C2.12106 9.30889 2.29772 9.23334 2.50263 9.23334C2.70754 9.23334 2.88611 9.30889 3.03833 9.46C3.19056 9.61111 3.26667 9.79111 3.26667 10V12.15L11.6667 3.75H9.5C9.29583 3.75 9.11979 3.67579 8.97187 3.52737C8.82396 3.37895 8.75 3.20228 8.75 2.99737C8.75 2.79246 8.82396 2.61389 8.97187 2.46167C9.11979 2.30945 9.29583 2.23334 9.5 2.23334H13.5C13.7244 2.23334 13.9083 2.305 14.0517 2.44834C14.195 2.59167 14.2667 2.77556 14.2667 3V7C14.2667 7.20417 14.1908 7.38021 14.0392 7.52814C13.8875 7.67605 13.7068 7.75 13.4971 7.75C13.2921 7.75 13.1163 7.67605 12.9698 7.52814C12.8233 7.38021 12.75 7.20417 12.75 7V4.83334L4.35 13.2333H6.5C6.70889 13.2333 6.88889 13.3092 7.04 13.4608C7.19111 13.6125 7.26667 13.7932 7.26667 14.0029C7.26667 14.2079 7.19111 14.3837 7.04 14.5302C6.88889 14.6767 6.70889 14.75 6.5 14.75H2.51667Z" fill="currentColor"></path>
                                    </g>
                                </svg>
                            </span>
                        </a>
                    </div>
                </div>
            </main> 
        </div>
    )
}

export default GeneratedContentV1;