import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../components/parts/Sidebar";
import Header from "../components/parts/Header";
import ChatBot from "../routes/GeneratedContent/ChatBot";
import useScreenWidth from "../helpers/useScreenWidth";

const SidebarLayout = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [sidebarRight, setSidebarRight] = useState(false);
    const width = useScreenWidth();
    console.log(width);
    useEffect(() => {
        if(width < 768) {
            setSidebarOpen(false);
        } else {
            setSidebarOpen(true)
        }
    }, [width])
    return (
        <div className={`sidebar-layout ${!sidebarOpen ? 'sidebar-close' : ''} ${sidebarRight ? 'sidebar-to-right' : ''}`}>
            <SideBar 
                setSidebarRight={()=>{setSidebarRight(!sidebarRight)}}
                sidebarOpen={sidebarOpen}
            />
            <div className="wrapper sidebar-fixed">
                <main className="content" style={{ paddingLeft: sidebarOpen ? '280px' : '52px', paddingTop: "66px" }}>
                    <Header
                        sidebarOpen={sidebarOpen}
                        sidebarToggle={() => {
                            setSidebarOpen((prevState) => !prevState);
                        }}
                    />
                    <Outlet />
                    <ChatBot className='bottom-[20px] right-[16px]'/>
                </main> 
            </div>
        </div>
    )
}

export default SidebarLayout;