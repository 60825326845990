const authBase = 'auth';

export const auth = (instance) => ({
    async login(payload) {
        const { data } = await instance.post(`${authBase}/login`, payload);
        return data;
    },
    async register(payload) {
        const { data } = await instance.post(`${authBase}/register`, payload);
        return data;
    },
    async sendLoginCode(payload) {
        const { data } = await instance.post(`${authBase}/send-login-code`, payload);
        return data;
    },
    async loginWithCode(payload) {
        const { data } = await instance.post(`${authBase}/login-code`, payload);
        return data;
    },
    async forgotPassword(payload) {
        const { data } = await instance.post(`${authBase}/forgot-password`, payload);
        return data;
    },
    async logout() {
        const { data } = await instance.get(`${authBase}/logout`);
        return data;
    },
    async changePassword(payload) {
        try {
            const { data } = await instance.post(`${authBase}/change-password`, payload.data, {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            });
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async resetPassword(payload) {
        const { data } = await instance.post(`${authBase}/reset-password`, payload);
        return data;
    },
})