import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const MyTeamUsers = () => {
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(null);

    const users = [
        {
            first_name: "Peter",
            last_name: "Gomz",
            avatar: require('../../assets/img/user-image.png'),
            email: "info@peter.com",
            password: "**************",
            confirm_password: "**************",
        },
        {
            first_name: "John",
            last_name: "Doe",
            avatar: null,
            email: "john@peter.com",
            password: "*******",
            confirm_password: "*******",
        },
        {
            first_name: "Bob",
            last_name: "Jackman",
            avatar: require('../../assets/img/user-image.png'),
            email: "bob@peter.com",
            password: "************",
            confirm_password: "************",
        },
        {
            first_name: "Ricardo",
            last_name: "Doe",
            avatar: require('../../assets/img/user-image.png'),
            email: "ricardo@peter.com",
            password: "*********",
            confirm_password: "*********",
        },
        {
            first_name: "Hugh",
            last_name: "Jackman",
            avatar: null,
            email: "hugh@peter.com",
            password: "***********",
            confirm_password: "***********",
        },
    ];

    return (
        <>
        <section className="page-section">
            <div className="container">
                <div className="flex items-center justify-between mb-[25px]">
                    <h2>Users</h2>
                    <Link to="/add-user" className="btn btn-primary-10 btn--lg radius-5">
                        + Add User
                    </Link>
                </div>
                <div className="table-wrapper">
                    <table className="table-default">
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Password</th>
                                <th>Confirm Password</th>
                                <th style={{ width: '0%' }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                users.map((item, index)=>{
                                    return <tr key={index}>
                                    <td>
                                        <div className="flex items-center">
                                            <div className="image-wrapper image-sm mr-[8px]">
                                                {
                                                    item.avatar ? <img src={item.avatar} alt="" /> :
                                                    <img src={require('../../assets/img/image-placeholder.png')} alt="" />
                                                }                                              
                                            </div>
                                            <p>{ item.first_name }</p>
                                        </div>
                                    </td>
                                    <td>{ item.last_name }</td>
                                    <td>{ item.email }</td>
                                    <td>{ item.password }</td>
                                    <td>{ item.confirm_password }</td>
                                    <td>
                                        <div className="btns-group">
                                            <Link to={`/edit-user/${index+1}`} className="btn btn--primary btn--lg radius-5 mr-[12px]">
                                                Edit
                                            </Link>
                                            <button className="btn btn-outline-primary-10 btn--lg radius-5" data-modal="#delete-user" onClick={(e)=>{
                                                e.preventDefault();
                                                setConfirmDeleteOpen(index);
                                            }}>
                                                <span>Delete</span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </section>

        <div id="delete-user" className={`modal modal-default ${confirmDeleteOpen !== null ? 'modal--show' : ''}`}>
            <div className="modal__dialog modal__dialog--660">
                <div className="modal__content">
                    <div className="modal__header">
                        <a
                            href="#" className="btn btn-close ml-auto" data-close-modal=""
                            onClick={(e)=>{
                                e.preventDefault();
                                setConfirmDeleteOpen(null);
                            }}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.624474 1.027C1.32693 0.324577 2.46543 0.324577 3.16749 1.027L11.6444 9.50394L20.1214 1.027C20.8238 0.324577 21.9623 0.324577 22.6644 1.027C23.3668 1.72945 23.3668 2.86799 22.6644 3.57005L14.1874 12.047L22.6644 20.5239C23.3668 21.2263 23.3668 22.3649 22.6644 23.0669C21.9619 23.7694 20.8234 23.769 20.1214 23.0669L11.6444 14.59L3.16749 23.0669C2.46507 23.769 1.32655 23.769 0.624474 23.0669C-0.0779495 22.3645 -0.0779495 21.226 0.624474 20.5239L9.1014 12.047L0.624474 3.57005C-0.0779495 2.86759 -0.0779495 1.72907 0.624474 1.027Z" fill="#EA8207"></path>
                            </svg>
                        </a>
                    </div>
                    <div className="modal__body">
                        <p className="mb-[30px]">Are you sure to delete this user?</p>
                        <div className="btns-group">
                            <button className="btn btn--primary btn--lg radius-5 mr-[12px]">
                                Delete
                            </button>
                            <button
                                className="btn btn-outline-primary-10 btn--lg radius-5"
                                onClick={(e)=>{
                                    e.preventDefault();
                                    setConfirmDeleteOpen(null);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                    <div className="modal__footer"></div>
                </div>
            </div>
        </div>
        </>
    )
}

export default MyTeamUsers;