import React from 'react';
import { Outlet } from "react-router-dom";
import InfinityLogo from "../assets/img/react/new-infinity-logo.png";

const AuthLayout = () => {
    return (
        <div className="auth-fullscreen-wrpr split-screen p-[20px]">
            <div className="auth-left-top-logo" style={{ zIndex: '2' }}>
                <img src={require("../assets/img/big-logo.png")} alt="" />
            </div>
            <div className="auth-form-box-fullscreen" style={{ zIndex: '2' }}>
                <Outlet />
            </div>
            <div className="auth-content" style={{ zIndex: '2' }}>
                <div className="auth-content-inner">
                    <div className="logo">
                        <img src={InfinityLogo} alt="" />
                    </div>
                    <h1>Experience the Power of AI Kit: Your All-In-One Solution for Inspired Content Creation and Goal Achievement.</h1>
                    <p className="mb-[24px]">Unlock the potential of AI Kit, where generating high-quality content is effortless and positivity reigns. Whether writing captivating content, sending lessons, or defining and achieving your goals, AI Kit provides the tools to bring your ideas to life. With features like generating new words, writing SEO-optimized blogs and product descriptions, or even creating content for entire websites, AI Kit is revolutionizing content creation.</p>
                    <div className="d-flex align-items-center">
                        <ul className="avatars-list">
                            <li>
                                <img src={require("../assets/img/avatar-img-1.jpg")} alt="" />
                            </li>
                            <li>
                                <img src={require("../assets/img/avatar-img-2.jpg")} alt="" />
                            </li>
                            <li>
                                <img src={require("../assets/img/avatar-img-3.jpg")} alt="" />
                            </li>
                            <li>
                                <img src={require("../assets/img/avatar-img-4.jpg")} alt="" />
                            </li>
                        </ul>
                        <p className="ml-[16px]">AI Kit - where positivity inspires efficiency and innovation – sign up now!</p>
                    </div>
                </div>
            </div>
            <video autoPlay muted loop className="auth-layout-video-bg">
                <source src={require("../assets/videos/auth-layout-video-bg.webm")} type="video/webm" />
            </video>
        </div>
    )
}

export default AuthLayout;