import React, { useState } from 'react';

import api from '../../api';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getToken } from '../../redux/slices/applicationSlice';
import PrimaryButton from '../../components/base/PrimaryButton';

const Security = () => {
    const token = useSelector(getToken);
    const [passwordSettings, setPasswordSettings] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const buttonDisabled = Object.values(passwordSettings).some(value => !value);
    const handleChangePasswordInput = ({target}) => {
        setPasswordSettings(prev => ({
            ...prev,
            [target.id]: target.value
        }))
    }
    const handleChangePassword = async () => {
        if(passwordSettings.newPassword.length < 6) {
            toast.error('New Password length must be at least 6 characters');
            return;
        }
        if(passwordSettings.confirmPassword !== passwordSettings.newPassword) {
            toast.error('Confirm password do not match new password');
            return;
        }
        const toastId = toast.loading("Changing password...");
        const response = await api.auth.changePassword({
            data: {
                oldPassword: passwordSettings.oldPassword,
                newPassword: passwordSettings.newPassword
            },
            token
        });
        toast.update(toastId, {
            render: response.message, 
            type: response.success ? 'success' : 'error', 
            isLoading: false,
            autoClose: 5000
        });
    }
    return <>
        <div className='settings-component-wrapper'>
            <div className="settings-component-side flex-col">
                <span className='settings-component-title' >Change Password</span>
                <span className='settings-component-subtitle'>Securing your account, change your password</span>
            </div>
            <div className="settings-component-side flex-col gap-[24px]">
                <div className='input-container w-100'>
                    <label htmlFor='oldPassword' className='modal-component-label' >Current Password</label>
                    <input 
                        id='oldPassword'
                        autoComplete='current-password'
                        className="input-custom__input input-mt-10"
                        type="password"
                        value={passwordSettings.oldPassword}
                        onChange={handleChangePasswordInput}
                    />
                </div>
                <div className='input-container w-100'>
                    <label htmlFor='newPassword' className='modal-component-label' >New Password</label>
                    <input 
                        id='newPassword'
                        autoComplete='new-password'
                        className="input-custom__input input-mt-10"
                        type="password"
                        value={passwordSettings.newPassword}
                        onChange={handleChangePasswordInput}
                    />
                </div>
                <div className='input-container w-100'>
                    <label htmlFor='confirmPassword' className='modal-component-label' >Confirm Password</label>
                    <input 
                        id='confirmPassword'
                        autoComplete='new-password'
                        className="input-custom__input input-mt-10"
                        type="password"
                        value={passwordSettings.confirmPassword}
                        onChange={handleChangePasswordInput}
                    />
                </div>
                <PrimaryButton 
                    className='w-fit ml-auto'
                    onClick={handleChangePassword}
                    disabled={buttonDisabled}
                >
                    <span>Change Password</span>
                </PrimaryButton>
            </div>
        </div>
        <div className="settings-divider"></div>
        <div className='settings-component-wrapper'>
            <div className="settings-component-side flex-col">
                <span className='settings-component-title' >Two-factor authentication (2FA)</span>
                <span className='settings-component-subtitle'>Manage your company information</span>
            </div>
            <div className="settings-component-side flex-col">
                <div className='settings-checkbox-container mb-[30px]'>
                    <label htmlFor='a1' className='sett-checkbox'>
                        <input id='a1' defaultChecked type="checkbox" className='sett-checkbox-hid' hidden />
                        <div className='sett-checkbox-sub'></div>
                    </label>
                    <div className='sett-checkbox-desc'>
                        <span className='sett-title'>Text Message (SMS) </span>
                        <span className='sett-checkbox-desc-sub-title'>Receive a one-time passcode via SMS each time you login.</span>
                    </div>
                </div>

                <div className='settings-checkbox-container'>
                    <label htmlFor='a2' className='sett-checkbox'>
                        <input id='a2' type="checkbox" className='sett-checkbox-hid' hidden />
                        <div className='sett-checkbox-sub'></div>
                    </label>
                    <div className='sett-checkbox-desc'>
                        <span className='sett-title'>Authenticator App (TOTP)</span>
                        <span className='sett-checkbox-desc-sub-title'>Use an app to receive temporary one-time passcode each time you login.</span>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default Security;