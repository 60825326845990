import React, { useEffect, useState, } from 'react';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from '../../../../assets/img/icons/icon-close.svg';
import { useSelector } from 'react-redux';
import { getFormOptions } from '../../../../redux/slices/applicationSlice';

const AddOns = ({ selectedForSetting, setSelectedForSetting }) => {
    console.log('selectedForSetting:', selectedForSetting);

    const [inputValue, setInputValue] = useState('');
    const [data, setData] = useState(selectedForSetting?.settings?.addons?.length ? selectedForSetting?.settings?.addons : []);
    console.log('data:', data);

    const formOptions = useSelector(getFormOptions);
    const addonsOptions = formOptions.filter(({key}) => key === 'lesson_kit_addons');

    // [
    //     "Transcription",
    //     "Lesson Plan",
    //     "Tutor Education Assistant",
    //     "Rubric",
    //     "Syllabus Gnerator",
    //     "Course Description",
    //     "Course Feedback Generator",
    //     "Warm Up",
    //     "Guided Questions",
    //     "Learning Materials",
    //     "Home Work",
    //     "Hand Outs",
    // ]
    const [list, setList] = useState([]);

    const [activateInteractiveTeacher, setActivateInteractiveTeacher] = useState(true);
    const [keepItPositive, setKeepItPositive] = useState(false);

    useEffect(()=>{
        setSelectedForSetting((prevState)=>({
            ...prevState,
            settings: {
                ...prevState.settings,
                addons: data.map(addon => ({ title: addon?.title, description: addon?.description, key: addon?.key || addon?.title.replace(/ /g, '_').toLowerCase() }))
            }
        }))
    }, [data])

    useEffect(() => {
        if(!list?.length && addonsOptions) {
            setList(addonsOptions.map(option => ({ title: option.title, description: option.description, key: option.title.replace(/ /g, '_').toLowerCase() })));
        }
    },[addonsOptions])

    return <>
        <div className='flex flex-col gap-[16px]'>
            <div>
                <label 
                    className='text-[#A7A7A7] text-[12px] leading-[14px] block mb-[10px]'
                    htmlFor='add-on'
                >Add Custom Add-on</label>
                <div className='flex gap-[10px]'>
                    <input
                        id='add-on'
                        className='h-[40px] p-[8px] px-[14px] flex-1 bg-[#1B1B1B] rounded-[8px]
                        border border-solid border-[#333] text-[14px]'
                        placeholder='Ex. Page Title, Meta Description...'
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    <button 
                        className='h-[40px] flex items-center justify-center gap-[8px] px-[12px]
                        radial-bg rounded-full text-[#331A04] text-[14px] font-medium'
                        disabled={!inputValue}
                        onClick={() => {
                            setData([...data, { title: inputValue, description: '', key: inputValue.replace(/ /g, '_').toLowerCase() }]);
                            setInputValue('');
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10 4.16699V15.8337" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4.16602 10H15.8327" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Add
                    </button>
                </div>
            </div>
            <ul className='flex gap-[8px] flex-wrap'>
                {data.map((option, index) => <li
                    key={ index }
                    className={classNames(`text-[#ffffff80] flex h-[26px] pl-[10px] pr-[6px] items-center gap-[6px] text-[12px]
                    border border-solid bg-[#202020] rounded-[24px] border-[#383838]`)}
                >
                    {option?.title}
                    <CloseIcon className='cursor-pointer' onClick={() => setData(data.filter(item => item?.title !== option?.title))}/>
                </li>)}
            </ul>
            <div className='flex items-center gap-[16px]'>
                <div className='h-[1px] flex-1 bg-[#1F1F1F]'/>
                <span className='text-[12px] text-[#A7A7A7]'>or select</span>
                <div className='h-[1px] flex-1 bg-[#1F1F1F]'/>
            </div>
            <ul className='mt-[8px]'>
                {
                    list.map((listItem, listItemIndex)=>{
                        return <li className="mb-[8px]" key={listItemIndex}>
                            <a 
                                href="#" className="d-flex items-center min-h-[30px] text-[14px] text-[#E8E8E8]"
                                onClick={(e)=>{
                                    e.preventDefault();
                                    if(data.map(itemMap => itemMap.title).includes(listItem?.title)){
                                        setData(data.filter(item => item.title !== listItem?.title));
                                    } else {
                                        setData((prevState) => ([...prevState, listItem]));
                                    }
                                }}
                            >
                                <div className={`checkbox-item-square mr-[12px] ${data.map(itemMap => itemMap.title).includes(listItem?.title) ? 'active' : ''}`}>
                                    {
                                        data.map(itemMap => itemMap.title).includes(listItem?.title) && <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.6654 3.79199L5.2487 10.2087L2.33203 7.29199" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    }
                                </div>
                                { listItem?.title }
                            </a>
                        </li>
                    })
                }
            </ul>
            <div className='h-[1px] bg-[#1F1F1F]'></div>
            <div className="d-flex items-center gap-[16px] flex-col lg:flex-row">
                <a 
                    href="#" className="d-flex items-center min-h-[30px] text-[14px] text-[#E8E8E8] flex-1"
                    onClick={(e)=>{
                        e.preventDefault();
                        setActivateInteractiveTeacher((prevState) => !prevState);
                    }}
                >
                    <div className={`checkbox-item-square mr-[12px] ${activateInteractiveTeacher ? 'active' : ''}`}>
                        {
                            activateInteractiveTeacher && <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6654 3.79199L5.2487 10.2087L2.33203 7.29199" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        }
                    </div>
                    Activate Interactive Teacher
                </a>
                <a 
                    href="#" className="d-flex items-center min-h-[30px] text-[14px] text-[#E8E8E8] flex-1"
                    onClick={(e)=>{
                        e.preventDefault();
                        setKeepItPositive((prevState) => !prevState);
                    }}
                >
                    <div className={`checkbox-item-square mr-[12px] ${keepItPositive ? 'active' : ''}`}>
                        {
                            keepItPositive && <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6654 3.79199L5.2487 10.2087L2.33203 7.29199" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        }
                    </div>
                    Keep It Positive
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className='ml-[6px]'>
                        <path d="M4.74089 12.7891C4.38116 12.7891 4.07005 12.6637 3.80755 12.4128C3.54505 12.162 3.4138 11.8451 3.4138 11.462V5.55577C3.4138 5.38725 3.44347 5.22329 3.50282 5.06388C3.56216 4.90448 3.66374 4.75733 3.80755 4.62243L6.15547 2.1141C6.34019 1.91966 6.56623 1.79084 6.83359 1.72764C7.10096 1.66445 7.36589 1.69118 7.62839 1.80785C7.86172 1.93424 8.03429 2.12382 8.14609 2.3766C8.2579 2.62938 8.28464 2.89674 8.2263 3.17868L7.84714 5.01618H12.2951C12.6431 5.01618 12.9537 5.15424 13.2269 5.43035C13.5001 5.70646 13.6367 6.01077 13.6367 6.34327V6.88766C13.6367 7.01007 13.6197 7.15095 13.5857 7.3103C13.5516 7.46964 13.5152 7.59938 13.4763 7.69952L11.7263 11.7683C11.6194 12.0405 11.4203 12.2787 11.129 12.4829C10.8378 12.687 10.5292 12.7891 10.203 12.7891H4.74089ZM1.61824 12.7891C1.37639 12.7891 1.16797 12.703 0.992969 12.5309C0.817969 12.3587 0.730469 12.1483 0.730469 11.8995V5.92035C0.730469 5.67729 0.812097 5.46584 0.975352 5.28597C1.13861 5.10611 1.3452 5.01618 1.59514 5.01618H1.63889C1.87911 5.01618 2.08915 5.10611 2.26901 5.28597C2.44887 5.46584 2.5388 5.67729 2.5388 5.92035V11.8995C2.5388 12.1483 2.4487 12.3587 2.26849 12.5309C2.08826 12.703 1.87638 12.7891 1.63283 12.7891H1.61824Z" fill="#FF8114"/>
                    </svg>
                </a>
            </div>
        </div>
    </>;
};

export default AddOns;