import React, { createContext, useState } from 'react'
import { goalMilestones } from './data'

export const GenerateFormContext = createContext()

export const initialState = {
    settings: {
        number_of_blog: 4,
        minAmountWords: 500
    },
    goalTitle: 'Publish a Novel',
    goalSendReminders: true,
    goalRemindersFrequency: {
        Mon: true,
        Tue: true,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
        Sun: false
    },
    goalMilestones,
    goalMilestonesNumber: 8,
    add_ons: [],
    courses: [],
    selectedMethod: 'goal'
}

export const GeneratorContextComponent = ({children}) => {
    const [form, setForm] = useState(initialState);

    return (
        <GenerateFormContext.Provider value={[form, setForm]} >
            {children}
        </GenerateFormContext.Provider>
    )
}
