import classNames from "classnames"

const TextArea = ({placeholder, rows, value, change, className, ...props}) => {
    return <textarea 
        className={classNames("textarea", className)} 
        rows={rows} 
        value={value} 
        onChange={(e) => change(e.currentTarget.value)} 
        placeholder={placeholder}
        {...props}
    />
}

export default TextArea