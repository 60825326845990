import React, { useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import { useClickOutside } from '../../helpers/useClickOutside';
import { ReactComponent as CalendarIcon } from '../../assets/img/icons/icon-calendar.svg';
import { ReactComponent as DropDownIcon } from '../../assets/img/icons/icon-arrow_drop_down.svg';
import classNames from 'classnames';
import InputMask from 'react-input-mask';

const DatePicker = ({onChange, className, classNamesImportant, iconType = 'arrow', openInitial, closeWhenChange = false, showMask = true, placeholderText="MM/DD/YYYY", ...props}) => {
    const [opened, setOpened] = useState(false);
    const calendarRef = useRef();
    useClickOutside(calendarRef, () => setOpened(false));
    return <div 
        ref={calendarRef} 
        className={classNamesImportant ? classNamesImportant : classNames(`flex items-center p-[8px] pl-[14px] bg-[#1B1B1B]
        rounded-[8px] border border-solid border-[#333] text-[14px] h-[40px] relative`, className)}
    >
        <ReactDatePicker
            placeholderText={placeholderText}
            dateFormat="MM/dd/yyy"
            open={opened}
            preventOpenOnFocus
            onChange={(date) => {
                onChange(date);
                closeWhenChange && setOpened(false);
            }}
            onFocus={() => setOpened(true)}
            onBlur={() => setOpened(false)}
            {...(showMask && ({ customInput: <InputMask mask="99/99/9999" placeholder={placeholderText} /> }))}
            {...props}
        />
        <div 
            onClick={() => setOpened(!opened)} 
            className='flex-1 cursor-pointer'
        >
            {iconType === 'arrow' && <DropDownIcon />}
            {iconType === 'calendar' && <CalendarIcon />}
        </div>
    </div>;
};

export default DatePicker;