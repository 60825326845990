import React from 'react'
import { useSelector } from 'react-redux';
import { getFormOptions } from '../../redux/slices/applicationSlice';

export default function BotRoleSelect({ value, change }) {
    const roleOptions = useSelector(getFormOptions).filter(({key}) => key === 'bot_role');
    const handleToggle = (item) => () => {
        const currentIndex = value.indexOf(item);
        const result = [...value];
        if (currentIndex === -1) {
            result.push(item);
        } else {
            result.splice(currentIndex, 1);
        }
        change(result);
    };
    const handleClickTitle = (items) => () => {
        const isChecked = items.every(item => value.includes(item));
        let result = [...value];
        if (isChecked) {
            result = result.filter(element => !items.includes(element));
        } else {
            result = [...result, ...items];
        }
        change(result);
    };
    return <ul className='flex flex-col gap-[2px]'>
        {roleOptions.map(({title, items}) => <li>
            <div 
                className='flex items-center gap-[10px] py-[6px] px-[8px] cursor-pointer'
                onClick={handleClickTitle(items)}
            >
                <div 
                    className={`w-[20px] h-[20px] rounded-[4px] flex items-center justify-center
                    ${items.every(item => value.includes(item)) ? 'bg-orange-primary' : 'bg-[#232323]'}`}
                >
                    {items.every(item => value.includes(item)) &&
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M11.6654 3.79169L5.2487 10.2084L2.33203 7.29169" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    }
                </div>
                <span className='text-[#A7A7A7] text-[14px]'>{title}</span>
            </div>
            <ul className='ml-[30px]'>
                {items.map((item) => <li
                    onClick={handleToggle(item)}
                    className='flex items-center gap-[10px] py-[6px] px-[8px] cursor-pointer'
                >
                    <div 
                        className={`min-w-[20px] h-[20px] rounded-[4px] flex items-center justify-center
                        ${value.indexOf(item) !== -1 ? 'bg-orange-primary' : 'bg-[#232323]'}`}
                    >
                        {value.indexOf(item) !== -1 &&
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M11.6654 3.79169L5.2487 10.2084L2.33203 7.29169" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        }
                    </div>
                    <span className='text-[#A7A7A7] text-[14px] line-clamp-1'>{item}</span>
                </li>)}
            </ul>
        </li>)}
    </ul>
}
