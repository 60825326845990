import React from 'react'
import LoadIco from '../../assets/img/loader.png'

export const Loader = () => {
    return (
        <div className='loader-spin-container'>
            <div className='loader-spinner mb-[36px]'>
                <img src={LoadIco} alt="" />
            </div>
            <span className='mb-[24px] loader-spin-title'>Generating...</span>
            <span className='loader-spin-subtitle'>Please wait until your text generated</span>
        </div>
    )
}
