import React, { useState, useEffect } from 'react';

export const Pagination = ({ currentPage: propCurrentPage, totalPages, onPageChange, maxVisiblePages = 5, small = false }) => {
    const [currentPage, setCurrentPage] = useState(propCurrentPage)

    useEffect(() => {
        setCurrentPage(propCurrentPage)
    }, [propCurrentPage])

    const generatePageButtons = () => {
        const buttons = []

        if (totalPages <= maxVisiblePages) {
            for (let i = 1; i <= totalPages; i++) {
                buttons.push(
                <button
                    key={i}
                    className={`btn pagination-page-btn ${i === currentPage ? 'active' : ''}`}
                    onClick={() => handlePageClick(i)}
                >
                    {i}
                </button>
                )
            }
        } else {
            const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
            const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1)

            for (let i = startPage; i <= endPage; i++) {
                buttons.push(
                <button
                    key={i}
                    className={`btn pagination-page-btn ${i === currentPage ? 'active' : ''}`}
                    onClick={() => handlePageClick(i)}
                >
                    {i}
                </button>
                )
            }

            if (startPage > 1) {
                const dividerStart = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2) - 1)
                buttons.unshift(<div key="start" className='pagination-page-divider'>...</div>)
                buttons.unshift(
                <button
                    key={dividerStart}
                    className="btn pagination-page-btn"
                    onClick={() => handlePageClick(dividerStart)}
                >
                    {dividerStart}
                </button>
                )
            }

            if (endPage < totalPages) {
                const dividerEnd = Math.min(totalPages, startPage + maxVisiblePages)
                buttons.push(<div key="end" className='pagination-page-divider'>...</div>)
                buttons.push(
                <button key={dividerEnd} className="btn pagination-page-btn" onClick={() => handlePageClick(dividerEnd)}>
                    {dividerEnd}
                </button>
                )
            }
        }

        return buttons
    }

    const handlePageClick = (page) => {
        setCurrentPage(page)
        onPageChange(page)
    }

    const handlePrevClick = () => {
        if (currentPage > 1) {
            handlePageClick(currentPage - 1)
        }
    }

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            handlePageClick(currentPage + 1)
        }
    }

    return (
        <div className={`pagination ${small ? 'small' : ''}`}>
            <button
                className={`btn pagination-prev-btn ${currentPage === 1 ? 'disabled' : ''}`}
                onClick={handlePrevClick}
                disabled={currentPage === 1}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M4.16797 9.99967H15.8346" stroke="#A7A7A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.33464 5.83301L4.16797 9.99967" stroke="#A7A7A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.33464 14.1667L4.16797 10" stroke="#A7A7A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                { small ? "" : "Prev" }
            </button>

            <div className='pagination-page-wrap'>{generatePageButtons()}</div>

            <button 
                className={`btn pagination-next-btn ${currentPage === totalPages ? 'disabled' : ''}`}
                onClick={handleNextClick}
                disabled={currentPage === totalPages}
            >
                { small ? "" : "Next" }
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M15.832 10.0003L4.16536 10.0003" stroke="#331A04" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.6654 14.167L15.832 10.0003" stroke="#331A04" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.6654 5.83333L15.832 10" stroke="#331A04" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
    )
}
