import Tippy from '@tippyjs/react';
import React from 'react';

const ButtonTooltip = ({tooltip, children }) => {
    return <Tippy
        offset={[0,4]}
        arrow={false}
        className='generator-button-tooltip'
        placement='bottom'
        content={tooltip}
    >
        {children}
    </Tippy>
};

export default ButtonTooltip;