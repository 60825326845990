const coursesBase = 'api/courses';

export const courses = (instance) => ({
    async createCourse(payload) {
        const { data } = await instance.postForm(coursesBase, payload.data, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async getCourses(payload) {
        const { data } = await instance.get(coursesBase, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async getCourse(payload) {
        const { data } = await instance.get(`${coursesBase}/${payload.id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async updateCourse(payload) {
        const { data } = await instance.putForm(`${coursesBase}/${payload.id}`, payload.data, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async deleteCourse(payload) {
        const { data } = await instance.delete(`${coursesBase}/${payload.id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },

    async uploadAddon(payload) {
        const { data } = await instance.postForm(`${coursesBase}/upload-addon`, payload.data, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },

})