import React, { useState } from 'react';
import { GeneratedToolkit } from '../../components/ToolKit/GeneratedToolkit';
import ContentBox from "./ContentBox";
import ToolbarSidebar from "./ToolbarSidebar";
import GeneratedContentSidebar from "./GeneratedContentSidebar";

const GeneratedContent = () => {
    const [toolbarSidebarClose, setToolbarSidebarClose] = useState(false);
    const closeSide = () => {
        setToolbarSidebarClose(true)

        setTimeout(() => {
            setToolbarSidebarClose(false)
        }, 100)
    }
    return (
        <>   
            <GeneratedContentSidebar toolbarSidebarClose={toolbarSidebarClose} setToolbarSidebarClose={setToolbarSidebarClose} />
            <div className="pl-[0px] ml-[10px] content-box-wrpr">
               <ContentBox closeSideBar={closeSide} />
            </div>
        </>
    )
}

export default GeneratedContent;