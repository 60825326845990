import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../components/parts/Sidebar";
import Header from "../components/parts/Header";

const GeneratePageLayout = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);

    return (
        <>
            <div className={`sidebar-layout ${!sidebarOpen ? 'sidebar-close' : ''}`}>
                {/* <SideBar /> */}
                <div className="wrapper sidebar-fixed">
                    <main className="content">
                        {/* <Header sidebarToggle={()=>{ setSidebarOpen((prevState)=>!prevState) }} /> */}
                        <Outlet />
                    </main>
                </div>
            </div>
        </>
    )
}

export default GeneratePageLayout