import React from 'react'
import getUserImageSrc from '../../helpers/getUserImageSrc';
import { useSelector } from 'react-redux';
import { getUser } from '../../redux/slices/applicationSlice';
import { Link } from 'react-router-dom';
import PrivateButton from './PrivateButton';
import { highlightWordInText } from './NewWords';
import classNames from 'classnames';

const WordCard = ({setActiveWord, idx, el, setOpenDeleteModal, setActiveDropdownId, activeDropdownId, destination='page'}) => {
    const user = useSelector(getUser);
    return <div 
        className={classNames('pointer', {
            'h-full': destination === 'popup'
        })} 
        onClick={() => setActiveWord(idx)}
    >
        <div className="card-cust">
            <div className="card-cust--header p-16px">
                <div className="flex flex-col">
                    <div className="d-flex items-center justify-between min-h-[20px]">
                        <div className="text-12 text-white--09">{el.word_category}</div>
                        { el.is_private ? <div className="sm-orange-badge">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-[4px]">
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.91797 12.25H4.08464C3.44005 12.25 2.91797 11.7279 2.91797 11.0833V6.41667C2.91797 5.77208 3.44005 5.25 4.08464 5.25H9.91797C10.5626 5.25 11.0846 5.77208 11.0846 6.41667V11.0833C11.0846 11.7279 10.5626 12.25 9.91797 12.25Z" stroke="#FF8114" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M7.0026 9.96884V8.45801" stroke="#FF8114" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M7.30936 7.71115C7.48021 7.882 7.48021 8.15901 7.30936 8.32987C7.1385 8.50072 6.86149 8.50072 6.69064 8.32987C6.51979 8.15901 6.51979 7.882 6.69064 7.71115C6.86149 7.54029 7.1385 7.54029 7.30936 7.71115" stroke="#FF8114" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M4.66797 5.25V4.08333V4.08333C4.66797 2.79475 5.71272 1.75 7.0013 1.75V1.75C8.28989 1.75 9.33464 2.79475 9.33464 4.08333V4.08333V5.25" stroke="#FF8114" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Private
                        </div> : null }
                    </div>
                    
                    <div className="text-16 text-primary mt-6px">
                        <span className="font-600">{el.word}</span>
                        <span className="text-white--03 text-10 ml-[4px]">(noun)</span>
                    </div>
                    <ul className="ul-points mt-16px">
                        <li className="ul-points--item">
                            <p>{el.pronunciation}</p>
                        </li>
                        <li className="ul-points--item">
                            <p>{el.syllable_division}</p>
                        </li>
                    </ul>
                    <p className="mt-16px text-12 font-300 text-white">
                        {el.definition}
                    </p>
                </div>
            </div>
            <div className="card-cust--body p-16px pt-0 relative">
                <div className="comment-block">
                    <div
                        className="comment-body" 
                        dangerouslySetInnerHTML={{ __html: highlightWordInText(el.example, el.word) }}
                    >
                    </div>
                    <div className="comment-footer">
                        <div className="flex items-center comment-footer-user">
                            <div className="user user-20 mr-8px"> 
                                <img src={getUserImageSrc(el.creator)} alt='user' />
                            </div>
                            <div className="text-12 text-white--09 comment-footer-user--text">
                                { el.creator.username || `${el.creator?.first_name} ${el.creator?.last_name}` }
                            </div>
                        </div>
                        {user.id === el.creator.id && <span className="ico ico-20" onClick={(e) => {
                            e.stopPropagation();
                            setActiveDropdownId(el.id === activeDropdownId ? null : el.id);
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <g opacity="0.7">
                                    <path d="M15.4196 9.99997C15.4196 10.2302 15.233 10.4168 15.0028 10.4168C14.7726 10.4168 14.5859 10.2302 14.5859 9.99997C14.5859 9.76976 14.7726 9.58313 15.0028 9.58313C15.233 9.58313 15.4196 9.76976 15.4196 9.99997" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M10.4157 9.99997C10.4157 10.2302 10.2291 10.4168 9.99887 10.4168C9.76866 10.4168 9.58203 10.2302 9.58203 9.99997C9.58203 9.76976 9.76866 9.58313 9.99887 9.58313C10.2291 9.58313 10.4157 9.76976 10.4157 9.99997" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.41571 9.99997C5.41571 10.2302 5.22909 10.4168 4.99887 10.4168C4.76866 10.4168 4.58203 10.2302 4.58203 9.99997C4.58203 9.76976 4.76866 9.58313 4.99887 9.58313C5.22909 9.58313 5.41571 9.76976 5.41571 9.99997" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                            </svg>
                        </span>}
                    </div>
                </div>
                {el.id === activeDropdownId && 
                    <ul 
                        className="def-list list--5 py-[10px] absolute bottom-[40px] right-[20px]
                        bg-[#1B1B1B] border border-solid border-[#333] rounded-[8px] text-[12px]"
                        onClick={e => {
                            e.stopPropagation();
                            setActiveDropdownId(null);
                        }}
                    >
                        <li className="def-list--item px-[10px]">
                            <Link
                                className="d-block py-[5px] px-[10px]"
                                to={`/generated-page/new-word/${el.id}`}
                            >
                                Edit
                            </Link>
                        </li>
                        <li className="def-list--item px-[20px] py-[5px]">
                            <PrivateButton item={el}/>
                        </li>
                        <li className="def-list--item px-[10px]">
                            <button
                                className="d-block py-[5px] px-[10px]"
                                onClick={(e)=>{
                                    e.preventDefault();
                                    setOpenDeleteModal(el);
                                }}
                            >
                                Delete
                            </button>
                        </li>
                    </ul>}
            </div>
        </div>
    </div>
}

export default WordCard