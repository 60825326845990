import React from 'react';
import { useNavigate } from "react-router-dom";

const NewsletterListItem = ({ data }) => {
    const navigate = useNavigate();

    return <li
        className='flex gap-[10px] cursor-pointer'
        onClick={(e)=>{
            e.preventDefault();
            navigate(`/generated-page/courses/${data.id}`)
        }}
    >
        <img
            src={`${process.env.REACT_APP_API_URL}/${data?.cover}`}
            alt={data?.title}
            className='w-[90px] h-[90px] rounded-[8px]
            border border-solid border-white border-opacity-[0.12]'
        />
        <div className='flex flex-col justify-between flex-1'>
            <div className='flex items-center gap-[8px]'>
                {/* <img 
                    src={imageSrc} 
                    alt={name}
                    className='w-[20px] h-[20px] rounded-full border border-solid border-white border-opacity-[0.08]'
                />
                <span className='text-[12px] opacity-80'>{name}</span> */}
            </div>
            <h3 className=''>{data?.title}</h3>
            <p className='text-[12px] opacity-80 line-clamp-2'>{data?.description}</p>
        </div>
        <div className='flex items-center gap-[8px]'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                <path d="M7.75 5.69114H16.0034" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.0034 9.86363H7.75" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.75 14.0361H16.0034" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.37125 5.31599C4.16406 5.31599 3.99609 5.48395 3.99609 5.69114C3.99609 5.89834 4.16406 6.0663 4.37125 6.0663C4.57844 6.0663 4.74641 5.89834 4.74641 5.69114C4.74641 5.48395 4.57844 5.31599 4.37125 5.31599" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.37125 9.48848C4.16406 9.48848 3.99609 9.65644 3.99609 9.86363C3.99609 10.0708 4.16406 10.2388 4.37125 10.2388C4.57844 10.2388 4.74641 10.0708 4.74641 9.86363C4.74641 9.65644 4.57844 9.48848 4.37125 9.48848" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.37125 13.661C4.16406 13.661 3.99609 13.8289 3.99609 14.0361C3.99609 14.2433 4.16406 14.4113 4.37125 14.4113C4.57844 14.4113 4.74641 14.2433 4.74641 14.0361C4.74641 13.8289 4.57844 13.661 4.37125 13.661" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span className='text-[13px] opacity-90'>{data?.lessons?.length} lessons</span>
        </div>
    </li>
};

export default NewsletterListItem;