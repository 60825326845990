import React, { useEffect, useState } from 'react';
import GoalCheckpoint from './GoalCheckpoint';
import { useNavigate } from 'react-router-dom';
import { settings } from '../../api/parts/settings';
import { format, isValid } from 'date-fns';
import api from '../../api';
import { getToken } from '../../redux/slices/applicationSlice';
import { useSelector } from 'react-redux';

const MyGoalModal = ({goal, close, setAssets, setOpenedModal}) => {
    const content = JSON.parse(goal.content);
    const navigate = useNavigate();
    const [checkpoints, setCheckpoints] = useState(content.tasks);
    console.log(checkpoints)
    const doneCheckpoints = checkpoints.filter(({completed}) => completed).length;
    const token = useSelector(getToken);

    const deleteAction = async(id) => {
        const response = await api.assets.deleteAsset({
            id,
            token
        });

        if(response.success){
            setOpenedModal({
                name: 'save',
                title: response.message,
                text: ''
            });

            if(!token) return;
            api.assets.getAssets({token}).then(data => {
                setAssets(data.data);
            })

            return;
        }

        setOpenedModal(null);
    }

    return (
        <div className='modal__wrap' onClick={close}>
            <div style={{width: 872}} className='modal-content max-h-[calc(100%-120px)] pt-[36px] relative' onClick={(e) => e.stopPropagation()}>
                <button className="close-modal-block absolute top-[16px] right-[16px]" onClick={close}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g opacity="0.5">
                            <path d="M15 5L5 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5 5L15 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                    </svg>
                </button>
                <div className="modal-body overflow-y-auto">
                    <div className='flex flex-col overflow-y-auto'>
                        <span className='modal-body-title'>{goal.name}</span>
                        <div className='flex items-center gap-[12px]'>
                            <div className="w-[300px] h-[6px] bg-[#202020] rounded-full">
                                <div style={{width: `${doneCheckpoints * 100 / checkpoints.length}%`}} className='h-full progress-gradient rounded-full'/>
                            </div>
                            <span className="text-orange-primary text-[12px] font-medium">{doneCheckpoints} / {checkpoints.length}</span>
                        </div>
                        <div className='flex justify-between items-end pb-[30px] border-b border-white border-opacity-[0.06] border-solid'>
                            <div className='flex gap-[24px] text-[14px]'>
                                <div className='flex gap-[6px] text-orange-primary'>
                                    <span className='font-medium'>Category:</span>
                                    <span className='font-light'>{goal.category}</span>
                                </div>
                                <div className='flex gap-[6px]'>
                                    <span className='font-medium opacity-90'>Start:</span>
                                    <span className='font-light text-[#A7A7A7]'>{format(new Date(content.settings.goalStartDate), 'MM.dd.yyyy')}</span>
                                </div>
                                <div className='flex gap-[6px]'>
                                    <span className='font-medium opacity-90'>Deadline:</span>
                                    <span className='font-light text-[#A7A7A7]'>{format(new Date(content.settings.goalEndDate), 'MM.dd.yyyy')}</span>
                                </div>
                            </div>
                            <div className='flex gap-[8px]'>
                                <button 
                                    className='modal-button px-[12px]'
                                    onClick={() => navigate(`/generated-page/goal/${goal.id}`)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M14.8688 5.60686L12.5063 3.26311L13.2375 2.51311C13.475 2.27561 13.7656 2.15686 14.1094 2.15686C14.4531 2.15686 14.7625 2.28186 15.0375 2.53186L15.6188 3.11311C15.8938 3.37561 16.0156 3.67874 15.9844 4.02249C15.9531 4.36624 15.8188 4.65686 15.5813 4.89436L14.8688 5.60686ZM3.1125 15.8819C2.85 15.8819 2.64062 15.8006 2.48438 15.6381C2.32813 15.4756 2.25 15.2756 2.25 15.0381V13.8756C2.25 13.7756 2.275 13.6662 2.325 13.5475C2.375 13.4287 2.43125 13.3381 2.49375 13.2756L11.7375 4.05061L14.1 6.37561L4.89375 15.6194C4.79375 15.6944 4.6875 15.7569 4.575 15.8069C4.4625 15.8569 4.35625 15.8819 4.25625 15.8819H3.1125Z" fill="#A7A7A7"/>
                                    </svg>
                                    Edit
                                </button>
                                <button className='modal-button px-[6px]' onClick={()=>{
                                    close();
                                    setOpenedModal({
                                        name: 'confirm-delete',
                                        action: () => deleteAction(goal.id),
                                        title: `Do you really want to delete "${goal.name}" goal?`
                                    })
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" opacity={0.5}>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M14.7918 19.25H7.20178C6.24203 19.25 5.44453 18.5094 5.37303 17.5514L4.55078 6.41669H17.4153L16.6205 17.5469C16.5518 18.5066 15.7534 19.25 14.7918 19.25V19.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M11.0013 10.0833V15.5833" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M3.66797 6.41665H18.3346" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M15.5846 6.41667L14.6561 3.93983C14.3875 3.22392 13.7036 2.75 12.9391 2.75H9.06347C8.29897 2.75 7.61514 3.22392 7.34655 3.93983L6.41797 6.41667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M14.1437 10.0833L13.7495 15.5833" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.855 10.0833L8.24917 15.5833" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <ol className='mt-[30px] pr-[10px] pb-[10px] overflow-y-auto flex flex-col gap-[8px] no-scroll'>
                            {checkpoints.map((checkpoint, index) =>  
                                <GoalCheckpoint 
                                    key={index} 
                                    index={index} 
                                    setCheckpoints={setCheckpoints} 
                                    title={checkpoint.task_title}
                                    deadline={format(isValid(new Date(checkpoint.deadline)) ? new Date(checkpoint.deadline) :  new Date(), 'MM.dd.yyyy')}
                                    description={checkpoint.task_description}
                                    completed={checkpoint.completed}
                                    labels={[]}
                                    checkpoints={checkpoints}
                                    settings={content.settings}
                                    id={goal.id}
                                    setAssets={setAssets}
                                    subtasks={checkpoint.subtasks}
                                />)}
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyGoalModal;