import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import SelectBox from "../../components/base/SelectBox";

const AddEditUser = ({ edit }) => {

    const [user, setUserData] = useState(edit ? {
        first_name: "Peter",
        last_name: "Gomz",
        avatar: require('../../assets/img/user-image.png'),
        email: "info@peter.com",
        password: "**************",
        confirm_password: "**************",
    } : {
        first_name: "",
        last_name: "",
        avatar: null,
        email: "",
        password: "",
        confirm_password: "",
    });

    const [creditsUsedActive, setCreditsUsedActive] = useState('Last 7 Days');

    const avatarChange = function(e){
        console.log('e:', e);
        var file = e.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
      
         reader.onloadend = function (e) {
            setUserData((prevState) => { return {...prevState, avatar: reader.result } })
          }.bind(this);
        // console.log('url:', url)
    }

    return (
        <section className="page-section">
            <div className="container">
                <div className="flex items-center justify-between mb-[25px]">
                    <h2>{ edit ? 'Edit' : 'Add' } User</h2>
                </div>
                <div className="card card-border">
                    <div className="row gy-20">
                        <div className="col-12">
                            <label className="upload-image w-fit">
                                <img
                                    className="upload-image__preview image-wrapper"
                                    src={user.avatar ? user.avatar : require('../../assets/img/image-placeholder.png')} alt=""
                                />
                                <input type="file" className="hidden" onChange={avatarChange} />
                                <p className="upload-image__text">Upload image</p>
                            </label>
                        </div>
                        <div className="col-lg-4">
                            <div className="input-custom">
                                <label className="input-custom__label mb-[10px]">First Name</label>
                                <div className="input-custom__wrap-input">
                                    <input className="input-custom__input" type="text" placeholder="First Name"
                                        value={user.first_name} onInput={(e)=>{
                                            setUserData((prevState) => { return {...prevState, first_name: e.target.value } })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="input-custom">
                                <label className="input-custom__label mb-[10px]">Last Name</label>
                                <div className="input-custom__wrap-input">
                                    <input className="input-custom__input" type="text" placeholder="Last Name"
                                        value={user.last_name} onInput={(e)=>{
                                            setUserData((prevState) => { return {...prevState, last_name: e.target.value } })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="input-custom">
                                <label className="input-custom__label mb-[10px]">Email</label>
                                <div className="input-custom__wrap-input">
                                    <input className="input-custom__input" type="text" placeholder="Email"
                                        value={user.email} onInput={(e)=>{
                                            setUserData((prevState) => { return {...prevState, email: e.target.value } })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="input-custom">
                                <label className="input-custom__label mb-[10px]">Password</label>
                                <div className="input-custom__wrap-input">
                                    <input className="input-custom__input" type="password" placeholder="Password" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="input-custom">
                                <label className="input-custom__label mb-[10px]">Confirm Password</label>
                                <div className="input-custom__wrap-input">
                                    <input className="input-custom__input" type="password" placeholder="Confirm Password" />
                                </div>
                            </div>
                        </div>

                        {
                            edit ?
                            <>
                                <div className="col-lg-4">
                                    <div className="input-custom">
                                        <label className="input-custom__label mb-[10px]">Creation Date</label>
                                        <div className="input-custom__wrap-input">
                                            <input className="input-custom__input" type="text" placeholder="7 May 2023" value="7 May 2023" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="input-custom">
                                        <label className="input-custom__label mb-[10px]">Last Login Date</label>
                                        <div className="input-custom__wrap-input">
                                            <input className="input-custom__input" type="text" placeholder="7 May 2023" value="7 May 2023" />
                                        </div>
                                    </div>
                                </div>
                            </> : null
                        }

                        <div className="col-lg-4">
                            <div className="input-custom">
                                <label className="input-custom__label mb-[10px]">Role/Permission</label>
                                <SelectBox
                                    className="default-select searchable flex-1"
                                    propsOptions={[
                                        {label: '', value: '', placeholder: true},
                                        {label: 'User', value: 'User'},
                                        {label: 'Admin', value: 'Admin'},
                                    ]}
                                    isSearchable={true}
                                    placeholder="Select"
                                    // value={modelData.target_audience}
                                    // change={(value)=>{
                                    //     setModelData((prevState)=>{ return {...prevState, target_audience: value } });
                                    // }}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="btns-group">
                                {
                                    edit ?
                                    <button className="btn btn--primary btn--lg radius-5 mr-[12px]">
                                        Save
                                    </button> :
                                    <button className="btn btn--primary btn--lg radius-5 mr-[12px]">
                                        Add
                                    </button>
                                }
                                
                                <Link to="/my-team-users" className="btn btn-outline-primary-10 btn--lg radius-5">
                                    Cancel
                                </Link>
                            </div>
                        </div>
                        {
                            edit ? <>
                            <hr className="hr-default my-[30px]"></hr>
                            <h4 className="mt-[0] mb-[10px]">Credits Used</h4>
                            <div className="tabs mt-[0]">
                                <div className="tabs-head mb-[25px]">
                                    <ul className="flex items-center">
                                        {
                                            ["Last 7 Days", "Last 30 Days", "All Time"].map((item, index)=> {
                                                return <li key={index}>
                                                    <a 
                                                        className={`btn btn--md rounded-full ${creditsUsedActive === item ? 'btn-primary-10' : 'color-white'}`} 
                                                        href="#" 
                                                        onClick={(e)=>{
                                                            e.preventDefault();
                                                            setCreditsUsedActive(item);
                                                        }}
                                                    >Last 7 Days</a>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="tabs-body">
                                    <div className="tab-content">
                                        <span>Credits Used: 23</span>
                                    </div>
                                </div>
                            </div> </> : null
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AddEditUser;