import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    generateLoading: false,
    settings: {},
    tasks: []
}

const goalGeneratorSlice = createSlice({
    name: 'goalGenerator',
    initialState,
    reducers: {
        setGoalState: (state, action) => {
            state.settings = action.payload.settings;
            state.tasks = action.payload.tasks
        },
        setGenerateLoading: (state, action) => {
            state.generateLoading = action.payload;
        },
        setGoalSettings: (state, action) => {
            state.settings = action.payload;
        },
        setGoalTasks: (state, action) => {
            state.tasks = action.payload;
        },
        addGoalTask: (state, action) => {
            state.tasks.push(action.payload);
        },
        updateGoalTask: (state, action) => {
            state.tasks[action.payload.index] = {...state.tasks[action.payload.index], ...action.payload.data}
        },
        deleteGoalSubtask: (state, action) => {
            state.tasks[action.payload.taskIndex].subtasks.splice(action.payload.subtaskIndex, 1); 
        },

        setBlogs: (state, action) => {
            state.blogs = action.payload;
        },
        setActiveBlogIndex: (state, action) => {
            state.activeBlogIndex = action.payload; 
        },

        setBlogVersions: (state, action) => {
            if(!state.blogs[state.activeBlogIndex]) {
                state.blogs[state.activeBlogIndex] = {}
            }
            state.blogs[state.activeBlogIndex].versions = Object.values(action.payload).map(value => ({
                language: state.blogs[state.activeBlogIndex].settings.language,
                tone: state.blogs[state.activeBlogIndex].settings.tone,
                ...value
            })); 
        },
        deleteActiveBlogVersion: (state, action) => {
            let { versions, activeVersionIndex } = state.blogs[state.activeBlogIndex];
            versions.splice(activeVersionIndex, 1);
            if(versions.length === 0) {
                console.log('sweijfioewhfbuo')
                state.blogs.splice(state.activeBlogIndex, 1);
                return;
            }
            if(activeVersionIndex === versions.length) {
                state.blogs[state.activeBlogIndex].activeVersionIndex = 0;
            }
        },
        copyActiveBlogVersion: (state, action) => {
            let { versions, activeVersionIndex } = state.blogs[state.activeBlogIndex];
            versions.push(versions[activeVersionIndex]);
        },
        setBlogActiveVersionIndex: (state, action) => {
            state.blogs[state.activeBlogIndex].activeVersionIndex = action.payload;
        },

        setBlogContent: (state, action) => {
            const activeBlog = state.blogs[state.activeBlogIndex];
            activeBlog.versions[activeBlog.activeVersionIndex].content = action.payload;
        },
        setBlogActiveContent: (state, action) => {
            const activeBlog = state.blogs[state.activeBlogIndex];
            activeBlog.versions[activeBlog.activeVersionIndex].activeContent = action.payload;
        },
        setBlogActiveButton: (state, action) => {
            const activeBlog = state.blogs[state.activeBlogIndex];
            activeBlog.versions[activeBlog.activeVersionIndex].activeButton = action.payload;
        },
        setBlogIsEdit: (state, action) => {
            const activeBlog = state.blogs[state.activeBlogIndex];
            activeBlog.versions[activeBlog.activeVersionIndex].isEdit = action.payload;
        },
        setBlogTone: (state, action) => {
            const activeBlog = state.blogs[state.activeBlogIndex];
            activeBlog.versions[activeBlog.activeVersionIndex].tone = action.payload;
        },
        setBlogLanguage: (state, action) => {
            const activeBlog = state.blogs[state.activeBlogIndex];
            activeBlog.versions[activeBlog.activeVersionIndex].language = action.payload;
        },
        addBlogKeyword: (state, action) => {
            const { versions, activeVersionIndex } = state.blogs[state.activeBlogIndex];
            if(!versions[activeVersionIndex].keywords) versions[activeVersionIndex].keywords = []
            versions[activeVersionIndex].keywords.push(action.payload);
        },
        deleteBlogKeyword: (state, action) => {
            const { versions, activeVersionIndex } = state.blogs[state.activeBlogIndex];
            if(!versions[activeVersionIndex].keywords) return;
            versions[activeVersionIndex].keywords = versions[activeVersionIndex].keywords.filter((keyword) => keyword !== action.payload);
        },
        setBlogGeneratingContent: (state, action) => {
            state.generatingContent = action.payload;
        }
    }
})


export const {
    addBlogKeyword, setBlogLanguage, setBlogTone, setBlogActiveButton, 
    setBlogContent, setBlogVersions, setBlogs, setActiveBlogIndex, setBlogActiveContent, 
    setBlogActiveVersionIndex, deleteBlogKeyword, deleteActiveBlogVersion, copyActiveBlogVersion,
    setBlogIsEdit, setBlogGeneratingContent, setGoalTasks, setGoalState, addGoalTask,
    updateGoalTask, setGoalSettings, deleteGoalSubtask, setGenerateLoading
} = goalGeneratorSlice.actions

export const getGeneratedBlogs = (state) => state.goalGenerator.blogs;
export const getGenerateLoading = (state) => state.goalGenerator.generateLoading;
export const getActiveBlogIndex = (state) => state.goalGenerator.activeBlogIndex;
export const getBlogGeneratingContent = (state) => state.goalGenerator.generatingContent;
export const getBlogActiveVersion = ({goalGenerator: {blogs, activeBlogIndex}}) => {
    const activeBlog = blogs[activeBlogIndex];
    return activeBlog.versions[activeBlog.activeVersionIndex];
}
export const getGeneratedGoal = (state) => state.goalGenerator;

export default goalGeneratorSlice.reducer