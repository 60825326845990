import { ReactComponent as KitIcon } from '../../../assets/img/icons/new-word-kit.svg';
import NewWord from './NewWord';

export const newsletters = [
    {
        src: require('../../../assets/img/swiper/Programming with Python.png'),
        title: 'Programming with Python',
        description: "Discover the versatility of Python with our Programming with Python course. Whether you're a beginner or an experienced developer, master Python's fundamentals and build real-world applications in this comprehensive program.",
        modules: 13,
        createdBy: {
            name: 'SandBill',
            imageSrc: require('../../../assets/img/user-image.png'),
            me: true
        },
        language: 'eng',
        frequency: 'monthly'
    }, {
        src: require('../../../assets/img/swiper/American History Basic Cource.png'),
        title: 'American History: Basic Cource',
        description: "Explore the rich tapestry of American history in our Basic Course. Dive into key events, figures, and milestones that have shaped the nation's story, gaining a foundational understanding of the United States' compelling past.",
        modules: 8,
        createdBy: {
            name: 'MoonBabe',
            imageSrc: require('../../../assets/img/avatar-img-5.jpg'),
            me: false
        },
        language: 'eng',
        frequency: 'weekly'
    }, {
        src: require("../../../assets/img/swiper/Beginner's Guide to Spanish.png"),
        title: "Beginner's Guide to Spanish",
        description: "Embark on a linguistic journey with our Beginner's Guide to Spanish. Start building your language skills from scratch, learning essential vocabulary and grammar, and soon you'll be conversing with confidence in this vibrant and widely spoken language.",
        modules: 9,
        createdBy: {
            name: 'Deploya',
            imageSrc: require('../../../assets/img/avatar-img-6.jpg'),
            me: false
        },
        language: 'esp',
        frequency: 'daily'
    }
];

export const navLinks = [
    {
        title: 'New Word',
        icon: <div className='new-word-generator-icon'>
            <KitIcon />
        </div>,
        Component: NewWord
    },
    // {
    //     title: 'Tutorials',
    //     icon: <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
    //         <path d="M10.9995 21.5037H15.0012" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M10.9995 3.49624H15.0012" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M3.9963 10.4992V14.5008" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M22.0036 10.4992V14.5008" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M7.99825 21.5037V21.5037C5.78819 21.5037 3.99658 19.7121 3.99658 17.5021V17.5021" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M7.99825 21.5036C5.78819 21.5036 3.99658 19.712 3.99658 17.502" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M18.0024 3.49612C20.2125 3.49612 22.0041 5.28773 22.0041 7.49779" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M3.99658 7.49779C3.99658 5.28773 5.78819 3.49612 7.99825 3.49612" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M22.0041 17.502C22.0041 19.712 20.2125 21.5036 18.0024 21.5036" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M13.0003 14.0006V12.5C14.1054 12.5 15.0012 11.6042 15.0012 10.4992C15.0012 9.39413 14.1054 8.49832 13.0003 8.49832C11.8953 8.49832 10.9995 9.39413 10.9995 10.4992" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M13.0002 16.4516C12.9737 16.4511 12.9518 16.4721 12.9512 16.4986V16.5017C12.9512 16.5215 12.9631 16.5394 12.9814 16.5469C12.9998 16.5545 13.0209 16.5503 13.0349 16.5363C13.0489 16.5223 13.0531 16.5012 13.0455 16.4829C13.0379 16.4646 13.02 16.4526 13.0002 16.4526H12.9992" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M13.0502 16.5017C13.0502 16.5293 13.0278 16.5517 13.0002 16.5517C12.9726 16.5517 12.9502 16.5293 12.9502 16.5017C12.9502 16.474 12.9726 16.4517 13.0002 16.4517" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M13.0001 16.4516C13.0277 16.4516 13.0501 16.474 13.0501 16.5017" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //     </svg>,
    //     Component: null
    // }
]

export const subCategories = [
    {
        title: 'Educational',
        newsletters: [
            {
                src: require('../../../assets/img/swiper/Curriculum.png'),
                title: 'Curriculum'
            },
            {
                src: require('../../../assets/img/swiper/Digitalization.png'),
                title: 'Digitalization'
            },
            {
                src: require('../../../assets/img/swiper/Inclusion.png'),
                title: 'Inclusion'
            },
            {
                src: require('../../../assets/img/swiper/Assessment.png'),
                title: 'Assessment'
            },
        ]
    },
    {
        title: 'Business',
        newsletters: [
            {
                src: require('../../../assets/img/swiper/Leadership.png'),
                title: 'Leadership'
            },
            {
                src: require('../../../assets/img/swiper/Productivity.png'),
                title: 'Productivity'
            },
            {
                src: require('../../../assets/img/swiper/Innovation.png'),
                title: 'Innovation'
            },
            {
                src: require('../../../assets/img/swiper/Assessment.png'),
                title: 'Assessment'
            },
        ]
    },
    {
        title: 'Self-help',
        newsletters: [
            {
                src: require('../../../assets/img/swiper/Positivity.png'),
                title: 'Positivity'
            },
            {
                src: require('../../../assets/img/swiper/Mindfulness.png'),
                title: 'Mindfulness'
            },
            {
                src: require('../../../assets/img/swiper/Affirmations.png'),
                title: 'Affirmations'
            },
            {
                src: require('../../../assets/img/swiper/Assessment.png'),
                title: 'Assessment'
            },
        ]
    }
]

export const services = [
    {
        name: 'Mailchimp',
        image: require('../../../assets/img/services/Mailchimp.jpg')
    }, {
        name: 'Mailerlite',
        image: require('../../../assets/img/services/Mailerlite.jpg')
    }, {
        name: 'Active Campaign',
        image: require('../../../assets/img/services/Active Campaign.jpg')
    }, {
        name: 'Sendinblue',
        image: require('../../../assets/img/services/Sendinblue.png')
    }, {
        name: 'Campaign Monitor',
        image: require('../../../assets/img/services/Campaign Monitor.jpg')
    }, {
        name: 'Sendy',
        image: require('../../../assets/img/services/Sendy.jpg')
    }, {
        name: 'GetResponse',
        image: require('../../../assets/img/services/GetResponse.png')
    }, {
        name: 'SendGrid',
        image: require('../../../assets/img/services/SendGrid.jpg')
    }, {
        name: 'Mailjet',
        image: require('../../../assets/img/services/Mailjet.png')
    }, {
        name: 'Moosend',
        image: require('../../../assets/img/services/Moosend.jpg')
    }
]

export const categories = [
    {
        title: 'IT Newsletters',
        newsletters: [
            {
                src: require('../../../assets/img/swiper/Programming with Python.png'),
                title: 'Programming with Python'
            },
            {
                src: require('../../../assets/img/swiper/Web Development Fundamentals.png'),
                title: 'Web Development Fundamentals'
            },
            {
                src: require('../../../assets/img/swiper/Cybersecurity Essentials.png'),
                title: 'Cybersecurity Essentials'
            },
            {
                src: require('../../../assets/img/swiper/Cloud Computing Fundamentals.png'),
                title: 'Cloud Computing Fundamentals'
            }
        ]
    },
    {
        title: 'Language Newsletters',
        newsletters: [
            {
                src: require("../../../assets/img/swiper/Beginner's Guide to Spanish.png"),
                title: "Beginner's Guide to Spanish"
            },
            {
                src: require('../../../assets/img/swiper/Conversational French for Travelers.png'),
                title: 'Conversational French for Travelers'
            },
            {
                src: require('../../../assets/img/swiper/Intermediate Mandarin Chinese.png'),
                title: 'Intermediate Mandarin Chinese'
            },
            {
                src: require('../../../assets/img/swiper/Japanese for Business and Travel.png'),
                title: 'Japanese for Business and Travel'
            }
        ]
    },
    {
        title: 'Language Newsletters',
        newsletters: [
            {
                src: require("../../../assets/img/swiper/Introduction to Music Theory.png"),
                title: "Introduction to Music Theory"
            },
            {
                src: require("../../../assets/img/swiper/Play the Guitar Beginner's Course.png"),
                title: "Play the Guitar: Beginner's Course"
            },
            {
                src: require('../../../assets/img/swiper/Singing and Vocal Techniques.png'),
                title: 'Singing and Vocal Techniques'
            },
            {
                src: require('../../../assets/img/swiper/Jazz Improvisation Techniques.png'),
                title: 'Jazz Improvisation Techniques'
            }
        ]
    }
]

export const users = [
    {
        name: 'Jane Cooper',
        email: 'alma.lawson@example.com'
    }, {
        name: 'Brooklyn Simmons',
        email: 'nevaeh.simmons@example.com'
    }, {
        name: 'Eleanor Pena',
        email: 'bill.sanders@example.com'
    }, {
        name: 'Dianne Russell',
        email: 'deanna.curtis@example.com'
    }
]