import React from 'react';
import { useSelector } from 'react-redux';
import { getActiveBlogIndex, getGeneratedBlogs, setBlogActiveButton, setBlogActiveContent, setBlogContent, setBlogLanguage, setBlogTone } from '../../../redux/slices/blogGeneratorSlice';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

const ModalAction = ({title, list, onSelect}) => {
    const dispatch = useDispatch();
    const blogs = useSelector(getGeneratedBlogs);
    const activeBlogIndex = useSelector(getActiveBlogIndex);
    const {versions, activeVersionIndex} = blogs[activeBlogIndex] || {};
    const version =  versions?.[activeVersionIndex] || {};
    const { activeButton, content } = version;
    const currentField = version[activeButton];
    return <div>
        {title && <p className='text-[10px] uppercase ml-[10px] leading-[23px] text-[#A7A7A7] opacity-40 font-semibold'>
            {title}
        </p>}
        <ul className='mt-[4px] flex flex-col gap-[4px]'>
            {list.map((option, index) => <li 
                key={index}
                className={classNames('modal-action-list-item min-h-[28px] px-[10px] text-[14px] opacity-90 flex items-center justify-between cursor-pointer',
                currentField === option ? 'text-[#FF8114]' : 'text-[#A7A7A7]')}
                onClick={() => onSelect(option)}
            >
                {option}
                {currentField === option && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clipPath="url(#clip0_2757_45055)">
                        <path d="M6.9834 9.1168L5.66673 7.78346C5.52451 7.65013 5.34945 7.58346 5.14155 7.58346C4.93365 7.58346 4.75315 7.65291 4.60007 7.7918C4.45562 7.9418 4.3834 8.12235 4.3834 8.33346C4.3834 8.54458 4.46118 8.71124 4.61673 8.83346L6.45007 10.6835C6.58643 10.839 6.76219 10.9168 6.97733 10.9168C7.19249 10.9168 7.37784 10.839 7.5334 10.6835L11.3167 6.90013C11.4723 6.75791 11.5501 6.58563 11.5501 6.38328C11.5501 6.18094 11.4732 5.99766 11.3194 5.83346C11.1656 5.70013 10.9817 5.63624 10.7676 5.6418C10.5534 5.64735 10.3754 5.7168 10.2334 5.85013L6.9834 9.1168ZM8.00237 15.0168C7.03817 15.0168 6.12647 14.8357 5.26728 14.4734C4.40808 14.1111 3.66043 13.612 3.02433 12.9759C2.38823 12.3398 1.88905 11.5929 1.5268 10.7352C1.16453 9.87756 0.983398 8.96663 0.983398 8.00243C0.983398 7.03539 1.16485 6.12379 1.52777 5.26765C1.89069 4.4115 2.39078 3.66431 3.02805 3.02608C3.66533 2.38784 4.41174 1.88506 5.26728 1.51775C6.12284 1.15045 7.03254 0.966797 7.99638 0.966797C8.96424 0.966797 9.8771 1.14992 10.735 1.51618C11.5928 1.88244 12.3404 2.38386 12.9776 3.02046C13.6149 3.65706 14.1168 4.40401 14.4834 5.2613C14.8501 6.1186 15.0334 7.032 15.0334 8.00151C15.0334 8.97033 14.8497 9.88145 14.4824 10.7349C14.1151 11.5883 13.6124 12.3339 12.9741 12.9715C12.3359 13.6092 11.5895 14.1095 10.7348 14.4724C9.88024 14.8353 8.96941 15.0168 8.00237 15.0168Z" fill="#FF8114"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_2757_45055">
                        <rect width="16" height="16" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>}
            </li>)}
        </ul>
    </div>
};

export default ModalAction;