const base = 'api/payments';

export const payments = (instance) => ({
    async createIntent(payload) {
        const { data } = await instance.get(`${base}/create-intent`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async subscribePlan(payload) {
        const { data } = await instance.post(`/api/pay-authorize/subscribe`, {
            planId: payload.planId
        }, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async cancelPlan(payload) {
        const { data } = await instance.delete(`/api/pay-authorize/cancel-subscription`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async resolveSubscribe(payload) {
        const { data } = await instance.get(`${base}/resolve-subscribe`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
})