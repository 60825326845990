const base = 'api/payment-cards';

export const paymentCards = (instance) => ({
    async addCard(payload) {
        try {
            const { data } = await instance.post(`${base}`, payload.data, {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            });
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async getCards(payload) {
        const { data } = await instance.get(`${base}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async deleteCard(payload) {
        try {
            const { data } = await instance.delete(`${base}/${payload.id}`, {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            });
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async editCard(payload) {
        try {
            const { data } = await instance.put(`${base}/${payload.id}`, payload.data, {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            });
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async setCardDefault(payload) {
        const { data } = await instance.get(`${base}/set-default/${payload.id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
})