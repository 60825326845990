import React, { useState } from 'react';
import { SearchInput } from '../../../components/base/SearchInput';
import { SmallImageSwiper } from '../SmallImageSwiper';

const categories = [
    {
        title: 'IT Newsletters',
        newsletters: [
            {
                src: require('../../../assets/img/swiper/Programming with Python.png'),
                title: 'Programming with Python'
            },
            {
                src: require('../../../assets/img/swiper/Web Development Fundamentals.png'),
                title: 'Web Development Fundamentals'
            },
            {
                src: require('../../../assets/img/swiper/Cybersecurity Essentials.png'),
                title: 'Cybersecurity Essentials'
            },
            {
                src: require('../../../assets/img/swiper/Cloud Computing Fundamentals.png'),
                title: 'Cloud Computing Fundamentals'
            }
        ]
    },
    {
        title: 'Language Newsletters',
        newsletters: [
            {
                src: require("../../../assets/img/swiper/Beginner's Guide to Spanish.png"),
                title: "Beginner's Guide to Spanish"
            },
            {
                src: require('../../../assets/img/swiper/Conversational French for Travelers.png'),
                title: 'Conversational French for Travelers'
            },
            {
                src: require('../../../assets/img/swiper/Intermediate Mandarin Chinese.png'),
                title: 'Intermediate Mandarin Chinese'
            },
            {
                src: require('../../../assets/img/swiper/Japanese for Business and Travel.png'),
                title: 'Japanese for Business and Travel'
            }
        ]
    },
    {
        title: 'Language Newsletters',
        newsletters: [
            {
                src: require("../../../assets/img/swiper/Introduction to Music Theory.png"),
                title: "Introduction to Music Theory"
            },
            {
                src: require("../../../assets/img/swiper/Play the Guitar Beginner's Course.png"),
                title: "Play the Guitar: Beginner's Course"
            },
            {
                src: require('../../../assets/img/swiper/Singing and Vocal Techniques.png'),
                title: 'Singing and Vocal Techniques'
            },
            {
                src: require('../../../assets/img/swiper/Jazz Improvisation Techniques.png'),
                title: 'Jazz Improvisation Techniques'
            }
        ]
    }
]

const PresetNewsletters = () => {
    const [searchValue, setSearchValue] = useState('');
    const [selectedNewsletters, setSelectedNewsletters] = useState([]);
    return <div className='input-container w-100'>
        <div className='input-search-variant-two'>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <circle cx="10.215" cy="10.2155" r="5.88495" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M17.67 17.6694L14.377 14.3764" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <input  placeholder='Search newsletters...' />
        </div>
        <ul className='flex flex-col gap-[16px]'>
            {categories.map(({title, newsletters}, categoryIndex) => <li>
                <p className="input-custom__label mb-[10px]">{title}</p>
                <SmallImageSwiper 
                    data={newsletters}
                    activeSlideIndex={selectedNewsletters[categoryIndex]}
                    setActiveSlideIndex={(itemIndex) => {
                        setSelectedNewsletters(prev => {
                            const result = [...prev];
                            result[categoryIndex] = itemIndex;
                            return result;
                        })
                    }}
                />
            </li>)}
        </ul>
    </div>
};

export default PresetNewsletters;