import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import SelectBox from "../../components/base/SelectBox";
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
TimeAgo.addDefaultLocale(en);

const MyProjects = () => {
    const timeAgo = new TimeAgo('en-US');
    const projects = [
        {
            name: "First Project",
            description: "First Project Description",
            last_changed: '2023-08-01T12:51:31',
            type: '2'
        },
        {
            name: "Random Project",
            description: "Random project description",
            last_changed: '2023-07-30T11:22:41',
            type: '1'
        },
    ];

    const [rowView, setRowView] = useState(false);

    const [search, setSearch] = useState('');
    const [sortValue, setSortValue] = useState('');

    const filteredProjects = [...projects].filter(itemF => itemF.name.toLowerCase().includes(search.toLowerCase())).sort((a,b) => (a[sortValue] > b[sortValue]) ? 1 : ((b[sortValue] > a[sortValue]) ? -1 : 0));

    return (
        <section className="page-section">
            <div className="container">
                <div className="row-heading row-heading--lighten-60 rounded-[16px] px-[20px] mb-[20px]">
                    <div className="heading-item py-[10px]">
                        <div className="title-24 font-[800]">
                            My Projects
                        </div>
                        <div className="text-2">
                            <p>Manage your projects</p>
                        </div>
                    </div>
                </div>
                
                <div className="project-filters">
                    <div className="project-filters__title">
                        Recent Projects
                    </div>
                    <div className="project-filters__actions">
                        <div className="project-filters__actions-item">
                            <div className="select-wrapp">
                                <SelectBox
                                //  only-icon 
                                className="default-select flex-1 w-auto ml-[10px] sort-select-box min-w-[234px]"
                                propsOptions={[
                                    {label: 'Team', value: 'Team'},
                                    {label: 'Private', value: 'Private'},
                                ]}
                                icon={
                                    <>
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.25 18.525H3.775C3.4525 18.525 3.18021 18.4195 2.95812 18.2085C2.73604 17.9976 2.625 17.7309 2.625 17.4085C2.625 17.0862 2.73604 16.8125 2.95812 16.5875C3.18021 16.3625 3.4525 16.25 3.775 16.25H8.25C8.58667 16.25 8.8625 16.3637 9.0775 16.5912C9.2925 16.8188 9.4 17.0898 9.4 17.4044C9.4 17.7352 9.2925 18.0047 9.0775 18.2128C8.8625 18.4209 8.58667 18.525 8.25 18.525ZM20.25 7.84999H3.775C3.4525 7.84999 3.18021 7.7445 2.95812 7.53354C2.73604 7.32257 2.625 7.0559 2.625 6.73354C2.625 6.41117 2.73604 6.13332 2.95812 5.89999C3.18021 5.66665 3.4525 5.54999 3.775 5.54999H20.25C20.5562 5.54999 20.8203 5.66791 21.0422 5.90374C21.2641 6.13959 21.375 6.4148 21.375 6.72936C21.375 7.0602 21.2641 7.32968 21.0422 7.53781C20.8203 7.74593 20.5562 7.84999 20.25 7.84999ZM14.25 13.2H3.775C3.4525 13.2 3.18021 13.0928 2.95812 12.8785C2.73604 12.6642 2.625 12.3933 2.625 12.066C2.625 11.7387 2.73604 11.4667 2.95812 11.25C3.18021 11.0333 3.4525 10.925 3.775 10.925H14.25C14.5796 10.925 14.8495 11.0364 15.0597 11.2594C15.2699 11.4823 15.375 11.7499 15.375 12.0623C15.375 12.3979 15.2699 12.6715 15.0597 12.8829C14.8495 13.0943 14.5796 13.2 14.25 13.2Z" fill="#A7A7A7"/>
                                        </svg>
                                        <span style={{ marginLeft: '10px', color: 'rgba(255, 255, 255, 0.50)', fontSize: '16px', fontWeight: '400' }}>Filter by</span>
                                    </>
                                }
                                // value={filterValue}
                                // change={(value)=>{
                                //     setFilterValue(value);
                                // }}
                            />
                            </div>
                        </div>
                        <div className="project-filters__actions-item">
                            <div className="select-wrapp">
                            <SelectBox
                                className="default-select flex-1 w-auto ml-[10px] sort-select-box"
                                propsOptions={[
                                    {label: 'Name', value: 'name'},
                                    {label: 'Date', value: 'last_changed'},
                                    {label: 'Type', value: 'type'},
                                ]}
                                icon={
                                    <>
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.25 18.525H3.775C3.4525 18.525 3.18021 18.4195 2.95812 18.2085C2.73604 17.9976 2.625 17.7309 2.625 17.4085C2.625 17.0862 2.73604 16.8125 2.95812 16.5875C3.18021 16.3625 3.4525 16.25 3.775 16.25H8.25C8.58667 16.25 8.8625 16.3637 9.0775 16.5912C9.2925 16.8188 9.4 17.0898 9.4 17.4044C9.4 17.7352 9.2925 18.0047 9.0775 18.2128C8.8625 18.4209 8.58667 18.525 8.25 18.525ZM20.25 7.84999H3.775C3.4525 7.84999 3.18021 7.7445 2.95812 7.53354C2.73604 7.32257 2.625 7.0559 2.625 6.73354C2.625 6.41117 2.73604 6.13332 2.95812 5.89999C3.18021 5.66665 3.4525 5.54999 3.775 5.54999H20.25C20.5562 5.54999 20.8203 5.66791 21.0422 5.90374C21.2641 6.13959 21.375 6.4148 21.375 6.72936C21.375 7.0602 21.2641 7.32968 21.0422 7.53781C20.8203 7.74593 20.5562 7.84999 20.25 7.84999ZM14.25 13.2H3.775C3.4525 13.2 3.18021 13.0928 2.95812 12.8785C2.73604 12.6642 2.625 12.3933 2.625 12.066C2.625 11.7387 2.73604 11.4667 2.95812 11.25C3.18021 11.0333 3.4525 10.925 3.775 10.925H14.25C14.5796 10.925 14.8495 11.0364 15.0597 11.2594C15.2699 11.4823 15.375 11.7499 15.375 12.0623C15.375 12.3979 15.2699 12.6715 15.0597 12.8829C14.8495 13.0943 14.5796 13.2 14.25 13.2Z" fill="#A7A7A7"/>
                                        </svg>
                                        <span style={{ marginLeft: '10px', color: 'rgba(255, 255, 255, 0.50)', fontSize: '16px', fontWeight: '400' }}>Sort by</span>
                                    </>
                                }
                                value={sortValue}
                                change={(value)=>{
                                    setSortValue(value);
                                }}
                            />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="search-project mb-[20px]">
                    <div className="input-custom__wrap-input icon-left">
                        <div className="icon-input flex" style={{ pointerEvents: "none" }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.33585 16.125C7.414 16.125 5.79838 15.4691 4.48898 14.1574C3.17959 12.8457 2.5249 11.2499 2.5249 9.36993C2.5249 7.48997 3.18076 5.89583 4.49248 4.5875C5.80419 3.27917 7.40003 2.625 9.27998 2.625C11.1599 2.625 12.7541 3.28037 14.0624 4.5911C15.3707 5.90183 16.0249 7.49772 16.0249 9.37875C16.0249 10.1429 15.9166 10.8375 15.6999 11.4625C15.4832 12.0875 15.1666 12.6833 14.7499 13.25L20.6749 19.125C20.8916 19.3509 20.9999 19.6281 20.9999 19.9564C20.9999 20.2847 20.8916 20.5618 20.6749 20.7875C20.436 21.0125 20.159 21.125 19.8438 21.125C19.5286 21.125 19.2556 21.0083 19.0249 20.775L13.1617 14.9C12.6538 15.2821 12.0794 15.5817 11.4384 15.7991C10.7974 16.0164 10.0966 16.125 9.33585 16.125ZM9.28593 13.85C10.5523 13.85 11.6129 13.4205 12.4677 12.5615C13.3225 11.7024 13.7499 10.6437 13.7499 9.3853C13.7499 8.12688 13.322 7.06473 12.4663 6.19883C11.6105 5.33294 10.5511 4.9 9.28813 4.9C8.01126 4.9 6.944 5.33294 6.08635 6.19883C5.22872 7.06473 4.7999 8.12688 4.7999 9.3853C4.7999 10.6437 5.22749 11.7024 6.08268 12.5615C6.93788 13.4205 8.00563 13.85 9.28593 13.85Z" fill="#A7A7A7"></path>
                            </svg>
                        </div>
                        <input
                            className="input-custom__input" type="text" placeholder="Search"
                            value={search}
                            onInput={(e)=>{
                                setSearch(e.target.value);
                            }}
                        />
                    </div>
                    <a href="" className="btn" onClick={(e)=>{
                        e.preventDefault();
                        setRowView(!rowView);
                    }}>
                        {
                            rowView ? 
                            <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 17.9375C2.7375 17.9375 2.51563 17.8469 2.33438 17.6656C2.15313 17.4844 2.0625 17.2625 2.0625 17V14.375C2.0625 14.1125 2.15313 13.8906 2.33438 13.7094C2.51563 13.5281 2.7375 13.4375 3 13.4375H17C17.2625 13.4375 17.4878 13.5281 17.676 13.7094C17.8642 13.8906 17.9583 14.1125 17.9583 14.375V17C17.9583 17.2625 17.8642 17.4844 17.676 17.6656C17.4878 17.8469 17.2625 17.9375 17 17.9375H3ZM3 12.25C2.7375 12.25 2.51563 12.1608 2.33438 11.9823C2.15313 11.8038 2.0625 11.5736 2.0625 11.2917V8.70835C2.0625 8.44585 2.15313 8.22051 2.33438 8.03231C2.51563 7.84412 2.7375 7.75002 3 7.75002H17C17.2625 7.75002 17.4878 7.84412 17.676 8.03231C17.8642 8.22051 17.9583 8.44585 17.9583 8.70835V11.2917C17.9583 11.5736 17.8642 11.8038 17.676 11.9823C17.4878 12.1608 17.2625 12.25 17 12.25H3ZM3 6.56252C2.7375 6.56252 2.51563 6.47181 2.33438 6.2904C2.15313 6.10896 2.0625 5.88023 2.0625 5.60419V3.00002C2.0625 2.73752 2.15313 2.51217 2.33438 2.32398C2.51563 2.13578 2.7375 2.04169 3 2.04169H17C17.2625 2.04169 17.4878 2.13578 17.676 2.32398C17.8642 2.51217 17.9583 2.73752 17.9583 3.00002V5.60419C17.9583 5.88023 17.8642 6.10896 17.676 6.2904C17.4878 6.47181 17.2625 6.56252 17 6.56252H3Z" fill="#393939"/>
                            </svg> :
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.72501 21.55H7.82501V16.15H2.45001V19.275C2.45001 19.905 2.67168 20.4417 3.11501 20.885C3.55835 21.3283 4.09501 21.55 4.72501 21.55ZM9.32501 21.55H14.7V16.15H9.32501V21.55ZM16.2 21.55H19.275C19.9119 21.55 20.4544 21.3283 20.9027 20.885C21.3509 20.4417 21.575 19.905 21.575 19.275V16.15H16.2V21.55ZM2.45001 14.65H7.82501V9.32499H2.45001V14.65ZM9.32501 14.65H14.7V9.32499H9.32501V14.65ZM16.2 14.65H21.575V9.32499H16.2V14.65ZM2.45001 7.82499H7.82501V2.42499H4.72501C4.09501 2.42499 3.55835 2.6491 3.11501 3.09734C2.67168 3.54555 2.45001 4.0881 2.45001 4.72499V7.82499ZM9.32501 7.82499H14.7V2.42499H9.32501V7.82499ZM16.2 7.82499H21.575V4.72499C21.575 4.0881 21.3509 3.54555 20.9027 3.09734C20.4544 2.6491 19.9119 2.42499 19.275 2.42499H16.2V7.82499Z" fill="#393939"></path>
                            </svg>
                        }
                    </a>
                </div>
                <div className="row g-20 ">
                    <div className={rowView ? 'col-lg-12' : `col-lg-4`}>
                        <Link to="/create-new-project" className="project-card add-new-project">
                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M39.9828 64.5832C38.9578 64.5832 38.079 64.2325 37.3464 63.5311C36.6137 62.8297 36.2474 61.9026 36.2474 60.7498V43.8332H19.1641C18.1432 43.8332 17.263 43.4565 16.5234 42.7032C15.7838 41.9499 15.4141 41.0527 15.4141 40.0115C15.4141 38.8926 15.7838 37.986 16.5234 37.2915C17.263 36.5971 18.1432 36.2498 19.1641 36.2498H36.2474V19.1665C36.2474 18.0679 36.6185 17.1682 37.3606 16.4675C38.1027 15.7668 38.986 15.4165 40.0106 15.4165C41.0351 15.4165 41.928 15.7668 42.6891 16.4675C43.4502 17.1682 43.8307 18.0679 43.8307 19.1665V36.2498H60.8307C61.8752 36.2498 62.7752 36.6019 63.5307 37.3061C64.2863 38.0103 64.6641 38.908 64.6641 39.9991C64.6641 41.0903 64.2863 42.0021 63.5307 42.7345C62.7752 43.467 61.8752 43.8332 60.8307 43.8332H43.8307V60.7498C43.8307 61.9026 43.4516 62.8297 42.6932 63.5311C41.9348 64.2325 41.0314 64.5832 39.9828 64.5832Z" fill="#FF8114"></path>
                            </svg>
                            <span className="mt-[10px]">Add Project</span>
                        </Link>
                    </div>
                    {
                        filteredProjects.map((item, index)=>{
                            return <div className={rowView ? 'col-lg-12' : `col-lg-4`} key={index}>
                                <div className="project-card cursor-pointer">
                                    <div className="project-card__title">
                                        { item.name }
                                    </div>
                                    <div className="project-card__desc">
                                        { item.description }
                                    </div>
                                    <div className="project-card__footer">
                                        <p>Last changes { timeAgo.format(new Date(item.last_changed)) }</p>
                                        <a href="" className="btn">
                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22.3956 38.6982C21.9594 38.297 21.7427 37.7804 21.7456 37.1482C21.7485 36.5161 21.9833 35.9667 22.45 35.5L31.7 26.25H9.84998C9.21581 26.25 8.68228 26.0386 8.24938 25.6158C7.81644 25.193 7.59998 24.643 7.59998 23.9658C7.59998 23.3219 7.81644 22.7833 8.24938 22.35C8.68228 21.9167 9.21581 21.7 9.84998 21.7H31.7L22.45 12.4C21.9833 11.9985 21.75 11.4732 21.75 10.8239C21.75 10.1746 21.9672 9.63334 22.4018 9.2C22.8696 8.76667 23.4113 8.55 24.0268 8.55C24.6422 8.55 25.1833 8.76667 25.65 9.2L38.85 22.4C39.0833 22.6 39.2583 22.8337 39.375 23.1011C39.4916 23.3685 39.55 23.6514 39.55 23.95C39.55 24.2833 39.4909 24.585 39.3727 24.855C39.2545 25.125 39.0803 25.3733 38.85 25.6L25.65 38.8C25.1818 39.2667 24.6398 39.4833 24.0239 39.45C23.4079 39.4167 22.8652 39.1661 22.3956 38.6982Z" fill="currentColor"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default MyProjects;