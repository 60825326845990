import React, { useEffect, useRef, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/routes.js";
import { GeneratorContextComponent } from "./routes/GeneratorV4/GeneratorContextComponent.jsx";
import { useDispatch, useSelector } from "react-redux";
import { getUserThunk } from "./redux/thunks/applicationThunks.js";
import { setAuthorized, setFormOptions, setSettings, setSocket, setToken, getFormOptions, getToken } from "./redux/slices/applicationSlice.js";
import api from "./api/index.js";
import { ToastContainer } from "react-toastify";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import Socket from "./helpers/socketClient.js";
import 'react-toastify/dist/ReactToastify.css';
import CopyToPromptDropdown from "./components/base/CopyToPromptDropdown";

function App() {
	const dispatch = useDispatch();
	// const [stripe, setStripe] = useState(null);
	const formOptions = useSelector(getFormOptions);
	const tokenFromStore = useSelector(getToken);

	useEffect(() => {
		api.settings.getSettings().then((response) => {
			if(!response.success) return;
			// setStripe(loadStripe(response.data.stripe_pk));
			dispatch(setSettings(response.data));
		}).catch(e => console.log(e));
		const token = localStorage.getItem('authToken');

		if(!token) {
			dispatch(setAuthorized(false));
			return;
		}

		dispatch(setToken(token));
		dispatch(getUserThunk({token}));
	}, [dispatch])

    useEffect(()=>{
        if(!formOptions?.length && tokenFromStore){
            api.generationForms.getGenerationForms({ token: tokenFromStore }).then((response) => {
                if(!response.success) return;
                dispatch(setFormOptions(response.data));	
            }).catch(e => console.log(e));
        }
    }, [tokenFromStore])

	return (
		<div className="App">
			<ToastContainer theme='dark'/>
			{/* <CopyToPromptDropdown /> */}
			<GeneratorContextComponent>
				{/* <Elements stripe={stripe}>
					<RouterProvider router={router} />
                </Elements> */}
				<RouterProvider router={router} />
			</GeneratorContextComponent>
		</div>
	);
}

export default App;
