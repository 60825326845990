import React, { useEffect, useRef, useState } from 'react';
import { SmallImageSwiper } from '../SmallImageSwiper';
import classNames from 'classnames';

const topicsList = [
    'Promoting Growth Mindset: Techniques and Activities',
    'Building Emotional Intelligence: The Educator’s Guide',
    'Mindfulness and Stress-Reduction: Classroom Practices',
    'Inclusive Language: Encouraging Diversity and Unity',
    'Role of Positive Reinforcement in Behavior Management',
    'Gratitude Journals: An In-Class Exercise',
    'Positive Affirmations for Students: A Resource Compilation',
    'Critical Thinking and Optimism: Bridging the Gap',
    'Teacher Self-Care: Sustaining Positivity in the Classroom',
    'Student-Centric Decision Making: Empowering Voices',
    'Building Resilience: Tools for Adversity',
    'Parental Engagement: Cultivating Home-to-School Positivity',
];

const sliderData = [
    {
        src: require('../../../assets/img/swiper/Global Management Principles.png'),
        title: 'Global Management Principles'
    },
    {
        src: require('../../../assets/img/swiper/Behavioral Biology Concepts.png'),
        title: 'Behavioral Biology Concepts'
    },
    {
        src: require('../../../assets/img/swiper/Design Thinking Basics.png'),
        title: 'Design Thinking Basics'
    },
    {
        src: require('../../../assets/img/swiper/JavaScript Fundamentals.png'),
        title: 'JavaScript Fundamentals'
    }
]

const CustomNewsletter = () => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(null);
    const [topicInputValue, setTopicInputValue] = useState('');
    const [openTopics, setOpenTopics] = useState(false);
    const [selectedTopics, setSelectedTopics] = useState([]);

    const topicsRef = useRef();
    useEffect(() => {
        if(openTopics) {
            topicsRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, [openTopics])
    return <div className='input-container w-100'>
        <label className="input-custom__label mb-[10px]">Do you want to learn more about</label>
        <SmallImageSwiper data={sliderData} activeSlideIndex={activeSlideIndex} setActiveSlideIndex={setActiveSlideIndex}/>
        <div className="or-row-my">
            <div className="or-row">
                <span className="or-text">
                    or choose own topic
                </span>
            </div>
        </div>
        <div className="input-container w-100">
            <label className="input-custom__label mb-[10px]">
                Newsletter Topic Description
            </label>
            <label className="widget-area">
                <textarea className="textarea" placeholder="Ex. Personal finances..." value={topicInputValue} onChange={(e) => setTopicInputValue(e.target.value)}></textarea>
                <div className="widget-area--actions">
                    <div className="widget-area--actions-container">
                        <button
                            disabled={!topicInputValue} 
                            className="btn w-full btn--primary-gradient btn-lg-2 
                            rounded-full font-[500] content-box-footer-save max-w-max"
                            onClick={() => {
                                setOpenTopics(true);
                            }}
                        >
                            <span className="text-13">Generate Topics</span>
                        </button>
                    </div>
                </div>
            </label>
            {openTopics && <div className="input-container w-100 pt-[16px]" ref={topicsRef}>
                <div className="input-container--heading">
                    <label className="input-custom__label mb-[10px]">
                        Newsletter Topics ({topicsList.length})
                    </label>
                    <button 
                        className={classNames("btn btn--outline-tansparent btn--sm r-300 w-[87px]", {
                            'selected-button': selectedTopics.length === topicsList.length
                        })}
                        onClick={() => {
                            setSelectedTopics(topics => topics.length === topicsList.length ? [] : topicsList)
                        }}
                    >
                        {selectedTopics.length === topicsList.length ? 
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M16.6666 5.41663L7.49992 14.5833L3.33325 10.4166" stroke="#FF8114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg> : <span className="text-13 font-[500] overflow-hidden whitespace-nowrap">
                                Select All
                            </span>
                        }
                    </button>
                </div>
                <ul className="topic-list mt-[10px]">
                    {topicsList.map(topic => <li className="topic-item">
                        <div className="topic">
                            <p 
                                className="topic-text text-14 flex-auto"
                                style={{
                                    ...(selectedTopics.includes(topic)) && {color: '#FF8114'}
                                }}
                            >
                                {topic}
                            </p>
                            <button
                                className={classNames("btn btn--outline-tansparent btn--sm r-300 w-[68px]", {
                                    'selected-button': selectedTopics.includes(topic)
                                })}
                                onClick={() => setSelectedTopics((prev) => {
                                    if(prev.includes(topic)) {
                                        return prev.filter((item) => item !== topic)
                                    } else {
                                        return [...prev, topic]
                                    }
                                })}
                            >
                                {selectedTopics.includes(topic) ? 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M16.6666 5.41663L7.49992 14.5833L3.33325 10.4166" stroke="#FF8114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg> : <span className="text-13 font-[500]">
                                    Select
                                    </span>
                                }
                            </button>
                        </div>
                    </li>)}
                </ul>
            </div>}
        </div>

</div>
};

export default CustomNewsletter;