const base = 'api/generation-forms';

export const generationForms = (instance) => ({
    async getGenerationForms(payload) {
        const { data } = await instance.get(base, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            },
        });
        return data;
    },
    async getProductPlatforms(payload) {
        const { data } = await instance.get(`${base}?keys=platforms&productId=${payload.productId}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            },
        });
        return data;
    },
})