import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import 'swiper/css';

import classNames from 'classnames';

export const SmallImageSwiper = ({data, activeSlideIndex, setActiveSlideIndex, size = 'md'}) => {
    let sliderId = Math.ceil(Math.random() * (9999 - 1) + 1);
    return (
        <div className='swiper-small'>
            
            <div className={`swiper-arrow-button swiper-arrow-button-next swiper-arrow-button-next-${sliderId}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                    <path d="M14 28L22 18L14 8" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <Swiper
                loop={false}
                spaceBetween={10}
                slidesPerView={"auto"}
                navigation={{
                    prevEl: null,
                    nextEl: `.swiper-arrow-button-next-${sliderId}`,
                    disabledClass: "swiper-button-disabled"
                }}
                modules={[Navigation]}
            >
                {data.map(({src, title}, index) => <SwiperSlide key={index}>
                    <div 
                        className={classNames('photo-card cursor-pointer', size, {
                            'photo-card--active': index === activeSlideIndex
                        })}
                        onClick={() => setActiveSlideIndex(index)}
                    >
                        <img src={src} alt={title} loading='lazy'/>
                        <div className="photo-card--content">
                            <p>{title}</p>
                        </div>
                    </div>
                </SwiperSlide>)}
            </Swiper>
        </div>
    )
}
