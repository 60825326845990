import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    items: [],
    activeItemIndex: 0
}

const etsyGeneratorSlice = createSlice({
    name: 'etsyGenerator',
    initialState,
    reducers: {
        setEtsyItemData: (state, action) => {
            state.items[state.activeItemIndex].data = action.payload;
        },
        setEtsyItems: (state, action) => {
            state.items = action.payload;
        },
        addWord: (state, action) => {
            const length = state.items.push(action.payload);
            state.activeWordIndex = length - 1;
        },
        addEtsyItem: (state, action) => {
            const newLen = state.items.push(action.payload);
            state.activeItemIndex = newLen - 1;
        },
        setitemsettings: (state, action) => {
            state.items[state.activeWordIndex].settings = action.payload;
        },
        setWordData: (state, action) => {
            // console.log(state, action)
            // if(!state.items[state.activeWordIndex]) {
            //     state.items[state.activeWordIndex] = {
            //         settings: {},
            //         data: action.payload
            //     }
            //     return;
            // }
            state.items[state.activeWordIndex].data = action.payload;
        },
        setEtsyActiveIndex: (state, action) => {
            state.activeItemIndex = action.payload;
        },
        setActiveVersionData: (state, action) => {
            state.items[state.activeWordIndex].data[action.payload.activeVersionIndex] = action.payload.data;
        },
        deleteEtsyItem: (state, action) => {
            state.items.splice(state.activeItemIndex, 1);
            state.activeItemIndex = 0;
        },
        copyEtsyItem: (state, action) => {
            state.items.push(state.items[state.activeItemIndex]);
        },
        setSelectedWordVersion: (state, action) => {
            state.items.forEach((word, index, array) => {
                word.selectedVersionId = action.payload
            })
        },
        addEtsyKeyword: (state, action) => {
            const  versions  = state.items[state.activeItemIndex];
            console.log(versions);
            state.items[state.activeItemIndex].settings.keywords = action.payload.keyword
        },
        resetEtsyGenerator: (state, action) => {
            state.items = [];
            state.activeItemIndex = 0
        },
    }
})


export const {
    setitemsettings, setWordData, setActiveWordIndex, 
    setActiveVersionData, addWord, deleteWordVersion, copyWordVersion, 
    setSelectedWordVersion, resetEtsyGenerator, setEtsyItemData, setEtsyItems, deleteEtsyItem,
    copyEtsyItem, setEtsyActiveIndex, addEtsyItem, addEtsyKeyword
} = etsyGeneratorSlice.actions

export const getGenerateditems = (state) => state.etsyGenerator.items;
export const getActiveWord = (state) => state.etsyGenerator.items[state.etsyGenerator.activeWordIndex];
export const getActiveWordIndex = (state) => state.etsyGenerator.activeWordIndex;
export const getEtsyState = (state) => state.etsyGenerator;

export default etsyGeneratorSlice.reducer;