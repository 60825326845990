const base = 'api/new-words';

export const newWords = (instance) => ({
    async createNewWord(payload) {
        const { data } = await instance.post(base, payload.body, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async getWords(payload) {
        const { data } = await instance.get(`${base}?${payload?.queries || ''}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async getWord(payload) {
        const { data } = await instance.get(`${base}/${payload.id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async updateNewWord(payload) {
        const { data } = await instance.put(`${base}/${payload.id}`, payload.body, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async deleteNewWord(payload) {
        const { data } = await instance.delete(`${base}/${payload.id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async likeNewWord(payload) {
        const { data } = await instance.put(`api/new-words-likes/${payload.id}`, null, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async exportNewWord(payload) {
        const response = await instance.get(`${base}/export/${payload.id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return response;
    },
})