import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    words: [],
    activeWordIndex: 0
}

const newWordGeneratorSlice = createSlice({
    name: 'newWordGenerator',
    initialState,
    reducers: {
        setWordState: (state, action) => {
            state.words = action.payload;
        },
        addWord: (state, action) => {
            const length = state.words.push(action.payload);
            state.activeWordIndex = length - 1;
        },
        setWordSettings: (state, action) => {
            state.words[state.activeWordIndex].settings = action.payload;
        },
        setWordData: (state, action) => {
            // console.log(state, action)
            // if(!state.words[state.activeWordIndex]) {
            //     state.words[state.activeWordIndex] = {
            //         settings: {},
            //         data: action.payload
            //     }
            //     return;
            // }
            state.words[state.activeWordIndex].data = action.payload;
        },
        setActiveWordIndex: (state, action) => {
            state.activeWordIndex = action.payload;
        },
        setActiveVersionData: (state, action) => {
            state.words[state.activeWordIndex].data[action.payload.activeVersionIndex] = action.payload.data;
        },
        deleteWordVersion: (state, action) => {
            state.words[state.activeWordIndex].data.splice(action.payload, 1);
            if(state.words[state.activeWordIndex].data.length === 0) {
                state.words.splice(state.activeWordIndex, 1); 
            }
        },
        copyWordVersion: (state, action) => {
            state.words[state.activeWordIndex].data.push(state.words[state.activeWordIndex].data[action.payload]);
        },
        setSelectedWordVersion: (state, action) => {
            state.words.forEach((word, index, array) => {
                word.selectedVersionId = action.payload
            })
        },
    }
})


export const {
    setWordState, setWordSettings, setWordData, setActiveWordIndex, setActiveVersionData, addWord, deleteWordVersion, copyWordVersion, setSelectedWordVersion
} = newWordGeneratorSlice.actions

export const getGeneratedWords = (state) => state.newWordGenerator.words;
export const getActiveWord = (state) => state.newWordGenerator.words[state.newWordGenerator.activeWordIndex];
export const getActiveWordIndex = (state) => state.newWordGenerator.activeWordIndex;

export default newWordGeneratorSlice.reducer;