import React from 'react'
import iconPerson from '../../assets/img/icons/icon-person.svg';
import iconEmail from '../../assets/img/icons/icon-email.svg';
import iconPasswordReset from '../../assets/img/icons/icon-password-reset.svg';

function SettingsReferal() {
    return (
        <div className="tabs-content flex-auto mb-[20px]">
            <div className="container-820">
                <div className="row mb-[-20px]">
                    <div className="col-xl-6 col-lg-12 mb-[20px]">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">
                                First Name
                            </label>
                            <div className="input-custom__wrap-input icon-left">
                                <div className="icon-input">
                                    <img src={iconPerson}/>
                                </div>
                                <input className="input-custom__input" type="text" placeholder="Current Password"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 mb-[20px]">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">
                                Last Name
                            </label>
                            <div className="input-custom__wrap-input icon-left">
                                <div className="icon-input">
                                    <img src={iconPerson}/>
                                </div>
                                <input className="input-custom__input" type="text" placeholder="New Password"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 mb-[20px]">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">
                                Email
                            </label>
                            <div className="input-custom__wrap-input icon-left">
                                <div className="icon-input">
                                    <img src={iconEmail}/>
                                </div>
                                <input className="input-custom__input" type="text" placeholder="Email"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 mb-[20px]">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">
                                <span>Password</span> &nbsp;
                                <span className="color-primary-10 text-[12px]">
                                    (Enter 6 word password)
                                </span>
                            </label>
                            <div className="input-custom__wrap-input icon-left">
                                <div className="icon-input">
                                    <img src={iconPasswordReset}/>
                                </div>
                                <input className="input-custom__input" type="password" placeholder="**************"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 mb-[20px]">
                        <button className="btn btn-primary-10 btn-xl rounded-[360px] 
                            text-[16px] 
                            font-[400] min-h-[56px]">
                            Sign up and get referral link
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsReferal