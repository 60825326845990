import { startOfToday, startOfYesterday } from "date-fns";
import History from "./History";
import Settings from "./Settings";
import Uploads from "./Uploads";
import Testing from "./Testing";

export const navLinks = [
    {
        title: 'History',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M9.85156 15H14.1516" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 11.75H16" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.85156 8.5H14.1516" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21.0018 11.791C21.0018 16.33 16.9538 19.973 12.0018 19.973C11.3608 19.973 10.7368 19.91 10.1328 19.794" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.599 18.326C4.42 16.836 3 14.474 3 11.791" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.6012 18.326C6.6002 19.18 6.6022 20.332 6.6022 21.541" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 11.791C3 7.25199 7.048 3.60999 12 3.60999C16.952 3.60999 21 7.25299 21 11.792" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.1316 19.79L6.60156 21.54" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        Component: History
    },
    {
        title: 'Settings',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M13.9061 10.5908C14.9605 11.6452 14.9605 13.3548 13.9061 14.4092C12.8517 15.4636 11.1421 15.4636 10.0877 14.4092C9.03327 13.3548 9.03327 11.6452 10.0877 10.5908C11.1421 9.53638 12.8517 9.53638 13.9061 10.5908" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.25231 12.5C5.25231 12.797 5.27931 13.094 5.31531 13.382L3.72731 14.624C3.37531 14.9 3.27931 15.393 3.50331 15.78L4.91531 18.223C5.13831 18.61 5.61231 18.773 6.02731 18.607L7.44931 18.036C7.73031 17.923 8.04331 17.968 8.29531 18.135C8.51531 18.281 8.74331 18.415 8.97931 18.535C9.24931 18.672 9.44531 18.917 9.48831 19.217L9.70531 20.73C9.76831 21.172 10.1473 21.5 10.5933 21.5H13.4103C13.8563 21.5 14.2353 21.172 14.2983 20.73L14.5153 19.218C14.5583 18.918 14.7563 18.671 15.0273 18.535C15.2623 18.417 15.4893 18.284 15.7083 18.139C15.9623 17.971 16.2763 17.923 16.5583 18.037L17.9773 18.607C18.3913 18.773 18.8653 18.61 19.0893 18.223L20.5013 15.78C20.7253 15.393 20.6293 14.899 20.2773 14.624L18.6893 13.382C18.7253 13.094 18.7523 12.797 18.7523 12.5C18.7523 12.203 18.7253 11.906 18.6893 11.618L20.2773 10.376C20.6293 10.1 20.7253 9.607 20.5013 9.22L19.0893 6.777C18.8663 6.39 18.3923 6.227 17.9773 6.393L16.5583 6.963C16.2763 7.076 15.9623 7.029 15.7083 6.861C15.4893 6.716 15.2623 6.583 15.0273 6.465C14.7563 6.329 14.5583 6.082 14.5153 5.782L14.2993 4.27C14.2363 3.828 13.8573 3.5 13.4113 3.5H10.5943C10.1483 3.5 9.76931 3.828 9.70631 4.27L9.48831 5.784C9.44531 6.083 9.24831 6.329 8.97931 6.466C8.74331 6.586 8.51531 6.721 8.29531 6.866C8.04231 7.032 7.72931 7.077 7.44831 6.964L6.02731 6.393C5.61231 6.227 5.13831 6.39 4.91531 6.777L3.50331 9.22C3.27931 9.607 3.37531 10.101 3.72731 10.376L5.31531 11.618C5.27931 11.906 5.25231 12.203 5.25231 12.5V12.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        Component: Settings
    },
    // {
    //     title: 'Uploads',
    //     icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    //         <path d="M15 15.5L12 12.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M12 12.5L9 15.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M12 20.5V12.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M8 19.5H6C3.79086 19.5 2 17.7091 2 15.5C2 13.2909 3.79086 11.5 6 11.5L5.99 11.4296C5.74111 9.68739 6.26051 7.92287 7.41362 6.59331C8.56673 5.26375 10.2401 4.50001 12 4.5C13.7599 4.50001 15.4333 5.26375 16.5864 6.59331C17.7395 7.92287 18.2589 9.68739 18.01 11.4296L18 11.5C20.2091 11.5 22 13.2909 22 15.5C22 17.7091 20.2091 19.5 18 19.5H16" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //     </svg>,
    //     Component: Uploads
    // },
    {
        title: 'Testing',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M18.9989 8.49818V5.49693C18.9989 4.3919 18.1031 3.49609 16.998 3.49609H4.99302C3.88799 3.49609 2.99219 4.3919 2.99219 5.49693V18.5023" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.9924 3.49609V16.5015" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.9955 16.502H4.99302C3.88799 16.502 2.99219 17.3978 2.99219 18.5028V18.5028C2.99219 19.6078 3.88799 20.5036 4.99302 20.5036H9.9951" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.8534 11.4992H15.3516" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.1033 11.5V15.2836L21.6974 19.4453C22.0816 20.0616 22.1017 20.8376 21.7499 21.4729C21.3982 22.1082 20.7299 22.503 20.0037 22.5046H15.2067C14.4793 22.5056 13.8086 22.1117 13.4552 21.4759C13.1017 20.8401 13.1212 20.0626 13.506 19.4453L16.1001 15.2836V11.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.2343 15.5012H15.9609" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        Component: Testing
    },
    // {
    //     title: 'Tutorials',
    //     icon: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    //         <path d="M11 21.5037H15.0017" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M11 3.49624H15.0017" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M3.9924 10.4991V14.5008" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M22.0002 10.4991V14.5008" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M7.99385 21.5037V21.5037C5.78379 21.5037 3.99219 19.7121 3.99219 17.5021V17.5021" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M7.99385 21.5036C5.78379 21.5036 3.99219 19.712 3.99219 17.502" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M18 3.49615C20.2101 3.49615 22.0017 5.28776 22.0017 7.49782" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M3.99219 7.49782C3.99219 5.28776 5.78379 3.49615 7.99385 3.49615" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M22.0017 17.502C22.0017 19.712 20.2101 21.5036 18 21.5036" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M13.0008 14.0006V12.5C14.1059 12.5 15.0017 11.6042 15.0017 10.4992C15.0017 9.39416 14.1059 8.49835 13.0008 8.49835C11.8958 8.49835 11 9.39416 11 10.4992" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M13.0002 16.4517C12.9737 16.4511 12.9518 16.4721 12.9512 16.4986V16.5017C12.9512 16.5215 12.9631 16.5394 12.9814 16.547C12.9998 16.5546 13.0209 16.5504 13.0349 16.5364C13.0489 16.5223 13.0531 16.5012 13.0455 16.4829C13.0379 16.4646 13.02 16.4527 13.0002 16.4527H12.9992" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M13.0502 16.5017C13.0502 16.5293 13.0278 16.5517 13.0002 16.5517C12.9726 16.5517 12.9502 16.5293 12.9502 16.5017C12.9502 16.474 12.9726 16.4517 13.0002 16.4517" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //         <path d="M12.9986 16.4516C13.0263 16.4516 13.0487 16.474 13.0487 16.5017" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    //     </svg>,
    //     Component: null
    // }
]

export const chats = [
    {
        id: 1,
        title: 'Ideas for Positive Content',
        updateTime: new Date(),
        content: ''
    }, {
        id: 2,
        title: 'Kitten Menu for 6-Month-Old',
        updateTime: startOfYesterday(),
        content: ''
    }, {
        id: 3,
        title: 'Task Analysis and Recommendations',
        updateTime: startOfYesterday(),
        content: ''
    }, {
        id: 4,
        title: 'Create Custom Privacy Policy',
        updateTime: startOfYesterday(),
        content: ''
    }, {
        id: 5,
        title: 'Compare 10K+ Social Websites',
        updateTime: startOfYesterday(),
        content: ''
    }, {
        id: 6,
        title: 'Boost Your Social Presence',
        updateTime: startOfYesterday(),
        content: ''
    }, {
        id: 7,
        title: 'Python Programming Module',
        updateTime: new Date('2023-09-30'),
        content: ''
    }, {
        id: 8,
        title: 'Self-Help Cource',
        updateTime: new Date('2023-09-30'),
        content: ''
    }, {
        id: 9,
        title: 'NFT Roadmap Outline',
        updateTime: new Date('2023-09-30'),
        content: ''
    }, {
        id: 10,
        title: 'Task Analysis and Recommendations',
        updateTime: new Date('2023-09-30'),
        content: ''
    }, {
        id: 11,
        title: 'Create Custom Privacy Policy',
        updateTime: new Date('2023-09-30'),
        content: ''
    }, {
        id: 12,
        title: 'Ideas for Positive Content',
        updateTime: new Date('2023-09-30'),
        content: ''
    }, {
        id: 13,
        title: 'Mindfulness and Stress-Reduction: Classroom Practices',
        updateTime: new Date('2023-09-30'),
        content: ''
    }, 
]