import React, { useState } from 'react';
import SelectBox from '../../components/base/SelectBox';
import Label from '../../components/base/Label';
import Tippy from '@tippyjs/react';
import BotRoleSelect from '../GeneratorV4/BotRoleSelect';
import { botToneOptions } from '../../configs/selectOptions';
import { useSelector } from 'react-redux';
import { getSocket, getToken } from '../../redux/slices/applicationSlice';
import { ReactComponent as BotIcon } from '../../assets/img/icons/icon-bot.svg';
import { toast } from 'react-toastify';

const Testing = ({botKit, setBotKit}) => {
    const token = useSelector(getToken);
    const socket = useSelector(getSocket);
    const [formValues, setFormValues] = useState({
        roles: [],
        bot_tone: 'Balanced'
    });
    const [roleSelectOpened, setRoleSelectOpened] = useState(false);
    const [message, setMessage] = useState(null);
    console.log(message);
    
    const [inputValue, setInputValue] = useState('');
    const handleSendMessage = () => {
        setInputValue('');
        socket.send({
            event: 'chat-bot-local',
            payload: {
                messages: false,
                settings: {
                    brand_tone: botKit.brand_tone,
                    response_length: botKit.response_length,
                    multilang_enabled: botKit.multilang_enabled,
                    language: botKit.language,
                    imported_data: botKit.imported_data,
                    include_citatiaons: botKit.include_citatiaons,
                    ...formValues
                },
                messageText: inputValue
            },
            handler: (response) => {
                if(response?.isError){
                    toast.error("AI bot is not available at the moment");
                    return;
                }

                setMessage(response.messages[2].contents[0].content);
            }
        }); 
    }
    return <div className='py-[13px] px-[16px] flex-1 flex flex-col overflow-auto'>
        <h2 className='text-[14px] font-medium leading-[14px]'>Testing</h2>
        <p className='text-[12px] leading-[14px] opacity-40 mt-[6px]'>Experiment with queries and chatbot's reactions.</p>
        <div className='flex flex-col gap-[16px] flex-1 mt-[20px] overflow-y-scroll no-scroll'>
                <div className='flex flex-col gap-[10px]'>
                    <Label>Acting as / Role</Label>
                    <Tippy
                        // ref={roleSelectRef}
                        offset={[0,4]}
                        arrow={false}
                        className='generator-button-tooltip-modal w-[318px] max-h-[516px] overflow-auto no-scroll'
                        placement='bottom-start'
                        visible={roleSelectOpened}
                        content={<BotRoleSelect
                            value={formValues.roles}
                            change={(roles) => setFormValues({
                                ...formValues,
                                roles
                            })}
                        />}
                        // onClickOutside={() => setRoleSelectOpened(!roleSelectOpened)}
                    >
                        <button 
                            className='h-[40px] py-[8px] pl-[14px] pr-[8px] flex items-center justify-between
                            bg-[#1B1B1B] rounded-[8px] border border-solid border-[#333]'
                            onClick={() => setRoleSelectOpened(!roleSelectOpened)}
                        >
                            <span className='text-[14px] text-[#ffffffe6]'>Select bot role</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <g opacity="0.9">
                                    <path d="M9.37513 12.292L6.70847 9.64616C6.40291 9.32671 6.3352 8.97949 6.50534 8.60449C6.67548 8.22949 6.97236 8.04199 7.39597 8.04199H12.6668C13.0765 8.04199 13.3665 8.22949 13.5366 8.60449C13.7067 8.97949 13.639 9.32671 13.3335 9.64616L10.6876 12.292C10.5974 12.3753 10.4949 12.4378 10.3803 12.4795C10.2658 12.5212 10.146 12.542 10.021 12.542C9.88208 12.542 9.75881 12.5212 9.65117 12.4795C9.54354 12.4378 9.45152 12.3753 9.37513 12.292Z" fill="white"/>
                                </g>
                            </svg>
                        </button>
                    </Tippy>
                </div>
                <div className='flex flex-col gap-[10px]'>
                    <Label>Bot Tone</Label>
                    <SelectBox
                        className='default-select flex-1'
                        propsOptions={botToneOptions}
                        value={formValues.bot_tone}
                        change={(bot_tone) => setFormValues({
                            ...formValues,
                            bot_tone
                        })}
                    />
                </div>
            {message && <>
                <div className='w-full h-[1px] bg-[#1F1F1F]'/>
                <div className='chat-bot-card bg-[#151515]'>
                    <div className='chat-bot-button chat-bot-user-img'>
                        <BotIcon className='w-[20px] h-[20px]'/>
                    </div>
                    <p className='chat-bot-text whitespace-pre-wrap'>
                        {message}
                    </p>
                </div>
            </>}
        </div>
        <div className='chat-bot-input-container'>
            <input
                className='chat-bot-input'
                placeholder='Send a message...'
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => {
                    if(e.key === 'Enter' && inputValue) handleSendMessage();
                }}
            />
            <button
                disabled={!inputValue}
                className='disabled:opacity-30 ml-[10px]'
                onClick={handleSendMessage}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4814 18.7097L21.4684 12.8777C22.1774 12.5017 22.1774 11.4897 21.4684 11.1127L10.4974 5.28969C9.66943 4.84969 8.74143 5.67969 9.09243 6.54569L11.2814 11.9457L9.07443 17.4557C8.72843 18.3227 9.65543 19.1477 10.4814 18.7097Z" stroke="white" stroke-width="1.5036" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.2812 11.9496L22.0013 11.9996" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4 15H5.6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3 12H5.6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2 9H5.6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
    </div>
};

export default Testing;