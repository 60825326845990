import React, { useState } from 'react';
import { cardsContent } from "../../data";
const content = [...cardsContent];

const WantToCreate = () => {
    return (
        <section className="page-section">
            <div className="container px-[20px]">
                <div className="border rounded-[16px]">
                    <div className="row-heading row-heading--lighten-60 rounded-[16px] px-[20px]">
                        <div className="heading-item py-[10px]">
                            <div className="title-24 font-[800]">
                                Text Generator
                            </div>
                            <div className="text-2">
                                <p>Generate captivating content</p>
                            </div>
                        </div>
                    </div>

                    <div className="p-[20px] pb-[10px]">
                        <h3 className="color-secondary-lighten-60 font-[500] mb-[25px]">What Do You Want to Create?</h3>

                        <ul className="text-generator-list">
                            {
                                content.map((item, index)=>{
                                    return <li key={index}>
                                        <a href="#" className="btn text-generator-item">{ item.title }</a>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WantToCreate;