import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../../api';

export const generateText = createAsyncThunk(
    '/generate-text/',
    async (payload, { rejectWithValue }) => {
        console.log('generateText createAsyncThunk');
        try {
            const res = await api.openai.generateText(payload);
            return res
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);

export const editText = createAsyncThunk(
    '/edit-text/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.openai.editText(payload);
            return res
        } catch (err) {
            return rejectWithValue(err?.response.data);
        }
    },
);