const base = 'api/bot-chat-messages';

export const botChatMessages = (instance) => ({
    async getMessages(payload) {
        const { data } = await instance.get(`${base}/messages/${payload.chatId}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            },
        });
        return data;
    },
    async activateMessage(payload) {
        const { data } = await instance.get(`${base}/activate/${payload.messageId}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            },
        });
        return data;
    },
    async createFeedbackChatMessages(payload) {
        const { data } = await instance.post(`/api/feedback-chat-messages`, payload.data, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            },
        });
        return data;
    },
})