import React, { useMemo, useState } from 'react';
import {useDropzone} from 'react-dropzone';
import FileDropzone from '../../components/base/FileDropzone';

export function bytesToSize(bytes) {
    const sizes = ['B', 'KB', 'MB']
    if (bytes === 0) return 'n/a'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
    if (i === 0) return `${bytes} ${sizes[i]}`
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}

const initialFiles = [
    {
        name: 'Custom File Name 1.pdf',
        size: '10MB'
    }, {
        name: 'Custom File Name 5.doc',
        size: '10MB'
    }
]

const exrensionIcons = {
    'pdf': <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4856 7.483L18.1863 4.18367C17.7488 3.74617 17.1549 3.5 16.5366 3.5H8.16927C6.8801 3.5 5.83594 4.54417 5.83594 5.83333V22.1667C5.83594 23.4558 6.8801 24.5 8.16927 24.5H19.8359C21.1251 24.5 22.1693 23.4558 22.1693 22.1667V9.13267C22.1693 8.51433 21.9231 7.9205 21.4856 7.483V7.483Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.1693 9.33333H17.5026C16.8586 9.33333 16.3359 8.81067 16.3359 8.16667V3.5" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.0625 19.3083H15.9358" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6406 14.8762V13.5135C12.6406 13.1379 12.9451 12.8334 13.3208 12.8334H14.6835C15.0591 12.8334 15.3636 13.1379 15.3636 13.5135V14.8762C15.3636 15.2519 15.0591 15.5564 14.6835 15.5564H13.3208C12.9451 15.5552 12.6406 15.2507 12.6406 14.8762Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.33594 20.3198V18.9571C9.33594 18.5815 9.64044 18.277 10.0161 18.277H11.3788C11.7544 18.277 12.0589 18.5815 12.0589 18.9571V20.3198C12.0589 20.6955 11.7544 21 11.3788 21H10.0161C9.64044 21 9.33594 20.6955 9.33594 20.3198Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9453 20.3198V18.9571C15.9453 18.5815 16.2498 18.277 16.6255 18.277H17.9881C18.3638 18.277 18.6683 18.5815 18.6683 18.9571V20.3198C18.6671 20.6955 18.3626 21 17.987 21H16.6243C16.2498 21 15.9453 20.6955 15.9453 20.3198H15.9453Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.6132 18.2817L14.8516 15.5283" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.3906 18.2817L13.1523 15.5283" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>,
    'doc' : <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4856 7.483L18.1863 4.18367C17.7488 3.74617 17.1549 3.5 16.5366 3.5H8.16927C6.8801 3.5 5.83594 4.54417 5.83594 5.83333V22.1667C5.83594 23.4558 6.8801 24.5 8.16927 24.5H19.8359C21.1251 24.5 22.1693 23.4558 22.1693 22.1667V9.13267C22.1693 8.51433 21.9231 7.9205 21.4856 7.483V7.483Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.1693 9.33333H17.5026C16.8586 9.33333 16.3359 8.81067 16.3359 8.16667V3.5" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.33594 12.8333H16.3359" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.33594 16.3333H16.3359" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.33594 19.8333H14.3876" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>,
    'docx': <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4856 7.483L18.1863 4.18367C17.7488 3.74617 17.1549 3.5 16.5366 3.5H8.16927C6.8801 3.5 5.83594 4.54417 5.83594 5.83333V22.1667C5.83594 23.4558 6.8801 24.5 8.16927 24.5H19.8359C21.1251 24.5 22.1693 23.4558 22.1693 22.1667V9.13267C22.1693 8.51433 21.9231 7.9205 21.4856 7.483V7.483Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.1693 9.33333H17.5026C16.8586 9.33333 16.3359 8.81067 16.3359 8.16667V3.5" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.33594 12.8333H16.3359" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.33594 16.3333H16.3359" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.33594 19.8333H14.3876" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
}

const Uploads = () => {
    const [displayFiles, setDisplayFiles] = useState(initialFiles);
    return <div className='py-[13px] px-[16px] flex-1 flex flex-col overflow-auto'>
        <h2 className='text-[14px] font-medium leading-[14px]'>Uploads</h2>
        <p className='text-[12px] leading-[14px] opacity-40 mt-[6px]'>Add your files to talk with them</p>
        <div className='flex-auto mt-[20px]'>
            <FileDropzone 
                options={{
                    maxSize: Math.pow(10, 7),
                    accept: {
                        'application/pdf': [],
                        'application/msword': [],
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
                        'image/jpeg': []
                    },
                    onDropAccepted: (files) => {
                        console.log(files);
                        const formatFiles = files.map(file => ({
                            name: file.name,
                            size: bytesToSize(file.size)
                        }))
                        setDisplayFiles([...displayFiles, ...formatFiles]);
                    }
                }}
            />
            <ul className='flex flex-col gap-[8px] mt-[16px]'>
                {displayFiles.map(({name, size}, index) => <li
                    key={index}
                    className='p-[16px] flex justify-between items-center bg-[#202020] rounded-[8px]'
                >
                    <div className='flex gap-[16px] items-center flex-1'>
                        {exrensionIcons[name.split('.')[1]]}
                        <div className='flex-1'>
                            <p className='text-[13px] opacity-90 line-clamp-1'>{name}</p>
                            <p className='text-[12px] opacity-50 font-light'>{size}</p>
                        </div>
                    </div>
                    <svg 
                        onClick={() => setDisplayFiles(prev => {
                            const result = [...prev];
                            result.splice(index, 1);
                            return result;
                        })} 
                        cursor="pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g opacity="0.5">
                            <path d="M15 5L5 15" stroke="#A7A7A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5 5L15 15" stroke="#A7A7A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                    </svg>
                </li>)}
            </ul>
        </div>
        <button className='generated-button btn' onClick={() => {}}>
            Submit
        </button>
    </div>
};

export default Uploads;