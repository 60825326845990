const EtsyCardContent = ({ data }) => {
    return <>
        <div className='w-full max-w-[882px] mx-auto py-[24px] px-[16px] pb-[15px] lg:pb-[75px] flex flex-col gap-[16px] h-[304px] overflow-y-scroll no-scroll flex-1'>
            {data?.title &&
                <div className={`editor-custom-container`}>
                    <p className='text-[12px] lg:text-[14px]font-semibold leading-[18px] lg:leading-[24px] opacity-90'>
                    Title: 
                    <span className='text-[#A7A7A7] ml-[5px]  font-normal'>{data?.title}</span>
                    </p>
                </div>
            }

            {data?.description && 
                <div className={`editor-custom-container`}>
                    <p className='text-[12px] lg:text-[14px]font-semibold leading-[18px] lg:leading-[24px] opacity-90'>
                    Description: 
                    <span className='text-[#A7A7A7] ml-[5px]  font-normal'>{data?.description}</span>
                    </p>
                </div>
            }

            {(data?.hash_tags && data?.hash_tags.length > 0) && 
                <div className={`editor-custom-container`}>
                    <p className='text-[12px] lg:text-[14px] font-semibold leading-[18px] lg:leading-[24px] opacity-90'>
                    Hashtags: 
                    <>{data?.hash_tags && data?.hash_tags?.map((tag, idx) => <div key={idx} className='text-[#A7A7A7] font-normal'>{tag}</div>)}</>
                    </p>
                </div>
            }

            { data?.research && 
                <div className={`editor-custom-container`}>
                    <p className='text-[12px] lg:text-[14px] font-semibold leading-[18px] lg:leading-[24px] opacity-90'>
                    Research: 
                    <span className='text-[#A7A7A7] ml-[5px]  font-normal'>{data?.research}</span>
                    </p>
                </div>
            }
        </div>
    </>
};

export default EtsyCardContent;