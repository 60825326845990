const base = 'api/reports';

export const reports = (instance) => ({
    async createReport(payload) {
        const { data } = await instance.postForm(base, payload.data, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            },
            formSerializer: {
                indexes: null
            },
        });
        return data;
    },
})