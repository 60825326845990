import React, { useEffect, useRef, useState } from 'react';
import Logo from "../../assets/img/gen4logo.svg";
import { NavLink, useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import classNames from 'classnames';
import { kitDetailsContent } from "./data"

const cardsContentData = [
    {title: 'Blog Kit', description: 'Information about your blog content', param: 'blog'}, 
    {title: 'Lesson Kit', description: 'Create your own email educational tutor', param: 'lesson-kit'},  
    // {title: 'Email Educator Old', description: 'Create your own email educational tutor', param: 'email-old'},  
    {title: 'Goal Realization Kit', description: 'Schedule your goal to achieve it', param: 'goal'},
    {title: 'New Word Kit', description: 'New word creation according to your description', param: 'new-word'},
    {title: 'Landing Page Kit', description: 'Landing Page Generator', param: 'landing-page'},
    {title: 'Entire Website Kit', description: 'Entire Website Generator', param: 'entire-website'},
    {title: 'Bots Kit', description: 'Construct a personalized bot to suit your specific requirements.', param: 'bot'},
    {title: 'General Kit', description: 'General tool with ability to state what type of product u want written as a fill in the blank', param: 'general'},
    {title: 'Essay', description: 'Create an essay', param: 'essay'},
    {title: 'Etsy Listing', description: 'Create an etsy listing', param: 'etsy-listing'},
    {title: 'Product Description', description: 'Describe your own product', param: 'product-description'},
    {title: 'Meta Tags', description: 'Meta Tag Generator', param: 'meta-tags'},
    {title: 'Prompt Assistant', description: 'Create your own prompt', param: 'prompt-assistant'},
    {title: 'Press Release', description: 'Describe your press release', param: 'press-release'},
    {title: 'Social Media Campaign', description: 'Create your Social Media Campaign', param: 'social-media-campaign'},
]

const KitDetails = ({}) => {

    const { kit } = useParams();

    const activeCard = cardsContentData.find(({param}) => param === kit);

    console.log('kit:', kit);

    return <>
        <div className="gen4-main-block" style={{ transform: "none" }}>
            <div className="gen4-header">
                <div className='gen4-header-part'>
                    <div className='mr-[16px]'>
                        <img src={Logo} alt=""/>
                    </div>
                    <div className='gen4-header-select-container'>
                        <h3>
                            {activeCard?.title}
                        </h3>
                        {/* <p className="mt-[8px]">Info page</p> */}
                    </div>
                </div>

                <div className="gen4-header-part">
                    <div className="d-flex align-items-center gap-[8px]">
                        <NavLink to={`/generated-content?type=${kit}`} className="goal-generator-button generator-form-button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path stroke="#767676" d="M5 12.5H19" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path stroke="#767676" d="M10 7.5L5 12.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path stroke="#767676" d="M10 17.5L5 12.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div
                className='py-[15px] px-[30px] kit-details-page-content'
                style={{ maxHeight: "calc(100dvh - 50px)", overflowY: "auto" }}
                dangerouslySetInnerHTML={{ __html: kitDetailsContent[kit] ? kitDetailsContent[kit] : 'In Dev' }}
            >
            </div>
        </div>
    </>
}

export default KitDetails;