import classNames from 'classnames';
import React from 'react';

const Input = ({ className, ...props }) => {
    return <input
        className={classNames('primary-input', className)}
        {...props}
    />
};

export default Input;