import React, { useState } from 'react';
import api from '../../api';
import { useSelector } from 'react-redux';
import { getToken } from '../../redux/slices/applicationSlice';
import Input from '../../components/base/Input';
import { brandIcons } from './AddCardModal';
import PrimaryButton from '../../components/base/PrimaryButton';
import { toast } from 'react-toastify';
import ReactInputMask from 'react-input-mask';

const EditCardModal = ({ close, card, setCards }) => {
    const token = useSelector(getToken);
    console.log(card);
    
    const [inputValues,setInputValues] = useState({
        expMonth: String(card.exp_month),
        expYear: String(card.exp_year),
        cardNumber: '',
        cardCode: '',
        
        firstName: card?.firstName || '',
        lastName: card?.lastName || '',
        city: card?.city || '',
        state: card?.state || '',
        address: card?.address || '',
        zip: card?.zip || '',
        country: card?.country || '',
    })

    const [isLoading, setIsLoading] = useState(false);
    const handleEditCard = async () => {
        if(!inputValues.firstName) {
            toast.error('First Name is requiered');
            return;
        }
        if(!inputValues.lastName) {
            toast.error('Last Name is requiered');
            return;
        }
        if(!inputValues.address) {
            toast.error('Address is requiered');
            return;
        }
        if(!inputValues.city) {
            toast.error('City is requiered');
            return;
        }
        if(!inputValues.state) {
            toast.error('State is requiered');
            return;
        }
        if(!inputValues.zip) {
            toast.error('zip is requiered');
            return;
        }
        if(!inputValues.country) {
            toast.error('Country is requiered');
            return;
        }

        if(inputValues.cardNumber.length < 13) {
            toast.error('Enter a valid credit card number');
            return;
        }
        if(inputValues.expMonth.length < 2) {
            toast.error('Enter a valid expiry month');
            return;
        }
        if(inputValues.expYear.length < 2) {
            toast.error('Enter a valid expiry year');
            return;
        }
        if(inputValues.cardCode.length < 3) {
            toast.error('Enter a valid CVV');
            return;
        }

        setIsLoading(true);
        try {
            const toastId = toast.loading("Editing card...");
            const response = await api.payAuthorize.editCard({
                id: card.id,
                data: inputValues,
                token
            });
            console.log(response);
            toast.update(toastId, {
                render: response.success ? 'Updated successfully!' : String(response.message),
                type: response.success ? 'success' : 'error', 
                isLoading: false,
                autoClose: 5000
            });
            if(!response.success) return;
            close();
            api.paymentCards.getCards({token}).then(response => {
                if(!response.success) return;
                setCards(response.data);
            })
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }
    const CardIcon = brandIcons[card.brand];
    return <div className='modal-content relative p-[36px]'>
        <button 
            className="z-10 absolute top-[16px] right-[16px] w-[34px] h-[34px] flex items-center justify-center
            bg-[#1B1B1B] rounded-[8px] border border-solid border-[#333]"
            onClick={close}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g opacity="0.5">
                    <path d="M15 5L5 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M5 5L15 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </svg>
        </button>
        <div className='flex flex-col gap-[36px]'>
            <div>
                <h2 className='modal-body-title' >Edit Card</h2>
                <span className='modal-body-subtitle' >Please fill in form below to edit card</span>
                <div className='py-[8px] flex items-center gap-[12px] '>
                    <CardIcon className='w-[28px] h-[20px]'/>
                    <span className='text-[14px] opacity-90'>•••• •••• •••• {card.last4}</span>
                </div>
            </div>
            <div className='flex flex-col gap-[16px]'>
            <div className="d-flex items-center gap-[16px]">
                    <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>First Name</p>
                        <Input
                            className="min-h-[40px]"
                            placeholder='John'
                            value={inputValues.firstName}
                            onChange={(e) => setInputValues({...inputValues, firstName: e.target.value})}
                            autoComplete='cc-name'
                        />
                    </div>
                    <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Last Name</p>
                        <Input
                            className="min-h-[40px]"
                            placeholder='Smith'
                            value={inputValues.lastName}
                            onChange={(e) => setInputValues({...inputValues, lastName: e.target.value})}
                            autoComplete='cc-name'
                        />
                    </div>
                </div>
                <div className="d-flex items-center gap-[16px]">
                    <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>City</p>
                        <Input
                            className="min-h-[40px]"
                            placeholder='City'
                            value={inputValues.city}
                            onChange={(e) => setInputValues({...inputValues, city: e.target.value})}
                            // autoComplete='cc-name'
                        />
                    </div>
                    <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>State</p>
                        <Input
                            className="min-h-[40px]"
                            placeholder='State'
                            value={inputValues.state}
                            onChange={(e) => setInputValues({...inputValues, state: e.target.value})}
                            // autoComplete='cc-name'
                        />
                    </div>
                </div>
                <div className='flex-1 flex flex-col gap-[8px]'>
                    <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Address</p>
                    <Input
                        className="min-h-[40px]"
                        placeholder='Address'
                        value={inputValues.address}
                        onChange={(e) => setInputValues({...inputValues, address: e.target.value})}
                        // autoComplete='cc-name'
                    />
                </div>
                <div className="d-flex items-center gap-[16px]">
                    <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>ZIP</p>
                        <Input
                            className="min-h-[40px]"
                            placeholder='zip'
                            value={inputValues.zip}
                            onChange={(e) => setInputValues({...inputValues, zip: e.target.value})}
                            // autoComplete='cc-name'
                        />
                    </div>
                    <div className='flex-1 flex flex-col gap-[8px]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Country</p>
                        <Input
                            className="min-h-[40px]"
                            placeholder='Country'
                            value={inputValues.country}
                            onChange={(e) => setInputValues({...inputValues, country: e.target.value})}
                            // autoComplete='cc-name'
                        />
                    </div>
                </div>
                
                <hr style={{ backgroundColor: '#333', height: '1px' }} />

                <div className='flex-1 flex flex-col gap-[8px]'>
                    <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Card Number</p>
                    <div className='py-[8px] pl-[12px] pr-[8px] bg-[#1B1B1B] rounded-[8px] h-[40px]
                    flex items-center gap-[12px] border border-solid border-[#333]'>
                        <CardIcon className='w-[28px] h-[20px]'/>
                        <ReactInputMask
                            mask="9999 9999 9999 9999"
                            inputMode="numeric"
                            placeholder={`•••• •••• •••• ${card.last4}`}
                            type='tel'
                            className='bg-inherit text-[14px] text-[white] opacity-90 w-full'
                            maskChar={null}
                            value={inputValues.cardNumber}
                            onChange={(e) => setInputValues({...inputValues, cardNumber: e.target.value.replace(/\s/g, '')})}
                            autoComplete='cc-number'
                        />
                    </div>
                </div>
                <div className="d-flex items-center gap-[16px]">
                    <div className='flex-1 flex flex-col gap-[8px] w-[33.33%]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Expiry Month</p>
                        <Input
                            className="min-h-[40px]"
                            type='number'
                            value={inputValues.expMonth}
                            onChange={({target: {value}}) => {
                                if(/^(|[1-9]|0[1-9]|1[0-2])$/.test(value)) setInputValues({...inputValues, expMonth: value});
                            }}
                            min={1}
                            max={12}
                        />
                    </div>
                    <div className='flex-1 flex flex-col gap-[8px] w-[33.33%]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>Expiry Year</p>
                        <Input
                            className="min-h-[40px]"
                            type='number'
                            value={inputValues.expYear}
                            onChange={({target: {value}}) => {
                                if(/^(|[0-9]{1,4})$/.test(value)) setInputValues({...inputValues, expYear: value});
                            }}
                            min={2023}
                            max={2043}
                        />
                    </div>
                    <div className='flex-1 flex flex-col gap-[8px] w-[33.33%]'>
                        <p className='text-[12px] text-[#A7A7A7] leading-[14px]'>CVV</p>
                        <Input
                            className="min-h-[40px]"
                            type='number'
                            value={inputValues.cardCode}
                            onChange={({target: {value}}) => {
                                if(/^(|[0-9]{1,4})$/.test(value)) setInputValues({...inputValues, cardCode: value});
                            }}
                        />
                    </div>
                </div>
            </div>
            <PrimaryButton
                onClick={handleEditCard}
                className="w-fit ml-auto"
                disabled={isLoading}
            >
                <span>Edit Card</span>
            </PrimaryButton>
        </div>
    </div>
}

export default EditCardModal;