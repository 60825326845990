import ProductDescription from './ProductDescription/ProductDescription';
import GeneratorStart from './GeneratorStart/GeneratorStart';
import GeneratedContent from './GeneratedContent/GeneratedContent';
import MyAssets from './MyAssets/MyAssets';
import Settings from './SettingsOld/Settings';

import SignIn from './Auth/SignIn';
import SignInManually from './Auth/SignInManually';
import ForgotPassword from './Auth/ForgotPassword';
import ResetPassword from './Auth/ResetPassword';
import SignUp from './Auth/SignUp';

import MyTeamUsers from './MyTeam/MyTeamUsers';
import AddEditUser from './MyTeam/AddEditUser';
import NewWords from './NewWords/NewWords';

import MyProjects from './MyProjects/MyProjects';
import CreateNewProject from './MyProjects/CreateNewProject';

import Pricing from './Pricing/Pricing';

import WantToCreate from './TextGenerator/WantToCreate';

import GeneratedContentV1 from './GeneratedContentV1/GeneratedContentV1';

import SettingsNew from "./Settings/SettingsNew";

import KitDetails from "./GeneratorV4/KitDetails";

import GeneratorContainer from "./GeneratorV4/GeneratorContainer";
import MyEmailNewsLetters from './MyEmailNewsLetters/MyEmailNewsLetters';
import ChatBot from './ChatBot/ChatBot';
import BlogGeneratedContent from './GeneratedContent/Blog/BlogGeneratedContent';
import NewWordGeneratedContent from './GeneratedContent/NewWord/NewWordGeneratedContent';

import LandingContentGeneratedContent from './GeneratedContent/LandingContent/LandingContentGeneratedContent';
import GeneralGeneratedContent from './GeneratedContent/General/GeneralGeneratedContent';
import EssayGeneratedContent from './GeneratedContent/Essay/EssayGeneratedContent';
import ProductDescriptionGeneratedContent from './GeneratedContent/ProductDescription/ProductDescriptionGeneratedContent';
import EtsyGeneratedContent from './GeneratedContent/Etsy/EtsyGeneratedContent';
import PromptGeneratedContent from './GeneratedContent/Prompt/PromptGeneratedContent';
import MetaTagsGeneratedContent from './GeneratedContent/MetaTags/MetaTagsGeneratedContent';
import PressReleaseGeneretedContent from './GeneratedContent/PressRelease/PressReleaseGeneratedContent';
import SocialGeneratedContent from './GeneratedContent/Social/SocialGeneratedContent';
import AssetSharedPage from './SharedPages/AssetSharedPage';
import NewWordSharedPage from './SharedPages/NewWordSharedPage';

import EtsyNewGeneratedContent from './GeneratedContent/EtsyNew/EtsyNewGeneratedContent';

export const Pages = {
    ProductDescription,
    GeneratorStart,
    GeneratedContent,
    MyAssets,
    Settings,
    SignIn,
    SignInManually,
    ForgotPassword,
    SignUp,
    MyTeamUsers,
    AddEditUser,
    MyProjects,
    CreateNewProject,
    Pricing,
    WantToCreate,
    GeneratedContentV1,
    SettingsNew,
    KitDetails,
    GeneratorContainer,
    NewWords,
    MyEmailNewsLetters,
    ChatBot,
    ResetPassword,
    BlogGeneratedContent,
    NewWordGeneratedContent,
    LandingContentGeneratedContent,
    GeneralGeneratedContent,
    EssayGeneratedContent,
    ProductDescriptionGeneratedContent,
    EtsyGeneratedContent,
    PromptGeneratedContent,
    MetaTagsGeneratedContent,
    PressReleaseGeneretedContent,
    SocialGeneratedContent,
    AssetSharedPage,
    EtsyNewGeneratedContent,
    NewWordSharedPage,
}