import React, { useContext, useEffect, useState, } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { GenerateFormContext, initialState } from '../GeneratorContextComponent';
import { getSocket, getToken, setUser } from '../../../redux/slices/applicationSlice';
import { getUserUpdateThunk } from '../../../redux/thunks/applicationThunks';
import Step1 from "./Step1";
import Step2 from "./Step2";

import { toast } from 'react-toastify';
import { checkUserHaveCredits } from '../../../helpers/userHelpers';

const EmailEducatorConfigComponent = ({ setLoading, currentCredits, priceCredits, changePayloadForCalculate }) => {
    const dispatch = useDispatch();
    const socket = useSelector(getSocket);
    const token = useSelector(getToken);

    const [step, setStep] = useState(1);
    
    const [randomGenerateLoading, setRandomGenerateLoading] = useState(false);

    const [generateForm, setGenerateForm] = useContext(GenerateFormContext);
    const { selectedMethod, courses, } = generateForm;

    console.log('selectedMethod:', selectedMethod);
    console.log('courses:', courses);

    const generateRandom = async() => {
        let checkRes = await checkUserHaveCredits(token);
        if(!checkRes?.success){
            dispatch(setUser(checkRes?.data));
            return;
        }

        setStep(2);
        setRandomGenerateLoading(true);

        const generateMethodOperation = selectedMethod === 'goal' ? 'goal_suggestions' : selectedMethod === 'topic' ? 'topic_suggestions' : selectedMethod === 'presets' && courses?.length ? courses[0].method+'_suggestions' : 'goal_suggestions';

        socket.send({
            event: 'lesson-kit-tools',
            payload: {
                operation: generateMethodOperation,
                amount: 2,
            },
            handler: (response) => {
                if(response?.isError){
                    toast.error("AI bot is not available at the moment");
                    return;
                }

                if(response.isDone){
                    dispatch(getUserUpdateThunk({ token }));

                    setRandomGenerateLoading(false);

                    setGenerateForm((prevState) => {
                        return {
                            ...prevState,
                            courses: [...prevState.courses, {
                                method: selectedMethod !== 'presets' ? selectedMethod : courses[0] ? courses[0].method : 'goal', // goal | topic
                                text: response.data[0],
                                backgroundInformation: "",
                                language: "English",
                                country: "Global",
                                numberOfLessons: 3,
                                lessonType: 'Curation',
                                subjectLevel: "Intermediate",
                                readingLevel: "Intermediate",
                                specialEducation: "None",
                                competencyMapping: "",
                                addons: [],
                                files: [],
                                cover: null,
                                scheduleLessonFrequency: '',
                                scheduleRepeatOn: '',
                                scheduleBegins: '',
                                scheduleTime: '',
                                scheduleIsSendOneMessage: false,
                            }]
                        }
                    })
                }
            }
        });
    }

    return <>
        { step === 1 &&
            <Step1 
                setStep={setStep} generateRandom={generateRandom} randomGenerateLoading={randomGenerateLoading}
            />
        }
        { step === 2 &&
            <Step2 
                setStep={setStep}
                generateRandom={generateRandom} randomGenerateLoading={randomGenerateLoading}
                changePayloadForCalculate={changePayloadForCalculate}
                currentCredits={currentCredits}
                priceCredits={priceCredits}
            />
        }
    </>;
};

export default EmailEducatorConfigComponent;