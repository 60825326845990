import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
// import InfinityLogo from "../../assets/img/infinity-logo.svg";
import api from '../../api';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(true);
    const [showConfirmPassword, setShowConfirmPassword] = useState(true);
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        if(!email) {
            setErrorMessage('Email is requiered');
            return;
        };
        if(!validateEmail(email)) {
            setErrorMessage('Email is not valid');
            return;
        };
            try {
                setLoading(true);
                const resp = await api.auth.forgotPassword({email});
                if(resp.success) {
                    setLoading(false);
                    setSuccess(true);
                }
            } catch (e) {
                setLoading(false);
                setErrorMessage(e.response.data.error);
            }
    }

    return (
        <form className="auth-form" onSubmit={handleSubmit} noValidate>
            {/* <img src={ InfinityLogo } alt="" className="mb-[10px]" /> */}
                <>
                    <h3 className="mb-[8px]">Forgot Password?</h3>
                    <div className="auth-dont-have mb-[48px]">
                        <p>No worries, we’ll send you reset instructions.</p>
                    </div>
                </>
        
                <>
                    <div className="input-custom">
                        <label className="input-custom__label mb-[10px]">Email Address</label>
                        <div className="input-custom__wrap-input icon-left">
                            <div className="icon-input">
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.7501 21.05C3.13718 21.05 2.60478 20.8199 2.1529 20.3597C1.70103 19.8995 1.4751 19.3713 1.4751 18.775V6.22505C1.4751 5.62192 1.70103 5.0878 2.1529 4.6227C2.60478 4.1576 3.13718 3.92505 3.7501 3.92505H20.2501C20.8699 3.92505 21.4082 4.1576 21.8649 4.6227C22.3217 5.0878 22.5501 5.62192 22.5501 6.22505V18.775C22.5501 19.3713 22.3217 19.8995 21.8649 20.3597C21.4082 20.8199 20.8699 21.05 20.2501 21.05H3.7501ZM12.0001 13.425C12.1001 13.425 12.1949 13.4125 12.2845 13.3875C12.3741 13.3625 12.4888 13.308 12.6286 13.2238L19.9278 8.348C20.0093 8.3077 20.0834 8.2313 20.1501 8.1188C20.2168 8.0063 20.2501 7.86961 20.2501 7.70872C20.2501 7.44751 20.1168 7.22242 19.8501 7.03347C19.5834 6.84452 19.3251 6.85005 19.0751 7.05005L12.0001 11.675L4.9751 7.05005C4.70843 6.88338 4.4376 6.87505 4.1626 7.02505C3.8876 7.17505 3.7501 7.39872 3.7501 7.69607C3.7501 7.84381 3.7876 7.97307 3.8626 8.08385C3.9376 8.19465 4.01834 8.28392 4.10482 8.35167L11.3721 13.2238C11.5116 13.308 11.6261 13.3625 11.7157 13.3875C11.8053 13.4125 11.9001 13.425 12.0001 13.425Z" fill="white" fillOpacity="0.12"></path>
                                </svg>
                            </div>
                            <input 
                                className="input-custom__input"
                                autoComplete='username'
                                type="email"
                                placeholder="Enter your email address"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                        {success ? <p className="input-custom__label my-[10px]" style={{color: 'green', opacity: 0.8, minHeight: 14}}>Check your email!</p>
                        :
                        <p className="input-custom__label my-[10px]" style={{color: 'red', opacity: 0.8, minHeight: 14}}>{errorMessage}</p>}
                    </div>
                </>
        
            <button 
                className="btn btn-primary-10 btn-xl rounded-[8px] mb-[48px] w-100 submit-btn" 
                type='submit'
                disabled={loading || success}
            >
                {loading ? <div className="d-flex justify-center align-items-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 38 38" stroke="#fff"
                        style={{ height: '20px' }}
                    >
                        <g fill="none" fillRule="evenodd">
                            <g transform="translate(1 1)" strokeWidth="2">
                                <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
                                <path d="M36 18c0-9.94-8.06-18-18-18">
                                    <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/>
                                </path>
                            </g>
                        </g>
                    </svg>
                </div> : 
                <span>Reset Password</span>
    }
            </button>
            <Link to="/sign-in" className="back-to-link">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.1665 9.99998H15.8332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8.33317 5.83331L4.1665 9.99998" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8.33317 14.1667L4.1665 10" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                Back to Login
            </Link>
        </form>
    )
}

export default ForgotPassword;