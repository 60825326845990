import { useEffect, useRef, useState } from "react"

export const Accordion = ({children, openBlock, initOpen=true, className}) => {

    const ref = useRef(null)

    const [first, setFirst] = useState(initOpen)

    useEffect(() => {
        if (!ref.current) return
        let height = 0
        if (openBlock) {
            ref.current.style.display = 'block'
            height = ref.current.clientHeight
            ref.current.style.height = '0px'
            
            setTimeout(() => {
                ref.current.style.height = height + (first ? 0 : 0) + 'px' // было 12 px
                setFirst(false)
            }, 0)
           
        } else {
            ref.current.style.height = '0px'
            
            setTimeout(() => {
                if (!ref.current) return
                ref.current.style.display = 'none'
                ref.current.style.height = null

            }, 100)
        }
    }, [openBlock])


    return (
        <div ref={ref} className={`accordion ${className ? className : null} ${openBlock && 'accordion-open'}`}>
            {children}
        </div>
    )
}