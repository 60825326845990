import { createSlice } from '@reduxjs/toolkit'
import { generateText, editText } from '../thunks/TextGeneratorThunks';

const initialState = {
    data: true ? {
        "content":{
            "blog1": {
                "title": "The Benefits of Family Weekend Outdoor Activities",
                "part1": "As parents, we understand the importance of studying and reading for our children's cognitive development. However, it's equally vital to balance intellectual growth with physical activity. In this blog, we will explore the benefits of incorporating outdoor activities into your family's weekend routine.",
                "part2": "Participating in sports and games helps children develop essential motor skills and coordination. From playing catch to riding their bikes, these activities engage various muscle groups and promote overall physical fitness. Additionally, outdoor games often involve teamwork and cooperation, fostering social skills and promoting bonding between siblings and peers.",
                "part3": "Engaging in outdoor activities also provides children with an opportunity to connect with nature. Studies have shown that spending time in nature can have a positive impact on children's mental health and wellbeing, reducing stress and anxiety. It encourages creativity and imagination, as they explore natural environments, play with leaves and rocks, or simply observe different plants and animals.",
                "part4": "In conclusion, while studying and reading are essential for children's intellectual growth, it is equally important to incorporate outdoor activities into their routine. These activities not only promote physical fitness but also contribute to children's mental wellbeing. So, why not plan a family weekend outside filled with study breaks, books, games, sports, and other exciting outdoor activities? Your children will thank you for it!",
                "words_count": 282
            },
            "blog2": {
                "title": "Fun and Educational Outdoor Activities for the Whole Family",
                "part1": "When it comes to spending quality time with our family, it's essential to find activities that are both fun and educational. In this blog, we will share some exciting outdoor activities that will not only keep your little ones entertained but also enhance their learning and development.",
                "part2": "One way to incorporate education into outdoor activities is through nature scavenger hunts. Create a list of items found in nature, such as different kinds of leaves, rocks, or flowers. Encourage your children to explore and collect these items, teaching them about various plants and the environment while having fun.",
                "part3": "Another idea is to set up a mini sports tournament in your backyard. Choose different sports like soccer, basketball, or even relay races. This will not only get your family moving and enjoying outdoor time together but also promote friendly competition and teamwork.",
                "part4": "In addition to physical activities, outdoor reading and study sessions can be a refreshing change for children. Set up a cozy reading nook in your garden or local park, where your kids can enjoy reading their favorite books while surrounded by nature. This will help them develop a love for reading and encourage their imagination.",
                "words_count": 309
            }
        },
        "usage":{"prompt_tokens":131,"completion_tokens":580,"total_tokens":711}
    } : null,
    generatedIn: null,
    loading: false,
}

const TextGeneratorSlice = createSlice({
    name: 'textGenerator',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(generateText.fulfilled, (state, action) => {
            console.log('FULLFILLED GENERATE TEXT');

            let formatedContent = action.payload.content;
            if(formatedContent.includes(',\n}')) {
                formatedContent = formatedContent.split(',\n}').join('}');
            }
            if(formatedContent.includes(',\n}')) {
                formatedContent = formatedContent.split(',}').join('}');
            }
            if(formatedContent.includes(',\n}')) {
                formatedContent = formatedContent.split(', }').join('}');
            }

            return {
                ...state,
                data: {
                    ...action.payload,
                    content: JSON.parse(formatedContent)
                },
                loading: false,
            };
        });
        builder.addCase(generateText.pending, (state, action) => {  
            console.log('PENDING GENERATE TEXT');
            return {
                ...state,
                data: null,
                loading: true,
            };
        });
    },
    reducers: {
        startTextGenerator: (state) => {
            state.loading = true;
        },
        setTextGeneratorData: (state, action) => {
			state.data = action.payload;
            if(state.loading){
                state.loading=false;
            }
		},
        setGeneratedInTime: (state, action) => {
			state.generatedIn = action.payload;
		},
        setTextGeneratorLoading: (state, action) => {
            state.loading = action.payload;
        }
    }
})


export const { setTextGeneratorData, setGeneratedInTime, startTextGenerator, setTextGeneratorLoading } = TextGeneratorSlice.actions

export const getGeneratedContent = (state) => state.textGenerator.data;
export const getTextGeneratorLoading = (state) => state.textGenerator.loading;
export const getGeneratedInTime = (state) => state.textGenerator.generatedIn;

export default TextGeneratorSlice.reducer