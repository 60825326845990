import { useEffect, useState } from "react";
import { ToolkitBlocksContainer } from "./ToolkitBlocksContainer";
import { AssetsBlocksContainer } from "./AssetsBlocksContainer";
import { TextBigContainer } from "./TextBigContainer";
import {Tabs} from "../../components/base/Tabs";
import { NewWordsToolKit } from "./NewWordsToolKit";


export const GeneratedToolkit = () => {

    function sortBySearch(array, searchValue) {
        const filteredArray = array.filter(item => item.title.toLowerCase().includes(searchValue.toLowerCase()));
        // filteredArray.sort((a, b) => a.title.localeCompare(b.title));
        return [...filteredArray];
    }

    const myWords = [
        {
            id: '1',
            title: 'Flibberdoodle',
            content: 'Flibberdoodle refers to a fictional creature that dwells deep within the enchanted forests. It is said to be a mischievous, yet friendly, creature resembling a cross between a squirrel and a hummingbird. Flibberdoodles are known for their colorful plumage and their ability to play delightful melodies on their tiny flutes. Legend has it that encountering a Flibberdoodle brings good luck and joyful moments.',
        },
        {
            id: '2',
            title: 'Quiblitz',
            content: 'A verb that means to engage in playful banter or lighthearted argumentation for the sheer joy of intellectual sparring without any real intention of reaching a conclusion.',
        },
        {
            id: '3',
            title: 'Zazzlefluff',
            content: 'Zazzlefluff is a noun that represents the whimsical, intangible essence of pure joy and exuberance.',
        },
    ]
    

    const [blocks, setBlocks] = useState([
        {
            id: '1',
            title: 'Keep it positive',
            content: ['Joe', 'Empowerment', 'Discovery', 'Transformation', 'Inspiration', 'Insight'],
            //d
            avaliableBtn: [true, true, true],
            variant: 'elements-2',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M4.73893 12.7896C4.37921 12.7896 4.0681 12.6641 3.8056 12.4133C3.5431 12.1625 3.41185 11.8455 3.41185 11.4625V5.55622C3.41185 5.38771 3.44152 5.22375 3.50087 5.06434C3.56021 4.90494 3.66179 4.75779 3.8056 4.62289L6.15352 2.11456C6.33824 1.92011 6.56428 1.79129 6.83164 1.7281C7.099 1.6649 7.36393 1.69164 7.62643 1.80831C7.85977 1.9347 8.03234 2.12428 8.14414 2.37706C8.25595 2.62984 8.28268 2.8972 8.22435 3.17914L7.84518 5.01664H12.2931C12.6412 5.01664 12.9518 5.1547 13.225 5.43081C13.4982 5.70692 13.6348 6.01122 13.6348 6.34372V6.88812C13.6348 7.01053 13.6178 7.15141 13.5837 7.31076C13.5497 7.4701 13.5132 7.59984 13.4743 7.69997L11.7243 11.7687C11.6174 12.0409 11.4183 12.2791 11.1271 12.4833C10.8359 12.6875 10.5272 12.7896 10.2011 12.7896H4.73893ZM1.61629 12.7896C1.37444 12.7896 1.16602 12.7035 0.991016 12.5313C0.816016 12.3592 0.728516 12.1487 0.728516 11.9V5.92081C0.728516 5.67775 0.810143 5.46629 0.973399 5.28643C1.13665 5.10657 1.34325 5.01664 1.59319 5.01664H1.63694C1.87716 5.01664 2.0872 5.10657 2.26706 5.28643C2.44692 5.46629 2.53685 5.67775 2.53685 5.92081V11.9C2.53685 12.1487 2.44674 12.3592 2.26653 12.5313C2.08631 12.7035 1.87443 12.7896 1.63087 12.7896H1.61629Z" fill="#A7A7A7"/>
            </svg>
        },
        {
            id: '2',
            title: 'General',
            content: ['Prompt Database', 'Topics / Subjects', 'Power word', 'Ebooks', 'Structure', 'SEO', 'Change the World', 'Call to action'],
            avaliableBtn: [true, true, true],
            variant: 'elements-3',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M11.0827 1.16666H2.91602C1.95352 1.16666 1.16602 1.95416 1.16602 2.91666V11.0833C1.16602 12.0458 1.95352 12.8333 2.91602 12.8333H11.0827C12.0452 12.8333 12.8327 12.0458 12.8327 11.0833V2.91666C12.8327 1.95416 12.0452 1.16666 11.0827 1.16666ZM6.41602 8.74999H4.08268C3.76185 8.74999 3.49935 8.48749 3.49935 8.16666C3.49935 7.84582 3.76185 7.58332 4.08268 7.58332H6.41602C6.73685 7.58332 6.99935 7.84582 6.99935 8.16666C6.99935 8.48749 6.73685 8.74999 6.41602 8.74999ZM8.74935 6.41666H4.08268C3.76185 6.41666 3.49935 6.15416 3.49935 5.83332C3.49935 5.51249 3.76185 5.24999 4.08268 5.24999H8.74935C9.07018 5.24999 9.33268 5.51249 9.33268 5.83332C9.33268 6.15416 9.07018 6.41666 8.74935 6.41666Z" fill="#9D9D9D"/>
            </svg>
        },
        {
            id: '3',
            title: 'Product Related',
            content: ['Blog', 'Coding', 'Copywriting'],
            avaliableBtn: [true, true, true],
            variant: 'elements-3',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M7 0L1.59135 2.86243L7 5.71724L12.4087 2.86243L7 0ZM13 11.0767V3.90538L7.46154 6.82871V14L13 11.0767ZM6.53846 6.82871L1 3.90538V11.0767L6.53846 14V6.82871Z" fill="#A7A7A7"/>
            </svg>
        },
        {
            id: '4',
            title: 'Platforms',
            content: ['Amazon', 'Ebay', 'Walmart Marketplace', 'Etsy', 'AliExpress', 'Shopify'],
            avaliableBtn: [true, true, true],
            variant: 'elements-2',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M4.08398 10.5C3.44232 10.5 2.92315 11.025 2.92315 11.6667C2.92315 12.3083 3.44232 12.8333 4.08398 12.8333C4.72565 12.8333 5.25065 12.3083 5.25065 11.6667C5.25065 11.025 4.72565 10.5 4.08398 10.5ZM0.583984 1.75C0.583984 2.07083 0.846484 2.33333 1.16732 2.33333H1.75065L3.85065 6.76083L3.06315 8.18416C2.63732 8.96583 3.19732 9.91666 4.08398 9.91666H10.5007C10.8215 9.91666 11.084 9.65416 11.084 9.33333C11.084 9.0125 10.8215 8.75 10.5007 8.75H4.08398L4.72565 7.58333H9.07148C9.50898 7.58333 9.89398 7.34416 10.0923 6.9825L12.1806 3.19666C12.2298 3.10825 12.255 3.00852 12.2538 2.90736C12.2526 2.8062 12.225 2.70711 12.1737 2.6199C12.1225 2.53269 12.0493 2.46037 11.9615 2.41011C11.8737 2.35985 11.7743 2.33338 11.6732 2.33333H3.03982L2.64898 1.49916C2.6021 1.39959 2.52782 1.31544 2.43485 1.25655C2.34187 1.19767 2.23404 1.16649 2.12398 1.16666H1.16732C0.846484 1.16666 0.583984 1.42916 0.583984 1.75ZM9.91732 10.5C9.27565 10.5 8.75648 11.025 8.75648 11.6667C8.75648 12.3083 9.27565 12.8333 9.91732 12.8333C10.559 12.8333 11.084 12.3083 11.084 11.6667C11.084 11.025 10.559 10.5 9.91732 10.5Z" fill="#A7A7A7"/>
            </svg>

        },
    ]);
    
    //d
    // const [activeTab, setActiveTab] = useState('home') // home | assets | words
    // const [listStyle, setListsStyle] = useState(1) // 1 or 2
    // const [oneBlockId, setOneBlockId] = useState(null)

    // const changeOnOpenBlock = (id) => {
    //     setOneBlockId(id)
    // }

    // const oneBlockObj = oneBlockId ? blocks.filter(item => item.id === oneBlockId)[0] : {}


    const [isOpenInput, setIsOpenInput] = useState(false)
    const [searchValue, setSearchValue] = useState('')
   

    // new
    const [activeTabName, setActiveTabName] = useState('')

    useEffect(() => {
        setIsOpenInput(false)
        setSearchValue('')
    }, [activeTabName])

    return (
        <div className="blog-toolkit-wrapper">
            <div className="blog-toolkit-head">
                <form className="toolbar-toolkit d-flex align-center">
                    {/*<div className="d-flex">*/}
                        <span className="opacity-90">
                            Selected
                        </span>
                        <div className="toolbar-toolkit__selected">0</div>
                    {/*</div>*/}
                    <div className="ml-[auto] opacity-90 cursor-pointer">Clear All</div>
                </form>
                
            </div>

            <div className="blog-toolkit-content">
                <Tabs labelsArray={["Toolkit", "My Assets", "New Words"]} idxCallBack={(val) => setActiveTabName(val)} />


                {activeTabName === 'Toolkit' && 
                    <ToolkitBlocksContainer searchable={searchValue} blocks={sortBySearch(blocks, searchValue)} setBlocks={setBlocks}  />
                }


                {activeTabName === 'My Assets' && <AssetsBlocksContainer sortBySearch={sortBySearch} searchable={searchValue} />}

                {activeTabName === 'New Words' && <NewWordsToolKit />}


            </div>

        </div>
    )



}