import React, { useState, useEffect, useRef } from 'react';
import noUiSlider from 'nouislider';

import '../../assets/css/nouislider.css'

const Slider = () => {
    const sliderRef = useRef(null);
    const [active, setActive] = useState(2)
    const labels = ['Bad', 'Normal', 'Good', 'Nice']

    useEffect(() => {
      const slider = sliderRef.current;
  
      noUiSlider.create(slider, {
        start: 2,
        step: 1, 
        tooltips: true,
        connect: 'lower',
        range: {
          min: 0,
          max: 3,
        },
        format: {
            to: value => labels[value],
            from: value => value,
          },
      });
  
      slider.noUiSlider.on('update', (values, handle) => {
        const selectedLabel = values[handle];
        const selectedIndex = labels.indexOf(selectedLabel);
        console.log('Slider value:', selectedIndex);
      });
  
      return () => {
        slider.noUiSlider.destroy();
      };
    }, []); 
  
    return (
      <div className="col-12">
        <div className="input-custom slider-container">
          <label className="input-custom__label justify-between mb-[8px]">
            Detail Level
          </label>
  
          <div className="widget--slider mt-[50px]">
            <div
              className="range-slider one-slider one-slider--custom-message slider-text flex-auto noUi-target noUi-ltr noUi-horizontal noUi-txt-dir-ltr"
              ref={sliderRef}
            ></div>
          </div>
        </div>
      </div>
    );
  };
  
  export default Slider;