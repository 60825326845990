import { format } from 'date-fns';
import React, { useEffect, useRef } from 'react';

class CircleProgress {
    interval = null;
    constructor(item, options) {
        this.item =
        typeof item == "string"
            ? document.querySelector(item)
            : item
        this.radius = this.item.width / 2;
        this.ctx = this.item.getContext("2d");
        this.ctx.lineCap = "round";
        this.opt = {
        fps: (1000 / 360).toFixed(2),
        start: 0,
        end: 100,
        lineWidth: this.item.width * 0.1,
        successColor: "#FF8114",
        isGradient: {
            from: "#F8B405",
            to: "#FF8114",
            direction: 170,
        },
        defaultColor: "#2E2E2E",
        ...options,
        };
        this.init();
    }


    arcDrow(color, isAnimate = false, isGradient = false) {
        let step = isAnimate ? this.opt.start : 360;
        return function () {
        this.ctx.beginPath();
        this.ctx.arc(
            this.radius,
            this.radius,
            this.radius - this.opt.lineWidth,
            (Math.PI / 180) * 270,
            (Math.PI / 180) * (270 + step)
        );
        if(isGradient) {
            const gradient = this.ctx.createRadialGradient(
                this.item.width,
                this.item.height,
                0,
                this.item.width,
                this.item.height,
                Math.max(this.item.width, this.item.height)
            );
            
            gradient.addColorStop(0, this.opt.isGradient.from);
            gradient.addColorStop(1, this.opt.isGradient.to);

            this.ctx.strokeStyle = gradient;
        } else {
            this.ctx.strokeStyle = color;
        }
        this.ctx.lineWidth = this.opt.lineWidth;
        this.ctx.stroke();

        }.call(this);
    }
    arcMove() {
        this.interval = setInterval(() => {
            this.opt.start += 1;
            this.clear();
            this.arcDrow.call(this, this.opt.defaultColor);
            if(!(this.opt.start === this.opt.end + 1)) {
            this.arcDrow.call(this, this.opt.successColor, true, this.opt.isGradient);
        }

        if (this.opt.start >= ( this.opt.end * 3.6 )) {
            clearInterval(this.interval);
        }
        }, this.opt.fps);
    }
    clear() {
        this.ctx.clearRect(0, 0, this.item.width, this.item.height);
    }
    pause() {
        clearInterval(this.interval)
    }
    stop() {
        this.opt.start = 0
        clearInterval(this.interval)
    }
    restart() {
        this.opt.start = 0;
        this.pause()
        this.arcMove()
    }
    play() {
        this.arcMove()
    }
    init() {
        if (this.item !== null) {
        this.arcMove();
        }
    }
    destroy() {
    }
}

const CircleProgressBar = ({data: {endDate, checkpoints}, cardView}) => {
    const doneCheckpoints = checkpoints.filter(({completed}) => completed).length;
    const goalCheckpoints = checkpoints.length;
    const ref = useRef();
    useEffect(() => {
        const progress = new CircleProgress(ref.current, {
            end: doneCheckpoints * 100 / goalCheckpoints
        })
        return () => {
            progress.stop();
            progress.clear();
        }
    }, [doneCheckpoints, goalCheckpoints]);
    return (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
            <canvas 
                className={cardView ? '' : 'w-full h-full'} 
                ref={ref} 
                width={160} 
                height={160}
            />
            <div className={`${cardView ? 'leading-normal' : 'leading-none'} absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] text-center whitespace-nowrap`}>
                <span 
                    className={`${cardView ? 'text-[24px]' : 'text-[12px]'} leading-[10px] font-medium opacity-90`}
                >
                    {doneCheckpoints} / {goalCheckpoints}
                </span>
                <br />
                <span 
                    className={`${cardView ? 'text-[12px]' : 'text-[10px]'} text-[#A7A7A7] font-light`}
                >
                    {cardView && 'till'} {format(new Date(endDate), 'MM.dd.yyyy')}
                </span>
            </div>
        </div>
    );
};

export default CircleProgressBar;