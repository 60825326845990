import React, { useContext, useState } from 'react';

import InputSuggestion from "./InputSuggestion";
import TextareaGenerator from "./TextareaGenerator";
import { GenerateFormContext } from './GeneratorContextComponent';
import SelectBox from '../../components/base/SelectBox';
import NumberInput from '../../components/base/NumberInput';
import logo from '../../assets/img/logo.png';
import listChecksImg from '../../assets/img/icons/list-checks.svg';
import GoalInputSuggestion from './GoalInputSuggestion';
import { useSelector } from 'react-redux';
import { getFormOptions } from '../../redux/slices/applicationSlice';
import { NavLink } from 'react-router-dom';

const GoalConfigStage1 = () => {
    const [generateForm, setGenerateForm] = useContext(GenerateFormContext);
    const { 
        goalCategory, goalMilestonesNumber, goalTitle, goalDescription, goalChallenges
    } = generateForm;
    const setFormValue = (key, value) => setGenerateForm(prev => ({...prev, [key]: value}));
    const [showSugg, setShowSugg] = useState(false);
    const formOptions = useSelector(getFormOptions);
    const categoryOptions = formOptions.filter(({key}) => key === 'goal_category');

    return <>
        <div className="get4-content-header mb-[36px]">
            <div className='flex items-center justify-center mb-[20px]'>
                <div className='blog-kit-icon-container mr-[12px]'>
                    <img src={listChecksImg} alt='list checks'/>
                </div>
                <img src={logo} className='h-[28px] mr-[6px] hidden lg:block' alt='list checks'/>
                <h2 className="font-[Gilroy] text-[24px] font-semibold leading-none">Goal Realization Kit</h2>
            </div>
            <p className="get4-content-subtitle">AIKIT.AI's Goal Generator is the ultimate tool for goal planning and execution. <br /> This sophisticated platform allows you to use AI to generate detailed goals, milestones, tasks, and action plans tailored to your specific objectives. <br /> Whether it's personal development, professional projects, or any other goal category, the Goal Generator streamlines the planning process, ensuring your path to success is clear and well-structured.</p>
        </div>
        <div className='flex flex-col mb-[16px] gap-[16px]'>
            <div className='generator-settings-part'>
                <div className={`input-custom min-w-[364px] mr-[16px]`}>
                    <label className="input-custom__label mb-[10px]">Goal Category</label>
                    <div className="input-custom__wrap-input gen4-settings-select">
                        <SelectBox
                            className='default-select flex-1'
                            propsOptions={[
                                { label: 'Select Category', value: '', placeholder: true },
                                ...categoryOptions.map(item => ({ label: item.title, value: item.title }))
                            ]}
                            value={goalCategory || 'Select category'}
                            change={(val) => setFormValue('goalCategory', val)}
                        />
                    </div>
                </div>
                <div className={`input-custom`}>
                    <label className="input-custom__label mb-[10px]">Milestones Number</label>
                    <div className="input-custom__wrap-input gen4-settings-select">
                        <NumberInput 
                            value={goalMilestonesNumber || 8}
                            onChange={(val) => setFormValue('goalMilestonesNumber', val)} 
                        />
                    </div>
                </div>
            </div>
            <GoalInputSuggestion
                destination='form'
                value={goalTitle}
                setValue={(value) => setFormValue('goalTitle', value)}
                showTopicSugg={showSugg}
                setShowTopicSugg={setShowSugg}
                to='goal'
            />
            <label htmlFor='blog_description'>
                <div className="d-flex items-center justify-between">
                    <p
                        className='text-[12px] leading-[14px] text-[#A7A7A7]'
                    >
                        Goal Description
                    </p>
                    <NavLink to={`/kit-details/goal`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M7.00019 7.41542L7.7746 6.98518C8.16108 6.7705 8.40078 6.36316 8.40078 5.92106C8.355 5.18588 7.7245 4.62543 6.98902 4.66615C6.33184 4.63887 5.74827 5.08311 5.59961 5.72383" stroke="#FF8114" strokeLinecap="round" strokeLinejoin="round"/>
                            <circle cx="7.00023" cy="7.00023" r="5.25219" stroke="#FF8114" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7.05913 9.27624C7.05909 9.30845 7.03296 9.33454 7.00075 9.33452C6.96854 9.33451 6.94243 9.3084 6.94241 9.27619C6.9424 9.24398 6.96849 9.21785 7.0007 9.21781C7.0162 9.21779 7.03107 9.22394 7.04204 9.2349C7.053 9.24586 7.05915 9.26074 7.05913 9.27624" stroke="#FF8114" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </NavLink>
                </div>
                <textarea
                    value={goalDescription}
                    onChange={(e) => setFormValue('goalDescription', e.target.value)}
                    className='bg-[#1B1B1B] w-full resize-none pt-[16px] pb-[8px] pl-[14px] pr-[8px] rounded-[8px] mt-[10px]
                    border border-solid border-[#333] h-[120px] text-[14px] leading-[22px] text-[#ffffffe6]
                    overflow-y-auto'
                    id="blog_description"
                    placeholder={`• Enroll in and finish an advanced web development course.\n• Coordinate a local park cleanup with volunteers and sponsors.\n• Create and post engaging weekly content on lifestyle topics.\n• Work towards and complete a 10k run.`}
                    onFocus={(e) => {
                        setShowSugg(false);
                    }}
                />
            </label>
            <label htmlFor='goalChallenges'>
                <p
                    className='text-[12px] leading-[14px] text-[#A7A7A7]'
                >
                    Challenges to Overcome
                </p>
                <textarea
                    value={goalChallenges}
                    onChange={(e) => setFormValue('goalChallenges', e.target.value)}
                    className='bg-[#1B1B1B] w-full resize-none pt-[16px] pb-[8px] pl-[14px] pr-[8px] rounded-[8px] mt-[10px]
                    border border-solid border-[#333] h-[120px] text-[14px] leading-[22px] text-[#ffffffe6]
                    overflow-y-auto'
                    id="goalChallenges"
                    placeholder={`• Balancing study time with work commitments.\n• Recruiting volunteers and gathering resources.\n• Consistency in content creation and audience engagement.\n• Maintaining a regular training routine.`}
                />
            </label>
        </div>
    </>;
};

export default GoalConfigStage1;