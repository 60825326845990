import React, { useEffect, useRef, useState } from 'react';
import EditorToolbar from '../Blog/EditorToolbar';
import { useQuill } from 'react-quilljs';
import classNames from 'classnames';
import DefaultToolbar from '../Blog/DefaultToolbar';
import EditorControl from '../Blog/EditorControl';
import { useDispatch } from 'react-redux';
import BlogVariantKeywords from '../Blog/BlogVariantKeywords';
import { useSelector } from 'react-redux';
import {
    getActiveBlogIndex, getBlogGeneratingContent, getGeneratedBlogs,
    setBlogActiveButton, setBlogActiveContent, setBlogActiveVersionIndex, setBlogContent, setBlogGeneratingContent, setGenerateLoading
} from '../../../redux/slices/blogGeneratorSlice';
import api from '../../../api';
import { useParams } from 'react-router-dom';
import { getSocket, getToken, setUser } from '../../../redux/slices/applicationSlice';
import { getUserUpdateThunk } from '../../../redux/thunks/applicationThunks';
import ImageUploader from "quill-image-uploader";
import Quill from "quill";
import 'quill-image-uploader/dist/quill.imageUploader.min.css';
// Quill.register("modules/imageUploader", ImageUploader);

import { toast } from 'react-toastify';
import { checkUserHaveCredits } from '../../../helpers/userHelpers';
import useScreenWidth from '../../../helpers/useScreenWidth';
 

const LandingPageCardContent = ({index, isFullScreen, setCardFullScreen, setUpdateBlogs, markup, isNotDone = false}) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const blogs = useSelector(getGeneratedBlogs);
    const socket = useSelector(getSocket);
    
    const activeBlogIndex = useSelector(getActiveBlogIndex);
    const generatingContent = useSelector(getBlogGeneratingContent);
    const { activeVersionIndex, versions, selectedVersionId } = blogs[activeBlogIndex] || {};
    const isSelected = selectedVersionId === versions[activeVersionIndex]?.id;
    const { id } = useParams();
    const screenWidth = useScreenWidth();
    const mobileScreen = screenWidth < 1199;

    const { quill, quillRef } = useQuill({
        theme: 'snow',
        modules: {
          toolbar: '#toolbar' + index,
          history: {
            delay: 2000,
            userOnly: true
          },
          imageUploader: {
            upload: (file) => {
                return new Promise(async (resolve, reject) => {
                    try {
                        const { data: { attachments }, success } = await api.assets.changeAsset({
                            id,
                            data: {
                                attachments: file
                            },
                            token
                        })
                        if(success) {
                            resolve(process.env.REACT_APP_API_URL + attachments[attachments.length - 1].path);
                        }
                        reject("Upload failed");
                    } catch (e) {
                        reject("Upload failed");
                    }
                });
              },
          }
        },
        formats: [
            'bold', 'italic', 'underline', 'align', 'link', 'image', 'video',
            'color', 'background', 'header', 'font', 'size',
            'clean', 'imageBlot'
        ],
    });

    const [showHTMLcontent, setShowHTMLcontent] = useState(true);
    const [isEdit, setIsEdit] = useState(false);

    const [editorActiveButton, setEditorActiveButton] = useState(null);
    const [imagePopupOpened, setImagePopupOpened] = useState(false);
    const [imageLinkOpened, setImageLinkOpened] = useState(false);
    const [footerActions, setFooterActions] = useState({
        keyword: false,
        customPrompt: false
    });
    const [customPrompt, setCustomPrompt] = useState('');
    const [editBlogLoading, setEditBlogLoading] = useState(false);

    const changeIsEditAction = (value) => {
        quill.setText(generatingContent || markup);

        if(value){
            setIsEdit(true);

            setTimeout(() => {
                setShowHTMLcontent(false);
            }, 30);
        } else {
            setIsEdit(false);
            setShowHTMLcontent(true);
        }
    }

    const editBlog = async({operation, isActive, customPayload = {}}) => {
        let checkRes = await checkUserHaveCredits(token);
        if(!checkRes?.success){
            dispatch(setUser(checkRes?.data));
            return;
        }

        dispatch(setGenerateLoading(false));
        setEditBlogLoading(true);

        socket.send({
            event: 'landing-page-edit',
            payload: {
                content: versions[activeVersionIndex]?.content,
                operation,
                ...customPayload
            },
            handler: (response) => {
                if(response?.isError){
                    setEditBlogLoading(false);
                    dispatch(setGenerateLoading(false));

                    toast.error("AI bot is not available at the moment");

                    return;
                }

                if(quillRef?.current?.firstChild){
                    const { firstChild } = quillRef.current;
                    firstChild.scroll({ top: firstChild.scrollHeight, behavior: 'smooth' });
                }

                const setEditContent = () => {
                    isActive ? dispatch(setBlogActiveContent(response.payload)) : dispatch(setBlogContent(response.payload));
                } 

                if(response.isDone) {
                    dispatch(setBlogGeneratingContent(null));
                    setEditContent();
                    // setUpdateBlogs(true);
                    setEditBlogLoading(false);
                    dispatch(getUserUpdateThunk({ token }));
                    return;
                } else {
                    setEditContent();
                }

                dispatch(setBlogGeneratingContent(response.payload));
            }
        })
    }
    const wordsCount = markup?.split(' ').filter(word => word.length > 0).length;
    const imageButtonRef = useRef();

    useEffect(() => {
        if (!quill || !markup) return;
        quill.disable();
        quill.setText(generatingContent || markup);
        // quill.clipboard.dangerouslyPasteHTML(generatingContent || markup, 'api');
    }, [quill, markup, generatingContent]);

    useEffect(() => {
        if (!quill) return;
        if(isEdit) {
            quill.enable();
        } else {
            quill.disable();
        }
    }, [quill, isEdit]);


    return <>
        <div 
            id={'toolbar'+ index}
            className={classNames("flex justify-between py-[6px] px-[8px] rounded-full bg-[#1B1B1B] mx-[16px] min-h-[40px]", isNotDone ? 'block-disabled' : '')}
            style={{
                display: isEdit ? 'flex' : 'none'
            }}
        >
            <button
                onClick={() => {
                    changeIsEditAction(false);
                }}
                className={classNames('blog-generator-button label fill text-[#A7A7A7]')}
                style={{ width: "initial" }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M14.8688 5.60674L12.5063 3.26299L13.2375 2.51299C13.475 2.27549 13.7656 2.15674 14.1094 2.15674C14.4531 2.15674 14.7625 2.28174 15.0375 2.53174L15.6188 3.11299C15.8938 3.37549 16.0156 3.67861 15.9844 4.02236C15.9531 4.36611 15.8188 4.65674 15.5813 4.89424L14.8688 5.60674ZM3.1125 15.8817C2.85 15.8817 2.64062 15.8005 2.48438 15.638C2.32813 15.4755 2.25 15.2755 2.25 15.038V13.8755C2.25 13.7755 2.275 13.6661 2.325 13.5474C2.375 13.4286 2.43125 13.338 2.49375 13.2755L11.7375 4.05049L14.1 6.37549L4.89375 15.6192C4.79375 15.6942 4.6875 15.7567 4.575 15.8067C4.4625 15.8567 4.35625 15.8817 4.25625 15.8817H3.1125Z" fill="#A7A7A7"/>
                </svg>
                Close Edit Manually
            </button>

            <div style={{ display: 'none' }}>
                <EditorToolbar
                    isEdit={isEdit}
                    quill={quill}
                    activeButton={editorActiveButton}
                    setActiveButton={setEditorActiveButton}
                    setImagePopupOpened={setImagePopupOpened}
                    setImageLinkOpened={setImageLinkOpened}
                    isFullScreen={isFullScreen}
                    setCardFullScreen={setCardFullScreen}
                    editBlog={editBlog}
                    imageButtonRef={imageButtonRef}
                />
            </div>
        </div>
        <div
            className={classNames("flex justify-between py-[6px] px-[8px] rounded-full bg-[#1B1B1B] mx-[16px] default-toolbar-wrpr",
            isEdit ? 'hidden' : 'flex')}
        >
            <DefaultToolbar
                disabled={isNotDone || editBlogLoading}
                footerActions={footerActions}
                setBlogIsEdit={changeIsEditAction}
                setFooterActions={setFooterActions}
                isFullScreen={isFullScreen}
                setCardFullScreen={setCardFullScreen}
                editBlog={editBlog}
                hiddenButtons={["Emojis", "List", "Copy", "History"]}
            />
        </div>

        <pre className={classNames(isEdit ? 'flex overflow-y-auto' : 'hidden', 'hide-ql-tooltip')}
            ref={quillRef}
            // style={{display: isEdit ? 'block': 'none'}}
        />
        <div
            className={classNames(mobileScreen ? 'overflow-x-auto max-w-[calc(100vw-124px)]' : '', !showHTMLcontent ? 'hidden' : 'flex', "p-[20px] overflow-y-auto no-scroll", versions?.length > 1 ? 'mb-[56px]' : 'mb-[20px]')}
            dangerouslySetInnerHTML={{ __html: markup }}
        ></div>

        <div
            // className={classNames('blog-generator-card-footer', isOpen || isFullScreen ? 'block' : 'hidden')}
            className='blog-generator-card-footer'
        >
            {isEdit ? <EditorControl
                activeButton={editorActiveButton}
                closeEditor={() => changeIsEditAction(false)}
                imageLinkOpened={imageLinkOpened}
                quill={quill}
                setActiveButton={setEditorActiveButton}
                setImageLinkOpened={setImageLinkOpened}
                // setUpdateBlogs={setUpdateBlogs}
                onlyInnerTextEmit={true}
            /> : footerActions.keyword ? <BlogVariantKeywords
                data={versions[activeVersionIndex]}
                setUpdateBlogs={setUpdateBlogs}
                editBlog={editBlog}
                setFooterActions={setFooterActions}
            /> : footerActions.customPrompt ? <div>
                <div className='flex gap-[10px] mob-flex-wrap'>
                    <input
                        className='bg-inherit h-[32px] text-[13px] flex-1 pl-[12px] pr-[8px]
                        border border-solid border-[#333] rounded-[8px]'
                        value={customPrompt}
                        onChange={(e) => setCustomPrompt(e.target.value)}
                        placeholder="Ex. Rewrite the introductory paragraph to create a stronger hook and engage the reader's interest from the start..."
                    />
                    <button 
                        className='goal-generator-action btn--primary-gradient btn'
                        style={{
                            height: 32,
                            padding: '0 12px',
                            fontSize: 13
                        }}
                        onClick={() => {
                            dispatch(setBlogActiveContent(null));
                            dispatch(setBlogActiveButton(null));
                            editBlog({
                                operation: 'custom',
                                isActive: false,
                                customPayload: {
                                    data: customPrompt
                                }
                            });
                            setFooterActions({
                                keyword: false,
                                customPrompt: false
                            });
                        }}
                        disabled={!customPrompt}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M13.3337 4.33301L6.00033 11.6663L2.66699 8.33301" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Apply
                    </button>
                </div>
            </div> : versions?.length > 1 ? <div className='flex justify-between items-center'>
                <ul className='flex gap-[8px]'>
                    {versions.map((value, index) => <li
                        key={index}
                        className={classNames(`rounded-[8px] border border-solid border-[#333333] bg-[#1B1B1B]
                        p-[10px] h-[32px] flex items-center text-[13px] opacity-90 text-[#A7A7A7] cursor-pointer hover:text-white`, {
                            'border-[#FF8114] text-white': index === activeVersionIndex
                        })}
                        onClick={() => dispatch(setBlogActiveVersionIndex(index))}
                    >
                        <span className="capitalize">{ value?.pageName || value?.title }</span>
                    </li>)}
                </ul> 
                <div className='flex gap-[16px] items-center'>
                    <span className='text-[13px] opacity-30'>
                        {wordsCount} words
                    </span>
                </div>
            </div> : null }
        </div>
    </>
};

export default LandingPageCardContent;