export const axiosConfig = {
    baseURL: 'https://works.webstaginghub.com/openai-api',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
}
console.log(process.env.REACT_APP_TEST_API_URL, "process.env.REACT_APP_API_URL");

export const apiConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'X-Requested-With': 'XMLHttpRequest',
    },
}

export const testApiConfig = {
    baseURL: process.env.REACT_APP_TEST_API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'X-Requested-With': 'XMLHttpRequest',
    },
}