import React from 'react'
import { Link, NavLink, Route, Routes, useLocation } from 'react-router-dom'
import Header from '../../components/parts/Header'

import iconPerson from '../../assets/img/icons/icon-person.svg';
import iconZip from '../../assets/img/icons/icon-zip-code.svg';
import {ReactComponent as IconUser} from '../../assets/img/icons/ico-user.svg'
import {ReactComponent as IconLock} from '../../assets/img/icons/ico-lock.svg'
import {ReactComponent as IconCredit} from '../../assets/img/icons/ico-credit.svg'
import {ReactComponent as IconNotification} from '../../assets/img/icons/ico-notification.svg'
import {ReactComponent as IconReferral} from '../../assets/img/icons/ico-referral.svg'
import {ReactComponent as IconSettigns} from '../../assets/img/icons/ico-settings.svg'

import SettingsAccount from './SettingsAccount';
import SettingsBilling from './SettingsBilling';
import SettingsSecurity from './SettingsSecurity';
import SettingsNotifications from './SettingsNotifications';
import SettingsReferal from './SettingsReferal';
import SettingsGlobal from './SettingsGlobal';


function Settings() {
    const location = useLocation();
    const locationPathname = location.pathname;
    console.log('location', location);
    let routesConfig = [
        {
            title: "Account", 
            description: "Manage your password and 2-step verification preferences",
            path:"account",
            icon: IconUser,
            component: SettingsAccount
        },
        {
            title: "Security", 
            description: "Manage your password and 2-step verification preferences",
            path:"security",
            icon: IconLock,
            component: SettingsSecurity

        },
        {
            title: "Plan & Billing", 
            description: <>
                Manage your password and 2-step verification preferences
                <br></br>
                billing information
            </>,
            path:"billing",
            icon: IconCredit,
            component: SettingsBilling

        },
        {
            title: "Notifications", 
            description: "Manage when you'll be notified on which channels",
            path:"notifications",
            icon: IconNotification,
            component: SettingsNotifications

        },
        {
            title: "Referral Program", 
            description: "Share and earn!",
            path:"referal",
            icon: IconReferral,
            component: SettingsReferal

        },
        {
            title: "SettingsOld Section",
            description: "Update your setting option",
            path:"section",
            icon: IconSettigns,
            component: SettingsGlobal

        },

    ]
    return (
        <section className="page-section">
            <div className="container px-[20px]">
                <div className="row-heading row-heading--lighten-60 
                rounded-[16px] px-[20px]">
                    <div className="heading-item py-[10px]">
                        <div className="title-24 font-[800]">
                            Account Settings
                        </div>
                        <div className="text-2">
                            <p>Control your account</p>
                        </div>
                    </div>
                </div>
                <div className="tabs-container mt-[20px] mb-[-20px]">
                    <div className="tabs-sections mb-[20px]">
                        <ul className="tabs-list">
                            {routesConfig.map((routeItem)=>{
                                return (
                                    <li key={routeItem.path} className={`tabs-list--item p-[20px] ${ (locationPathname==='/settings'+routeItem.path || (locationPathname==='/settings/'+routeItem.path))?'active-tab':''}`}>
                                        <NavLink to={routeItem.path} className="btn btn-link items-start">
                                            <span className="ico ico-36 mr-[10px]">
                                                <routeItem.icon></routeItem.icon>
                                            </span>
                                            <div className="flex flex-col text-left">
                                                <h3 className="title-24">{routeItem.title}</h3>
                                                <div className="mt-[10px]">
                                                    <p className="text-1">
                                                        {routeItem.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <Routes>
                        {routesConfig.map(item=>{
                            return <Route key={item.path} path={item.path} Component={item.component}/>
                        })}
                    </Routes>
                </div>
            </div>
        </section>
    )
}

export default Settings