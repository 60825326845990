import { toast } from 'react-toastify';
import api from '../api';


export const checkUserHaveCredits = async(token) => {
    let userRes = await api.users.getUser({ token });
    
    if(userRes?.data){
        if(userRes?.data?.credits < 1){
            toast.error("Not Enough Credits");
        }

        return {
            data: userRes?.data,
            success: userRes?.data?.credits > 0
        };
    }
}