const base = 'api/share-kits';

export const share = (instance) => ({
    async shareKit(payload) {
        const { data } = await instance.post(`${base}`, payload.data, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async getKitData(payload) {
        const { data } = await instance.get(`${base}/${payload.id}`);
        return data;
    },
})