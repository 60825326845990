const assetsBase = 'api/assets';

export const assets = (instance) => ({
    async createAsset(payload) {
        const { data } = await instance.postForm(assetsBase, payload.data, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async getAssets(payload) {
        // const { data } = await instance.get(`${assetsBase}?${payload?.queries || ''}`, {
        const { data } = await instance.get(`${assetsBase}?${payload?.queries || ''}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async getAssetById(payload) {
        const { data } = await instance.get(`${assetsBase}/${payload.id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async changeAsset(payload) {
        const { data } = await instance.putForm(`${assetsBase}/${payload.id}`, payload.data, {
            formSerializer: {
                indexes: null
            },
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async deleteAsset(payload) {
        const { data } = await instance.delete(`${assetsBase}/${payload.id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async cloneAsset(payload) {
        const { data } = await instance.get(`${assetsBase}/clone/${payload.id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async exportAsset(payload) {
        try {
            const response = await instance.get(`${assetsBase}/export/${payload.id}?type=${payload.type}&ext=${payload.ext}`, {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                },
                responseType: 'blob'
            });
            const url = URL.createObjectURL(response.data);
            const a = document.createElement('a');
            a.href = url;
            a.download = payload.filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } catch(e) {
            console.log(e);
        }
    },
    async getProducts() {
        const { data } = await instance.get(`api/products`);
        return data;
    },
    async getProductСategories() {
        const { data } = await instance.get(`api/product-categories`);
        return data;
    },
})