const base = 'api/settings';

export const settings = (instance) => ({
    async getSettings(payload) {
        const { data } = await instance.get(`${base}`);
        return data;
    },
    async getKeepPositive(payload) {
        const { data } = await instance.get(`/api/keep-positive`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async createKeepPositive(payload) {
        const { data } = await instance.post(`/api/keep-positive`, {
                "text": payload.data,
                "is_global": true
            }, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async keepPositiveToggleFavorite(payload) {
        const { data } = await instance.get(`/api/keep-positive/toggle-favorite/${payload.id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async keepPositiveDelete(payload) {
        const { data } = await instance.delete(`/api/keep-positive/${payload.id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
})