import React, { useState } from 'react';
import Select from 'react-select';
import SelectBox from './SelectBox';
import Label from './Label';
import Input from './Input';
import TextArea from './TextArea';
import FileDropzone from './FileDropzone';
import extensionIcons from '../../configs/extensionIcons';
import { bytesToSize } from '../../routes/ChatBot/Uploads';
import PrimaryButton from './PrimaryButton';
import api from '../../api';
import { useSelector } from 'react-redux';
import { getToken } from '../../redux/slices/applicationSlice';
import { toast } from 'react-toastify';


const HelpModal = ({ closeModal }) => {
    const token = useSelector(getToken);
    const [stage, setStage] = useState('initial');

    const [formValues, setFormValues] = useState({
        topic: '',
        subject: '',
        description: '',
        attachments: []
    });
    const disabledButton = !formValues.topic || !formValues.subject || !formValues.description;

    const handleSubmit = async () => {
        if(stage === 'problem') {
            const toastId = toast.loading('Creating a report...');
            try {
                const response = await api.reports.createReport({
                    data: formValues,
                    token
                });
                toast.update(toastId, {
                    render: response.success ? 'Report created successfully!' : 'Error. Please try again.',
                    type: response.success ? 'success' : 'error', 
                    isLoading: false,
                    autoClose: 5000
                });
                if(response.success) closeModal();
            } catch (e) {
                if(e?.response?.data?.message){
                    toast.error(e?.response?.data?.message);
                }
            }
        } else {
            const toastId = toast.loading('Creating a feedback...');
            try {
                const response = await api.feedbacks.createFeedback({
                    data: formValues,
                    token
                });
                toast.update(toastId, {
                    render: response.success ? 'Feedback created successfully!' : 'Error. Please try again.',
                    type: response.success ? 'success' : 'error', 
                    isLoading: false,
                    autoClose: 5000
                });
                if(response.success) closeModal();
            } catch (e) {
                if(e?.response?.data?.message){
                    toast.error(e?.response?.data?.message);
                }
            }
        }
    }
    

    return <div className='w-[calc(100vw-30px)] lg:w-[572px] p-[36px] bg-[#151515] relative rounded-[16px] lg:max-h-[calc(100vh-60px)] lg:overflow-hidden flex flex-col'>
    <button 
        className='w-[34px] h-[34px] flex items-center justify-center rounded-[8px]
        absolute top-[16px] right-[16px] border border-solid border-[#333333] bg-[#1B1B1B]'
        onClick={closeModal}
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g opacity="0.5">
                <path d="M15 5L5 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M5 5L15 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </svg>
    </button>
    {stage === 'initial' && <>
        <h2 className='text-[24px] font-medium leading-[24px] opacity-90'>Need Help?</h2>
        <p className='text-[14px] text-[#A7A7A7] opacity-50 leading-[22px] mt-[20px]'>
            Tell if you stuck with problem or leave feedback for us.
        </p>
        <div className='flex gap-[16px] mt-[36px] mob-flex-wrap'>
            <button
                className='min-w-[200px] flex-1 flex flex-col items-center justify-center py-[24px] px-[10px] bg-[#202020] rounded-[8px]'
                onClick={() => setStage('problem')}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M4.66268 5.30371V24.5045" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M4.6626 19.2521C7.23074 17.7846 10.3441 17.6274 13.047 18.8287L14.9526 19.6756C17.6555 20.8769 20.7689 20.7196 23.337 19.2521V5.30347C20.7689 6.77099 17.6555 6.92823 14.9526 5.72692L13.0471 4.88002C10.3441 3.67872 7.23075 3.83595 4.6626 5.30347" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <h3 className='text-[13px] font-medium opacity-90 mt-[20px]'>Report a Problem</h3>
                <p className='text-[12px] opacity-50 mt-[12px]'>
                    Tell us about your problem
                </p>
            </button>
            <button
                className='min-w-[200px] flex-1 flex flex-col items-center justify-center py-[24px] px-[10px] bg-[#202020] rounded-[8px]'
                onClick={() => setStage('feedback')}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.9269 8.62036C13.1284 8.2118 13.5445 7.95312 14 7.95312C14.4556 7.95312 14.8716 8.2118 15.0732 8.62036L15.9486 10.395C16.1229 10.7482 16.4598 10.9931 16.8496 11.0498L18.8076 11.3343C19.2582 11.3998 19.6327 11.7155 19.7735 12.1486C19.9142 12.5817 19.797 13.0571 19.471 13.3751L18.0539 14.7573C17.772 15.0323 17.6434 15.4282 17.71 15.8163L18.0444 17.766C18.1214 18.2149 17.9369 18.6686 17.5685 18.9363C17.2 19.204 16.7115 19.2394 16.3084 19.0275L14.5567 18.107C14.2082 17.9238 13.7919 17.9238 13.4433 18.107L11.6917 19.0276C11.2885 19.2395 10.8 19.2042 10.4316 18.9364C10.0631 18.6687 9.87861 18.215 9.95562 17.7661L10.29 15.8163C10.3566 15.4282 10.228 15.0323 9.94617 14.7573L8.5291 13.3751C8.20309 13.0571 8.08583 12.5817 8.22662 12.1486C8.36741 11.7155 8.74183 11.3998 9.19251 11.3343L11.1504 11.0498C11.5403 10.9931 11.8772 10.7482 12.0515 10.395L12.9269 8.62036Z" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M24.5381 10.5757L23.3672 10.9562" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M4.6329 17.0435L3.46191 17.4239" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.9998 25.083V23.8516" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.9998 2.91699V4.14848" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M20.5083 22.9579L19.7853 21.9626" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7.49182 5.04271L8.21489 6.03802" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M3.46191 10.5757L4.6329 10.9562" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M24.5382 17.424L23.3672 17.0435" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M20.5083 5.04271L19.7853 6.03802" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7.49182 22.9579L8.21489 21.9626" stroke="#FF8114" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <h3 className='text-[13px] font-medium opacity-90 mt-[20px]'>Leave a Feedback</h3>
                <p className='text-[12px] opacity-50 mt-[12px]'>
                    Leave suggestions for us
                </p>
            </button>
        </div>
    </>}
    {stage === 'problem' && <>
        <h2 className='text-[24px] font-medium leading-[24px] opacity-90'>Report a Problem</h2>
        <p className='text-[14px] text-[#A7A7A7] opacity-50 leading-[22px] mt-[20px] mb-[36px]'>
            Tell us about your problem
        </p>
        <div className='overflow-y-scroll no-scroll flex-1'>
        <Label className='mb-[10px]'>Select Topic</Label>
        <SelectBox
            className='input-custom__wrap-input gen4-settings-select'
            propsOptions={[
                {value: '', label: 'Select Topic', placeholder: true},
                {value: 'Account Issues', label: 'Account Issues'},
                {value: 'Technical Glitches', label: 'Technical Glitches'},
                {value: 'Bug Reporting', label: 'Bug Reporting'},
                {value: 'Content Issues', label: 'Content Issues'},
                {value: 'Payment Problems', label: 'Payment Problems'},
                {value: 'Accessibility Concerns', label: 'Accessibility Concerns'},
            ]}
            value={formValues.topic}
            change={(topic) => setFormValues(prev => ({...prev, topic}))}
            
        />
        <Label className='mt-[16px]'>Problem Subject</Label>
        <Input
            value={formValues.subject}
            onChange={(e) => setFormValues(prev => ({...prev, subject: e.target.value}))}
            placeholder='Type problem subject...'
            className='w-full mt-[10px]'
        />
        <Label className='mt-[16px]'>Problem Description</Label>
        <TextArea
            value={formValues.description}
            onChange={(e) => setFormValues(prev => ({...prev, description: e.target.value}))}
            placeholder='Describe your problem here...'
            className='w-full mt-[10px] h-[120px]'
        />
        <Label className='mt-[16px] mb-[10px]'>Attachments <span className='opacity-40'>(optional)</span></Label>
        { formValues.attachments?.length < 10 ? 
            <FileDropzone
                options={{
                    maxSize: Math.pow(10, 7),
                    accept: {
                        'application/pdf': [],
                        'image/jpeg': [],
                        'image/png': []
                    },
                    onDropAccepted: (files) => {
                        if((files?.length + formValues.attachments?.length) > 10){
                            toast.error('Maximum number of files: 10');
                            return;
                        }

                        setFormValues({
                            ...formValues,
                            attachments: [...formValues.attachments, ...files]
                        })
                        // const formatFiles = files.map(file => ({
                        //     name: file.name,
                        //     size: bytesToSize(file.size)
                        // }))
                        // setDisplayFiles([...displayFiles, ...formatFiles]);
                    }
                }}
            />
        : null }
        <ul className='flex flex-col gap-[8px] mt-[16px]'>
            {formValues.attachments.map(({name, size}, index) => <li
                key={index}
                className='p-[16px] flex justify-between items-center bg-[#202020] rounded-[8px]'
            >
                <div className='flex gap-[16px] items-center flex-1'>
                    {extensionIcons[name.split('.')[1]]}
                    <div className='flex-1'>
                        <p className='text-[13px] opacity-90 line-clamp-1'>{name}</p>
                        <p className='text-[12px] opacity-50 font-light'>{bytesToSize(size)}</p>
                    </div>
                </div>
                <svg 
                    onClick={() => setFormValues(prev => {
                        const result = {...prev};
                        result.attachments.splice(index, 1);
                        return result;
                    })} 
                    cursor="pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g opacity="0.5">
                        <path d="M15 5L5 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5 5L15 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                </svg>
            </li>)}
        </ul>
        </div>
        <div className='flex items-center justify-end gap-[10px] mt-[36px]'>
            <button 
                style={{
                    height: 40,
                    padding: '0 20px'
                }} 
                className='modal-button'
                onClick={closeModal}
            >
                Cancel
            </button>
            <PrimaryButton 
                style={{
                    height: 40,
                    padding: '0 20px'
                }}
                disabled={disabledButton}
                onClick={handleSubmit}
            >
                Submit
            </PrimaryButton>
        </div>
    </>}
    {stage === 'feedback' && <>
        <h2 className='text-[24px] font-medium leading-[24px] opacity-90'>Leave a Feedback</h2>
        <p className='text-[14px] text-[#A7A7A7] opacity-50 leading-[22px] mt-[20px] mb-[36px]'>
            Leave suggestions for us
        </p>
        <div className='overflow-y-scroll no-scroll flex-1'>
        <Label className='mb-[10px]'>Select Topic</Label>
        <SelectBox
            className='input-custom__wrap-input gen4-settings-select'
            propsOptions={[
                {value: '', label: 'Select Topic', placeholder: true},
                {value: 'User Experience', label: 'User Experience'},
                {value: 'Features and Functionality', label: 'Features and Functionality'},
                {value: 'Content Feedback', label: 'Content Feedback'},
                {value: 'Performance', label: 'Performance'},
                {value: 'Customer Service', label: 'Customer Service'},
                {value: 'Design and Aesthetics', label: 'Design and Aesthetics'},
                {value: 'Positive Feedback', label: 'Positive Feedback'},
            ]}
            value={formValues.topic}
            change={(topic) => setFormValues(prev => ({...prev, topic}))}
            
        />
        <Label className='mt-[16px]'>Problem Subject</Label>
        <Input
            value={formValues.subject}
            onChange={(e) => setFormValues(prev => ({...prev, subject: e.target.value}))}
            placeholder='Type problem subject...'
            className='w-full mt-[10px]'
        />
        <Label className='mt-[16px]'>Problem Description</Label>
        <TextArea
            value={formValues.description}
            onChange={(e) => setFormValues(prev => ({...prev, description: e.target.value}))}
            placeholder='Describe your problem here...'
            className='w-full mt-[10px] h-[120px]'
        />
        <Label className='mt-[16px] mb-[10px]'>Attachments <span className='opacity-40'>(optional)</span></Label>
        { formValues.attachments?.length < 10 ?
            <FileDropzone
                options={{
                    maxSize: Math.pow(10, 7),
                    accept: {
                        'application/pdf': [],
                        'image/jpeg': [],
                        'image/png': []
                    },
                    onDropAccepted: (files) => {
                        if((files?.length + formValues.attachments?.length) > 10){
                            toast.error('Maximum number of files: 10');
                            return;
                        }

                        setFormValues({
                            ...formValues,
                            attachments: [...formValues.attachments, ...files]
                        })
                        // const formatFiles = files.map(file => ({
                        //     name: file.name,
                        //     size: bytesToSize(file.size)
                        // }))
                        // setDisplayFiles([...displayFiles, ...formatFiles]);
                    }
                }}
            />
        : null }
        <ul className='flex flex-col gap-[8px] mt-[16px]'>
            {formValues.attachments.map(({name, size}, index) => <li
                key={index}
                className='p-[16px] flex justify-between items-center bg-[#202020] rounded-[8px]'
            >
                <div className='flex gap-[16px] items-center flex-1'>
                    {extensionIcons[name.split('.')[1]]}
                    <div className='flex-1'>
                        <p className='text-[13px] opacity-90 line-clamp-1'>{name}</p>
                        <p className='text-[12px] opacity-50 font-light'>{bytesToSize(size)}</p>
                    </div>
                </div>
                <svg 
                    onClick={() => setFormValues(prev => {
                        const result = {...prev};
                        result.attachments.splice(index, 1);
                        return result;
                    })} 
                    cursor="pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g opacity="0.5">
                        <path d="M15 5L5 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5 5L15 15" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                </svg>
            </li>)}
        </ul>
        </div>
        <div className='flex items-center justify-end gap-[10px] mt-[36px]'>
            <button 
                style={{
                    height: 40,
                    padding: '0 20px'
                }} 
                className='modal-button'
                onClick={closeModal}
            >
                Cancel
            </button>
            <PrimaryButton 
                style={{
                    height: 40,
                    padding: '0 20px'
                }}
                disabled={disabledButton}
                onClick={handleSubmit}
            >
                Submit
            </PrimaryButton>
        </div>
    </>}
</div>
};

export default HelpModal;