import React, { useRef, useEffect, useState } from 'react'
import { useClickOutside } from '../../helpers/useClickOutside';
import CreatableSelect from "react-select/creatable";
import Select, { components, MenuListProps } from "react-select";

export const colourOptions = [
    { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
    { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
    { value: 'purple', label: 'Purple', color: '#5243AA' },
    { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
    { value: 'orange', label: 'Orange', color: '#FF8B00' },
    { value: 'yellow', label: 'Yellow', color: '#FFC400' },
    { value: 'green', label: 'Green', color: '#36B37E' },
    { value: 'forest', label: 'Forest', color: '#00875A' },
    { value: 'slate', label: 'Slate', color: '#253858' },
    { value: 'silver', label: 'Silver', color: '#666666' },
  ];

  const menuHeaderStyle = {
    padding: "8px 12px",
    background: colourOptions[2].color,
    color: "white",
  };

const MenuList = (props) => {
    return (
        <components.MenuList {...props}>
            {props.children}
        </components.MenuList>
    );
};

const Option = (props) => {
    return (
        <components.Option {...props}>
            <li
                // onClick={handleToggle(item)}
                className='flex items-center gap-[10px] py-[6px] px-[8px] cursor-pointer'
            >
                {!props.data.__isNew__ &&
                <div 
                    className={`min-w-[20px] h-[20px] rounded-[4px] flex items-center justify-center
                    ${props.isSelected ? 'bg-orange-primary' : 'bg-[#232323]'}`}
                >
                    {props.isSelected &&
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M11.6654 3.79169L5.2487 10.2084L2.33203 7.29169" stroke="#331A04" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    }
                </div>}
                <span className='text-[#A7A7A7] text-[14px] line-clamp-1'>{props.label}</span>
            </li>
        </components.Option>
    );
};
const InputMultiSelect = ({ placeholder, value = [], options, change }) => {
    return <CreatableSelect
        // defaultValue={colourOptions[1]}
        options={options}
        components={{ MenuList, Option, IndicatorSeparator: null }}
        isMulti
        // menuIsOpen
        styles={{
            option: (baseStyles, state) => ({
                ...baseStyles,
                // borderColor: state.isFocused ? 'grey' : 'red',
                backgroundColor: '#1B1B1B',
                ':focus': {
                    backgroundColor: '#1B1B1B',
                },
                ":active": {
                    backgroundColor: '#1B1B1B',
                }
            }),
            control: (baseStyles, props) => ({
                ...baseStyles,
                backgroundColor: '#1B1B1B',
                borderColor: '#333',
                boxShadow: 'none',
                ":hover": {
                    borderColor: '#333',
                },
                minHeight: 40,
                borderRadius: 8
            }),
            menu: (baseStyles, props) => ({
                ...baseStyles,
                backgroundColor: '#1B1B1B',
                borderColor: '#333',
                ":hover": {
                    borderColor: '#333',
                }     
            }),
            multiValue: (baseStyles, props) => ({
                ...baseStyles,
                backgroundColor: '#383838',
                borderColor: '#333',
                ":hover": {
                    borderColor: '#383838',
                },
                color: 'white'
            }),
            multiValueLabel: (baseStyles, props) => ({
                ...baseStyles,
                color: 'white'
            }),
            input: (baseStyles, props) => ({
                ...baseStyles,
                color: 'white'    
            }),
        }}
        // minMenuHeight={400}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        placeholder={placeholder}
        value={value}
        onChange={(value) => change(value)}
    />
}

export default InputMultiSelect;