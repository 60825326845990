import React, { useEffect } from 'react'


const TextareaSuggestion = ({ value, onInput, placeholder, suggestions = [] }) => {
    return (
        <div className="textarea-with-suggestion input-custom__input">
            <div className="input-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.65 20.1742C4.30417 20.1742 4.02604 20.0671 3.81563 19.8527C3.60521 19.6384 3.5 19.3551 3.5 19.0027C3.5 18.6837 3.60521 18.4159 3.81563 18.1992C4.02604 17.9826 4.30417 17.8742 4.65 17.8742H13.05C13.3629 17.8742 13.6286 17.984 13.8472 18.2036C14.0657 18.4232 14.175 18.6925 14.175 19.0115C14.175 19.3638 14.0657 19.6457 13.8472 19.8571C13.6286 20.0685 13.3629 20.1742 13.05 20.1742H4.65ZM4.65 10.8242C4.30417 10.8242 4.02604 10.7129 3.81563 10.4902C3.60521 10.2676 3.5 9.99674 3.5 9.67772C3.5 9.34205 3.60521 9.06589 3.81563 8.84922C4.02604 8.63255 4.30417 8.52422 4.65 8.52422H19.375C19.7046 8.52422 19.9745 8.63401 20.1847 8.85359C20.3949 9.07319 20.5 9.35083 20.5 9.68649C20.5 10.0055 20.3949 10.2749 20.1847 10.4946C19.9745 10.7144 19.7046 10.8242 19.375 10.8242H4.65ZM4.65 15.4742C4.30417 15.4742 4.02604 15.3671 3.81563 15.1527C3.60521 14.9384 3.5 14.6634 3.5 14.3277C3.5 14.0087 3.60521 13.7409 3.81563 13.5242C4.02604 13.3076 4.30417 13.1992 4.65 13.1992H19.375C19.7046 13.1992 19.9745 13.309 20.1847 13.5286C20.3949 13.7482 20.5 14.0175 20.5 14.3365C20.5 14.6722 20.3949 14.9457 20.1847 15.1571C19.9745 15.3685 19.7046 15.4742 19.375 15.4742H4.65ZM4.65 6.12422C4.30417 6.12422 4.02604 6.01705 3.81563 5.80272C3.60521 5.5884 3.5 5.30507 3.5 4.95272C3.5 4.63372 3.60521 4.36589 3.81563 4.14922C4.02604 3.93255 4.30417 3.82422 4.65 3.82422H19.375C19.7046 3.82422 19.9745 3.93401 20.1847 4.15359C20.3949 4.37319 20.5 4.64249 20.5 4.96149C20.5 5.31384 20.3949 5.59572 20.1847 5.80712C19.9745 6.01852 19.7046 6.12422 19.375 6.12422H4.65Z" fill="white" fillOpacity="0.25"/>
                </svg>
            </div>
            <textarea
                placeholder={placeholder}                            
                value={ value }
                onInput={
                    onInput
                    // (e)=>{
                    //     setModelData((prevState)=>{ return {...prevState, describe_your_blog: e.target.value } });
                    // }
                }
            ></textarea>
            <div className="textarea-suggestion-block">
                <p>Suggestions</p>
                <div className="badges-group-wrpr">
                    {suggestions.map((item, index)=>{
                        return <a 
                            href="#" className="badge-plus-item active" key={index}
                            onClick={(e)=>{
                                e.preventDefault();
                                // setModelData((prevState)=>{ return {...prevState, describe_your_blog: prevState.describe_your_blog.length ? prevState.describe_your_blog + ', ' + item : item } });
                            }}
                        >
                            { item?.title ? item?.title : item }
                            <span className="badge-icon">
                                {
                                    item?.drop ? <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.02348 10.1447C7.92472 10.1447 7.82839 10.125 7.7345 10.0856C7.64061 10.0462 7.56533 9.98809 7.50867 9.91133L4.192 6.62799C4.05867 6.48077 3.99478 6.3023 4.00033 6.09258C4.00589 5.88286 4.07533 5.70299 4.20867 5.55299C4.37533 5.39188 4.55867 5.31966 4.75867 5.33633C4.95867 5.35299 5.13089 5.42799 5.27533 5.56133L8.02533 8.31133L10.792 5.56133C10.9309 5.41688 11.1073 5.34188 11.3212 5.33633C11.5351 5.33077 11.7142 5.40816 11.8587 5.56848C12.0142 5.71768 12.0864 5.89823 12.0753 6.11015C12.0642 6.32205 11.9864 6.49466 11.842 6.62799L8.55867 9.91133C8.49015 9.98809 8.40851 10.0462 8.31377 10.0856C8.21901 10.125 8.12225 10.1447 8.02348 10.1447Z" fill="white" fillOpacity="0.5"/>
                                    </svg> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.73316 11.8168C5.57761 11.6502 5.49983 11.464 5.49983 11.2585C5.49983 11.0529 5.57761 10.8779 5.73316 10.7335L8.4665 7.98349L5.7165 5.21682C5.56094 5.08349 5.48594 4.90571 5.4915 4.68349C5.49705 4.46127 5.57761 4.27793 5.73316 4.13349C5.87761 3.97793 6.05261 3.90293 6.25817 3.90849C6.46372 3.91405 6.63872 3.98905 6.78317 4.13349L10.0998 7.45016C10.1665 7.51682 10.2221 7.59738 10.2665 7.69182C10.3109 7.78627 10.3332 7.88349 10.3332 7.98349C10.3332 8.0946 10.3109 8.1946 10.2665 8.28349C10.2221 8.37238 10.1665 8.45016 10.0998 8.51682L6.79983 11.8168C6.65539 11.9613 6.47761 12.0307 6.2665 12.0252C6.05539 12.0196 5.87761 11.9502 5.73316 11.8168Z" fill="#A7A7A7"/>
                                    </svg>
                                }
                            </span>
                        </a>
                    })}
                </div>
            </div>
        </div>
    )
}

export default TextareaSuggestion;