import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

// import GeneratedContent from './GeneratedContent';

import logo from '../../assets/img/gen4logo.svg';

import { Navigate, useNavigate, useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getGeneratedWords, setWordState } from '../../redux/slices/newWordGeneratorSlice';

import api from '../../api';
import { getToken } from '../../redux/slices/applicationSlice';
import { useDispatch } from 'react-redux';
import NewWordContent from './NewWordContent';

const NewWordSharedPage = () => {
    const { id } = useParams();
    const words = useSelector(getGeneratedWords);
    const token = useSelector(getToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [sideBarOpened, setSideBarOpened] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if(!id) return;
        const fetchWords = async () => {
            try {
                const response = await api.share.getKitData({
                    id
                });
                if(!response.success) return;
                dispatch(setWordState(JSON.parse(response.data.payload.content_edit)));
            } catch (e) {
                console.log(e);
                if(e.response.status === 404) {
                    navigate('/new-words', {
                        replace: true
                    })
                }
            }
        }
        fetchWords();
    }, [id, token, dispatch, navigate])
    if(!id && !words.length) return <Navigate to='/generated-content?type=new-word' replace />;
    return <main className='w-screen h-screen overflow-hidden flex'>
        {/* <nav className='flex flex-col w-[72px] bg-[#151515]'>
            <div className={classNames('generated-tab w-[72px] h-[60px]', {
                'generated-tab--active-top': activeTabObjectIndex === 0,
                'generated-tab--closed': !sideBarOpened,
            })}>
                <img src={logo} alt='Logo'/>
            </div>
            {navLinks.map(({ icon, title }, index) =>
                <button
                    key={index} 
                    className={classNames('generated-tab w-[72px] h-[72px]', {
                        'generated-tab--active': title === activeTab,
                        'generated-tab--active-top': index === activeTabObjectIndex - 1,
                        'generated-tab--active-bottom': index === activeTabObjectIndex + 1,
                        'generated-tab--closed': !sideBarOpened,
                    })}
                    onClick={() => {
                        setActiveTab(title);
                        setSideBarOpened(true);
                    }}
                >
                    {icon}
                    <span className='text-[10px] font-medium'>{title}</span>
                </button>
            )}
            <div className={classNames('generated-exit-wrapper', {
                    'generated-tab--active-bottom': activeTabObjectIndex === navLinks.length - 1,
                    'generated-tab--closed': !sideBarOpened,
                })}>
                    <Link to="/new-words" className='w-[72px] h-[72px] flex flex-col gap-[8px] justify-center items-center opacity-40 bg-[#111111]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M5 12.5H19" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10 7.5L5 12.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10 17.5L5 12.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span className='text-[10px] font-medium'>Exit</span>
                    </Link>
            </div>
        </nav> */}
        {/* <div className={classNames('generated-sidebar', {
            'generated-sidebar--closed': !sideBarOpened
        })}>
            <button 
                className='absolute top-[18px] right-[16px]' 
                onClick={() => {
                    setSideBarOpened(false);
                    setActiveTab(null);
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g opacity="0.5">
                        <path d="M18 6L6 18" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6 6L18 18" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                </svg>
            </button>
            {ActiveComponent && <ActiveComponent setIsLoading={setIsLoading} />}
        </div> */}
        {isLoading ? <div className="flex flex-col justify-center items-center h-full flex-1">
            <div className="lds-ring"><div /><div /><div /><div /></div>
            <div className="mt-[36px] text-[18px] font-medium leading-[13px] opacity-90">Loading...</div>
            <div className="mt-[24px] text-[14px] leading-[10px] opacity-50 text-[#A7A7A7]">Please wait for your results...</div>
        </div> : <NewWordContent setSideBarOpened={setSideBarOpened}/>}
    </main>
};

export default NewWordSharedPage;