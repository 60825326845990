import React, { useState } from 'react';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import { Accordion } from './Accordion';
import { TextBigContainer } from './TextBigContainer';

const a = 'The sun dipped below the horizon, casting a warm orange glow across the tranquil landscape. A gentle breeze rustled the leaves of the towering trees, creating a soothing melody. As dusk settled in, the stars began to twinkle in the vast expanse of the night sky, illuminating the world with their celestial light. In the distance, the sound of a babbling brook added to the symphony of nature, its rhythmic flow echoing through the air. It was a moment of serenity, where time seemed to stand still, and the beauty of the universe unfolded before your eyes.'
const b = 'The bustling city streets hummed with energy as people hurriedly went about their daily routines. The cacophony of car horns, chatter, and footsteps filled the air, creating a vibrant urban soundtrack. Neon signs illuminated the night, painting the buildings with splashes of color. Sidewalk cafes bustled with laughter and clinking glasses, while the aroma of freshly brewed coffee wafted through the air. Amidst the urban chaos, there was a sense of both anonymity and interconnectedness, as strangers passed each other with fleeting glances and shared moments of fleeting connection.'
const c = 'Stars sprinkled the velvety night sky, like diamonds scattered across a black velvet cloth.'

export const AssetsBlocksContainer = ({sortBySearch, searchable}) => {
    
    const [oneSelectedId, setOneSelectedId] = useState(null)
    const [myAssets, setMyAssets] = useState([
        {
            id: '1',
            title: 'Saved Prompts',
            content: ['Quantum', 'Gravity', 'Energy', 'Entropy', 'Electromagnetism', 'Relativity'],
            avaliableBtn: [true, true, true],
            listType: 1,
        },
        {
            id: '2',
            title: 'Saved Content Bits',
            content: [a, b, c],
            avaliableBtn: [true, true, true],
            listType: 2,
        },
        {
            id: '3',
            title: 'Websites & Variables',
            content: ['quizzicalmind.com', 'adventurousrecipes.com', 'inspiredbymusic.com'],
            avaliableBtn: [true, true, true],
            listType: 3,
        },
    ])

    const [isOpenBlocks, setIsOpenBlocks] = useState(Object.fromEntries(
        myAssets.map((el) => [el.id, true])
    ))

    const providedData = (provided) => {return (searchable ? {} : provided.dragHandleProps)}

    const oneBlockObj = oneSelectedId ? myAssets.filter(item => item.id === oneSelectedId)[0] : null

    const handleDragEnd = result => {
        const {destination,source} = result
        if (!result.destination) return
        
        const newItems= Array.from(myAssets)
        const [reOrdered] = newItems.splice(source.index, 1)
        newItems.splice(destination.index, 0, reOrdered)
        setMyAssets(newItems)
    }

    return (
        <>
        
        {!oneBlockObj ? <DragDropContext onDragEnd={result => {handleDragEnd(result)}}>
            <Droppable droppableId="blockContainer" direction="vertical">
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps} className="blog-toolkit-content__container">

                        <div className='input-search-variant-two'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <circle cx="10.215" cy="10.2155" r="5.88495" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M17.67 17.6694L14.377 14.3764" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <input  placeholder='Search assets...' />
                        </div>

                        {sortBySearch(myAssets, searchable).map((item, index) =>

                        <Draggable draggableId={item.id} index={index} key={item.id}>
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className='blog-toolkit-content__block'
                            >

                                <div className='toolkit-block-header'>
                                    <span className='toolkit-block-title'>{item.title}</span>
                                    {item.id === '2' && <div className='toolkit-block-counter'>
                                        <span>1</span>
                                    </div>}
                                </div>


                                <Accordion openBlock={isOpenBlocks[item.id]}>
                                    {item.listType === 1 && <>
                                        <ul className="btc__block-content toolkit-block-wrapper elements-2" style={{marginTop: '10px'}}>
                                        {item?.content?.map((item, idx) => <li key={idx} className="btc__block-item ">
                                                <span className="btc__item-text">{item}</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M7.99651 12.9166C7.79151 12.9166 7.61575 12.8465 7.46923 12.7062C7.3227 12.566 7.24943 12.3805 7.24943 12.15V8.76665H3.83276C3.6286 8.76665 3.45255 8.69131 3.30463 8.54065C3.15672 8.38999 3.08276 8.21055 3.08276 8.00231C3.08276 7.77854 3.15672 7.5972 3.30463 7.45831C3.45255 7.31942 3.6286 7.24998 3.83276 7.24998H7.24943V3.83331C7.24943 3.61359 7.32364 3.43366 7.47206 3.29351C7.62049 3.15338 7.79715 3.08331 8.00206 3.08331C8.20697 3.08331 8.38554 3.15338 8.53776 3.29351C8.68999 3.43366 8.7661 3.61359 8.7661 3.83331V7.24998H12.1661C12.375 7.24998 12.555 7.3204 12.7061 7.46123C12.8572 7.60207 12.9328 7.78161 12.9328 7.99983C12.9328 8.21806 12.8572 8.40042 12.7061 8.54691C12.555 8.6934 12.375 8.76665 12.1661 8.76665H8.7661V12.15C8.7661 12.3805 8.69026 12.566 8.5386 12.7062C8.38692 12.8465 8.20623 12.9166 7.99651 12.9166Z" fill="white" fillOpacity="0.5"/>
                                                </svg>
                                            </li>)}
                                        </ul>

                                    </>}

                                    {item.listType === 2 && <>
                                        <ul className="btc__block-content">
                                            {item?.content?.map((el,idx) => <TextBigContainer key={idx} data={el} /> )}
                                        </ul>
                                    </>}

                                    {item.listType === 3 && <>
                                        <ul className="btc__block-content">
                                            {(['quizzicalmind.com', 'adventurousrecipes.com', 'inspiredbymusic.com']).map((item, idx) => <li key={idx} className="btc__block-item btc__block-item-fix-delete">
                                                <span className="btc__item-text">{item}</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M7.99651 12.9166C7.79151 12.9166 7.61575 12.8465 7.46923 12.7062C7.3227 12.566 7.24943 12.3805 7.24943 12.15V8.76665H3.83276C3.6286 8.76665 3.45255 8.69131 3.30463 8.54065C3.15672 8.38999 3.08276 8.21055 3.08276 8.00231C3.08276 7.77854 3.15672 7.5972 3.30463 7.45831C3.45255 7.31942 3.6286 7.24998 3.83276 7.24998H7.24943V3.83331C7.24943 3.61359 7.32364 3.43366 7.47206 3.29351C7.62049 3.15338 7.79715 3.08331 8.00206 3.08331C8.20697 3.08331 8.38554 3.15338 8.53776 3.29351C8.68999 3.43366 8.7661 3.61359 8.7661 3.83331V7.24998H12.1661C12.375 7.24998 12.555 7.3204 12.7061 7.46123C12.8572 7.60207 12.9328 7.78161 12.9328 7.99983C12.9328 8.21806 12.8572 8.40042 12.7061 8.54691C12.555 8.6934 12.375 8.76665 12.1661 8.76665H8.7661V12.15C8.7661 12.3805 8.69026 12.566 8.5386 12.7062C8.38692 12.8465 8.20623 12.9166 7.99651 12.9166Z" fill="white" fillOpacity="0.5"/>
                                                </svg>
                                            </li>)}
                                        </ul>
                                    </>}

                                    <div {...(providedData(provided))} className={`${searchable ? 'not-allowed' : ''}`} title={searchable ? "undraggable in search" : null}>
                                        <div className='toolkit-block-footer'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="14" viewBox="0 0 48 14" fill="none">
                                                <g opacity="0.4">
                                                    <path d="M18 4.66669L24 9.66669L30 4.66669" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>

                                </Accordion>
                        
                            </div>)}
                        </Draggable>)}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        :
        <div>
            <div className="toolkit-oneblock-header relative">
                <a onClick={(e) => {e.preventDefault(); setOneSelectedId(null)}} href="" className="toolkit-oneblock-header__back">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M8.60024 17.7262C8.3669 17.4762 8.25024 17.197 8.25024 16.8887C8.25024 16.5804 8.3669 16.3179 8.60024 16.1012L12.7002 11.9762L8.57524 7.82621C8.3419 7.62621 8.2294 7.35955 8.23774 7.02621C8.24607 6.69288 8.3669 6.41788 8.60024 6.20121C8.8169 5.96788 9.0794 5.85538 9.38774 5.86371C9.69607 5.87205 9.95857 5.98455 10.1752 6.20121L15.1502 11.1762C15.2502 11.2762 15.3336 11.397 15.4002 11.5387C15.4669 11.6804 15.5002 11.8262 15.5002 11.9762C15.5002 12.1429 15.4669 12.2929 15.4002 12.4262C15.3336 12.5595 15.2502 12.6762 15.1502 12.7762L10.2002 17.7262C9.98357 17.9429 9.7169 18.047 9.40024 18.0387C9.08357 18.0304 8.8169 17.9262 8.60024 17.7262Z" fill="white" fillOpacity="0.5"/>
                    </svg>
                </a>

                <span className="toolkit-block-text-title">{oneBlockObj.title}</span>
            </div>

            <div className="blog-toolkit-content__container">
                <ul className="btc__block-content">
                    {oneBlockObj?.content?.map((item, idx) => (oneBlockObj.listType === 2 ? 
                    <TextBigContainer key={idx} data={item} /> 
                    :
                    <li key={idx} className="btc__block-item">
                        <span className="btc__item-text">{item}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M7.99651 12.9166C7.79151 12.9166 7.61575 12.8465 7.46923 12.7062C7.3227 12.566 7.24943 12.3805 7.24943 12.15V8.76665H3.83276C3.6286 8.76665 3.45255 8.69131 3.30463 8.54065C3.15672 8.38999 3.08276 8.21055 3.08276 8.00231C3.08276 7.77854 3.15672 7.5972 3.30463 7.45831C3.45255 7.31942 3.6286 7.24998 3.83276 7.24998H7.24943V3.83331C7.24943 3.61359 7.32364 3.43366 7.47206 3.29351C7.62049 3.15338 7.79715 3.08331 8.00206 3.08331C8.20697 3.08331 8.38554 3.15338 8.53776 3.29351C8.68999 3.43366 8.7661 3.61359 8.7661 3.83331V7.24998H12.1661C12.375 7.24998 12.555 7.3204 12.7061 7.46123C12.8572 7.60207 12.9328 7.78161 12.9328 7.99983C12.9328 8.21806 12.8572 8.40042 12.7061 8.54691C12.555 8.6934 12.375 8.76665 12.1661 8.76665H8.7661V12.15C8.7661 12.3805 8.69026 12.566 8.5386 12.7062C8.38692 12.8465 8.20623 12.9166 7.99651 12.9166Z" fill="white" fillOpacity="0.5"/>
                        </svg>
                    </li>))}

                </ul>
            </div>   
            
        </div>}
        </>
    )
}