import Content from "./Content";
import Addons from "../Blog/Addons";
import Settings from "./Settings";
import Keywords from "./Keywords";

export const navLinks = [
    {
        title: 'Content',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <rect x="3" y="3.5" width="18" height="18" rx="5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 16H7.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.5 9H12" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.5 12.5H7.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        Component: Content
    },
    {
        title: 'Add-ons',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.16425 10.9975C4.72707 10.9084 4.25287 10.9254 3.76367 11.0885C2.93632 11.3646 2.28105 12.0759 2.07596 12.9243C1.65379 14.668 2.96333 16.2246 4.63603 16.2246C4.8171 16.2246 4.99318 16.2056 5.16425 16.1716V19.393C5.16425 20.5585 6.10964 21.5038 7.27613 21.5038H18.892C20.0585 21.5038 21.0038 20.5585 21.0038 19.392V7.77613C21.0038 6.60964 20.0585 5.66425 18.892 5.66425H15.6706C15.7597 5.22707 15.7427 4.75287 15.5796 4.26367C15.3025 3.43632 14.5912 2.78105 13.7438 2.57596C12.0001 2.15379 10.4434 3.46333 10.4434 5.13603C10.4434 5.3171 10.4625 5.49318 10.4965 5.66425H7.27513C6.10864 5.66425 5.16325 6.60964 5.16325 7.77613V10.9975H5.16425Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.0002 10.999V15.0007" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.0017 13.0002H11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        Component: Addons
    },
    {
        title: 'Keywords',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.99902 11.961L13.3164 8.63361C12.5587 6.64849 13.2966 4.40405 15.0845 3.25586C16.8724 2.10767 19.2204 2.37029 20.7104 3.88511C22.2004 5.39992 22.4242 7.75193 21.2467 9.5206C20.0691 11.2893 17.8128 11.99 15.8405 11.1997L12.5391 14.5111H9.99902V11.961Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.6272 7.00314C17.6272 6.93407 17.5712 6.87809 17.5022 6.87809C17.4331 6.87809 17.3771 6.93407 17.3771 7.00314C17.3771 7.0722 17.4331 7.12819 17.5022 7.12819C17.5712 7.12819 17.6272 7.0722 17.6272 7.00314" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.99919 3.72754C5.65267 4.71418 2.68214 8.73245 3.01346 13.1772C3.34479 17.622 6.87823 21.1554 11.323 21.4868C15.7678 21.8181 19.786 18.8476 20.7727 14.501" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        Component: Keywords
    },
    {
        title: 'Settings',
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M18 5.5H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 5.5H11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13 12.5H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 12.5H6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18 19.5H21" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 19.5H11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.4137 4.08575C18.1947 4.86675 18.1947 6.13275 17.4137 6.91375C16.6327 7.69475 15.3668 7.69475 14.5857 6.91375C13.8047 6.13275 13.8047 4.86675 14.5857 4.08575C15.3668 3.30475 16.6327 3.30475 17.4137 4.08575" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.41375 11.0857C10.1947 11.8668 10.1947 13.1327 9.41375 13.9137C8.63275 14.6947 7.36675 14.6947 6.58575 13.9137C5.80475 13.1327 5.80475 11.8668 6.58575 11.0857C7.36675 10.3047 8.63275 10.3047 9.41375 11.0857" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.4137 18.0857C18.1947 18.8668 18.1947 20.1327 17.4137 20.9137C16.6327 21.6947 15.3668 21.6947 14.5857 20.9137C13.8047 20.1327 13.8047 18.8668 14.5857 18.0857C15.3668 17.3047 16.6327 17.3047 17.4137 18.0857" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        Component: Settings
    },
]