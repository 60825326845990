import React, { useEffect, useState } from 'react'
import api from '../../api';
import { useSelector } from 'react-redux';
import { getToken } from '../../redux/slices/applicationSlice';

function CollectionCard({id}) {
    const token = useSelector(getToken);
    const [assets, setAssets] = useState([]);
    console.log(assets);
    useEffect(() => {
        if(!id || !token) return;
        api.assets.getAssets({
            queries: `collectionId=${id}`,
            token
        }).then(response => {
            if(!response.success) return;
            setAssets(response.data);
        }).catch(e => {
            console.log(e);
        })
    }, [id, token])
   
    return assets.length ? <div className="multiple-images-wrpr">
        {
            [...assets].slice(0, 4).map((item, imageItemIndex)=>{
                return <div className="img-wrpr" key={imageItemIndex}>
                    {/* <img src={imageItem} alt="" /> */}
                    <div>
                        <p>{item.name}</p>
                        <p>{item.product.name}</p>
                    </div>
                    {
                        assets.length > 4 && imageItemIndex === 3 ? 
                            <div className="plus-images-count">{[...assets].slice(4).length}+</div>
                        : null
                    }
                </div>
            }) 
        }
    </div> :
    <div className='flex items-center justify-center w-full h-full mt-[30px]'>Collection is Empty</div>
}

export default CollectionCard