import { add, endOfYear, startOfToday } from "date-fns";

export const today = startOfToday();

export const dateSuggestions = [
    {
        label: 'in 1 week',
        startDate: today,
        endDate: add(today, {
            weeks: 1
        })
    },
    {
        label: 'in 2 week',
        startDate: today,
        endDate: add(today, {
            weeks: 2
        })
    },
    {
        label: 'in 30 days',
        startDate: today,
        endDate: add(today, {
            days: 30
        })
    },
    {
        label: 'in 3 months',
        startDate: today,
        endDate: add(today, {
            months: 3
        })
    },
    {
        label: 'in 6 months',
        startDate: today,
        endDate: add(today, {
            months: 6
        })
    },
    {
        label: 'in 1 year',
        startDate: today,
        endDate: add(today, {
            years: 1
        })
    },
    {
        label: 'in 3 years',
        startDate: today,
        endDate: add(today, {
            years: 3
        })
    },
    {
        label: 'in 5 years',
        startDate: today,
        endDate: add(today, {
            years: 5
        })
    },
    {
        label: 'in 10 years',
        startDate: today,
        endDate: add(today, {
            years: 10
        })
    },
    {
        label: 'in 20 years',
        startDate: today,
        endDate: add(today, {
            years: 20
        })
    },
    {
        label: 'by the end of the year',
        startDate: today,
        endDate: endOfYear(today)
    },
    {
        label: 'eventually',
        startDate: today,
        endDate: ''
    },
]

export const allSelectedFrequency = {
    Mon: true,
    Tue: true,
    Wed: true,
    Thu: true,
    Fri: true,
    Sat: true,
    Sun: true
}