import { ReactComponent as FacebookIcon } from "../../assets/img/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../assets/img/twitter-icon.svg";
import { ReactComponent as GoogleIcon } from "../../assets/img/google-icon.svg";

const SocialButton = ({ icon, text }) => <button
    type='button'
    className='w-full relative flex items-center h-[44px] px-[12px] rounded-full bg-[#ffffff08]'
>
    {icon}
    <div className='text-[14px] font-medium opacity-50 absolute left-1/2 -translate-x-1/2'>
        Sign Up with {text}
    </div>
</button>

const SocialButtons = () => {
    return <>
        <div className="mb-[24px] or-separate-line">
            <span>or continue with</span>
        </div>
        <ul className="flex flex-col gap-[8px]">
            <a href={`${process.env.REACT_APP_API_URL}/oauth/facebook`}>
                <SocialButton
                    icon={<FacebookIcon />}
                    text='Facebook'
                />
            </a>
            <a href={`${process.env.REACT_APP_API_URL}/oauth/twitter`}>
                <SocialButton
                    icon={<TwitterIcon />}
                    text='Twitter'
                />
            </a>
            <a href={`${process.env.REACT_APP_API_URL}/oauth/google`}>
                <SocialButton
                    icon={<GoogleIcon />}
                    text='Google'
                />
            </a>
        </ul>
    </>
};

export default SocialButtons;