const base = 'api/pay-authorize';

export const payAuthorize = (instance) => ({
    async addCard(payload) {
        try {
            const { data } = await instance.post(`${base}/add-card`, payload.data, {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            });
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async deleteCard(payload) {
        try {
            const { data } = await instance.delete(`${base}/${payload.id}`, {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            });
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
    async editCard(payload) {
        try {
            const { data } = await instance.put(`${base}/${payload.id}`, payload.data, {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            });
            return data;
        } catch (e) {
            return e.response.data;
        }
    },
})