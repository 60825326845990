import classNames from 'classnames';
import React from 'react';

const Label = ({ className, children, ...props }) => {
    return <label
        className={classNames('primary-label', className)}
        {...props}
    >
        {children}
    </label>
};

export default Label;