const base = 'api/referrals';

export const refferals = (instance) => ({
    async sendInvitation(payload) {
        const { data } = await instance.post(`${base}/invite`, payload.data, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
})