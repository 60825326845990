import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getGeneratedWords } from '../../../redux/slices/newWordGeneratorSlice';
import WordCard from './WordCard';
import GeneratedContentModal from '../GeneratedContentModal';
import api from '../../../api';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getToken, getUser } from '../../../redux/slices/applicationSlice';
import Popup from 'reactjs-popup';
import HelpModal from '../../../components/base/HelpModal';
import Share from './Share';
import ButtonTooltip from '../Blog/ButtonTooltip';

export default function Content({ setSideBarOpened, mobileScreen = false }) {
    const { id } = useParams();
    const token = useSelector(getToken);
    const meData = useSelector(getUser);
    const words = useSelector(getGeneratedWords);
    const navigate = useNavigate();
    const [cardFullScreen, setCardFullScreen] = useState(false);
    const [openedModal, setOpenedModal] = useState(null);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const closeModal = () => setShowHelpModal(false);

    const [wordCount, setWordCount] = useState({
        all: 0,
        my: 0
    })
    console.log(wordCount)

    const fetchData = async () => {
        let queries = "";
        const addQuery = (query, value) => {
            queries += queries.length ? `&${query}=${value}` : `${query}=${value}`;
        }
        addQuery('page', 1)
        addQuery('limit', 1)

        const resAll = await api.newWords.getWords({ token, queries })
        setWordCount(prev => ({ ...prev, all: resAll?.pagination?.total || 0 }))
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        console.log(words, "words");
    }, [words])

    useEffect(() => {
        if (!mobileScreen) setSideBarOpened(!cardFullScreen);
    }, [cardFullScreen, setSideBarOpened])

    return <div className='flex-1 flex flex-col'>
        <div className={classNames('py-[13px] px-[16px] flex items-center justify-between', {
            'px-[30px]': cardFullScreen
        })}>
            <div>
                <h1 className='text-[14px] font-medium leading-normal'>
                    Generated Content Preview
                </h1>
                <div className='text-[12px] leading-[14px] flex items-center gap-[5px]'>
                    <Link
                        to="/new-words"
                        className='opacity-40 hover:text-[#FF8114] hover:underline hover:opacity-100'
                    >
                        New Word
                    </Link>
                    <div className='w-[3px] h-[3px] rounded-full bg-white opacity-40' />
                    <span className=" opacity-40">{words?.length} words</span>
                    {/* <span className=" opacity-40">{wordCount.all} words</span> */}
                </div>
            </div>
            <button
                onClick={() => setShowHelpModal(true)}
                className='w-[32px] h-[32px] flex items-center justify-center opacity-50'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M9.68457 9.68482C9.92159 8.66326 10.852 7.95496 11.8998 7.99844C13.0725 7.93349 14.0778 8.82709 14.1508 9.99928C14.1508 11.5038 11.9999 12.0001 11.9999 13.0005" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12.1253 15.7523C12.1252 15.8213 12.0692 15.8772 12.0002 15.8772C11.9311 15.8772 11.8752 15.8212 11.8752 15.7522C11.8751 15.6832 11.931 15.6272 12 15.6271C12.0333 15.6271 12.0651 15.6402 12.0886 15.6637C12.1121 15.6872 12.1253 15.7191 12.1253 15.7523" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.62861 17.1605C1.94551 13.3308 2.63502 8.08546 6.21672 5.07926C9.79842 2.07305 15.0839 2.30347 18.3903 5.60997C21.6968 8.91646 21.9273 14.2019 18.9211 17.7836C15.9149 21.3653 10.6695 22.0548 6.8398 19.3717L3.99688 20.0034L4.62861 17.1605Z" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>
        </div>
        <div className='py-[4px] px-[16px] flex-1 gap-[12px] overflow-y-scroll no-scroll'>
            <ul className={classNames('flex flex-col gap-[12px]', {
                'h-full': cardFullScreen
            })}>
                {words.map((word, index) =>
                    <WordCard
                        key={index}
                        word={word}
                        index={index}
                        setCardFullScreen={setCardFullScreen}
                        isFullScreen={cardFullScreen}
                    />
                )}
            </ul>
        </div>
        <div className='generated-content-bottom-panel flex-wrap gap-[5px] lg:gap-[0px]'>
            <div className='editor-footer-column flex items-center gap-[10px] lg:gap-[20px]'>
                <ButtonTooltip tooltip='New Words Created'>
                    <div className='flex items-center gap-[10px]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M15.8346 1.66663H4.16797C2.79297 1.66663 1.66797 2.79163 1.66797 4.16663V15.8333C1.66797 17.2083 2.79297 18.3333 4.16797 18.3333H15.8346C17.2096 18.3333 18.3346 17.2083 18.3346 15.8333V4.16663C18.3346 2.79163 17.2096 1.66663 15.8346 1.66663ZM9.16797 12.5H5.83464C5.3763 12.5 5.0013 12.125 5.0013 11.6666C5.0013 11.2083 5.3763 10.8333 5.83464 10.8333H9.16797C9.6263 10.8333 10.0013 11.2083 10.0013 11.6666C10.0013 12.125 9.6263 12.5 9.16797 12.5ZM12.5013 9.16663H5.83464C5.3763 9.16663 5.0013 8.79163 5.0013 8.33329C5.0013 7.87496 5.3763 7.49996 5.83464 7.49996H12.5013C12.9596 7.49996 13.3346 7.87496 13.3346 8.33329C13.3346 8.79163 12.9596 9.16663 12.5013 9.16663Z" fill="#9D9D9D" />
                        </svg>
                        <div className='text-[14px] text-[#9D9D9D] leading-normal flex'>
                            {/* <div className='w-[40px]'>{wordCount.my}</div> */}
                            <div className='w-[40px]'>{words?.length}</div>
                            <span className='opacity-50'>words</span>
                        </div>
                    </div>
                </ButtonTooltip>
                {/* <div className='flex items-center gap-[10px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M12.1055 18.4167C11.7305 18.7917 11.2756 18.9792 10.7409 18.9792C10.2062 18.9792 9.7513 18.7917 9.3763 18.4167L1.58464 10.625C1.37398 10.4179 1.23105 10.1987 1.15582 9.96735C1.08059 9.73603 1.04297 9.49693 1.04297 9.25004V2.93754C1.04297 2.40421 1.22561 1.95143 1.59089 1.57921C1.95616 1.20698 2.40547 1.02087 2.9388 1.02087H9.2513C9.51208 1.02087 9.7616 1.06355 9.99986 1.14892C10.2381 1.23428 10.4608 1.3791 10.668 1.58337L18.418 9.31254C18.8069 9.70143 18.9978 10.1702 18.9909 10.7188C18.9839 11.2674 18.793 11.7292 18.418 12.1042L12.1055 18.4167ZM5.11528 6.14587C5.39959 6.14587 5.64611 6.04008 5.85486 5.82848C6.0636 5.61687 6.16797 5.36687 6.16797 5.07848C6.16797 4.80396 6.0636 4.56254 5.85486 4.35421C5.64611 4.14587 5.39959 4.04171 5.11528 4.04171C4.83096 4.04171 4.58464 4.14751 4.3763 4.3591C4.16797 4.57071 4.0638 4.81377 4.0638 5.08827C4.0638 5.37667 4.16797 5.62504 4.3763 5.83337C4.58464 6.04171 4.83096 6.14587 5.11528 6.14587Z" fill="#9D9D9D"/>
                    </svg>
                    <p className='text-[14px] text-[#9D9D9D] leading-normal'>
                        2.44
                        <span className='opacity-50 ml-[4px]'>credits used</span>
                    </p>
                </div> */}
                <div className='flex items-center gap-[10px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M12.1055 18.4167C11.7305 18.7917 11.2756 18.9792 10.7409 18.9792C10.2062 18.9792 9.7513 18.7917 9.3763 18.4167L1.58464 10.625C1.37398 10.4179 1.23105 10.1987 1.15582 9.96735C1.08059 9.73603 1.04297 9.49693 1.04297 9.25004V2.93754C1.04297 2.40421 1.22561 1.95143 1.59089 1.57921C1.95616 1.20698 2.40547 1.02087 2.9388 1.02087H9.2513C9.51208 1.02087 9.7616 1.06355 9.99986 1.14892C10.2381 1.23428 10.4608 1.3791 10.668 1.58337L18.418 9.31254C18.8069 9.70143 18.9978 10.1702 18.9909 10.7188C18.9839 11.2674 18.793 11.7292 18.418 12.1042L12.1055 18.4167ZM5.11528 6.14587C5.39959 6.14587 5.64611 6.04008 5.85486 5.82848C6.0636 5.61687 6.16797 5.36687 6.16797 5.07848C6.16797 4.80396 6.0636 4.56254 5.85486 4.35421C5.64611 4.14587 5.39959 4.04171 5.11528 4.04171C4.83096 4.04171 4.58464 4.14751 4.3763 4.3591C4.16797 4.57071 4.0638 4.81377 4.0638 5.08827C4.0638 5.37667 4.16797 5.62504 4.3763 5.83337C4.58464 6.04171 4.83096 6.14587 5.11528 6.14587Z" fill="#EA8207" />
                    </svg>
                    <p className='text-[14px] text-[#EA8207] leading-normal'>
                        {meData?.credits || 0}
                        <span className='opacity-50 ml-[4px]'>available credits</span>
                    </p>
                </div>
            </div>
            <div className='editor-footer-column flex items-center gap-[8px]'>
                <button
                    className="btn btn-outline-black btn-lg-2 rounded-full"
                    style={{ padding: '9px' }}
                    onClick={() => setOpenedModal('share')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g opacity="0.6">
                            <path d="M15.1483 18.7708C14.3747 18.7708 13.7086 18.4901 13.1502 17.9285C12.5917 17.367 12.3125 16.7107 12.3125 15.9595C12.3125 15.8524 12.3264 15.7236 12.3542 15.573C12.3819 15.4225 12.4097 15.2815 12.4375 15.1502L6.881 11.8958C6.61311 12.1736 6.3101 12.3993 5.97196 12.5729C5.63382 12.7465 5.27128 12.8333 4.88433 12.8333C4.12132 12.8333 3.46026 12.553 2.90117 11.9923C2.34206 11.4317 2.0625 10.7667 2.0625 9.99754C2.0625 9.22835 2.34206 8.56771 2.90117 8.01562C3.46026 7.46354 4.12132 7.1875 4.88433 7.1875C5.26636 7.1875 5.6242 7.26042 5.95785 7.40625C6.29151 7.55208 6.59722 7.76389 6.875 8.04167L12.4375 4.82725C12.4097 4.73092 12.3819 4.60502 12.3542 4.44956C12.3264 4.29412 12.3125 4.16242 12.3125 4.05446C12.3125 3.29131 12.5909 2.6331 13.1477 2.07985C13.7045 1.52662 14.3698 1.25 15.1434 1.25C15.902 1.25 16.5608 1.52686 17.1198 2.08058C17.6788 2.63429 17.9583 3.29575 17.9583 4.06496C17.9583 4.83415 17.6788 5.49479 17.1197 6.04687C16.5606 6.59896 15.8995 6.875 15.1365 6.875C14.7308 6.875 14.3665 6.81966 14.0436 6.70898C13.7207 6.5983 13.4334 6.40364 13.1815 6.125L7.60417 9.17102C7.63194 9.29242 7.65625 9.43784 7.67708 9.60727C7.69792 9.7767 7.70833 9.90928 7.70833 10.005C7.70833 10.1008 7.69792 10.2143 7.67708 10.3457C7.65625 10.477 7.63194 10.6034 7.60417 10.7248L13.1815 13.8542C13.4334 13.6311 13.715 13.4538 14.0265 13.3223C14.3379 13.1908 14.7079 13.125 15.1365 13.125C15.8995 13.125 16.5606 13.4053 17.1197 13.966C17.6788 14.5267 17.9583 15.1916 17.9583 15.9608C17.9583 16.7161 17.6796 17.3733 17.1222 17.9323C16.5648 18.4913 15.9069 18.7708 15.1483 18.7708Z" fill="white" />
                        </g>
                    </svg>
                </button>
                <button
                    className="btn btn-outline-black btn-lg-2 rounded-full"
                    style={{ padding: '9px' }}
                    onClick={() => setOpenedModal({
                        name: 'confirm-delete',
                        action: async () => {
                            try {
                                const response = await api.newWords.deleteNewWord({
                                    id,
                                    token
                                });
                                console.log(response);
                                if (!response.success) return;
                                navigate('/new-words', {
                                    replace: true
                                })
                            } catch (e) {
                                console.log(e);
                            }
                        },
                        title: `Do you really want to delete this word?`
                    })}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M5.26953 17.9378C4.76692 17.9378 4.32182 17.7509 3.93424 17.3772C3.54666 17.0034 3.35286 16.5583 3.35286 16.042V4.54199H2.14453V2.64616H6.89453V1.66699H13.082V2.64616H17.832V4.54199H16.6237V16.042C16.6237 16.5583 16.4348 17.0034 16.0569 17.3772C15.679 17.7509 15.2291 17.9378 14.707 17.9378H5.26953ZM7.4362 14.417H8.97786V6.12533H7.4362V14.417ZM11.0404 14.417H12.6029V6.12533H11.0404V14.417Z" fill="white" fillOpacity="0.6" />
                    </svg>
                </button>
                <button
                    className='generated-button btn generated-content-bottom-panel-btn'
                    onClick={async () => {
                        const selectedWord = words.find(({ data, selectedVersionId }) => data.find(({ id }) => id === selectedVersionId));
                        const selectedVersion = selectedWord.data.find(({ id }) => id === selectedWord.selectedVersionId);
                        try {
                            const response = await api.newWords.updateNewWord({
                                id,
                                body: {
                                    content_edit: JSON.stringify(words),
                                    "word": selectedVersion.word,
                                    "part_speech": selectedVersion.part_speech,
                                    "pronunciation": selectedVersion.pronunciation,
                                    "syllable_division": selectedVersion.syllable_division,
                                    "word_category": selectedVersion.word_category,
                                    "word_style": selectedWord.settings.selectValues.wordStyle,
                                    "word_tone": selectedVersion.wordTone,
                                    "definition": selectedVersion.definition,
                                    "example": selectedVersion.example,
                                },
                                token
                            })
                            if (!response.success) return;
                            setOpenedModal('save');
                        } catch (e) {
                            console.log(e);
                        }
                    }}
                >
                    Save
                </button>
            </div>
        </div>
        {openedModal && <GeneratedContentModal isOpened close={() => setOpenedModal(null)} >
            {openedModal?.name === 'confirm-delete' && <div className="modal__body w-full lg:w-[450px] modal-body-flex">
                <div className="text-center">
                    <h3 className="text-[24px] font-[500]" style={{ lineHeight: '1.5' }}>
                        {openedModal?.title ? openedModal.title : 'Do you really want to delete?'}
                    </h3>
                </div>
                <div className="d-flex items-center gap-[10px]">
                    <button
                        className="btn bg-[#202020] border border-solid box-border rounded-full py-[10px] px-[36px]"
                        onClick={() => { setOpenedModal(null); }}
                    >
                        <span className="text-[14px] font-medium">Close</span>
                    </button>
                    <button
                        className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]"
                        onClick={() => {
                            openedModal?.action && openedModal?.action();
                        }}
                    >
                        <span className="text-[14px] font-medium">Confirm</span>
                    </button>
                </div>
            </div>}
            {openedModal === 'share' && <Share setOpenedModal={setOpenedModal} />}
            {(openedModal === 'save' || openedModal?.name === 'save') && <div className="modal__body w-full lg:w-[450px] modal-body-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.99 26.9305L9.38 30.0585L9.896 33.9525C10.182 36.1125 11.88 37.8125 14.04 38.1005L17.944 38.6225L21.07 41.0105C22.8 42.3325 25.198 42.3325 26.928 41.0105L30.056 38.6205H30.052L33.948 38.1045C36.108 37.8185 37.808 36.1205 38.096 33.9605L38.616 30.0565C38.616 30.0585 39.824 28.4765 41.006 26.9305C42.328 25.2005 42.326 22.8025 41.006 21.0725L38.62 17.9425L38.104 14.0485C37.818 11.8885 36.12 10.1885 33.96 9.90048L30.054 9.38048L26.928 6.99248C25.198 5.67048 22.8 5.67048 21.07 6.99248L17.942 9.38048H17.946L14.05 9.89848C11.89 10.1845 10.19 11.8825 9.902 14.0425L9.38 17.9465C9.38 17.9445 8.172 19.5265 6.99 21.0725C5.67 22.8005 5.67 25.2005 6.99 26.9305V26.9305Z" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M29.6064 21.2041L22.6044 28.2061L18.3984 24.0041" stroke="#FF8114" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <div className="text-center">
                    <h3 className="text-[24px] font-[500] leading-none">
                        {openedModal?.title ? openedModal?.title : 'Saved'}
                    </h3>
                    {(openedModal?.name === 'save' && openedModal?.text) || openedModal === 'save' ?
                        <p className="mt-[20px] text-[14px] text-[#A7A7A7] leading-[22px]">
                            {openedModal?.text ? openedModal?.text : 'Your changes has been successfully saved'}
                        </p> : null}
                </div>
                <button
                    className="btn btn--primary-gradient rounded-full py-[10px] px-[36px]"
                    onClick={() => {
                        setOpenedModal(null);
                    }}
                >
                    <span className="text-[14px] font-medium">OK</span>
                </button>
            </div>}
        </GeneratedContentModal>}
        <Popup
            open={showHelpModal}
            onClose={closeModal}
            overlayStyle={{
                background: 'rgba(0,0,0,0.5)'
            }}
        >
            <HelpModal closeModal={closeModal} />
        </Popup>
    </div>
}
