const base = 'api/collections';

export const collections = (instance) => ({
    async createCollection(payload) {
        const { data } = await instance.post(`${base}`, payload.data, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            },
        });
        return data;
    },
    async getCollections(payload) {
        const { data } = await instance.get(`${base}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            },
        });
        return data;
    },
    async activateCollection(payload) {
        const { data } = await instance.post(`${base}/activate/${payload.id}`, payload.data, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            },
        });
        return data;
    },
})