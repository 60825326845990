const base = 'api/bot-kits';

export const botKits = (instance) => ({
    async getBotKit(payload) {
        const { data } = await instance.get(`${base}/${payload.botId}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            },
        });
        return data;
    },
    async getBotKitChats(payload) {
        const { data } = await instance.get(`${base}/chats/${payload.botId}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            },
        });
        return data;
    },
    async updateChat(payload) {
        const { data } = await instance.put(`${base}/chat/${payload.chatId}`, payload.body, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            },
        });
        return data;
    },
    async deleteChat(payload) {
        const { data } = await instance.delete(`${base}/chat/${payload.chatId}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            },
        });
        return data;
    },
})