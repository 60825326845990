import React, { useContext, useEffect, useState, } from 'react';
import Tippy from '@tippyjs/react';
import FileDropzone from '../../../../components/base/FileDropzone';
import { bytesToSize } from '../../../../routes/ChatBot/Uploads';
import { toast } from 'react-toastify';

const extensionIcons = {
    'pdf': <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4856 7.483L18.1863 4.18367C17.7488 3.74617 17.1549 3.5 16.5366 3.5H8.16927C6.8801 3.5 5.83594 4.54417 5.83594 5.83333V22.1667C5.83594 23.4558 6.8801 24.5 8.16927 24.5H19.8359C21.1251 24.5 22.1693 23.4558 22.1693 22.1667V9.13267C22.1693 8.51433 21.9231 7.9205 21.4856 7.483V7.483Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.1693 9.33333H17.5026C16.8586 9.33333 16.3359 8.81067 16.3359 8.16667V3.5" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.0625 19.3083H15.9358" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6406 14.8762V13.5135C12.6406 13.1379 12.9451 12.8334 13.3208 12.8334H14.6835C15.0591 12.8334 15.3636 13.1379 15.3636 13.5135V14.8762C15.3636 15.2519 15.0591 15.5564 14.6835 15.5564H13.3208C12.9451 15.5552 12.6406 15.2507 12.6406 14.8762Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.33594 20.3198V18.9571C9.33594 18.5815 9.64044 18.277 10.0161 18.277H11.3788C11.7544 18.277 12.0589 18.5815 12.0589 18.9571V20.3198C12.0589 20.6955 11.7544 21 11.3788 21H10.0161C9.64044 21 9.33594 20.6955 9.33594 20.3198Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9453 20.3198V18.9571C15.9453 18.5815 16.2498 18.277 16.6255 18.277H17.9881C18.3638 18.277 18.6683 18.5815 18.6683 18.9571V20.3198C18.6671 20.6955 18.3626 21 17.987 21H16.6243C16.2498 21 15.9453 20.6955 15.9453 20.3198H15.9453Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.6132 18.2817L14.8516 15.5283" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.3906 18.2817L13.1523 15.5283" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>,
    'doc' : <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4856 7.483L18.1863 4.18367C17.7488 3.74617 17.1549 3.5 16.5366 3.5H8.16927C6.8801 3.5 5.83594 4.54417 5.83594 5.83333V22.1667C5.83594 23.4558 6.8801 24.5 8.16927 24.5H19.8359C21.1251 24.5 22.1693 23.4558 22.1693 22.1667V9.13267C22.1693 8.51433 21.9231 7.9205 21.4856 7.483V7.483Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.1693 9.33333H17.5026C16.8586 9.33333 16.3359 8.81067 16.3359 8.16667V3.5" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.33594 12.8333H16.3359" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.33594 16.3333H16.3359" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.33594 19.8333H14.3876" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>,
    'docx': <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4856 7.483L18.1863 4.18367C17.7488 3.74617 17.1549 3.5 16.5366 3.5H8.16927C6.8801 3.5 5.83594 4.54417 5.83594 5.83333V22.1667C5.83594 23.4558 6.8801 24.5 8.16927 24.5H19.8359C21.1251 24.5 22.1693 23.4558 22.1693 22.1667V9.13267C22.1693 8.51433 21.9231 7.9205 21.4856 7.483V7.483Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.1693 9.33333H17.5026C16.8586 9.33333 16.3359 8.81067 16.3359 8.16667V3.5" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.33594 12.8333H16.3359" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.33594 16.3333H16.3359" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.33594 19.8333H14.3876" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>,
    'png' : <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16 15.524L13.457 13.455L11.324 15.683L10.15 14.895L8 16.713" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 18H9C8.448 18 8 17.552 8 17V12C8 11.448 8.448 11 9 11H15C15.552 11 16 11.448 16 12V17C16 17.552 15.552 18 15 18Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>,
    'jpg' : <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19 8H15C14.448 8 14 7.552 14 7V3" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16 15.524L13.457 13.455L11.324 15.683L10.15 14.895L8 16.713" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 18H9C8.448 18 8 17.552 8 17V12C8 11.448 8.448 11 9 11H15C15.552 11 16 11.448 16 12V17C16 17.552 15.552 18 15 18Z" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
}

const Files = ({ selectedForSetting, setSelectedForSetting }) => {
    const [formValues, setFormValues] = useState(selectedForSetting?.settings?.files || []);
    const [inputValue, setInputValue] = useState("");

    const getFileFromUrl = async() => {
        try {
            let response = await fetch(inputValue);
            let data = await response.blob();
            let metadata = {
              type: 'image/jpeg'
            };
            let file = new File([data], inputValue.split('/').pop(), metadata);
            
            if(file){
                setFormValues((prevState) => [...prevState, file]);
                toast.success('File read success');
            }
        } catch (error) {
            toast.error('CORS blocked or wrong url');
        }

        setInputValue('');
    }

    useEffect(() => {
        setSelectedForSetting((prevState)=>({
            ...prevState,
            settings: {
                ...prevState.settings,
                files: formValues
            }
        }))
    }, [formValues])

    return <>
        <FileDropzone
            options={{
                maxSize: Math.pow(10, 7),
                accept: {
                    'application/pdf': [],
                    'image/jpeg': [],
                    'image/png': []
                },
                onDropAccepted: (files) => {
                    console.log(files);
                    setFormValues((prevState) => [...prevState, ...files]);
                }
            }}
        />
        <div className='flex items-center gap-[16px] my-[16px]'>
            <div className='h-[1px] flex-1 bg-[#1F1F1F]'/>
            <span className='text-[12px] text-[#A7A7A7] opacity-40'>or</span>
            <div className='h-[1px] flex-1 bg-[#1F1F1F]'/>
        </div>
        <div className='flex gap-[12px]'>
            <input
                id='add-on'
                className='h-[40px] p-[8px] px-[14px] flex-1 bg-[#1B1B1B] rounded-[8px]
                border border-solid border-[#333] text-[14px]'
                placeholder='Paste your link here...'
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
            />
            <button 
                className={`h-[40px] w-[40px] flex items-center justify-center radial-bg rounded-full ${!inputValue ? 'opacity-70' : ''}`}
                disabled={!inputValue}
                onClick={() => {
                    getFileFromUrl();
                }}
            >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 4.16699V15.8337" stroke="#331A04" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.16797 10H15.8346" stroke="#331A04" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
        <ul className='flex flex-col gap-[8px] mt-[16px]'>
            {formValues.map(({name, size}, index) => <li
                key={index}
                className='p-[16px] flex justify-between items-center bg-[#202020] rounded-[8px]'
            >
                <div className='flex gap-[16px] items-center flex-1'>
                    {extensionIcons[name.split('.')[1]]}
                    <div className='flex-1'>
                        <p className='text-[13px] opacity-90 line-clamp-1'>{name}</p>
                        <p className='text-[12px] opacity-50 font-light'>{bytesToSize(size)}</p>
                    </div>
                </div>
                <svg 
                    onClick={() => setFormValues(prev => {
                        const result = {...prev};
                        result.splice(index, 1);
                        return result;
                    })} 
                    cursor="pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g opacity="0.5">
                        <path d="M15 5L5 15" stroke="#A7A7A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5 5L15 15" stroke="#A7A7A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                </svg>
            </li>)}
        </ul>
    </>;
};

export default Files;