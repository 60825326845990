import React, { useState } from 'react';
import { useSelector } from "react-redux";
import getUserImageSrc from "../../helpers/getUserImageSrc";
import { getToken, getUser } from "../../redux/slices/applicationSlice";
import { format } from "date-fns";
import api from "../../api";
import getBotImageSrc from "../../helpers/getBotImageSrc";
import { toast } from 'react-toastify';

const ChatMessage = ({
    mesage: { role, content, createdAt, reply_to, is_active, id }, 
    botName, 
    sendMessage, 
    messages,
    setChat,
    showRegenerate,
    botImage
}) => {
    const [inputValue, setInputValue] = useState('');
    const [responseOpen, setResponseOpen] = useState(false);
    const user = useSelector(getUser);
    const token = useSelector(getToken);
    if(role === 'assistant' && !is_active) return;
    const versions = messages.filter(message => message.role === 'assistant' && message.reply_to === reply_to);
    const activeVersionIndex = versions.findIndex(version => version.id === id);
    
    const sendDisabled = !inputValue;

    const handleChangeVersion = async (index) => {
        try {
            const response = await api.botChatMessages.activateMessage({
                messageId: versions[index].id,
                token
            });
            if(!response.success) return;
            setChat(prev => ({
                ...prev,
                messages: prev.messages.map(message => ({
                    ...message,
                    is_active: message.reply_to === reply_to ? message.id !== response.data.id ? false : true : message.is_active
                }))
            }))
        } catch (e) {
            console.log(e);
        }
    }
    
    const createFeedbackChatMessages = async () => {
        try {
            const response = await api.botChatMessages.createFeedbackChatMessages({
                data: {
                    "messageId": id,
                    "content": inputValue,
                    "tags": []
                },
                // messageId: versions[index].id,
                token
            });
            console.log('createFeedbackChatMessages response:', response);
            if(!response.success) return;
        } catch (e) {
            console.log(e);
        } finally {
            setResponseOpen(false);
            setInputValue("");
        }
    }

    const copyMessage = () => {
        navigator?.clipboard?.writeText(content).then(() => {
            toast.success('Copied successfully!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        })
    }

    return <li className='flex gap-[10px] lg:gap-[16px]'>
        <img
            src={role === 'user' ? getUserImageSrc(user) : getBotImageSrc(botImage)}
            alt='user'
            className='rounded-[8px] w-[32px] h-[32px]'
        />
        <div className='flex flex-col gap-[9px] w-full'>
            <div className='flex justify-between items-center mob-flex-wrap gap-[4px] lg:gap-[0px]'>
                <div className='flex gap-[12px]'>
                    <span className='text-[13px] text-[#ffffff99] font-medium'>
                        {role === 'user' ? `${user.first_name} ${user.last_name}`: `${botName} Bot`}
                    </span>
                    <span className='text-[13px] text-[#ffffff4d]'>
                        {format(new Date(createdAt), 'h:mm a')}
                    </span>
                </div>
                <div className="flex gap-[16px]">
                    {versions.length > 1 && <div className="flex items-center">
                        <button
                            onClick={() => handleChangeVersion(activeVersionIndex - 1)}
                            disabled={activeVersionIndex === 0}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M14.5 8L10.5 12L14.5 16" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                        <span className="text-[13px] text-[#ffffff99] font-medium">
                            {activeVersionIndex + 1} / {versions.length}
                        </span>
                        <button
                            onClick={() => handleChangeVersion(activeVersionIndex + 1)}
                            disabled={activeVersionIndex + 1 === versions.length}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M10.5 16L14.5 12L10.5 8" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                    </div>}
                    {role !== 'user' &&
                    <button onClick={(e)=>{
                        e.preventDefault();
                        setResponseOpen(!responseOpen);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M17.4978 15.0018H12.0547" stroke={responseOpen ? "#FF8114" : "#A7A7A7"} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M17.4978 12.5008H12.0547" stroke={responseOpen ? "#FF8114" : "#A7A7A7"} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M15.6837 17.5028H12.0547" stroke={responseOpen ? "#FF8114" : "#A7A7A7"} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9.02253 14.229L6.14133 15.7296C5.84151 15.8879 5.47787 15.8625 5.20293 15.6641C4.92798 15.4657 4.78927 15.1286 4.84496 14.7942L5.4919 11.0743L2.76243 8.43822C2.51857 8.20263 2.43068 7.84847 2.53609 7.52621C2.6415 7.20395 2.9217 6.97019 3.25763 6.92425L7.02337 6.38153L8.70574 2.99178C8.85782 2.68825 9.16823 2.49658 9.50774 2.49658C9.84724 2.49658 10.1577 2.68825 10.3097 2.99178L11.9946 6.38153L15.7603 6.92342C16.0959 6.9696 16.3757 7.2032 16.4811 7.52514C16.5864 7.84707 16.4989 8.20091 16.2555 8.43655L14.7174 9.92384" stroke={responseOpen ? "#FF8114" : "#A7A7A7"} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>}
                    <button onClick={(e)=>{
                        e.preventDefault();
                        copyMessage();
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M2.5 15.8335V16.7859C2.50014 17.1803 2.81985 17.5 3.21428 17.5002H4.16667" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.8359 7.5H11.7883C12.1827 7.50014 12.5025 7.81985 12.5026 8.21428V9.16667" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8.33073 17.5002H6.66406" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8.33073 7.50016H6.66406" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.8359 17.5002H11.7883C12.1827 17.5 12.5025 17.1803 12.5026 16.7859V15.8335" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M2.5 9.16667V8.21428C2.50014 7.81985 2.81985 7.50014 3.21428 7.5H4.16667" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12.5026 11.6665V13.3332" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M2.5026 11.6665V13.3332" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.66406 5V4.16667C6.66394 3.7246 6.8395 3.30061 7.15208 2.98802C7.46467 2.67543 7.88866 2.49988 8.33073 2.5H15.8307C16.2728 2.49988 16.6968 2.67543 17.0094 2.98802C17.322 3.30061 17.4975 3.7246 17.4974 4.16667V11.6667C17.4975 12.1087 17.322 12.5327 17.0094 12.8453C16.6968 13.1579 16.2728 13.3335 15.8307 13.3333H14.9974" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    {role !== 'user' && showRegenerate &&
                    <button onClick={() => {
                        setChat(prev => ({
                            ...prev,
                            messages: prev.messages.map(message => ({
                                ...message,
                                is_active: message.reply_to === reply_to ? false : message.is_active
                            }))
                        }))
                        sendMessage({reply_to});
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M16.6609 10.0269C16.6543 11.7244 16.0101 13.4194 14.7151 14.7144C12.1118 17.3177 7.8901 17.3177 5.28677 14.7144C4.82344 14.251 4.44677 13.7352 4.14844 13.1885" stroke="#A7A7A7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M3.33594 9.89183C3.3626 8.22183 4.01094 6.56016 5.2851 5.286C7.88844 2.68266 12.1101 2.68266 14.7134 5.286C15.1768 5.74933 15.5534 6.26516 15.8518 6.81183" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M13.2969 6.81288H16.2427V3.86621" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.70365 13.1875H3.75781V16.1342" stroke="#A7A7A7" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>}
                </div>
            </div>
            <div className='py-[10px] lg:py-[16px] px-[15px] lg:px-[24px] bg-[#151515] border border-solid border-[#1F1F1F] 
                rounded-[16px] text-[12px] lg:text-[16px] leading-[20px] lg:leading-[28px] text-[#ffffff99] whitespace-pre-wrap'>
                {content}
            </div>
            {
                responseOpen ? 
                // reply_to ? <div>reply id:{reply_to}</div> :
                <div className='flex w-full rounded-[8px] h-[40px] pl-[16px] pr-[12px] items-center gap-[16px] bg-[#1B1B1B]'>
                    <input
                        className='flex-1 bg-transparent text-[14px]'
                        placeholder='How can we improve this response?'
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                        onKeyDown={(e) => {
                            if(e.key === 'Enter' && !sendDisabled) createFeedbackChatMessages();
                        }}
                    />
                    <button
                        disabled={sendDisabled}
                        className='disabled:opacity-30 ml-[10px]'
                        onClick={() => createFeedbackChatMessages()}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10.4814 18.7097L21.4684 12.8777C22.1774 12.5017 22.1774 11.4897 21.4684 11.1127L10.4974 5.28969C9.66943 4.84969 8.74143 5.67969 9.09243 6.54569L11.2814 11.9457L9.07443 17.4557C8.72843 18.3227 9.65543 19.1477 10.4814 18.7097Z" stroke="white" strokeWidth="1.5036" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M11.2812 11.9496L22.0013 11.9996" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4 15H5.6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M3 12H5.6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M2 9H5.6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                </div> : null
            }
        </div>
    </li> 
}

export default ChatMessage;
