import React, { useState } from 'react'
import iconPerson from '../../assets/img/icons/icon-person.svg';
import iconRows2 from '../../assets/img/icons/ico-rows-2.svg';
import iconBasilCrossSolid from '../../assets/img/icons/basil_cross-solid.svg';
import iconArrowDropDown from '../../assets/img/icons/arrow-drop-down.svg';
import NumberInput from '../../components/base/NumberInput';
import SelectBox from '../../components/base/SelectBox';

function SettingsGlobal() {
    let [numberValue, setNumberValue] = useState(0)
    return (
        <div className="tabs-content flex-auto mb-[20px]">
            <div className="container-820">
                <div className="row mb-[-20px]">
                    <div className="col-xl-12 col-lg-12 mb-[20px]">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">
                                Your Mission
                            </label>
                            <div className="input-custom__wrap-input icon-left">
                                <div className="icon-input">
                                    <img src={iconPerson}/>
                                </div>
                                <input className="input-custom__input" type="text" placeholder="Create your mission and make it happen"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-[20px]">
                        <NumberInput
                            value={ numberValue }
                            change={(value)=>{setNumberValue(value)}}
                        ></NumberInput>
                    </div>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-6 mb-[20px]">
                                <div className="input-custom input-custom--check">
                                    <label className="custom-checkbox py-[12px] px-[20px] max-h-[48px]">
                                        <input type="checkbox" className="hidden"/>
                                        <span className="custom-checkbox-fake"></span>
                                        <span className="custom-checkbox-text ">Use New Word Library</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-[20px]">
                                <button className="btn btn-outline-white-50 btn-lg-5 rounded-[10px] w-full justify-start">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M23 10C23 9.46957 22.7893 8.96086 22.4142 8.58579C22.0391 8.21071 21.5304 8 21 8H14.68L15.64 3.43C15.66 3.33 15.67 3.22 15.67 3.11C15.67 2.7 15.5 2.32 15.23 2.05L14.17 1L7.59 7.58C7.22 7.95 7 8.45 7 9V19C7 19.5304 7.21071 20.0391 7.58579 20.4142C7.96086 20.7893 8.46957 21 9 21H18C18.83 21 19.54 20.5 19.84 19.78L22.86 12.73C22.95 12.5 23 12.26 23 12V10ZM1 21H5V9H1V21Z" fill="white"></path>
                                    </svg>
                                    <span className="ml-[10px]">Keep it positive</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 mb-[20px]">
                        <div className="input-custom">
                            <label className="input-custom__label mb-[10px]">
                                Set Brand Voice
                            </label>
                            <div className="input-custom__wrap-input icon-right">
                                <div className="icon-input">
                                    <label className="primary-upload-btn">
                                        <input type="file" style={{display:'none'}}/>
                                        <div className="primary-upload">
                                            <span className="ico">
                                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="28" height="28" rx="6" fill="#EA8207"></rect>
                                                <path d="M14 17V9C14 8.46957 13.7893 7.96086 13.4142 7.58579C13.0391 7.21071 12.5304 7 12 7H9C8.46957 7 7.96086 7.21071 7.58579 7.58579C7.21071 7.96086 7 8.46957 7 9V19C7 19.5304 7.21071 20.0391 7.58579 20.4142C7.96086 20.7893 8.46957 21 9 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V9C21 8.46957 20.7893 7.96086 20.4142 7.58579C20.0391 7.21071 19.5304 7 19 7H16" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M10 14L13.5 17L17 14" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </span>
                                        </div>
                                    </label>
                                </div>
                                <input className="input-custom__input" type="text" placeholder="type or load existing saved brand voice profiles/prompts"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 mb-[20px] global-setting-miltiselect">
                        <SelectBox
                            label="Select which product page you want to turn off"  
                            isMulti={true}      
                            propsOptions = {[{label: "Wild Life", value: "Wild Life"}, {label: "Select Life", value: "Select Life"}, {label: "Life", value: "Life"}]}
                        ></SelectBox>
                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsGlobal