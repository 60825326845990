export const categoryOptions = [
    {value: '', label: 'Select category', placeholder: true},
    {value: 'emotions', label: 'Emotions'},
    {value: 'nature', label: 'Nature'},
    {value: 'technology', label: 'Technology'},
    {value: 'art', label: 'Art'},
    {value: 'connections', label: 'Connections'},
    {value: 'wellness', label: 'Wellness'},
]

export const styleOptions = [
    {value: '', label: 'Select style', placeholder: true},
    {value: 'formal', label: 'Formal'},
    {value: 'informal', label: 'Informal'},
    {value: 'playful', label: 'Playful'},
    {value: 'elegant', label: 'Elegant'},
    {value: 'poetic', label: 'Poetic'},
    {value: 'serious', label: 'Serious'},
]

export const languageOptions = [
    {value: '', label: 'Select language', placeholder: true},
    {value: 'english', label: 'English'},
    {value: 'french', label: 'French'},
    {value: 'german', label: 'German'},
    {value: 'italian', label: 'Italian'},
    {value: 'spanish', label: 'Spanish'},
]

export const toneOptions = [
    {value: '', label: 'Select tone', placeholder: true},
    {value: 'informative', label: 'Informative'},
    {value: 'conversational', label: 'Conversational'},
    {value: 'inspirational', label: 'Inspirational'},
    {value: 'humorous', label: 'Humorous'},
    {value: 'thoughtful', label: 'Thoughtful'},
]

export const partSpeechOptions = [
    {value: '', label: 'Select part of speech', placeholder: true},
    {value: 'noun', label: 'Noun'},
    {value: 'verb', label: 'Verb'},
    {value: 'adjective', label: 'Adjective'},
    {value: 'adverb', label: 'Adverb'},
    {value: 'preposition', label: 'Preposition'},
    {value: 'conjunction', label: 'Conjunction'},
    {value: 'interjection', label: 'Interjection'},
]

export const botToneOptions = [
    {value: '', label: 'Select bot tone', placeholder: true},
    {value: 'Formal', label: 'Formal'},
    {value: 'Friendly', label: 'Friendly'},
    {value: 'Professional', label: 'Professional'},
    {value: 'Educational', label: 'Educational'},
    {value: 'Supportive', label: 'Supportive'},
    {value: 'Balanced', label: 'Balanced'},
]

export const botBrandToneOptions = [
    {value: '', label: 'Select brand tone', placeholder: true},
]

export const botLengthOptions = [
    {value: '', label: 'Select response length', placeholder: true},
    {value: 'Short', label: 'Short'},
    {value: 'Medium', label: 'Medium'},
    {value: 'Long', label: 'Long'},
]

export const productCategoryOptions = [
    {value: '', label: 'Select product category', placeholder: true},
    {value: 'Option 1', label: 'Option 1'},
    {value: 'Option 2', label: 'Option 2'},
    {value: 'Option 3', label: 'Option 3'},
]

export const natureOfProductOptions = [
    {value: '', label: 'Select nature of product', placeholder: true},
    {value: 'handmade', label: 'Handmade'},
    {value: 'digital', label: 'Digital'},
    {value: 'supplier', label: 'Supplier'},
    {value: 'vintage', label: 'Vintage'},
    {value: 'other', label: 'Other'},
]